import React, { useState } from "react";
import { Col, Row, Typography, Card, Form, Input, Button } from "antd";
// import { MinusCircleOutlined } from '@ant-design/icons';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const { Text } = Typography;

const WhoElseOnTeam = (props) => {
  const { skip, setBusinessContactForm, businessContactForm } = props;
  const [emailErrors, setEmailErrors] = useState("");

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setBusinessContactForm({
      ...businessContactForm,
      contact_email: emailInput === "" ? "NIL" : emailInput,
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailInput || !emailRegex.test(emailInput)) {
      setEmailErrors("Please enter a valid email address.");
    } else {
      setEmailErrors("");
    }
  };

  const handleNameChange = (e) => {
    setBusinessContactForm({
      ...businessContactForm,
      contact_name: e.target.value === "" ? "NIL" : e.target.value,
    });
  };

  const phonehandler = (e) => {
    // debugger
    // console.log(e);
    setBusinessContactForm({ ...businessContactForm, contact_phone: e });
  };

  return (
    <div>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Text className="bestdescribetext">Who else is on your team?</Text>
        </Col>
        <Col span={24} className="welcomebestdescriberow">
          <Text className="welcomebestdescribetext">
            Please share your managers or any other representative names and
            phone numbers
          </Text>
        </Col>

        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Card className="aboutbusinesscard">
            <Form
              {...formLayout}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size={"large"}
              // onValuesChange={handleFormValuesChange}
              className="typography"
            >
              <Form.Item className="skipsteponb">
                <Button
                  type="text"
                  onClick={skip}
                  className="skipsteponbbutton"
                  // icon={<PlusOutlined />}
                >
                  Skip this step
                </Button>
              </Form.Item>
              <Form.Item
                label={
                  <label className="typography aboutbusinessformlabel">
                    Name of Representative (Surname first)
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                }
              >
                <Input
                  placeholder="e.g John Doe"
                  type="name"
                  value={businessContactForm.name}
                  onChange={handleNameChange}
                />
              </Form.Item>

              <Form.Item
                label={
                  <label className="typography aboutbusinessformlabel">
                    Email Address of Representative{" "}
                    {/* <span style={{ color: "red" }}>*</span>{" "} */}
                  </label>
                }
                className="aboutbusinessformitem"
              >
                <Input
                  placeholder="e.g johndoe@gmail.com"
                  type="email"
                  value={businessContactForm.email}
                  onChange={handleEmailChange}
                />
              </Form.Item>
              {emailErrors && <p className="error">{emailErrors}</p>}

              <Form.Item
                label={
                  <label className="typography aboutbusinessformlabel">
                    Phone Number 
                    {/* <span style={{ color: "red" }}>*</span>{" "} */}
                  </label>
                }
                className="aboutbusinessformitem"
              >
                <PhoneInput
                  // inputComponent
                  //   style={{ backgroundColor: 'white', height: '7vh' }}
                  international
                  defaultCountry="NG"
                  autoComplete="tel"
                  type="tel"
                  //   value={phone}
                  placeholder="8060616263"
                  className="rounded rounded-full bg-gray-100 py-1 px-2 text-gray-700 shadow-sm border-none"
                  onChange={phonehandler}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default WhoElseOnTeam;
