import React from "react";
import { Col, Row, Spin, Typography } from "antd";
import RadioCard from "./RadioCard";

const { Text } = Typography;

const BestDescribeOnb = (props) => {
  const {
    setSelectedOption,
    selectedOption,
    handleRadioChange,
    usertypePostingData,
  } = props;

  return (
    <>
      <Spin spinning={usertypePostingData} tip="Loading...">
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Text className="bestdescribetext">
              Which of this best describes you?
            </Text>
          </Col>
          <Col span={24} className="welcomebestdescriberow">
            <Text className="welcomebestdescribetext">
              Welcome! we want to know you better so we can provide the best
              experience for you.
            </Text>
          </Col>

          <Col span={24} className="bestdescribeoptionrow">
            <RadioCard
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              handleRadioChange={handleRadioChange}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
};
export default BestDescribeOnb;
