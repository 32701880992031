import React, { useEffect, useState } from "react";
import { Layout, theme, Row, Col, Button } from "antd";
import nibblesuserdashblogo from "../../asset/nibblesuserdashblogo.jpg";
import { LocationIcon } from "../../asset/icons/icons";
import moment from "moment";
import "./queue.css";
// import ProcessingQueueOrders from "./queueProcessing";
import QueueAvailableForPickup from "./queueAvailableForPickUp";
import ViewAllQueueOrders from "./queueViewAll";
import { getAllQueueOrders } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { getOrderReducer } from "../../redux/Reducer/orderReducer";
const { Header, Content } = Layout;

const Queue = (props) => {
  const { selectedKey } = props;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { allQueueOrders, isloadingAllQueueOrders } =
    useSelector(getOrderReducer);
  const [selectedOrderCat, setSelectedOrderCat] = useState(2);
  // eslint-disable-next-line no-unused-vars
  const [tabKey, setTabKey] = useState(4);
  const dispatch = useDispatch();

  // Function to refresh orders every 15 minutes
  const startAutoRefresh = () => {
    setInterval(dispatch(getAllQueueOrders()), 15 * 60 * 1000); // 15 minutes
  };

  useEffect(() => {
    // Fetch orders when the component mounts
    dispatch(getAllQueueOrders());

    // Start auto-refresh when the component mounts
    startAutoRefresh();

    // Cleanup interval on component unmount
    return () => clearInterval(startAutoRefresh);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSelectedOrderCatP = () => {
  //   // debugger;
  //   setSelectedOrderCat(1);
  //   setTabKey(3);
  // };

  const handleSelectedOrderCatA = () => {
    // debugger;
    setSelectedOrderCat(2);
    setTabKey(4);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSelectedOrderCatV = () => {
    // debugger;
    setSelectedOrderCat(3);
    setTabKey(4);
  };

  // Get today's date
  const today = moment();
  const formattedDate = today.format("Do [of] MMMM YYYY");

  // console.log(allQueueOrders, "allQueueOrders");

  const renderContent = () => {
    switch (selectedOrderCat) {
      // case 1:
      //   return (
      //     <ProcessingQueueOrders
      //       allAdminOrdersByStatusId={allAdminOrdersByStatusId}
      //       isloadingAllAdminOrdersByStatusId={
      //         isloadingAllAdminOrdersByStatusId
      //       }
      //     />
      //   );
      case 2:
        return (
          <QueueAvailableForPickup
            allAdminOrdersByStatusId={allQueueOrders}
            isloadingAllAdminOrdersByStatusId={isloadingAllQueueOrders}
          />
        );
      case 3:
        return (
          <ViewAllQueueOrders
            allAdminOrdersByStatusId={allQueueOrders}
            isloadingAllAdminOrdersByStatusId={isloadingAllQueueOrders}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        {/* <Row> */}
        {selectedKey === "12" ? (
          ""
        ) : (
          <div>
            <div className="queueheader">
              <div className="queueheaderlogo">
                <img
                  src={nibblesuserdashblogo}
                  alt="NibblesLogo"
                  // style={{ width: collapsed ? "80px" : "", marginBottom: "8px" }}
                />
              </div>
              <div
              // className="headercol1"
              >
                <p className="headercoldtext2">
                  <LocationIcon /> Lagos, Nigeria
                </p>
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <SearchBox
                placeholder={"Search here"}
                onChange={(e) => handleSearch(e.target.value)}
                value={searchValue}
                width="100%"
                hoverwidth={"35em"}
              />
            </div> */}
          </div>
        )}
        {/* </Row> */}
      </Header>
      <Row className="queueordertabs">
        <Col span={24}>
          <p className="queueordertitle">Orders for Today ({formattedDate})</p>
          <div className="queueorderbuttonsdiv">
            {/* <Button
              type="text"
              className={
                selectedOrderCat === 1
                  ? "queueorderbuttonsselected"
                  : "queueorderbuttons"
              }
              shape="round"
              size={"large"}
              onClick={handleSelectedOrderCatP}
            >
              Processing
            </Button> */}
            <Button
              type="text"
              shape="round"
              size={"large"}
              className={
                selectedOrderCat === 2
                  ? "queueorderbuttonsselected"
                  : "queueorderbuttons"
              }
              onClick={handleSelectedOrderCatA}
            >
              Available for Pickup
            </Button>
            {/* <Button
              type="text"
              shape="round"
              size={"large"}
              className={
                selectedOrderCat === 3
                  ? "queueorderbuttonsselected"
                  : "queueorderbuttons"
              }
              onClick={handleSelectedOrderCatV}
            >
              View All
            </Button> */}
          </div>
        </Col>
      </Row>
      <Content
        style={{
          padding: "25px 48px",
        }}
      >
        {/* <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {renderContent()}
        </div>
      </Content>
    </Layout>
  );
};
export default Queue;
