// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBQ6ElB2n2o1-d2WYTeN-PclQDU7Bd37hY",
    authDomain: "nibblesbakery.firebaseapp.com",
    projectId: "nibblesbakery",
    storageBucket: "nibblesbakery.appspot.com",
    messagingSenderId: "199184547064",
    appId: "1:199184547064:web:27edefeba0ee5d6289cf0d",
    measurementId: "G-R8K59BE3N8"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestPermission = () => {
    console.log("Requesting user permission...");
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
  
        getToken(messaging, { vapidKey: "BG4UXAEneV26bJ_m618ZwUS0kfJndwSg-WPN6a2vOVVJFBbOCB3vu8ybJI1KqjUgv6OtHRY6ahv5B-iyn2Ul8e0" })
          .then(currentToken => {
            if (currentToken) {
              console.log("Client token:", currentToken);
            } else {
              console.log("Failed to generate the app registration token.");
            }
          })
          .catch(err => {
            console.log("An error occurred while requesting the token.", err);
          });
      } else {
        console.log("Notification permission denied.");
      }
    });
  };

requestPermission();

export const onMessageListener = () =>
    new Promise(resolve => {
        onMessage(messaging, payload => {
            resolve(payload);
        });
    });