import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Button, Form, Input } from 'antd';
// import nibbleslogo from "../../asset/nibbleslogo.jpg"
import nibbleslogo from "../../asset/nibbles-logo.svg";
import "./onboarding.css";
// import { ArrowLeftIcon, GoogleIcon, PhoneIcon, SendIcon } from "../../asset/icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getNewPassword, signOutUser, updateUserStatus } from "../../redux/Actions";


const { Title, Text } = Typography;

const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

export default function NewPassword() {
    const navigate = useNavigate()
    const { newPassworddetail, newpasswordLoading, authUser, statusUpdated, updatingStatus } = useSelector(getAuthReducer)
    const dispatch = useDispatch()
    const forgotpasswordemail = JSON.parse(localStorage.getItem("forgotpasswordemail"))
    const isCustomer = authUser?.user?.user_type_id !== 2 && authUser?.user_type_id!==1
    const isAdmin =  authUser?.user?.user_type_id === 2 || authUser?.user?.user_type_id === 1
    const [newPasswordDetails, setNewPasswordDetails] = useState({
        password: '',
        confirmPassword: "",
        email: isCustomer? (forgotpasswordemail === null || undefined ? "" : forgotpasswordemail): authUser?.user?.email,
    });
    const [newPasswordErrors, setNewPasswordErrors] = useState({
        password: '',
        confirmPassword: "",
    });
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };



    const handlePasswordChange = (e) => {
        const passwordInput = e.target.value
        setNewPasswordDetails({ ...newPasswordDetails, password: passwordInput })

        // Validate password field
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
        if (!passwordInput || !passwordRegex.test(passwordInput)) {
            let errorMsg = 'Password must meet the following criteria:';
            if (passwordInput && passwordInput.length < 6) {
                errorMsg += ' be at least 6 characters long,';
            }
            if (passwordInput && !/(?=.*[!@#$%^&*()_+])/.test(passwordInput)) {
                errorMsg += ' include at least one symbol,';
            }
            if (passwordInput && !/(?=.*[a-z])/.test(passwordInput)) {
                errorMsg += ' include at least one lowercase letter,';
            }
            if (passwordInput && !/(?=.*[A-Z])/.test(passwordInput)) {
                errorMsg += ' include at least one uppercase letter,';
            }
            if (passwordInput && !/(?=.*\d)/.test(passwordInput)) {
                errorMsg += ' include at least one digit,';
            }
            errorMsg = errorMsg.slice(0, -1); // Remove the last comma
            setNewPasswordErrors((prevErrors) => ({ ...prevErrors, password: errorMsg }));
        } else {
            setNewPasswordErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;

        // Validate the confirm password
        const newErrors = validateConfirmPassword(newConfirmPassword);

        // Set the confirm password and errors state
        setNewPasswordDetails({ ...newPasswordDetails, confirmPassword: newConfirmPassword })
        setNewPasswordErrors((prevErrors) => ({ ...prevErrors, confirmPassword: newErrors }));
    };

    const validateConfirmPassword = (confirmPassword) => {
        return confirmPassword !== newPasswordDetails.password ? "Passwords does not match." : "";
    };

    const handleNewPassword = (e) => {
        // debugger
        dispatch(getNewPassword(newPasswordDetails))
        if(isAdmin){
            dispatch(updateUserStatus(authUser?.user?.id))
        }

        // if (authUser=== undefined) {
        //     // debugger
        //     return null
        // } else if (authUser?.user?.user_type === null ) {
        //     navigate("/onboarding")
        // } else if (authUser?.user?.user_type !== null ) {
        //     navigate("/dashboard")
        // } else {
        //     return null
        // }
        // console.log(newPasswordDetails, "inhandlesignin")
    };

  
    useEffect(() => {
        // debugger
        if (newPassworddetail === true && isCustomer) {
            navigate("/signin")
        } else if(isAdmin && newPassworddetail===true && statusUpdated){
            dispatch(signOutUser())
            navigate("/signin")

        }else{

        }

        //   return () => {
        //     dispatch(resetUserStatus)
        //   }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPassworddetail, statusUpdated])


    return (
        <>
            <Row>

                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartedbackground">

                </Col>

                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartefirstrowlogo">
                    <Row className="getstartedrowlogo">
                        <Col span={24} className="getstartedcollogo">
                            <img alt="nibbleslogo" src={nibbleslogo} draggable={false} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className="getstartedcolpad">
                            {/* <div> */}
                            <Title className="typography getstartedtext" style={{ marginBottom: "0px" }} level={1}>Change Password</Title>
                            <Text className="joinbakery typography">Enter your new password</Text>
                        </Col>

                        <Col span={24} className="getstartedcolpad">
                            <Form
                                {...formLayout}
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                size={"large"}
                                // onValuesChange={handleFormValuesChange}
                                className="typography"
                            >

                                <Col xs={{ span: 24 }}>
                                    <Form.Item
                                        label={<label className="typography fw-bold">New Password <span style={{ color: 'red', marginBottom: '2em' }}>*</span></label>}
                                        name="password"
                                        rules={[
                                            {
                                                // required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            type='password'
                                            value={newPasswordDetails.password}
                                            onChange={handlePasswordChange}
                                            placeholder="Enter your password"
                                        />
                                    </Form.Item>
                                    {newPasswordErrors.password && <p className="error typography mb-3">Password: {newPasswordErrors.password}</p>}
                                </Col>


                                <Col xs={{ span: 24 }}>
                                    <Form.Item
                                        label={<label className="typography fw-bold">Confirm Password <span style={{ color: 'red', marginBottom: '2em' }}>*</span></label>}
                                        name="confirmPassword"
                                        rules={[
                                            {
                                                // required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            type='confirmpassword'
                                            value={newPasswordDetails.confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            placeholder="Enter your password"
                                        />
                                    </Form.Item>
                                    {newPasswordErrors.confirmPassword && <p className="error">{newPasswordErrors.confirmPassword}</p>}
                                    <Link to={"/signin"} style={{ textDecoration: "none", color: "#252A4D" }}>
                                        <p className="typography text-end mb-4">Back to Login</p>
                                    </Link>
                                </Col>

                                <Col xs={{ span: 24 }}>
                                    {/* <Form.Item> */}
                                    {/* <Link to={"/enterotp"} style={{textDecoration: "none"}}> */}
                                    <Button onClick={handleNewPassword} className="signupbutton typography" htmlType="submit" disabled={newPasswordDetails.password === "" || newPasswordDetails.confirmPassword === "" || newPasswordErrors.confirmPassword !== "" || newPasswordErrors.password !== "" || newpasswordLoading || updatingStatus} block>
                                        {newpasswordLoading || updatingStatus ? 'Loading...' : 'Change Password'}
                                    </Button>
                                    {/* </Link> */}
                                    {/* </Form.Item> */}
                                </Col>
                            </Form>


                        </Col>

                    </Row>
                </Col>
            </Row>
        </>
    );
}
