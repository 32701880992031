import React from 'react';
import { Col, Row } from 'antd';



const ComNotification = () => {

    return (
        <>
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col className="gutter-row" span={24}>
                    <Col className='dashboardtitlescol' span={24}>
                        <p className='dashboardtitlestypog'>
                            Nofication
                        </p>
                    </Col>
                </Col>
            </Row>
        </>
    )
};
export default ComNotification;