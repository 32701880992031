import React, { useEffect, useState } from "react";
import {
  ShoppingCartOutlined, UsergroupDeleteOutlined,
  InteractionOutlined, DeleteOutlined, StopOutlined, PlusSquareOutlined, PlusOutlined, BranchesOutlined, BuildOutlined, OrderedListOutlined, QuestionCircleOutlined, RightSquareOutlined
} from "@ant-design/icons";
// import nibbleslogo from "../../asset/nibblesdashboardlogo.jpg";
import nibbleslogo from "../../asset/nibbles-logo.svg";
import {
  Layout,
  Menu,
  theme,
  Row,
  Col,
  Divider,
  Avatar,
  notification,
  Button,
  Grid,
  Badge,
  Dropdown,
  Input,
  Typography,
} from "antd";
import {
  AnalyticsIcon,
  ApplicationsIcon,
  AvatarDefaultIcon,
  BriefCaseIcon,
  DashboardIcon,
  EmailIcon,
  HandWave,
  // ClusterOutlined,
  LocationIcon,
  LogoutIcon,
  MoneyIcon,
  NotificationIcon,
  OrderIcon,
  // OrderHistoryIcon,
  PendingOrdersIcon,
  UserIcon,
} from "../../asset/icons/icons";
import "./dashboard.css";
// import OrderHistoryDashboard from './orderHistoryDashboard';
// import ProductSetup from '../admin_module/dashboard/productSetup';
import ProductTypeSetup from "../admin_module/dashboard/productTypeSetup";
import CategorySetup from "../admin_module/dashboard/categorySetup";
import Application from "../admin_module/dashboard/application";
import TransactionLog from "../admin_module/dashboard/transactionLog";
import EmployeeManagement from "../admin_module/dashboard/userManagement";
import PromoManagement from "../admin_module/dashboard/promoManagement";
import OrderManagement from "../admin_module/dashboard/orderManagement";
import AvailableForPickup from "../admin_module/dashboard/availableForPickup";
import ArchivedOrders from "../admin_module/dashboard/archivedOrders";
import RejectedOrders from "../admin_module/dashboard/rejectedOrders";
import AdminDashboardContent from "../admin_module/dashboard/productManagement";
import { useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { Logout } from "../../Utils/logout";
import DistributorCustomers from "../admin_module/dashboard/distributorCustomers";
import RetailerCustomers from "../admin_module/dashboard/retailerCustomers";
import ReportingAnalysis from "../admin_module/dashboard/reportingAnalysisDashboard";
import ExclusiveCustomers from "../admin_module/dashboard/exclusiveCustomers";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Preloader from "../../Components/Preloader/Preloader";
import NotFound from "../NotFound/NotFound";
import Queue from "../queue/queue";
import GetHelp from "./getHelp";
import CreateAdminOrder from "../admin_module/dashboard/orderProcessing/createAdminOrders";

const { useBreakpoint } = Grid;



const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const AdminDashboard = (props) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { setConfettiActive, confettiActive } = props;
  const [selectedKey, setSelectedKey] = useState("");
  const { authUser } = useSelector(getAuthReducer);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true)
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const permissions = authUser?.user?.roles?.permissions ?? [];
  const avatar = authUser?.user?.avatar ?? <AvatarDefaultIcon />;



  const hasPermission = (permissionId) => {
    return permissions.some(perm => perm.permission_id === permissionId && perm.status === 1);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  useEffect(() => {
    // debugger
    // Check user's permissions and set the default selected key accordingly see utils folder/permissionDoc.txt
    if (permissions.some(perm => perm.permission_id === 1 && perm.status === 1)) {
      setSelectedKey("1");
    } else if (permissions.some(perm => perm.permission_id === 15 && perm.status === 1)) {
      setSelectedKey("CreateOrder");
    } else if (permissions.some(perm => perm.permission_id === 6 && perm.status === 1)) {
      setSelectedKey("CreateOrder");
    }
    else if (permissions.some(perm => perm.permission_id === 12 && perm.status === 1)) {
      setSelectedKey("CreateOrder");
    } else if (permissions.some(perm => perm.permission_id === 9 && perm.status === 1)) {
      setSelectedKey("Processing");
    } else if (permissions.some(perm => perm.permission_id === 10 && perm.status === 1)) {
      setSelectedKey("Available");
    }
    else if (permissions.some(perm => perm.permission_id === 11 && perm.status === 1)) {
      setSelectedKey("Rejected");
    } else if (permissions.some(perm => perm.permission_id === 8 && perm.status === 1)) {
      setSelectedKey("8");
    } else if (permissions.some(perm => perm.permission_id === 5 && perm.status === 1)) {
      setSelectedKey("4");
    } else if (permissions.some(perm => perm.permission_id === 7 && perm.status === 1)) {
      setSelectedKey("IndependentDistributors");
    } else if (permissions.some(perm => perm.permission_id === 2 && perm.status === 1)) {
      setSelectedKey("ReportingAnalysis")
    } else if (permissions.some(perm => perm.permission_id === 4 && perm.status === 1)) {
      setSelectedKey("3");
    }
    else if (permissions.some(perm => perm.permission_id === 3 && perm.status === 1)) {
      setSelectedKey("Producttypesetup");
    } else if (permissions.some(perm => perm.permission_id === 13 && perm.status === 1)) {
      setSelectedKey("12");
    }
    else if (permissions.some(perm => perm.permission_id === 14 && perm.status === 1)) {
      setSelectedKey("getHelp");
    } else {
      setSelectedKey("NotFound");
    }
    // Add more conditions as needed for other default selections
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const openNotification = () => {
    api.open({
      message: "Notifications",
      description:
        "No notification available.",
      duration: 4,
    });
  };
  const handleMenuSelect = (key) => {
    setSelectedKey(key);
  };
  // const [collapsed, setCollapsed] = useState(false);

  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };
  const screens = useBreakpoint();
  const isMd = screens.lg;

  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        if (hasPermission(1)) {
          return <AdminDashboardContent />;
        } else {
          return <>"1"</>;
        }
      case "2":
        if (hasPermission(3)) {
          return <ProductTypeSetup />;
        } else {
          return <NotFound />;
        }
      case "3":
        if (hasPermission(4)) {
          return <EmployeeManagement setSelectedKey={setSelectedKey} />;
        } else {
          return <NotFound />;
        }
      case "4":
        if (hasPermission(5)) {
          return <Application />;
        } else {
          return <NotFound />;
        }
      case "6":
        if (hasPermission(7)) {
          return <DistributorCustomers />;
        } else {
          return <NotFound />;
        }
      case "7":
        return <PromoManagement />;
      case "8":
        if (hasPermission(8)) {
          return <TransactionLog />;
        } else {
          return <NotFound />;
        }
      case "9":
        return <PromoManagement />;
      case "10":
        if (hasPermission(8)) {
          return <TransactionLog />;
        } else {
          return <NotFound />;
        }
      case "11":
        if (hasPermission(6) || (hasPermission(9)) || (hasPermission(10)) || (hasPermission(11)) || (hasPermission(12)) || (hasPermission(15))) {
          return <OrderManagement />;
        } else {
          return <NotFound />;
        };
      case "12":
        if (hasPermission(13)) {
          return <Queue selectedKey={selectedKey} />;
        } else {
          return <NotFound />;
        };

      case "getHelp":
        if (hasPermission(14)) {
          return <GetHelp selectedKey={selectedKey} />;
        } else {
          return <NotFound />;
        };
      case "CreateOrder":
        if (hasPermission(6) || (hasPermission(15)) || (hasPermission(12))) {
          return <CreateAdminOrder setConfettiActive={setConfettiActive} confettiActive={confettiActive} />;
        } else {
          return <NotFound />;
        };
      case "Processing":
        if (hasPermission(6) || (hasPermission(9)) || (hasPermission(12))) {
          return <OrderManagement setConfettiActive={setConfettiActive} confettiActive={confettiActive} />;
        } else {
          return <NotFound />;
        };
      case "Available":
        if (hasPermission(6) || (hasPermission(10)) || (hasPermission(12))) {
          return <AvailableForPickup />;
        } else {
          return <NotFound />;
        };
      case "Rejected":
        if (hasPermission(6) || (hasPermission(11)) || (hasPermission(12))) {
          return <RejectedOrders />;
        } else {
          return <NotFound />;
        };
      case "Archived":
        if (hasPermission(6) || (hasPermission(10)) || (hasPermission(12))) {
          return <ArchivedOrders />;
        } else {
          return <NotFound />;
        }
      case "IndependentDistributors":
        if (hasPermission(7)) {
          return <DistributorCustomers />;
        } else {
          return <NotFound />;
        }
      case "Retailers":
        if (hasPermission(7)) {
          return <RetailerCustomers />;
        } else {
          return <NotFound />;
        }
      case "Exclusive":
        if (hasPermission(7)) {
          return <ExclusiveCustomers />;
        } else {
          return <NotFound />;
        }
      case "ReportingAnalysis":
        if (hasPermission(2)) {
          return <ReportingAnalysis />;
        } else {
          return <NotFound />;
        }
      case "Producttypesetup":
        if (hasPermission(3)) {
          return <ProductTypeSetup />;
        } else {
          return <NotFound />;
        }
      case "CategoryManagement":
        if (hasPermission(3)) {
          return <CategorySetup />;
        } else {
          return <NotFound />;
        }
      case "NotFound":
        return <NotFound />
      default:
        return null;
    }
  };




  const items = [
    {
      label: (
        <div className="d-flex align-items-center gap-2">
          <div>
            <Avatar size={40} src={avatar} alt="My Profile" />
          </div>
          <div>
            <div style={{ fontWeight: 500, color: "#333333" }}>{authUser?.user?.name}</div>
            <div><Badge status={isOnline ? "success" : "error"} text={isOnline ? "Online" : "Offline - No Network"} /></div>
          </div>
        </div>
      ),
      key: '0',

    },
    {
      label: (
        <>
          <div className="rounded-2 d-flex flex-column gap-2" style={{ border: "1px solid #F7F7F7" }}>
            <Input
              prefix={<EmailIcon />}
              readOnly
              value={authUser?.user?.email}
              style={{ border: "none", backgroundColor: "#FCFCFC" }}
              className="input-profile"
            />
            <Input
              prefix={<UserIcon color={"#5F5F6B"} size={90} />}
              readOnly
              value={authUser?.user?.roles?.name}
              style={{ border: "none", backgroundColor: "#FCFCFC" }}
              className="input-profile"
            />
            <Input
              prefix={<LocationIcon />}
              readOnly
              value={authUser?.user?.location?.name}
              style={{ border: "none", backgroundColor: "#FCFCFC" }}
              className="input-profile"
            />
          </div>
          <Divider />
        </>
      ),
      key: '1',
      disabled: true,
    },
    {
      label: (
        <>
          <div className="d-flex" onClick={Logout} style={{ padding: "10px 10px 10px 0px" }}>
            <LogoutIcon color={"#5F5F6B"} />
            <Typography>Log out</Typography>
          </div>
        </>
      ),
      key: '2',
    }
  ];
  return (
    <>
      {loading ?
        <Preloader /> :
        <Layout style={{ marginLeft: isMd ? 280 : 0, minHeight: "100vh" }}>
          {contextHolder}

          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            width="280"
            // collapsed={!isDesktop}
            onBreakpoint={(broken) => {
            }}
            onCollapse={(collapsed, type) => {
            }}
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
              background: "#2F355B",
              zIndex: 1000,
              bottom: 0,
              top: 0
              // overflowY: 'auto', 
            }}
          >
            <SimpleBar style={{ maxHeight: "100%" }} className="custom-scroll">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2em 3em 3em 2em" }}>
                <img
                  src={nibbleslogo}
                  alt="NibblesLogo"
                />
              </div>
              <div style={{ padding: "0 16px" }} />
              <Menu
                theme="default"
                mode="inline"
                defaultSelectedKeys={[selectedKey]}
                selectedKeys={[selectedKey]}
                onSelect={({ key }) => handleMenuSelect(key)}
                // style={{ padding: "0em 2em 2em 2em" }}
                style={{ padding: "0em 1em 0em 0.5em" }}
              >
                {/* <div> */}
                {hasPermission(1) && (
                  <Menu.Item
                    key="1"
                    icon={
                      <PendingOrdersIcon color={selectedKey === "1" ? "#FFF" : "#FFF"} />
                    }
                    style={{
                      backgroundColor: selectedKey === "1" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "1" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                    }}
                    className="typography"
                  >
                    Dashboard
                  </Menu.Item>)}
                {hasPermission(6) || (hasPermission(12)) ? (
                  <SubMenu
                    key="5"
                    icon={
                      <OrderIcon
                        color={selectedKey === "5" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor: selectedKey === "5" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "5" ? "#FFF" : "#FFF",
                      paddingLeft: "0.2em",
                      gap: "9.167px",
                    }}
                    className="typography submenuadmindb"
                    title="Orders"
                  >
                    <Menu.Item
                      key="CreateOrder"
                      icon={
                        <RightSquareOutlined
                          color={selectedKey === "CreateOrder" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "CreateOrder" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "CreateOrder" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Create Order
                    </Menu.Item>
                    <Menu.Item
                      key="Processing"
                      icon={
                        <InteractionOutlined
                          color={selectedKey === "Processing" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Processing" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "Processing" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Processing Orders
                    </Menu.Item>
                    <Menu.Item
                      key="Available"
                      icon={
                        <ShoppingCartOutlined
                          color={selectedKey === "Available" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Available" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "Available" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Available for Pickup
                    </Menu.Item>
                    <Menu.Item
                      key="Rejected"
                      icon={
                        <StopOutlined
                          color={selectedKey === "Rejected" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Rejected" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "Rejected" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Rejected Orders
                    </Menu.Item>
                    <Menu.Item
                      key="Archived"
                      icon={
                        <DeleteOutlined
                          color={selectedKey === "Archived" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Archived" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "Archived" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Archived Orders
                    </Menu.Item>
                  </SubMenu>) : hasPermission(9) || hasPermission(10) || hasPermission(11) || hasPermission(15) ? (
                    <SubMenu
                      key="5"
                      icon={
                        <OrderIcon
                          color={selectedKey === "5" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor: selectedKey === "5" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "5" ? "#FFF" : "#FFF",
                        paddingLeft: "0.2em",
                        gap: "9.167px",
                      }}
                      className="typography submenuadmindb"
                      title="Orders"
                    >
                      {hasPermission(15) &&

                        <Menu.Item
                          key="CreateOrder"
                          icon={
                            <RightSquareOutlined
                              color={selectedKey === "CreateOrder" ? "#FFF" : "#FFF"}
                            />
                          }
                          style={{
                            backgroundColor:
                              selectedKey === "CreateOrder" ? "#1e2554" : "#2F355B",
                            color: selectedKey === "CreateOrder" ? "#FFF" : "#FFF",
                            padding: "2em",
                            gap: "9.167px",
                          }}
                          className="typography"
                        >
                          Create Order
                        </Menu.Item>
                      }

                      {hasPermission(9) && <>
                        <Menu.Item
                          key="Processing"
                          icon={
                            <InteractionOutlined
                              color={selectedKey === "Processing" ? "#FFF" : "#FFF"}
                            />
                          }
                          style={{
                            backgroundColor:
                              selectedKey === "Processing" ? "#1e2554" : "#2F355B",
                            color: selectedKey === "Processing" ? "#FFF" : "#FFF",
                            padding: "2em",
                            gap: "9.167px",
                          }}
                          className="typography"
                        >
                          Processing Orders
                        </Menu.Item></>
                      }

                      {hasPermission(10) && (
                        <>
                          <Menu.Item
                            key="Available"
                            icon={
                              <ShoppingCartOutlined
                                color={selectedKey === "Available" ? "#FFF" : "#FFF"}
                              />
                            }
                            style={{
                              backgroundColor:
                                selectedKey === "Available" ? "#1e2554" : "#2F355B",
                              color: selectedKey === "Available" ? "#FFF" : "#FFF",
                              padding: "2em",
                              gap: "9.167px",
                            }}
                            className="typography"
                          >
                            Available for Pickup
                          </Menu.Item>
                          <Menu.Item
                            key="Archived"
                            icon={
                              <DeleteOutlined
                                color={selectedKey === "Archived" ? "#FFF" : "#FFF"}
                              />
                            }
                            style={{
                              backgroundColor:
                                selectedKey === "Archived" ? "#1e2554" : "#2F355B",
                              color: selectedKey === "Archived" ? "#FFF" : "#FFF",
                              padding: "2em",
                              gap: "9.167px",
                            }}
                            className="typography"
                          >
                            Archived Orders
                          </Menu.Item>
                        </>)}

                      {hasPermission(11) && (
                        <Menu.Item
                          key="Rejected"
                          icon={
                            <StopOutlined
                              color={selectedKey === "Rejected" ? "#FFF" : "#FFF"}
                            />
                          }
                          style={{
                            backgroundColor:
                              selectedKey === "Rejected" ? "#1e2554" : "#2F355B",
                            color: selectedKey === "Rejected" ? "#FFF" : "#FFF",
                            padding: "2em",
                            gap: "9.167px",
                          }}
                          className="typography"
                        >
                          Rejected Orders
                        </Menu.Item>)}

                    </SubMenu>) : ""}


                {hasPermission(8) && (
                  <Menu.Item
                    key="8"
                    icon={
                      <MoneyIcon
                        color={selectedKey === "8" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor: selectedKey === "8" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "8" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                    }}
                    className="typography"
                  >
                    Transaction Logs
                  </Menu.Item>)}
                {hasPermission(5) && (
                  <Menu.Item
                    key="4"
                    icon={
                      <ApplicationsIcon
                        color={selectedKey === "4" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor: selectedKey === "4" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "4" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                    }}
                    className="typography"
                  >
                    Applications
                  </Menu.Item>)}
                {hasPermission(7) && (
                  <SubMenu
                    key="6"
                    icon={
                      <UserIcon
                        color={selectedKey === "6" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor: selectedKey === "6" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "6" ? "#FFF" : "#FFF",
                      paddingLeft: "0.2em",
                      gap: "9.167px",
                    }}
                    className="typography submenuadmindb"
                    title="Customers"
                  >
                    <Menu.Item
                      key="IndependentDistributors"
                      icon={
                        <UsergroupDeleteOutlined
                          color={
                            selectedKey === "IndependentDistributors"
                              ? "#FFF"
                              : "#FFF"
                          }
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "IndependentDistributors" ? "#1e2554" : "#2F355B",
                        color:
                          selectedKey === "IndependentDistributors"
                            ? "#FFF"
                            : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Independent distributors
                    </Menu.Item>
                    <Menu.Item
                      key="Retailers"
                      icon={
                        <BuildOutlined
                          color={selectedKey === "Retailers" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Retailers" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "Retailers" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Retailers
                    </Menu.Item>
                    <Menu.Item
                      key="Exclusive"
                      icon={
                        <BranchesOutlined
                          color={selectedKey === "Exclusive" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Exclusive" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "Exclusive" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Direct Customers
                    </Menu.Item>
                  </SubMenu>)}

                {hasPermission(2) && (
                  <Menu.Item
                    key="ReportingAnalysis"
                    icon={
                      <AnalyticsIcon
                        color={selectedKey === "ReportingAnalysis" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor:
                        selectedKey === "ReportingAnalysis" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "ReportingAnalysis" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                    }}
                    className="typography"
                  >
                    Reporting Analysis
                  </Menu.Item>
                )}
                {hasPermission(4) && (
                  <Menu.Item
                    key="3"
                    icon={
                      <BriefCaseIcon
                        color={selectedKey === "3" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor: selectedKey === "3" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "3" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                    }}
                    className="typography"
                  >
                    Employee Management
                  </Menu.Item>)}
                {hasPermission(3) && (
                  <SubMenu
                    key="2"
                    icon={
                      <DashboardIcon
                        color={selectedKey === "2" ? "#FFF" : "#FFF"}
                      />
                    }
                    style={{
                      backgroundColor: selectedKey === "2" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "2" ? "#FFF" : "#FFF",
                      paddingLeft: "0.2em",

                    }}
                    className="typography submenuadmindb"
                    title="Product Management"
                  >
                    <Menu.Item
                      key="Producttypesetup"
                      icon={
                        <PlusOutlined
                          color={
                            selectedKey === "Producttypesetup"
                              ? "#FFF"
                              : "#FFF"
                          }
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "Producttypesetup"
                            ? "#1e2554" : "#2F355B",
                        color:
                          selectedKey === "Producttypesetup"
                            ? "#FFF"
                            : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Category
                    </Menu.Item>
                    <Menu.Item
                      key="CategoryManagement"
                      icon={
                        <PlusSquareOutlined
                          color={selectedKey === "CategoryManagement" ? "#FFF" : "#FFF"}
                        />
                      }
                      style={{
                        backgroundColor:
                          selectedKey === "CategoryManagement" ? "#1e2554" : "#2F355B",
                        color: selectedKey === "CategoryManagement" ? "#FFF" : "#FFF",
                        padding: "2em",
                        gap: "9.167px",
                      }}
                      className="typography"
                    >
                      Product
                    </Menu.Item>
                  </SubMenu>)}




                {/* <Menu.Item
        key="7"
        icon={
          <ThunderboltOutlined
            color={selectedKey === "7" ? "#FFF" : "#FFF"}
          />
        }
        style={{
          backgroundColor: selectedKey === "7" ? "#28303F" : "#2F355B",
          color: selectedKey === "7" ? "#FFF" : "#FFF",
          padding: "2em",
          gap: "9.167px",
        }}
        className="typography"
      >
        Promos
      </Menu.Item> */}

                {/* <Menu.Item
        key="9"
        icon={
          <OrderHistoryIcon
            color={selectedKey === "9" ? "#FFF" : "#FFF"}
          />
        }
        style={{
          backgroundColor: selectedKey === "9" ? "#2F355B" : "#F9F9F9",
          color: selectedKey === "9" ? "#FFF" : "#53545C",
          padding: "2em",
          gap: "9.167px",
        }}
        className="typography"
      >
        Audit Trail
      </Menu.Item> */}
                {hasPermission(13) && (
                  <Menu.Item
                    key="12"
                    icon={
                      <OrderedListOutlined color={selectedKey === "12" ? "#1C1D22" : "#FFF"} />
                    }
                    style={{
                      backgroundColor:
                        selectedKey === "12" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "12" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                      marginTop: "10vh",
                    }}
                    className="typography"
                  >
                    Queue
                  </Menu.Item>)}
                {hasPermission(14) && (
                  <Menu.Item
                    key="getHelp"
                    icon={
                      <QuestionCircleOutlined color={selectedKey === "getHelp" ? "#1C1D22" : "#FFF"} />
                    }
                    style={{
                      backgroundColor:
                        selectedKey === "getHelp" ? "#1e2554" : "#2F355B",
                      color: selectedKey === "getHelp" ? "#FFF" : "#FFF",
                      padding: "2em",
                      gap: "9.167px",
                    }}
                    className="typography"
                  >
                    Complaint / Feedback
                  </Menu.Item>)}
                <Menu.Item
                  key="13"
                  icon={
                    <LogoutIcon
                      color={selectedKey === "13" ? "#1C1D22" : "#FFF"}
                    />
                  }
                  style={{
                    backgroundColor:
                      selectedKey === "13" ? "#1e2554" : "#2F355B",
                    color: selectedKey === "13" ? "#FFF" : "#FFF",
                    padding: "2em",
                    gap: "9.167px",
                  }}
                  className="typography"
                  onClick={Logout}
                >
                  Log Out
                </Menu.Item>
              </Menu>


            </SimpleBar>

          </Sider>
          <Layout>
            <Header
              style={{
                padding: '0 16px',
                backgroundColor: "rgba(255,255,255,0.7)",
                position: "fixed",
                width: isMd ? `calc(100% - 280px)` : "100%",
                left: isMd ? 280 : 0,
                zIndex: 999,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)",
                display: 'flex',
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                <Col
                  xs={24}
                  md={16}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <p className="headercoldtext1" style={{ margin: 0 }}>
                    {selectedKey === 2
                      ? "Pending Orders"
                      : selectedKey === 3
                        ? "Order History"
                        : <span className="d-flex align-items-center gap-1">Welcome, {authUser?.user?.name}<HandWave /></span>}
                  </p>
                  <Divider type="vertical" style={{ height: '1.5em', margin: '0 16px' }} />
                  <div className="d-flex align-items-center">
                    <LocationIcon />
                    <p className="headercoldtext2" style={{ margin: 0 }}>

                      {authUser?.user.location.name}, Nigeria
                    </p>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={8}
                  style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <Button type="text" onClick={openNotification} style={{ margin: '0px' }}>
                    <NotificationIcon />
                  </Button>
                  <Dropdown menu={{ items }} dropdownRender={(menu) => (
                    <div>
                      {React.cloneElement(menu, {
                        style: { width: "280px" },
                      })}
                      <Divider
                        style={{
                          margin: 0,
                        }}
                      />

                    </div>
                  )}

                    trigger={['click']} placement="bottomLeft" arrow>
                    <Button onClick={(e) => e.preventDefault()} type="text" style={{ margin: '0px', height: "fit-content", position: "relative" }}>

                      <Badge dot color={isOnline === true ? "green" : "red"} style={{ position: 'absolute', top: '4px', right: '4px' }}>
                        <Avatar
                          size="medium"
                          icon={<img src={avatar} alt="My Profile" />}
                        />
                      </Badge>
                    </Button>

                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                margin: "24px 1px 0",
                overflowX: "hidden"
              }}
            >
              <div
                style={{
                  padding: 24,
                  minHeight: "80vh",
                  width: "100%",
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,

                }}
              >
                {/* <UserDashboardContent /> */}
                {renderContent()}
              </div>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              Nibbles Bakery ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>}
    </>
  );
};
export default AdminDashboard;
