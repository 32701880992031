import React from "react";
import { Col, Row, Typography, Button } from 'antd';
import nibbleslogo from "../../asset/nibbleslogo.jpg"
import "./onboarding.css";
import { ArrowLeftIcon, GoogleIcon, PhoneIcon, SendIcon } from "../../asset/icons/icons";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

export default function GetStartedPage() {
  return (
    <>
      <Row>

        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartedbackground">

        </Col>

        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartefirstrowlogo">
          <Row className="getstartedrowlogo">
            <Col span={24} className="getstartedcollogo">
              <img alt="nibbleslogo" src={nibbleslogo} />
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpad">
              {/* <div> */}
              <Title className="typography getstartedtext" style={{ marginBottom: "0px" }} level={1}>Get Started</Title>
              <Text className="joinbakery typography">Join our bakery's community of happy customers. Sign up today!</Text>
            </Col>

            <Col span={24} className="getstartedcolpad">

              <Button block size={"large"} className="buttonicons typography"><p><GoogleIcon /> <span style={{ marginLeft: "1em" }}>Sign up with Google</span> </p> <p className="buttonendicons"><ArrowLeftIcon /></p></Button>

              <Button block size={"large"} className="buttonicons typography"><p><PhoneIcon /> <span style={{ marginLeft: "1em" }}>Sign up with Phone Number</span> </p> <p className="buttonendicons"><ArrowLeftIcon /></p></Button>

              <Link to={"signup"} style={{textDecoration: "none"}}>
              <Button block size={"large"} className="buttonicons typography"><p><SendIcon /> <span style={{ marginLeft: "1em" }}>Sign up with Email Address</span> </p> <p className="buttonendicons"><ArrowLeftIcon /></p></Button>
              </Link>
            </Col>


          </Row>

          <Row>
            <Col span={24} className="getstartedcolpad">
              <Text className="joinbakery typography">Already have an account? <Link to={"signin"} style={{ color: "#998C16", textDecoration: "underline" }} className="signinlink">Sign in</Link></Text>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpadterms">
              <Text className="terms">By signing up, you agree to the <Link to={"#"} style={{ color: "#252A4D" }} className="signinlink">Terms of Service</Link> and <Link to={"#"} style={{ color: "#252A4D" }} className="signinlink"> Privacy Policy </Link></Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
