import axios from "axios";
import { message } from 'antd';
// import { getLoggedInUser } from '../helpers/authUtils';
let aPIRootUrl = "";
const env = process.env.REACT_APP_NODE_ENV;
switch (env) {
  // @ts-ignore
  case "local":
    aPIRootUrl = process.env.REACT_APP_API_BASE_LOCAL_URL;
    break;
  case "development":
    aPIRootUrl = process.env.REACT_APP_API_BASE_DEV_URL;
    break;
  case "production":
    aPIRootUrl = process.env.REACT_APP_API_BASE_PROD_URL;
    break;
  default:
    aPIRootUrl = "";
}
const api = axios.create({
  baseURL: aPIRootUrl,
  headers: {
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': "Content-Type, Authorization",
    // "Content-Type": "application/json",
  },
});
let logoutTimer = null;
api.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.access_token) {
    config.headers.Authorization = `Bearer ${user.access_token}`;
    // Reset the logout timer when a new token is received
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("expirationTime");
      window.location.reload();
    }, 5 * 60 * 60 * 1000); // 5 hours
  }
  return config;
});
// Interceptor to handle response
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // debugger;
    const { response } = error;
    if (response && response.data && response.data.message === 'Unauthenticated.') {
      // Show a message to the user
      message.error('Your session has expired. Please sign in again.');

      // Redirect to sign-in page
      window.location.href = '/signin'; // Replace '/signin' with your actual sign-in route

      // Optional: You can also clear user data from localStorage here
      localStorage.removeItem('user');
      localStorage.removeItem('expirationTime');
    }
    return Promise.reject(error);
  }
);
// api.interceptors.request.use((config) => {
//   const user = JSON.parse(localStorage.getItem('user'));
//   if (user && user.token && config.url !== '/api/users/auth') {
//     config.headers.Authorization = `Bearer ${user.token}`;
//   }
//   return config;
// });
// api.interceptors.request.use(function (config) {
//   const state = typeof store !== 'undefined' ? Store.getState() : { auth: {} };
//   const authUser = state.auth.authUser;
//   const token = authUser === null ? '' : authUser.token;
//   config.headers.Authorization = `Bearer ${token}`;
//   return config
// });
export default api;