import React, { useEffect, useState } from 'react';
import { Tabs, Modal, Input, Form, Upload, message, Select, Switch, Tag  } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getAuthReducer } from '../../../redux/Reducer/authReducer';
import axios from 'axios';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(value);
};

const createCat = {
  color: '#131523',
  textAlign: 'center',
  fontFamily: '"MADE TOMMY"',
  fontSize: '24.101px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '33.742px',
};

const createCatDes = {
  color: '#5A607F',
  textAlign: 'center',
  fontFamily: '"MADE TOMMY"',
  fontSize: '19.281px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28.921px',
};

const createCatButton = {
  width: '209.68px',
  height: '48.202px',
  flexShrink: 0,
  borderRadius: '4.82px',
  background: '#252A4D',
};

const buttonText = {
  color: '#FFF',
  fontFamily: '"MADE TOMMY"',
  fontSize: '19.281px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28.921px',
};

const readMore = {
  color: '#1E5EFF',
  textAlign: 'center',
  fontFamily: '"MADE TOMMY"',
  fontSize: '19.281px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28.921px',
};

const CategorySetup = () => {
  const [productTypes, setProductTypes] = useState([]);
  const [locations, setLocations] = useState([]); // State for locations
  const [userTypes, setUserTypes] = useState([]); // State for user types
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('1');
  const [categoryItemCount, setCategoryItemCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [statusUpdateModalVisible, setStatusUpdateModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [form] = Form.useForm();

  const { authUser } = useSelector(getAuthReducer);

  // Function to fetch product types
  const prodTypes = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/product-types`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch product types");
        }
      })
      .then((data) => {
        setProductTypes(data.data);
        setLoading(false);
        if (data.length > 0) {
          setCategoryItemCount(data[0].products.length);
        }
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
        setLoading(false);
      });
  };

  // Fetch locations
  const fetchLocations = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/locations`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setLocations(data.data))
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  };

  // Fetch user types
  const fetchUserTypes = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/user-types`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Filter the user types to return only items with id 3 and 5
        const filteredUserTypes = data.data.filter(userType => userType.id === 3 || userType.id === 5);
        setUserTypes(filteredUserTypes); // Update state with the filtered data
      })
      .catch((error) => {
        console.error("Error fetching user types:", error);
      });
  };


  useEffect(() => {
    prodTypes();
    fetchLocations(); // Fetch locations
    fetchUserTypes(); // Fetch user types
  
    if (selectedItem) {
      form.setFieldsValue({
        id: selectedItem?.id,
        categoryId: 2,
        productTypeId: selectedItem?.product_type_id,
        locationId: selectedItem?.location_id || null, // Pre-fill location if available
        userTypeId: selectedItem?.user_type_id || null, // Pre-fill user type if available
        name: selectedItem?.name,
        description: selectedItem?.description,
        qty: selectedItem?.qty,
        amount: selectedItem?.amount,
        discount: selectedItem?.discount,
      });
    } else {
      form.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, form]);
  


  const handleTabChange = (key) => {
    setActiveKey(key);
    const selectedProductType = productTypes.find(pt => pt.id.toString() === key);
    if (selectedProductType) {
      setCategoryItemCount(selectedProductType.products.length);
    }
  };

  const handleAddNewItemClick = () => {
    setSelectedItem(null);
    setModalVisible(true);
  };

  const handleEditItemClick = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  const handleDeleteItemClick = (item) => {
    setSelectedItem(item);
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };

  const handleStatusUpdateItemClick = (item) => {
    setSelectedItem(item);
    setStatusUpdateModalVisible(true);
  };

  const handleStatusUpdateModalCancel = () => {
    setStatusUpdateModalVisible(false);
    setSelectedItem(null);
  };


  const handleDeleteItem = (product_id) => {
    const bearerToken = authUser.access_token;
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/products/${product_id}`, {
      method: 'DELETE',
      headers: headers
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(() => {
      const updatedProductTypes = productTypes.map(pt => {
        if (pt.id.toString() === activeKey) {
          return {
            ...pt,
            CategoryItems: pt.CategoryItems.filter(ci => ci.id !== selectedItem.id),
          };
        }
        return pt;
      });
      setProductTypes(updatedProductTypes);
      setCategoryItemCount(categoryItemCount - 1);
      message.success('Product deleted successfully');
      prodTypes();
    })
    .catch((error) => {
      message.error('Error deleting product');
      prodTypes();
    });
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };

  const imageUrls = [];

  const props = {
    multiple: true,
    onChange: async (info) => {
      await Promise.all(info.fileList.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file.originFileObj);
        formData.append("upload_preset", process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/image/upload`,
            formData
          );
          if (response.status === 200) {
            imageUrls.push(response.data.secure_url);
            file.url = response.data.secure_url;
          }
        } catch (err) {
          console.error('Error uploading image:', err);
        }
      }));
    },
  };

  const handleFormSubmit = async (values) => {
    try {
      const submissionData = {
        product_type_id: values.productTypeId,
        location_id: values.locationId, // Add location_id
        user_type_id: values.userTypeId, // Add user_type_id
        category_id: values.categoryId,
        name: values.name,
        description: values.description,
        qty: 0,
        amount: values.amount,
        discount: values.discount,
        images: imageUrls.map(url => ({ url })),
      };

      const url = selectedItem ? `${process.env.REACT_APP_API_BASE_DEV_URL}/products/${values?.id}`: `${process.env.REACT_APP_API_BASE_DEV_URL}/products`;

      const bearerToken = authUser.access_token;
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const method = selectedItem ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Failed to update the product');
      message.success(`Product ${selectedItem ? 'updated' : 'added'} successfully`);
      setModalVisible(false);
      form.resetFields();
      prodTypes();
    } catch (error) {
      console.error('Error:', error);
      message.error(`Error: ${error.message}`);
    }
  };

  const handleDuplicateProduct = async (product) => {
    try {
      // Ensure location_id is sent as an array and images is an array of objects with URLs
      const duplicateProductData = {
        product_type_id: product.product_type_id,
        location_id: Array.isArray(product.location_id) ? product.location_id : [product.location_id],
        user_type_id: product.user_type_id,
        category_id: product.category_id,
        name: `${product.name} - Copy`, // Add "(Duplicate)" to differentiate the product name
        description: product.description,
        qty: product.qty,
        amount: product.amount,
        discount: product.discount,
        // Ensure images is an array of objects, each with a 'url' property
        images: product.images ? product.images.map((image) => ({ url: image.url })) : [],
      };
  
      const bearerToken = authUser.access_token;
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
  
      const response = await fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/products`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(duplicateProductData),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to duplicate the product');
      }
  
      message.success(`Product duplicated successfully!`);
      // Refresh product list after duplicating
      prodTypes();
    } catch (error) {
      console.error('Error duplicating product:', error);
      message.error(`Error: ${error.message}`);
    }
  };
  
  
  


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleStatusUpdateItem = (product_id, status) => {
    try {
      const bearerToken = authUser.access_token;
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      let productStatus = status === 0 ? true : false;

      const requestBody = {
        product_id: product_id,
        status: productStatus
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_DEV_URL}/products/status`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody),
        }
      );

      message.success(`Product ${productStatus ? 'activated ' : 'deactivated '} successfully`);
      prodTypes();
    } catch (error) {
      console.error("Error updating product status:", error);
    }
    setStatusUpdateModalVisible(false);
    setSelectedItem(null);
  };

  const items = productTypes.map((productType) => ({
    key: productType.id.toString(),
    label: productType.name,
    children: (
      <div className="d-flex flex-wrap">
        <div
          className="mx-2 mb-2"
          style={{
            width: '221px',
            height: '405px',
            flexShrink: 0,
            borderRadius: '8px',
            marginTop: '10px',
            border: '1px dashed #2F355B',
            background: '#F9F9F9',
            cursor: 'pointer',
          }}
          onClick={handleAddNewItemClick}
        >
          <div
            className="text-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              height: '100%',
            }}
          >
            <div
              style={{
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '24px',
                background: '#E5E5E5',
              }}
            >
              +
            </div>
            <div
              style={{
                color: '#2F355B',
                textAlign: 'center',
                fontFamily: '"MADE TOMMY"',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '140%',
              }}
            >
              Add new Item
            </div>
          </div>
        </div>
        {productType.products.map((item, i) => (
          <div
            key={i}
            style={{
              width: '221px',
              height: '405px',
              flexShrink: 0,
              borderRadius: '8px',
              border: '1px solid #393C49',
              background: '#F9F9F9',
              marginLeft: i === 0 ? '0' : '8px',
              paddingBottom: '0px',
              marginTop: '10px',
              backgroundColor: item.status === 1 ? '' : 'rgba(250, 95, 95, 0.08)'
            }}
            className='mb-2 mx-2'
          >
            
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '50%',
              }}
            >
              <img
                src={item?.images[0]?.url}
                alt={item?.Name}
                style={{
                  width: '143.945px',
                  height: '143.945px',
                  borderRadius: '50%',
                }}
                className='mt-0'
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '56%',
                justifyContent: 'space-around',
              }}
            >
              <div style={{ height: '88px' }}>
                <div
                  style={{
                    color: '#2F355B',
                    textAlign: 'center',
                    fontFamily: '"MADE TOMMY"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    // lineHeight: '130%',
                    marginBottom: '12px',
                  }}
                >
                  {item.name}
                </div>
                <div
                  className="mb-0"
                  style={{
                    color: '#727272',
                    textAlign: 'center',
                    fontFamily: '"MADE TOMMY"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                  }}
                >
                  {formatCurrency((+(Number(item?.amount ?? 0).toFixed(2)) || 0.0))}
                </div>
                <div className="mb-2 text-center mb-2">
                {/* Display location and user type */}
                {locations
                  .filter((loc) => loc.id === item.location_id)
                  .map((location) => {
                    const userType = userTypes.find((ut) => ut.id === item.user_type_id);
                    return (
                      <Tag key={location.id} color="blue" style={{fontSize: '10px'}}>
                        {location.name} - {userType ? userType.name : 'Unknown User Type'}
                      </Tag>
                    );
                  })}

                {/* Duplicate button */}
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <button
                  className='mb-2'
                    style={{ backgroundColor: '#2F355B', color: '#FFF', border: 'none', padding: '5px 10px', borderRadius: '4px' }}
                    onClick={() => handleDuplicateProduct(item)}
                  >
                    Duplicate
                  </button>
                </div>
              </div>

              </div>
              <div
                style={{
                  width: '221px',
                  height: '52px',
                  flexShrink: 0,
                  borderRadius: '0px 0px 8px 8px',
                  background: '#2F355B',
                  marginBottom: '0px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <EditOutlined
                  style={{ color: '#FFF', fontSize: '16px' }}
                  onClick={() => handleEditItemClick(item)}
                />
  
                <DeleteOutlined style={{ color: '#FFF', fontSize: '16px' }} onClick={() => handleDeleteItemClick(item)} />
  
                <Switch
                  checked={item.status}
                  onChange={() => handleStatusUpdateItemClick(item)}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  style={{ backgroundColor: item.status ? '#52c41a' : '#ff4d4f' }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    ),
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container">
        <div
          style={{
            color: "#45464E",
            fontFamily: '"MADE TOMMY"',
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
          className="mt-5 mb-3"
        >
          Product Set-up
          <span
            style={{
              padding: "4px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              borderRadius: "30px",
              background: "rgba(37, 42, 77, 0.08)",
              color: "#252A4D",
              fontFamily: '"Open Sans"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "18px",
            }}
            className="mx-2"
          >
            {categoryItemCount}
          </span>
        </div>
        {productTypes.length === 0 ? (
          <div className="text-center">
            <div
              className="my-3"
              style={{
                marginTop: "200px",
              }}
            >
              {/* SVG content */}
            </div>
            <div className="my-3" style={createCat}>
              Create your product categories
            </div>
            <div className="my-3" style={createCatDes}>
              Once you create a category, you can add products to it.
            </div>
            <button style={createCatButton}>
              <div className="my-1" style={buttonText}>
                Create Category
              </div>
            </button>
            <div className="my-3" style={readMore}>
              Learn More
            </div>
          </div>
        ) : (
          <Tabs
            type="card"
            size="large"
            items={items}
            onChange={handleTabChange}
            activeKey={activeKey}
          />
        )}
      </div>

    <Modal
      title={selectedItem ? 'Edit Product' : 'Add Product'}
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      onOk={() => form.submit()}
      okText={selectedItem ? 'Update Product' : 'Add Product'}
      cancelText="Cancel"
      width={800}
    >
    <Form form={form} onFinish={handleFormSubmit} layout="vertical">
  <Form.Item name="id" label="id" hidden>
    <Input />
  </Form.Item>

  <div className="row">
    <div className="col">
      <Form.Item
        name="productTypeId"
        label="Product Category"
        rules={[{ required: true, message: 'Please select a product category!' }]}
      >
        <Select placeholder="Select a product category">
          {productTypes.map(type => (
            <Select.Option key={type.id} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>

    <div className="col">
      <Form.Item
        name="locationId"
        label="Location"
        rules={[{ required: true, message: 'Please select one or more locations!' }]} // Validation for multiple locations
      >
        <Select
          mode="multiple" // Enable multiple selection
          placeholder="Select locations"
        >
          {locations.map(location => (
            <Select.Option key={location.id} value={location.id}>
              {location.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>

    <div className="col">
      <Form.Item
        name="userTypeId"
        label="User Type"
        rules={[{ required: true, message: 'Please select a user type!' }]} // Validation for single user type
      >
        <Select placeholder="Select a user type">
          {userTypes.map(userType => (
            <Select.Option key={userType.id} value={userType.id}>
              {userType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  </div>

  <Form.Item
    name="name"
    label="Name"
    rules={[{ required: true, message: 'Please enter the product name!' }]}
  >
    <Input placeholder="Product Name" />
  </Form.Item>

  <Form.Item
    name="description"
    label="Description"
    rules={[{ required: true, message: 'Please enter the product description!' }]}
  >
    <Input.TextArea rows={4} placeholder="Description" />
  </Form.Item>

  <Form.Item
    name="amount"
    label="Amount"
    rules={[{ required: true, message: 'Please enter the product amount!' }]}
  >
    <Input type="number" placeholder="Amount" />
  </Form.Item>

  <Form.Item name="discount" label="Discount (%)">
    <Input type="number" placeholder="Discount" />
  </Form.Item>

  <Form.Item
    name="images"
    label="Upload Images"
    valuePropName="fileList"
    getValueFromEvent={normFile}
    rules={[{ required: true, message: 'Please upload product images!' }]}
    extra="Select multiple images"
  >
    <Upload {...props} listType="picture-card" beforeUpload={() => false}>
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Upload>
  </Form.Item>
</Form>


    </Modal>

      <Modal
        title="Delete Product"
        visible={deleteModalVisible}
        onCancel={handleDeleteModalCancel}
        onOk={()=> handleDeleteItem(selectedItem.id)}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>

      <Modal
        title="Update Product Status"
        visible={statusUpdateModalVisible}
        onCancel={handleStatusUpdateModalCancel}
        onOk={() => handleStatusUpdateItem(selectedItem.id, selectedItem.status)}
        okText="Yes"
        cancelText="Cancel"
      >
        <p>Are you sure you want to {selectedItem?.status ? 'deactivate ' : 'activate '}
           this product?</p>
      </Modal>
    </>
  );
};

export default CategorySetup;
