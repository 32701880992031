import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "antd";
import { BuildingIcon, DialIcon, EmailIcon } from "../../asset/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getAllIndependentDistributorship, getIndependentDistributorNearMe, postAssignedDistributor } from "../../redux/Actions";
import RadioRetailerOptionCard from "../radioRetailerOptionCard";

const ReAssignRetailerModal = (props) => {
  const {
    handleReAssignRetailerCancel,
    reAssignRetailerModalOpen,
    handleReAssignRetailerOk,
    details,
    independentDistributorNearMe,
    setChangeDistributorView,
    changeDistributorView,
  } = props;
  const {
    // isloadingIndependentDistributorNearMe,
    assignDistributor,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [user_type_id, setSelectedOption] = useState("");

  const handleChangeDistributor = () => {
    // debugger;
    setChangeDistributorView(!changeDistributorView);
  };

  const handleChooseDistributor = () => {
    // debugger;
    dispatch(
      postAssignedDistributor({
        distributor_id: user_type_id,
        user_id: details.id,
      })
    );
    setChangeDistributorView(!changeDistributorView);
    dispatch(getAllIndependentDistributorship({ user_type_id: 4 }));
  };

  useEffect(() => {
    // dispatch(getAllIndependentDistributorship({ user_type_id }));
    if (assignDistributor === true) {
      dispatch(getIndependentDistributorNearMe());
    } else {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignDistributor]);
  console.log(assignDistributor, "assignDistributor");
  console.log(independentDistributorNearMe, "assignDistributor");

  return (
    <Modal
      centered
      width={800}
      title={`Re-Assign A Retailer`}
      open={reAssignRetailerModalOpen}
      onOk={handleReAssignRetailerOk}
      onCancel={handleReAssignRetailerCancel}
      styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
      okButtonProps={{ hidden: false }}
    >
      <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
          >
            {changeDistributorView === true ? (
              <Card>
                <div className="mt-2">
                  <p>
                    <BuildingIcon />
                  </p>
                </div>
                {independentDistributorNearMe?.data?.length !== 0 ? (
                  <div>
                    <div>
                      <p className="retailwelcometext">
                        Choose the Nearest Distributor to you
                      </p>
                      <p>
                        Below is a list of all distributors to place your order
                        from
                      </p>
                    </div>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 16 }}
                        lg={{ span: 16 }}
                        xl={{ span: 16 }}
                      >
                        <div>
                          {independentDistributorNearMe?.data?.map(
                            (item, i) => (
                              <RadioRetailerOptionCard
                                setSelectedOption={setSelectedOption}
                                selectedOption={user_type_id}
                                item={item}
                              />
                            )
                          )}
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                      >
                        <div className="retailsupportdiv">
                          <Button
                            type="default"
                            className="queueorderbuttonsselected"
                            shape="round"
                            // size={"large"}
                            onClick={handleChooseDistributor}
                          >
                            Choose Distributor
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <p className="fs-small mt-4">
                      Contact this provider through email that a retailer has been assigned to them 🍞
                    </p>
                  </div>
                ) : (
                  <p className="retailwelcometext">
                    No distributor available for your business location
                  </p>
                )}
              </Card>
            ) : (
              <Card>
                {/* <div className="retailsupportdiv">
                  <Button
                    type="default"
                    className="queueorderbuttonsselected"
                    shape="round"
                    // size={"large"}
                    icon={<EarPhoneIcon />}
                    iconPosition={"end"}
                  >
                    Contact Support
                  </Button>
                </div> */}
                <div className="mt-2">
                  <p>
                    <BuildingIcon />
                  </p>
                </div>
                {details?.distributor !== null ? (
                  <div>
                    <div>
                      <p className="retailwelcometext">
                        {details?.distributor?.business?.name}
                      </p>
                      <p>{details?.distributor?.business?.address}</p>
                    </div>
                    <div className="firstqueuecardcontentdiv">
                      <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<EmailIcon />}
                      >
                        {details?.distributor?.email}
                      </Button>
                      <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<DialIcon />}
                      >
                        {details?.distributor?.business?.office_phone}
                      </Button>
                      <Button
                        hover={false}
                        shape="round"
                        className="retailbutton"
                      >
                        See location on map
                      </Button>
                      {/* <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<InstagramIcon />}
                      >
                        @dazzlingcakesndcream
                      </Button> */}
                    </div>
                    {/* <div className="firstqueuecardcontentdiv mt-3">
                      <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<Facebook />}
                      >
                        @dazzlingcakesndcream
                      </Button>
                      <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<Tiktok />}
                      >
                        @dazzlingcakesndcream
                      </Button>
                      <Button
                        hover={false}
                        shape="round"
                        className="retailbutton"
                      >
                        See location on map
                      </Button>
                    </div> */}
                    <p className="fs-small mt-4">
                      Contact the provider through email, phone number or
                      social media handles to start placing your bread orders 🍞
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="retailwelcometext">
                      Click the Choose Distributor button to assign a
                      distributor to this retailer.
                    </p>
                  </div>
                )}

                <Button
                  onClick={handleChangeDistributor}
                  shape="round"
                  className="retailbutton"
                >
                  {details?.distributor !== null
                    ? "Re-Assign Distributor"
                    : "Choose Distributor"}
                </Button>
              </Card>
            )}
          </Col>
    </Modal>
  );
};
export default ReAssignRetailerModal;
