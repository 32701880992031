/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Select,
  Spin,
  // Input,
  Table,
  Tooltip,
} from "antd";
import ReactFlagsSelect from "react-flags-select";
import { InfoIcon, LocationIcon } from "../../asset/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getBusinessArea,
  getLocalGovernmentByStateId,
  getState,
  postBusinessArea,
} from "../../redux/Actions";
import AOCemptystateimg from "../../asset/AOCemptystateimg.jpg";
import {

  EditOutlined,
} from "@ant-design/icons";
import EditAdminAOCModal from "./editAdminAOCModal";
import { LoadScript } from "@react-google-maps/api";

// Define libraries array outside of the component to keep it static
const libraries = ["places"];

// const { TextArea } = Input;
const AdminChooseAOCModal = (props) => {
  const {
    stateData,
    isloadingStateData,
    localGovernmentData,
    postBusinessAreas,
    postingbusinessAreas,
    businessAreasCompliance,
    isloadingbusinessAreasCompliance,
    updateBusinessAreas,
    // isloadingLocalGovernmentData,
  } = useSelector(getAuthReducer);
  const { userProfileDetails, businessAreasForm, setBusinessAreasForm, isAdminChangeAOCModalOpen, setIsAdminChangeAOCModalOpen } = props;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("NG");
  // const [inputValue, setInputValue] = useState("");

  const [editAOCModal, setEditAOCModal] = useState(false);
  const [aocData, setAocData] = useState({});
  const [options, setOptions] = useState([]);
  const autocompleteServiceRef = useRef(null);
  const handleEditAOC = (data)=>{
    setEditAOCModal(true)
    setAocData(data)
    console.log(data,"Rina");
  }
  const handleCancelAOC = ()=>{
    setEditAOCModal(false)
  }
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel = () => {
    setIsAdminChangeAOCModalOpen(false);
    setBusinessAreasForm({
      // business_id: aocDetails?.business?.id,
      // country: "Nigeria",
      state: "",
      lga: "",
      address: [],
    });
  };

  const handleStateChange = (value, statename) => {
    // debugger
    // console.log(`selected ${value}`);
    dispatch(getLocalGovernmentByStateId(value));
    setBusinessAreasForm({ ...businessAreasForm, state: statename });
  };

  const handleLocalGovernmentChange = (value, lganame) => {
    // console.log(`selected ${value}`);
    setBusinessAreasForm({ ...businessAreasForm, lga: lganame });
  };

  const handleStreetAddressChange = (addresses) => {
    setBusinessAreasForm({ ...businessAreasForm, address: addresses });
  };

  // const handleInputChange = (value) => {
  //   setInputValue(value);
  // };
  

  // const handleInputKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     // Prevent the default behavior of the Enter key
  //     event.preventDefault();
  //     // Add the current input value to the addresses list if it is not empty
  //     const value = inputValue.trim();
  //     if (value) {
  //       handleStreetAddressChange([...businessAreasForm.address, value]);
  //       setInputValue(""); // Clear the input value
  //     }
  //   }
  // };

  // const handleBlur = () => {
  //   // Add the current input value to the addresses list if it is not empty when the input loses focus
  //   const value = inputValue.trim();
  //   if (value) {
  //     handleStreetAddressChange([...businessAreasForm.address, value]);
  //     setInputValue(""); // Clear the input value
  //   }
  // };

  const handleAddLocation = () => {
    // debugger
    dispatch(postBusinessArea({ ...businessAreasForm, business_id: userProfileDetails?.business?.id }));
  };
  // console.log(userProfileDetails)

  useEffect(() => {
    if (postBusinessAreas.status === true) {
      dispatch(getBusinessArea(userProfileDetails?.business?.id));
      setBusinessAreasForm({
        // business_id: aocDetails?.business?.id,
        // country: "Nigeria",
        state: "",
        lga: "",
        address: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postBusinessAreas]);

  useEffect(() => {
    if(isloadingStateData){
      dispatch(getState());
    }
}, [isloadingStateData]);

useEffect(()=>{
  if(updateBusinessAreas?.status === true){
    setEditAOCModal(false)
    dispatch(getBusinessArea(userProfileDetails?.business?.id));
  }
},[updateBusinessAreas?.status])

  const columns = [
    {
      title: "State",
      dataIndex: "state",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Local Government",
      dataIndex: "lga",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Address",
      dataIndex: "address_list",
      // render: (text) => <span className="orderHistory-text">{text}</span>,
      // sorter: {
      //   compare: (a, b) => a.date - b.date,
      //   multiple: 3,
      // },
      key: "addresses",
      render: (addresses) => (
        <ul>
          {addresses?.map((address, index) => (
            <li key={index}>{address}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <Tooltip title="Edit area of coverage">
          <Button onClick={()=>handleEditAOC(record)} type="text" className="orderHistory-view-details">
          <EditOutlined/>
        </Button>
        </Tooltip>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const customNoDataText = () => (
    <div>
      {/* <p>No data available</p> */}
      <img src={AOCemptystateimg} alt="No Data" width={200} />
    </div>
  );

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // const googleplacesoptions = [];
  // for (let i = 10; i < 36; i++) {
  //   googleplacesoptions.push({
  //     value: i.toString(36) + i,
  //     // label: i.toString(36) + i,
  //   });
  // }

  const handlePlaceChanged = (value) => {
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptions(results);
        }
      );
    } else {
      setOptions([]);
    }
  }

  return (
    <>
      <Modal
        title={`Choose Areas of Intended Distribution Coverage for ${userProfileDetails?.business?.name}`}
        open={isAdminChangeAOCModalOpen}
        width={1100}
        centered
        onCancel={handleCancel}
        styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
        footer={
          [
            // <Button key="back" onClick={handleCancel}>
            //   Return
            // </Button>,
            // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            //   Submit
            // </Button>,
            // <Button
            //   //   key="link"
            //   //   href="https://google.com"
            //   //   type="primary"
            //   //   loading={loading}
            //   className="typography"
            //   closable={true}
            //   onClick={handleOk}
            // >
            //   Save Area of Coverage
            // </Button>,
          ]
        }
      >
        <p className="typography text-xxs mt-4 mb-4">
          Add the geographical areas your business/company covers
        </p>

        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <Tooltip title="These are places that this distributor will cover">
            <p className="typography" style={{ color: "#8B8D97" }}>
              Add States and Local goverments{" "}
              <span>
                <InfoIcon color={"#D1D1DB"} />
              </span>
            </p>
            </Tooltip>
            <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <Form layout="vertical">
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Country
                    </label>
                  }
                  className="aboutbusinessformitem"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <ReactFlagsSelect
                    selected={selected}
                    countries={[`NG`]}
                    onSelect={(code) => setSelected(code)}
                    // searchable
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      State
                    </label>
                  }
                  className="aboutbusinessformitem"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {isloadingStateData ? (
                    <Spin size="default" />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select a state"
                      onChange={(value, option) =>
                        handleStateChange(value, option?.label)
                      }
                      filterOption={filterOption}
                      options={stateData?.map((state) => ({
                        value: state.id,
                        label: state.name,
                      }))}
                      size="large"
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      LGA your business covers
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  <Select
                    showSearch
                    placeholder="Select Local Government"
                    filterOption={filterOption}
                    onChange={(value, option) =>
                      handleLocalGovernmentChange(value, option.label)
                    }
                    options={localGovernmentData?.map((localgovernment) => ({
                      value: localgovernment.id,
                      label: localgovernment.name,
                    }))}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Areas you cover in your LGA (Press enter to input multiple areas)
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  {/* <TextArea
                    // value={businessHistoryForm.strategy_brief}
                    onChange={handleStreetAddressChange}
                    placeholder="Enter Street Address"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  /> */}
                  {/* <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    value={businessAreasForm.address}
                    onChange={handleStreetAddressChange}
                    onInputKeyDown={handleInputKeyDown}
                    onSearch={handleInputChange}
                    tokenSeparators={[]}
                    placeholder="Enter addresses and press Enter to separate"
                    dropdownRender={(menu) => <div></div>}
                    onBlur={handleBlur}
                    open={false} // Prevent the dropdown from showing
                  /> */}
                  {/* <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    libraries={["places"]}
                  >
                    <Select
                      mode="multiple"
                      value={businessAreasForm.address}
                      placeholder="Enter a location"
                      notFoundContent={null}
                      onSearch={handlePlaceChanged}
                      onChange={(values) => handleStreetAddressChange(values)}
                      style={{ width: "100%" }}
                      options={options}
                    />
                  </LoadScript> */}

                    {/* LoadScript should only be loaded once */}
                  {window.google ? (
                    <Select
                      mode="multiple"
                      value={businessAreasForm?.address}
                      placeholder="Enter a location"
                      notFoundContent={null}
                      onSearch={handlePlaceChanged}
                      onChange={(values) => handleStreetAddressChange(values)}
                      style={{ width: "100%" }}
                      options={options}
                    />
                  ) : (
                    <LoadScript
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                      libraries={libraries}
                      onLoad={() => console.log('Google Maps API script loaded')}
                      onError={(error) => console.log('Error loading Google Maps API script', error)}
                    />
                  )}
                </Form.Item>
                <div className="addcompliancelocDiv">
                  <Button
                    className="addcomplianceloc"
                    icon={<LocationIcon color={"#FFF"} />}
                    onClick={handleAddLocation}
                    disabled={
                      businessAreasForm.state === "" ||
                      businessAreasForm.lga === "" ||
                      businessAreasForm.address === "" ||
                      postingbusinessAreas
                    }
                  >
                    {postingbusinessAreas ? "Loading..." : "Add Location"}
                  </Button>
                </div>
              </Form>
            </Col>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <p className="typography">Business Area of Coverage</p>
            <Spin spinning={isloadingbusinessAreasCompliance} tip="Loading...">
              <Table
                columns={columns}
                dataSource={businessAreasCompliance.data}
                onChange={onChange}
                locale={{ emptyText: customNoDataText }}
              />
            </Spin>
          </Col>
        </Row>
      </Modal>
      <EditAdminAOCModal open={editAOCModal} onCancel={handleCancelAOC} aocData={aocData} businessAreasForm={businessAreasForm}/>
    </>
  );
};
export default AdminChooseAOCModal;
