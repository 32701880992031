import React, { useEffect, useState } from "react";
import {
  
  Col,
  Row,
  Table,
  Menu,
  Modal,
  Button,
  message
  
} from "antd";
import { PlusOutlined, EditOutlined, MoreOutlined, DeleteOutlined } from "@ant-design/icons";
import "../admin.css";
import ProductTypeModal from "../../../Components/productManagementAdmin/producttypeModal";
import { useSelector } from 'react-redux';
import { getAuthReducer } from '../../../redux/Reducer/authReducer';

const createCat = {
  color: "#131523",
  textAlign: "center",
  fontFamily: '"MADE TOMMY"',
  fontSize: "24.101px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "33.742px", // Corresponds to 140%
};

const createCatDes = {
  color: "#5A607F",
  textAlign: "center",
  fontFamily: '"MADE TOMMY"',
  fontSize: "19.281px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "28.921px",
};

const createCatButton = {
  width: "30%",
  height: "48.202px",
  flexShrink: 0,
  borderRadius: "4.82px",
  background: "var(--New-Primary, #252A4D)", // This will depend on your CSS setup
};

const buttonText = {
  color: "#FFF",
  fontFamily: '"MADE TOMMY"',
  fontSize: "19.281px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "28.921px", // Corresponds to 150%
};

const items = [
  {
    key: 'sub4',
    label: '',
    icon: <MoreOutlined />,
    children: [
      // {
      //   key: 'View',
      //   label: 'View',
      //   icon: <EyeOutlined />,
      // },
      {
        key: 'Edit',
        label: 'Edit',
        icon: <EditOutlined />,
      },
       {
        key: 'Delete',
        label: 'Delete',
        icon: <DeleteOutlined />,
      },
      // {
      //   key: 'Activate',
      //   label: 'Activate',
      //   icon: <SwapOutlined />,
      // },
    ],
  },
];






const ProductTypeSetup = () => {
  const [open, setOpen] = useState(false);
  const [selectedProductAction, setSelectedProductAction] = useState("Add");
  const [productTypes, setProductTypes] = useState([]);

  const [currentProductType, setCurrentProductType] = useState(null);

  const { authUser } = useSelector(getAuthReducer);



  const prodTypes = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/product-types`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setProductTypes(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };


  const createProductType = (name) => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/product-types`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then((response) => {
        if (response.ok) {
          message.success(`Category ${name} has been created successfully`);
          return response.json();
        } else {
          throw new Error("Failed to create category");
        }
      })
      .then((data) => {
        prodTypes(); // Refresh product types
        setOpen(false);
      })
      .catch((error) => {
        console.error("Error creating product type:", error);
      });
  };

  const updateProductType = (id, name) => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/product-types/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then((response) => {
        if (response.ok) {
          message.success(`Category ${name} has been edited successfully`);
          return response.json();
        } else {
          throw new Error("Failed to update product type");
        }
      })
      .then((data) => {
        prodTypes(); // Refresh product types
        setOpen(false);
      })
      .catch((error) => {
        console.error("Error updating product type:", error);
      });
  };

  const deleteProductType = (id) => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/product-types/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {

          message.success(`Category has been deleted successfully`);

          prodTypes(); // Refresh product types
        } else {
          throw new Error("Failed to delete product type");
        }
      })
      .catch((error) => {
        console.error("Error deleting product type:", error);
      });
  };

  useEffect(() => {
    prodTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = productTypes.map((item) => ({
    ...item,
    key: item.id,
  }));

  // const data = productTypes;

  const columns = [
    // {
    //   title: "Icon",
    //   dataIndex: "icon",
    //   render: (text) => <span className="orderHistory-text">{text}</span>,
    //   key: "icon",
    // },
    {
      title: "Category Name",
      dataIndex: "name",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      key: "name",
    },
    {
      title: "No of Products",
      dataIndex: "products",
      render: (products) => <span className="orderHistory-text">{products.length}</span>,
      key: "numberOfItem",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <span className="orderHistory-text">
          <Menu
             onClick={(e) => onProductActionClick(e, record)}
            expandIcon={false}
            style={{
              // width: 10,
              background: 'transparent'
            }}
            mode="auto"
            items={items}
          />
        </span>
      ),
      key: "action",
    },
  ];

  
  const onProductActionClick = (e, record) => {
    if (e.key === "Delete") {
      confirmDelete(record.key);
    } else {
      setCurrentProductType(record);
      setSelectedProductAction(e.key);
      showModal();
    }
  };

  // const confirmDelete = (id) => {
  //   var item = productTypes.find(item => item.id === id)
  //   Modal.confirm({
  //     title: `Are you sure you want to delete this category: ${item.name}?`,
  //     onOk: () => deleteProductType(id),
  //     onCancel: () => console.log('Cancel'),
  //   });
  // };


  const confirmDelete = (id) => {
    var item = productTypes.find(item => item.id === id)

    if(item.products.length !== 0){
      Modal.confirm({
        title: 'Restricted!',
        content: <><p>You can not delete this product category ({item.name}), because there are product(s) attached to it.</p> <p>Please delete the product(s) under this category first.</p></>,
        onCancel: () => console.log('Cancel'),
      });
    }
    else{
      Modal.confirm({
        title: 'Are you sure you want to delete this category?',
        content: <><p>Category name: {item.name}</p> <p>Please ensure that this category has no products attached to it before deleting.</p></>,
        onOk: () => deleteProductType(id),
        onCancel: () => console.log('Cancel'),
      });
    }
   
  };
  
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
    setSelectedProductAction("Add");
    setCurrentProductType(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedProductAction("Add");
    setCurrentProductType(null);
  };


  const onFinish = (values) => {
    if (selectedProductAction === "Edit") {
      updateProductType(currentProductType.key, values.name);
    } else if (selectedProductAction === "Add") {
      createProductType(values.name);
    }
  };

  

  return (
    <>
      <div className="container mt-5">
        <Row>
          <Col span={12}>
            <div
              style={{
                color: "#45464E",
                fontFamily: '"MADE TOMMY"',
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              // className="mt"
            >
              Product Category
              {data.length === 0 ? (
                ""
              ) : (
                <span
                  style={{
                    padding: "4px 12px", // Default fallback if the CSS variable is not applicable
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "30px",
                    background: "rgba(37, 42, 77, 0.08)", // Default fallback
                    color: "#252A4D", // Default fallback
                    fontFamily: '"Open Sans"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "18px", // Typically in React you might use a unitless value for line-height
                  }}
                  className="mx-2"
                >
                  {data.length}
                </span>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                icon={<PlusOutlined />}
                className="createproducttbutton"
                onClick={() => showModal()}
              >
                Create Product Category
              </Button>
              <ProductTypeModal
              
                open={open}
                handleOk={handleOk}
                handleCancel={handleCancel}
                selectedProductAction={selectedProductAction}
                currentProductType={currentProductType}
                onFinish={onFinish}
              />
            </div>
          </Col>
        </Row>

        {data.length === 0 ? (
          <div className="text-center">
            <div
              className="my-3"
              style={{
                marginTop: "200px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="170"
                height="169"
                viewBox="0 0 170 169"
                fill="none"
              >
                <rect
                  opacity="0.01"
                  x="0.490234"
                  width="168.708"
                  height="168.708"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34.2305 85.5586H101.714V148.222H39.0507C36.3886 148.222 34.2305 146.063 34.2305 143.401V85.5586Z"
                  fill="#BDC167"
                />
                <path
                  d="M101.713 85.5586H135.454V143.401C135.454 146.063 133.296 148.222 130.634 148.222H101.713V85.5586Z"
                  fill="#131523"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M101.713 85.5586L116.776 102.429H150.518L135.454 85.5586H101.713Z"
                  fill="#D7DBEC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M102.316 85.5586L87.2532 102.429H18.565L33.6282 85.5586H102.316Z"
                  fill="#D7DBEC"
                />
                <circle cx="84.8433" cy="46.9966" r="26.5112" fill="#BDC167" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M83.8463 39.302L84.7177 40.5897L85.5892 39.302C88.2538 36.6769 91.9406 36.7557 94.6742 39.2691L94.8502 39.4366C97.5148 42.0617 97.5715 46.283 95.0203 48.976L94.8502 49.1494L84.7177 59.0475L74.8329 49.1494C72.1104 46.4673 72.1104 42.1187 74.8329 39.4366C77.5554 36.7545 81.1238 36.6199 83.8463 39.302Z"
                  fill="white"
                />
              </svg>
            </div>
            <div style={createCat}>Create First Product Category</div>
            <div style={createCatDes} className="my-3">
              <div>
                Organize all your items in stock by creating and adding them to
                categories.
              </div>
              <div>
                Categories helps to find items faster for your customers.
              </div>
            </div>
            <div className="my-3">
              <button style={createCatButton}  className="createproducttbutton pt-1"
                onClick={() => showModal()}>
                <svg
                className="mb-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3631 6.26172C16.0287 6.26172 16.5682 6.80124 16.5682 7.46677V13.4909L22.5935 13.4921C23.259 13.4921 23.7985 14.0316 23.7985 14.6971C23.7985 15.3626 23.259 15.9022 22.5935 15.9022L16.5682 15.901V21.9274C16.5682 22.593 16.0287 23.1325 15.3631 23.1325C14.6976 23.1325 14.1581 22.593 14.1581 21.9274V15.901L8.13279 15.9022C7.46726 15.9022 6.92773 15.3626 6.92773 14.6971C6.92773 14.0316 7.46726 13.4921 8.13279 13.4921L14.1581 13.4909V7.46677C14.1581 6.80124 14.6976 6.26172 15.3631 6.26172Z"
                    fill="white"
                  />
                </svg>{" "}
                <span style={buttonText}>Add Product Category</span>
              </button>
              {/* <div style={readMore}>Read More</div> */}
            </div>
          </div>
        ) : (
          <div className="p-3 mt-5 border" style={{ borderRadius: "5px" }}>
            <Table columns={columns} dataSource={data} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProductTypeSetup;
