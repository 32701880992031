import { DatePicker, Form, Radio } from "antd"
import RenderInputText from "./RenderInputText"
import Cleave from "cleave.js/react"

export const RenderRadioButton = ({ colConfig, type, onGenderChange}) => {
  debugger
  if (type === "edit") {
    return (
      <Form.Item name={colConfig.name}>
        <Radio.Group
          onChange={onGenderChange}
        // value={details?.gender}
        >
          <Radio value={"male"}>Male</Radio>
          <Radio value={"female"}>Female</Radio>
        </Radio.Group>
      </Form.Item>
    )
  } else return <RenderInputText colConfig={colConfig} type={type}/>
}

export const RenderDateField = ({ colConfig, type, handleDOBChange, date }) => {
  debugger
  if (type === "edit") {
    debugger
    return (
      <DatePicker
        size="large"
        style={{ width: "100%" }}
        onChange={handleDOBChange}
        format={"DD/MM/YYYY"}
        value={date}
      />
    )
  } else return <RenderInputText colConfig={colConfig} type={type}/>
}

export const RenderNumberField = ({ colconfig,type,details }) => {
  if (type === "edit") {
    return (
      <Form.Item name={colconfig?.name}>
        <div style={{ display: "flex", alignItems: "center", border: "1px solid #d9d9d9", borderRadius: 4, padding: 4 }}>
          <span style={{ marginRight: 8 }}>₦</span>
          <Cleave
            placeholder="e.g 200,000"
            style={{
              width: "100%",
              height: "100%",
              padding: "3%",
              // background: 'transparent',
              border: "0.5px solid #C6C7C9",
              borderRadius: "7px",
              backgroundColor: "white",
            }}
            fullWidth
            value={details?.business?.annual_turnover}
            options={{
              numeral: true,
              numeralThousandsGroupStyle: "thousand",
            }}
            onChange={(e) => { }}
          />
        </div>
      </Form.Item>
    )
  } else return <RenderInputText colConfig={colconfig} type={type} />
}

