import React, { useEffect, useState } from "react";
import {
  Tabs,
  Input,
  Spin,
  Button,
  message,
  Steps,
  Modal,
  Select,
  Form,
  InputNumber,
} from "antd";
// import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getOrderReducer } from "../../../redux/Reducer/orderReducer";
import { getAllAdminOrdersByStatusId } from "../../../redux/Actions";
// import { FormatNumber } from '../../../Utils/cartmgt';
import IncomingOrders from "./orderProcessing/incomingOrders";
import ConfirmedOrders from "./orderProcessing/confirmedOrders";
import ProcessingOrders from "./orderProcessing/processingOrder";
import { getAuthReducer } from "../../../redux/Reducer/authReducer";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { SearchIcon } from "../../../asset/icons/icons";
import { useMatchMedia } from "../../../Utils/media";

const newEmpButton = {
  display: "flex",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#252A4D",
  color: "#FFF",
  fontFamily: '"MADE TOMMY"',
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

const { Option } = Select;

// Utility function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(value);
};

const CreateOrderStep = ({
  onFinish,
  onCancel,
  setConfettiActive,
  confettiActive,
}) => {
  const { authUser } = useSelector(getAuthReducer);
  const [current, setCurrent] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const [initialProducts, setInitialProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: "",
    name: "",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    id: authUser?.user?.location_id,
    name: "",
  });
  const [customerList, setCustomerList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  // const [orderType, setOrderType] = useState('');
  // const [isExpressOrder, setIsExpressOrder] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false);


  useEffect(() => {
    if (confettiActive === true) {
      setCurrent(0);
    }
  }, [confettiActive, current]);

  const products = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/products`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setInitialProducts(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const handleCustomerChange = (value) => {
    const customer = customerList.find((cus) => cus.id === value);
    setSelectedCustomer({
      id: customer.id,
      name: customer.name,
    });
  };

  const handleLocationChange = (value) => {
    const location = locationList.find((loc) => loc.id === value);
    setSelectedLocation({
      id: location.id,
      name: location.name,
    });
  };

  const independentDistributors = () => {
    const bearerToken = authUser.access_token;
    fetch(
      `${process.env.REACT_APP_API_BASE_DEV_URL}/users?user_type_id=3&status=1`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setCustomerList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const locations = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/locations`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setLocationList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  useEffect(() => {
    products();
    independentDistributors();
    locations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = async () => {
    setIsLoadingCreateOrder(true);
    const payload = {
      user_id: selectedCustomer.id,
      location_id: selectedLocation.id,
      is_internal_order: true,
      is_approved: false,
      // "is_express": isExpressOrder,
      items: orderItems.map((item) => ({
        product_id: item.id,
        qty: item.quantity,
      })),
    };

    try {
      const bearerToken = authUser.access_token;
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_DEV_URL}/orders`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        }
      );

      setOrderItems([]);
      setInitialProducts([]);
      // setSelectedCustomer([]);
      setSelectedCustomer({});
      setSelectedLocation({});

      const responseData = await response.json();
      console.log("Order submitted successfully", responseData);
      if (responseData?.status === true) {
        message.success("Order created successfully!");
        setConfettiActive(true);
        setCurrent(0);
      } else {
        message.error(
          `Failed to Create Order, Kindly check your order details and try again!`
        );
      }
      onFinish();
      setIsLoadingCreateOrder(false);
    } catch (error) {
      console.error("Failed to submit order. Please try again", error);
    }
  };

  const handleAddProduct = () => {
    if (selectedProduct && quantity > 0) {
      const newItem = {
        ...selectedProduct,
        quantity,
        subtotal: +Number(selectedProduct?.amount ?? 0).toFixed(2) * quantity,
      };
      setOrderItems([...orderItems, newItem]);
      setSelectedProduct(null);
      setQuantity(1);
    }
  };

  const handleProductSelect = (productId) => {
    const product = initialProducts.find((product) => product.id === productId);
    setSelectedProduct(product);
  };

  const handleQuantityChange = (newQuantity, index) => {
    const updatedItems = orderItems.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          quantity: newQuantity,
          subtotal: item.amount * newQuantity,
        };
      }
      return item;
    });
    setOrderItems(updatedItems);
  };

  const handleDeleteProduct = (index) => {
    const newItems = orderItems.filter((_, idx) => idx !== index);
    setOrderItems(newItems);
  };

  const totalAmount = orderItems.reduce((acc, item) => acc + item.subtotal, 0);

  const steps = [
    {
      title: "Customer",
      content: (
        <>
          <div>
            <div className="row">
              <div className="row">
                <div className="col mt-4">
                  <Form.Item label="Select Customer">
                    <Select
                      value={selectedCustomer.id}
                      onChange={handleCustomerChange}
                    >
                      {customerList.map((cus) => (
                        <Option key={cus.id} value={cus.id}>
                          {cus.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Select Location">
                    <Select
                      value={selectedLocation.id}
                      onChange={handleLocationChange}
                      defaultValue={selectedLocation.id}
                      disabled={authUser.user.roles.name !== "Super Admin" ? true : false}
                    >
                      {locationList.map((loc) => (
                        <Option key={loc.id} value={loc.id}>
                        {loc.name} - {loc.address}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  {/* <div className="col-6">
                    <Form.Item label="Order Type">
                      <Select
                        placeholder="Select order type"
                        value={orderType}
                        onChange={setOrderType}
                      >
                        <Option value="regular">Regular</Option>
                        <Option value="special">Special</Option>
                      </Select>
                    </Form.Item>
                  </div> */}
                  {/* <div className="col-6">
                    <Form.Item label="Express Order">
                      <Checkbox
                        checked={isExpressOrder}
                        onChange={(e) => setIsExpressOrder(e.target.checked)}
                      >
                        Express Order
                      </Checkbox>
                    </Form.Item>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Product",
      content: (
        <>
          <div className="mt-4">
            <h6>Choose product and quantity</h6>
            <div className="border rounded-2 py-2">
              <div className="row bg-light mx-1">
                <div className="col-5 fw-bold">PRODUCT</div>
                <div className="col-2 fw-bold">QUANTITY</div>
                <div className="col-2 fw-bold">PRICE</div>
                <div className="col-2 fw-bold">SUBTOTAL</div>
                <div className="col-1 fw-bold"></div>
              </div>

              {orderItems.map((item, index) => (
                <div className="row mx-1 mt-3" key={index}>
                  <div className="col-5">
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={item.images[0]?.url}
                          width={40}
                          height={35}
                          className="border border-1 rounded-2"
                          alt="product"
                        />{" "}
                      </div>
                      <div className="col-10">{item.name}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <InputNumber
                      min={1}
                      value={item.quantity}
                      onChange={(value) => handleQuantityChange(value, index)}
                    />
                  </div>
                  <div className="col-2">{formatCurrency(item.amount)}</div>
                  <div className="col-2">{formatCurrency(item.subtotal)}</div>
                  <div className="col-1">
                    <span
                      className="text-danger mx-1"
                      onClick={() => handleDeleteProduct(index)}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>
                </div>
              ))}
              <hr className="mb-3" />

              <div className="row mx-1">
                <div className="col-6">
                  <Form.Item>
                    <Select
                      value={selectedProduct?.id || ""}
                      onChange={handleProductSelect}
                    >
                      {initialProducts.map((product) => (
                        <Option key={product.id} value={product.id}>
                          {product.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-2">
                  <Form.Item>
                    <InputNumber
                      min={1}
                      value={quantity}
                      onChange={setQuantity}
                    />
                  </Form.Item>
                </div>
                <div className="col-2">
                  {formatCurrency(
                    +Number(selectedProduct?.amount ?? 0).toFixed(2) || 0.0
                  )}
                </div>
                <div className="col-2">
                  {formatCurrency(
                    (
                      +Number(selectedProduct?.amount ?? 0).toFixed(2) *
                      quantity
                    ).toFixed(2) || 0.0
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  className="mx-2 mb-3 addOrder"
                  style={{ backgroundColor: "#131523", color: "white" }}
                  onClick={handleAddProduct}
                >
                  Click to add product <PlusOutlined />
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-end my-5">
              <span>
                TOTAL AMOUNT:{" "}
                <span className="fw-bold">
                  {formatCurrency(totalAmount.toFixed(2))}
                </span>
              </span>
            </div>

            <div className="d-flex justify-content-start my-5">
              <Form.Item label="Apply discount/Promos" style={{ width: 600 }}>
                <Input placeholder="Input percentage (%) or amount (N) if applicable" />
              </Form.Item>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Review Order",
      content: (
        <>
          <div>
            <div className="row">
              <div className="row">
                <div className="col mt-4">
                  <Form.Item label="Customer">
                    <Input value={selectedCustomer.name} disabled />
                  </Form.Item>

                  <Form.Item label="Customer">
                    <Input value={selectedLocation.name} disabled />
                  </Form.Item>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  {/* <div className="col-6">
                    <Form.Item label="Order Type">
                      <Input value={orderType} readOnly />
                    </Form.Item>
                  </div> */}
                  {/* <div className="col-6">
                    <Form.Item label="Express Order">
                      <Checkbox checked={isExpressOrder} disabled />
                    </Form.Item>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h6>Product details</h6>
            <div className="border rounded-2 py-2">
              <div className="row bg-light mx-1">
                <div className="col-6 fw-bold">PRODUCT</div>
                <div className="col-2 fw-bold">QUANTITY</div>
                <div className="col-2 fw-bold">PRICE</div>
                <div className="col-2 fw-bold">SUBTOTAL</div>
              </div>

              {orderItems.map((item, index) => (
                <div className="row mx-1 mt-3" key={index}>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={item.images[0]?.url}
                          width={40}
                          height={35}
                          className="border border-1 rounded-2"
                          alt="product"
                        />{" "}
                      </div>
                      <div className="col-10">{item.name}</div>
                    </div>
                  </div>
                  <div className="col-2">x{item.quantity}</div>
                  <div className="col-2">{formatCurrency(item.amount)}</div>
                  <div className="col-2">{formatCurrency(item.subtotal)}</div>
                </div>
              ))}

              <div
                className="mt-4 d-flex justify-content-end"
                style={{
                  backgroundColor: "#fffff4",
                  border: "1px 1px 1px solid black",
                }}
              >
                <div className="m-2 mx-3 ">
                  <div className="mb-1">
                    Subtotal:{" "}
                    <span className="" style={{ marginLeft: "57px" }}>
                      {formatCurrency(totalAmount)}
                    </span>
                  </div>
                  <div className="mb-1">
                    Discount: <span style={{ marginLeft: "55px" }}>0%</span>
                  </div>
                  <div>
                    Total Amount{" "}
                    <span className="fw-bold" style={{ marginLeft: "30px" }}>
                      {formatCurrency(totalAmount)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps
        current={current}
        items={steps.map((step) => ({ key: step.title, title: step.title }))}
      />
      <div>{steps[current].content}</div>
      <div className="d-flex justify-content-end" style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button onClick={prev} className="mx-3">
            Back
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={next}>
            Continue
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={handleFinish}
            disabled={isLoadingCreateOrder}
          >
            Done
          </Button>
        )}
        <Button onClick={onCancel} className="mx-3">
          Cancel
        </Button>
      </div>
    </>
  );
};

const OrderManagement = (props) => {
  const { setConfettiActive, confettiActive } = props;
  const [open, setOpen] = useState(false);
  const [tabKey, setTabKey] = useState("1");
  const { allAdminOrdersByStatusId, isloadingAllAdminOrdersByStatusId } =
    useSelector(getOrderReducer);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { authUser } = useSelector(getAuthReducer);
  const permissions = authUser?.user?.roles?.permissions ?? [];

  // eslint-disable-next-line no-unused-vars
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const showCreateOrderModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = allAdminOrdersByStatusId?.data?.filter((item) => {
    // debugger
    const matchesSearchQuery = Object.keys(item).some((key) => {
      const value = item[key];
      if (typeof value === "object" && value !== null) {
        return Object.values(value).some((nestedValue) =>
          nestedValue
            ?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
      return value
        ?.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });

    return matchesSearchQuery;
  });
  
  const hasPermission = (permissionId) => {
    return permissions.some(perm => perm.permission_id === permissionId && perm.status === 1);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };
  const handleCancel = () => {
    setModalVisible(false);
    dispatch(getAllAdminOrdersByStatusId({ order_status_id: tabKey }));
  };
  const handleFinish = () => {
    setModalVisible(false);
    dispatch(getAllAdminOrdersByStatusId({ order_status_id: tabKey }));
  };

  useEffect(() => {
    if (tabKey !== undefined) {
      dispatch(getAllAdminOrdersByStatusId({ order_status_id: tabKey }));
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  const handleAllAdminOrderByStatusIdApi = () => {
    if (tabKey !== undefined) {
      dispatch(getAllAdminOrdersByStatusId({ order_status_id: tabKey }));
    } else {
    }
  };

  const onTabKeyChange = (key) => {
    // console.log(key, "Tab key");
    setTabKey(key);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
    console.log("params", pagination, filters, sorter, extra);
    // dispatch(getAllSystemAdmin(pagination.current));
    dispatch(
      getAllAdminOrdersByStatusId({
        order_status_id: tabKey,
        page: pagination.current,
      })
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const hasSelected = selectedRowKeys.length > 0;

  const items = [
    {
      key: "1",
      label: (
        <div style={{ color: "#252A4D" }}>
          Incoming
          {tabKey === "1" ? (
            <span className="mx-2 tag">
              {allAdminOrdersByStatusId?.meta?.total}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
            <IncomingOrders
              // allAdminOrdersByStatusId={allAdminOrdersByStatusId}
              allAdminOrdersByStatusId={filteredData}
              open={open}
              setOpen={setOpen}
              handleAllAdminOrderByStatusIdApi={
                handleAllAdminOrderByStatusIdApi
              }
              onChange={onChange}
              // Pass other necessary props here
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ color: "#252A4D" }}>
          Confirmed
          {tabKey === "2" ? (
            <span className="mx-2 tag">
              {allAdminOrdersByStatusId?.meta?.total}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
            <ConfirmedOrders
              // allAdminOrdersByStatusId={allAdminOrdersByStatusId}
              allAdminOrdersByStatusId={filteredData}
              open={open}
              setOpen={setOpen}
              handleAllAdminOrderByStatusIdApi={
                handleAllAdminOrderByStatusIdApi
              }
              onChange={onChange}
              // Pass other necessary props here
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ color: "#252A4D" }}>
          Processing
          {tabKey === "3" ? (
            <span className="mx-2 tag">
              {allAdminOrdersByStatusId?.meta?.total}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
            <ProcessingOrders
              // allAdminOrdersByStatusId={allAdminOrdersByStatusId}
              allAdminOrdersByStatusId={filteredData}
              open={open}
              setOpen={setOpen}
              handleAllAdminOrderByStatusIdApi={
                handleAllAdminOrderByStatusIdApi
              }
              onChange={onChange}
              // Pass other necessary props here
            />
          </Spin>
        </div>
      ),
    },
  ];

  const usermanText = {
    width: "189px",
    flexShrink: 0,
    color: "#45464E",
    fontFamily: '"MADE TOMMY"',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px",
  };

  const exportButton = {
    display: "flex",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid var(--Gray-100, #E6E7EC)",
    background: "var(--Backgrounds-Background-White, #FFF)",
    // color: "#FFF",
    fontFamily: '"MADE TOMMY"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
    marginRight: "5px",
  };

  // const filter = {
  //   color: "var(--Black-80, #52575C)",
  //   fontFamily: "Inter",
  //   fontSize: "13.138px",
  //   fontStyle: "normal",
  //   fontWeight: 500,
  //   lineHeight: "22.992px", // 175%
  //   letterSpacing: "-0.145px",
  // };
  // const filterDiv = {
  //   display: "flex",
  //   width: "588.756px",
  //   justifyContent: "flex-end",
  //   alignItems: "flex-start",
  //   gap: "16.423px",
  //   flexShrink: 0
  // };
  // const filterCount = {
  //   borderRadius: "410.569px",
  //   background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
  //   display: "flex",
  //   padding: "3.285px 6.138px 4.423px 6.57px",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   color: "var(--Black-80, #52575C)",
  //   textAlign: "center",
  //   fontFamily: "Inter",
  //   fontSize: "9.854px",
  //   fontStyle: "normal",
  //   fontWeight: 400,
  //   lineHeight: "normal"
  // };

  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = useState();

  // const onCreate = (values) => {
  //   console.log('Received values of form: ', values);
  //   setFormValues(values);
  //   setOpen(false);
  // };

  const isMobileDevice = useMatchMedia("(max-width:450px)", true);

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px", margin: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Orders &gt; Processing Orders</span>
            </div>
            <div className="col d-flex justify-content-end">
            {(hasPermission(6) || (hasPermission(15)) || (hasPermission(12))) &&
              <button
                className="mx-2"
                style={newEmpButton}
                onClick={() => setModalVisible(true)}
              >
                {isMobileDevice ? "New Order" : "Create new order"}
              </button>}
              <button onClick={1} style={exportButton}>
                Export
              </button>
              <pre>{JSON.stringify(formValues, null, 2)}</pre>
            </div>
            <Modal
              title="Create New Order"
              width={700}
              visible={modalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <Form layout="vertical">
                <CreateOrderStep
                  onFinish={handleFinish}
                  onCancel={handleCancel}
                  setConfettiActive={setConfettiActive}
                  confettiActive={confettiActive}
                />
              </Form>
            </Modal>
          </div>
        </div>

        <div className="row mt-5">
          {/* <div className="d-flex justify-content-start">
            <div className="col-3">
              <Input placeholder="Basic usage" />
            </div>
            <div className="col d-flex justify-content-end" style={filterDiv}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                    fill="#52575C"
                  />
                </svg>
              </span>{" "}
              <span style={filter} className="">
                Filter
              </span>
              <span style={filterCount}>0</span>
            </div>
          </div> */}
        </div>

        <div className="d-flex justify-content-between mb-3">
          <div className="col-12 mt-3 col-md-5 col-sm-5">
            <Input
              placeholder={"Search by order id, customer name or location"}
              style={{
                width: "100%",
                border: "none",
                color: "#BECOCA",
                backgroundColor: "#F7F7F7",
                cursor: "text",
              }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>

          <div className="col-12 mt-3 col-md-5 col-sm-5">
            <span>
              <span className="px-2 order-customer-type-legend-text internal-order-text orderHistory-text"></span>
              <span className="fw-bold" style={{ color: "#328835" }}>
                {" "}
                Internal Order{" "}
              </span>
              <span className="px-2 order-customer-type-legend-text direct-customer-text orderHistory-text"></span>{" "}
              <span className="fw-bold" style={{ color: "#7f2da5" }}>
                {" "}
                Direct Customer
              </span>
              <span className="px-2 order-customer-type-legend-text independent-customer-text orderHistory-text"></span>{" "}
              <span className="fw-bold" style={{ color: "#b9892e" }}>
                {" "}
                Distributor
              </span>
            </span>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="border mt-2 p-2 overflow-x-hidden"
          items={items}
          onChange={onTabKeyChange}
        />
      </div>
    </>
  );
};
export default OrderManagement;
