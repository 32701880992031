import React from 'react';
// import Stopwatch from '../../asset/stopwatch.png';

const RenderSide = (props) => {

    const { statusid, item } = props;

    let status = "";


    const startDate = new Date(item?.start_date);
  const endDate = new Date(item?.estimated_end_date);

  let timeDifferenceText = 'Not Available';

  if (!isNaN(startDate) && !isNaN(endDate)) {
    const timeDifference = endDate - startDate;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    timeDifferenceText = `${hours}h ${minutes}m left`;
  }

    switch (statusid) {
        case 1:
            status = "Incoming";
            break;
        case 2:
            status = "Confirmed";
            break;
        case 3:
            status = "Processing";
            break;
        case 4:
            status = "Loading";
            break;
        case 5:
            status = "Archived";
            break;
        case 0:
            status = "Rejected";
            break;
        default:
            return null
    }

    return (
        <>
            <div className="basic-text mb-2 mt-2">CURRENT STATUS</div>
            <div className="border rounded order-proc py-2 mb-4">
                {status}
            </div>

            <div className="hash-id mb-1">#{item.reference}</div>
            <div className="basic-text">Last update: {item.start_date}</div>

            <hr className="my-4" />

            <div className="basic-text my-3 mt-2">CUSTOMER</div>

            <div className="basic-text-bold my-2">{item?.user?.name}</div>
            <div className="basic-text my-2">{item?.user?.email}</div>
            <div className="basic-text my-2">{item?.user?.phone}</div>
            <div className="basic-text my-2">{item?.user?.whatsapp_phone}</div>

            <div className="basic-text mt-4 mb-2">ORDER PLACED</div>
            <div className="basic-text-bold">{item?.start_date}</div>

            <div className="basic-text mt-4 mb-2">EST. COMPLETION DATE</div>
            <div className="basic-text-bold mb-2">{item.estimated_end_date ? item.estimated_end_date : 'Not Available'}</div>

            <div className="basic-text mt-5 mb-2">TIME REMAINING</div>
            <div className="time-left time-left-text">
              {/* <img src={Stopwatch} alt="stopwatch" style={{fontSize: '30px', height: '40px'}} /> */}
                <span className="time-left-text">{item.estimated_end_date ? timeDifferenceText : 'N/A'}</span>
            </div>
        </>
    )
}

export default RenderSide