import React from "react";
import { Button, Card, Col, Row, Spin } from "antd";
import { CountDownTimer, SuccessIcon } from "../../asset/icons/icons";

const ViewAllQueueOrders = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { isloadingAllAdminOrdersByStatusId, allAdminOrdersByStatusId } = props;

  return (
    <div>
      <Card style={{ background: "#F8F8F8" }}>
        <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col span={8}>
              <Button
                className="queueviewallprocessingbutton mb-3"
                hover={false}
                shape="round"
              >
                Preparing Orders
              </Button>
              <Card>
                <div className="firstqueuecardcontentdiv">
                  <div>
                    <p className="fw-bold processingqueuecardorderid">
                      #41523822
                    </p>
                    <p className="processingqueuecardname">Melting Moments</p>
                  </div>
                  <CountDownTimer />
                </div>
                <p className="text-end fw-bold">38 mins remaining</p>
              </Card>
            </Col>

            <Col span={8}>
              <Button
                className="queueviewallavailablebutton mb-3"
                hover={false}
                shape="round"
              >
                Available for Pickup
              </Button>
              <Card>
                <div className="firstqueuecardcontentdiv">
                  <div>
                    <p className="fw-bold processingqueuecardorderid">
                      #41523822
                    </p>
                    <p className="processingqueuecardname">
                      Dazzling Cakes and Cream
                    </p>
                  </div>
                  <SuccessIcon />
                </div>
              </Card>
            </Col>

            <Col span={8}></Col>
          </Row>
        </Spin>
      </Card>
    </div>
  );
};
export default ViewAllQueueOrders;
