import React, { useState } from "react";
import { Button, message, Upload, Modal, Spin } from "antd";
import axios from "axios";
import fileicon from "../asset/fileicon.jpg";
import fileicon2 from "../asset/fileicon2.jpg";
import { useDispatch } from "react-redux";
import { postBusinessDocument } from "../redux/Actions";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const RetailMOIUpload = (props) => {
  const { userbusinessid, meansofId } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();
  const [modalContent, setModalContent] = useState(null);
  const [meansofIdUpload, setMeansofIdUpload] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const showModal = (url) => {
    setModalContent(url);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    let reader = new FileReader();
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "upload_preset",
      process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY
    );
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/auto/upload`,
        formData
      );
      if (response.status === 200) {
        file.url = response.data.secure_url; // Assign the Cloudinary URL to the file object
        setMeansofIdUpload(response.data.secure_url);
        dispatch(
          postBusinessDocument({
            business_id: userbusinessid,
            document_type_id: meansofId,
            url: response.data.secure_url,
          })
        );
        onSuccess("ok");
      } else {
        onError("Upload failed.");
      }
    } catch (err) {
      onError(err);
    }

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const RetailMOIprops = {
    showUploadList: false,
    customRequest: customRequest,
    maxCount: 1,
  };

  const renderModalContent = () => {
    // debugger
    if (!modalContent) return null;

    const fileExtension = modalContent.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      return (
        <Spin spinning={isLoading} tip="Loading..." className="mt-4">
          <iframe
            onLoad={handleIframeLoad}
            src={modalContent}
            // src="https://dash.nibblesbakery.ng/"
            style={{
              width: "100%",
              height: "100vh",
              border: "none",
              overflow: "hidden",
              display: isLoading ? "none" : "block",
            }}
            // frameBorder="0"
            title="PDF Viewer"
          ></iframe>
        </Spin>
      );
    } else if (["doc", "docx", "xls", "xlsx", "txt", "ppt", "pptx"].includes(fileExtension)) {
      return (
        <DocViewer
          documents={[{ uri: modalContent }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    } else {
      return (
        <DocViewer
          documents={[{ uri: modalContent }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    }
  };

  return (
    <>
      {contextHolder}
      <div className="uploadcaccard">
        <div>
          <div>
            <div className="innerUploadcacdiv">
              {meansofIdUpload ? (
                <img
                  src={fileicon2}
                  alt="fileicon"
                  style={{ height: "100%" }}
                  className="mt-2 fileiconcac"
                />
              ) : (
                <img
                  src={fileicon}
                  alt={`Upload Means of Identification`}
                  className="fileiconcac"
                />
              )}
              <div style={{ marginLeft: "2em" }}>
                <p style={{ color: "#000" }} className="mt-2 mb-0 typography">
                  {meansofIdUpload
                    ? "Means of Identification"
                    : "Select a file to upload"}
                </p>
                {meansofIdUpload && (
                  <Button
                    type="text"
                    onClick={() => showModal(meansofIdUpload)}
                    style={{ color: "#1347F5" }}
                    className="mt-0 typography"
                  >
                    View Document
                  </Button>
                )}
                <Modal
                  title="Compliance Document"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  width={800}
                >
                  {renderModalContent()}
                </Modal>
              </div>
            </div>
          </div>
          <Upload {...RetailMOIprops}>
            <Button className="uploadcacbtn mt-2">
              {meansofIdUpload ? "Change Id Document" : "Upload Id Document"}
            </Button>
          </Upload>
        </div>
      </div>
    </>
  );
};
export default RetailMOIUpload;
