import React, { useEffect, useState, useCallback } from 'react'
import DoughnutChart from "../components/chartjs/dough";
import { Select, Card, Skeleton, Tooltip } from 'antd';
import LineChart from "../components/chartjs/line";
import PolarAreaChart from "../components/chartjs/polarArea";
import TopCustomers from "./topCustomers";
import OrderIcon from "../../../asset/OrderIcon.svg";
import ProdType from "../../../asset/ProdType.svg";
import ProdCategory from "../../../asset/ProdCategory.svg";
import Applications from "../../../asset/Applications.svg";
import avgIncome from "../../../asset/avgIncome.png";
import { ReactComponent as Staffavatars } from "../../../asset/Staffavatars.svg"
import { getAuthReducer } from '../../../redux/Reducer/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAllIndependentDistributorship, getPendingApplication } from '../../../redux/Actions';
import { Button } from 'antd';
import { AlarmClockIcon, AppUsageIcon, ArrowRightIcon, NewOrderIcon, NotificationBellIcon, RefundRequestIcon, ViewStaffIcon } from '../../../asset/icons/icons';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';






const AdminDashboardContent = (props) => {
  const { setSelectedKey } = props;
  const { authUser, pendingApplication, allApplicant, isloadingAllApplicant, isloadingPendingApplication } = useSelector(getAuthReducer)

  // eslint-disable-next-line no-unused-vars
    const [user_type_id, setUserTypeId] = useState(3);
    const dispatch = useDispatch();
    const [item, setItem] = useState("");
    const [topCustomers, setTopCustomers] = useState([]);
    const [isLoadingTopCustomers, setLoadingTopCustomers] = useState([]);
    // const [areaChart, setAreaChart] = useState("");
    const [prodCategory, setProdCategory] = useState("");
    const [prodType, setProdType] = useState("");
    const [report, setReport] = useState("");
    const [lineChartData, setLineChartData] = useState("");
    const [totalLabelTopCus, setTotalLabelTopCus] = useState("");
    const [totalLabel, setTotalLabel] = useState("");
    const [adminStaff, setAdminStaff] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const [loadingReport, setLoadingReport] = useState(false);



  // eslint-disable-next-line no-unused-vars
  const [selectedRange, setSelectedRange] = useState('today');
  // eslint-disable-next-line no-unused-vars
  const [selectedRangeTopCus, setSelectedRangeTopCus] = useState('today');
  const [chartData, setChartData] = useState([]);

  const stats = [
    { icon: ProdType, label: 'Product Categories', value: prodType?.length, bgColor: "pink", loading: prodType === "" },
    { icon: ProdCategory, label: 'Number of Products', value: prodCategory?.meta?.total, bgColor: "yellow", loading: prodCategory === "" },
    { icon: Applications, label: 'Applications', value: allApplicant?.meta?.total, bgColor: "blue", loading: isloadingAllApplicant === true || allApplicant?.length === 0 || !allApplicant?.meta?.total },
    { icon: OrderIcon, label: 'Total Orders', value: item?.total_orders, bgColor: "green", loading: item === "" },
  ];



  // Simulated function to fetch data based on the selected range
  const fetchDataForRange = (range) => {
    // Replace this with actual data fetching logic
    switch (range) {
      case 'today':
        return [80, 20, 50, 10];  // Incoming: 80, Processing: 20, Picked up: 50, Rejected: 10
      case 'weekly':
        return [70, 350, 140, 560]; // Incoming: 560, Processing: 140, Picked up: 350, Rejected: 70
      case 'bi-weekly':
        return [1120, 140, 700, 280]; // Incoming: 1120, Processing: 280, Picked up: 700, Rejected: 140
      case 'monthly':
        return [300, 600, 1500, 2400]; // Incoming: 2400, Processing: 600, Picked up: 1500, Rejected: 300
      case 'yearly':
        return [28800, 3600, 18000, 7200]; // Incoming: 28800, Processing: 7200, Picked up: 18000, Rejected: 3600
      default:
        return [0, 0, 0, 0]; // Default case with zero orders
    }
  };



  // const columnsTopCus = [
  //   {
  //     title: 'Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //   },
  //   {
  //     title: 'Type',
  //     dataIndex: 'type',
  //     key: 'type',
  //   },
  //   {
  //     title: 'Total Amount',
  //     dataIndex: 'amount',
  //     key: 'amount',
  //     // render: (text) => `$${text.toLocaleString()}`,
  //   },
  //   {
  //     title: 'Location',
  //     dataIndex: 'location',
  //     key: 'location',
  //   },
  // ];

useEffect(()=>{
  if(report!==""){
    const distributors = parseInt(report?.total_distributor_users)
    const retailers = parseInt(report?.total_retailer_users)
    const exclusive = parseInt(report?.total_exclusive_users)


    setCustomersType([distributors,retailers,exclusive])

  }
},[report])
  useEffect(() => {
    // Fetch and set data when selection changes
    const data = fetchDataForRange(selectedRange);
    setChartData(data);
  }, [selectedRange]);

  const handleRangeChange = (value) => {
    setDateRange(value);
  };

  const handleRangeChangeTopCus = (value) => {
    setDateRangeTopCus(value);
  };


  const [areaChart, setAreaChart] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [dateRange, setDateRange] = useState("today");
  const [dateRangeTopCus, setDateRangeTopCus] = useState("today");



  // let label = '';
  const getDateRange = (range) => {
    let startDate, endDate;
    switch (range) {
      case "today":
        setTotalLabel('Daily');
        startDate = moment().startOf('day').format("YYYY-MM-DD");
        endDate = moment().endOf('day').format("YYYY-MM-DD");
        break;
      case "weekly":

        setTotalLabel('Weekly');

        startDate = moment().startOf('isoWeek').format("YYYY-MM-DD");
        endDate = moment().endOf('isoWeek').format("YYYY-MM-DD");
        break;
      case "bi-weekly":

        setTotalLabel('Bi-Weekly');

        startDate = moment().startOf('isoWeek').subtract(1, 'week').format("YYYY-MM-DD");
        endDate = moment().endOf('isoWeek').format("YYYY-MM-DD");
        break;
      case "monthly":

        setTotalLabel('Monthly');

        startDate = moment().startOf('month').format("YYYY-MM-DD");
        endDate = moment().endOf('month').format("YYYY-MM-DD");
        break;
      case "yearly":

        setTotalLabel('Yearly');

        startDate = moment().startOf('year').format("YYYY-MM-DD");
        endDate = moment().endOf('year').format("YYYY-MM-DD");
        break;
      default:
        startDate = moment().startOf('day').format("YYYY-MM-DD");
        endDate = moment().endOf('day').format("YYYY-MM-DD");
    }
    return { startDate, endDate };
  };


  const getDateRangeTopCus = (range) => {
    let startDate, endDate;
    switch (range) {
      case "today":
        setTotalLabelTopCus('Daily');
        startDate = moment().startOf('day').format("YYYY-MM-DD");
        endDate = moment().endOf('day').format("YYYY-MM-DD");
        break;
      case "weekly":

        setTotalLabelTopCus('Weekly');

        startDate = moment().startOf('isoWeek').format("YYYY-MM-DD");
        endDate = moment().endOf('isoWeek').format("YYYY-MM-DD");
        break;
      case "bi-weekly":

        setTotalLabelTopCus('Bi-Weekly');

        startDate = moment().startOf('isoWeek').subtract(1, 'week').format("YYYY-MM-DD");
        endDate = moment().endOf('isoWeek').format("YYYY-MM-DD");
        break;
      case "monthly":

        setTotalLabelTopCus('Monthly');

        startDate = moment().startOf('month').format("YYYY-MM-DD");
        endDate = moment().endOf('month').format("YYYY-MM-DD");
        break;
      case "yearly":

        setTotalLabelTopCus('Yearly');

        startDate = moment().startOf('year').format("YYYY-MM-DD");
        endDate = moment().endOf('year').format("YYYY-MM-DD");
        break;
      default:
        startDate = moment().startOf('day').format("YYYY-MM-DD");
        endDate = moment().endOf('day').format("YYYY-MM-DD");
    }
    return { startDate, endDate };
  };


  const handleAreaChartData = useCallback(() => {
    setLoadingChart(true)
    const { startDate, endDate } = getDateRange(dateRange);
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/reports/pie-chart-data?start_date=${startDate}&end_date=${endDate}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {

        let chData = [
          data.data.total_pending_orders,
          data.data.total_orders_in_progress,
          data.data.total_completed_orders,
          data.data.total_declined_orders,
        ];

        setChartData(chData);


        setAreaChart(data.data);
        setLoadingChart(false)
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  }, [dateRange, authUser.access_token]);


  const handleTopCustomerData = useCallback(() => {
    const { startDate, endDate } = getDateRangeTopCus(dateRangeTopCus);
    const bearerToken = authUser.access_token;
    setLoadingTopCustomers(true)

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/reports/top-customers?start_date=${startDate}&end_date=${endDate}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setTopCustomers(data.data);
        setLoadingTopCustomers(false)


        // console.log('Top customer ', topCustomers);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
        setLoadingTopCustomers(false)
        setTopCustomers([])
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeTopCus, authUser.access_token]);
    


  const handleLineChartData = useCallback((year) => {
    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/reports/line-chart-data?year=${year}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setLineChartData(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.access_token]);

  useEffect(() => {
    handleLineChartData('2024');
  }, [handleLineChartData]);


  const handleYearChange = (value) => {
    if (value) {
      // console.log('Selected year:', value);
      handleLineChartData(value);
    } else {
      // console.log('No year selected');
    }
  };

  //   // const { startDate, endDate } = getDateRange(dateRange);
  //   const bearerToken = authUser.access_token;

  //   // console.log('chart data', chartData);
  //   // console.log('start date', startDate);
  //   // console.log('end date', endDate);

  //   fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/reports/line-chart-data?year=${year}`, {
  //     headers: {
  //       Authorization: `Bearer ${bearerToken}`,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw new Error("Failed to fetch item details");
  //       }
  //     })
  //     .then((data) => {

  //       setLineChartData(data.data);

  //       console.log('Line chart', lineChartData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching item details:", error);
  //     });
  // };

  useEffect(() => {
    handleAreaChartData();
    
    // eslint-disable-next-line no-unused-vars
  }, [dateRange, handleAreaChartData]);

  useEffect(() => {
    handleTopCustomerData();
    // handleLineChartData('2021');


    // eslint-disable-next-line no-unused-vars
  }, [dateRangeTopCus, handleTopCustomerData]);

  
  const handleReportData = () => {
    setLoadingReport(true)
    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/reports/dashboard`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setReport(data.data);
        setLoadingReport(false)

      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const handleProdCategoryData = () => {

    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/categories`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setProdCategory(data);

      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const handleProdTypeData = () => {

    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/product-types`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setProdType(data.data);

      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const handleAdmin = () => {

    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/users/all/employees?page=1`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setAdminStaff(data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  useEffect(() => {

    handleProdCategoryData();
    handleProdTypeData();
    handleAdmin();
    handleReportData();
    // handleTopCustomersData();
      // handleAPICall();
      // handleLineChartData();
      handleAreaChartData();
      dispatch(getPendingApplication());
      dispatch(getAllIndependentDistributorship({ user_type_id }));
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

  const handleDashboardViewApplication = () => {
    setSelectedKey("4");
  };

  const handleMangeOrder = () => {
    setSelectedKey("11");
  };

  const handleAminStaff = () => {
    setSelectedKey("3");
  };


  useEffect(() => {
    const bearerToken = authUser?.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/reports/dashboard`, {
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch item details');
        }
      })
      .then(data => {

        setItem(data.data);
        console.log('Item details:', data.data);
        // console.log('Item details:', item.id);
      })
      .catch(error => {
        console.error('Error fetching item details:', error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleActiveTab = (activeTab) => {
    setActiveTab(activeTab)
  }
  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const dataset = lineChartData;
  const borderWidth = 2;
  const showLabels = true;



  const labelsDough = ['Returning Customers', 'New Customers'];
  const dataValues = [30, 70];
  const totalDataValues = 100;
  const backgroundColor = ['#F79009', '#6366F1'];
  const borderColor = ['#F79009', '#6366F1'];

  const labelsDoughAppUsage = ['Website', 'Mobile App'];
  const dataValuesAppUsage = [40, 60];
  const backgroundColorAppUsage = ['#BDC167', '#252A4D'];
  const borderColorAppUsage = ['#BDC167', '#252A4D'];

  const labelsDoughUserType =  ['Distributors', 'Retailers', "Direct"];
  const [customersType, setCustomersType] = useState([0,0,0])
  const statsBgColor = ["#06AED4","#F79009","#6366F1"]


  
  // const polarAreaDataset =  [105, 0, 97, 0];


  // const period = [
  //   {
  //     value: 'today',
  //     label: 'Today',
  //   },
  //   {
  //     value: 'weekly',
  //     label: 'Weekly',
  //   },
  //   {
  //     value: 'bi-weekly',
  //     label: 'Bi-Weekly',
  //   },
  //   {
  //     value: 'monthly',
  //     label: 'Monthly',
  //   },
  //   {
  //     value: 'yearly',
  //     label: 'Yearly',
  //   }
  // ]


  return (
    <>
      <div>
        <div className="title my-5">Dashboard</div>

        <div className="notification d-flex justify-content-between">
          <div className="d-flex align-items-center notification-text gap-2">
            {isloadingPendingApplication || pendingApplication?.meta?.total === 0 || !pendingApplication?.meta?.total ? <NotificationBellIcon /> :
              <AlarmClockIcon />}
            {!pendingApplication?.meta?.total ? <div>
              <span>Scanning for pending applications... 🗃️</span>

            </div> :
              <div>
                You have{" "}
                {/* className="fw-bold" */}
                <span>
                  {" "}
                  {pendingApplication?.meta?.total}{" "}
                </span>{" "}
                pending applications awaiting review
              </div>}
          </div>
          <div className='d-flex align-items-center'>
            <div style={{ borderRight: "1.6px solid #DFE4EB", width: "40px", height: "38px" }}>
            </div>
            <Button
              onClick={pendingApplication?.meta?.total > 0 ? handleDashboardViewApplication : null}
              className={pendingApplication?.meta?.total === 0 || !pendingApplication?.meta?.total ? "dashboard-view-btn-disabled" : "dashboard-view-btn"}
            >
              view
            </Button>
          </div>
        </div>

        <Card bordered={true} style={{ width: "100%" }} className="my-5 stats-cards">
          <div className="mt-3">
            <div className="card1-title mb-2">Current Statistics</div>

            <div className="row mt-3">
              {stats.map(item =>
                <div className="col-12 col-md-6 col-lg-3 mb-3">
                  <Card
                    bordered={false}
                    className={`${item?.bgColor} text-center rounded-4 h-100`}
                    style={{ boxShadow: "none" }}
                  >
                    <div className="d-flex ">
                      <div style={{ marginRight: "10px" }}>
                        <img src={item?.icon} alt={item?.label} draggable={false} />
                      </div>
                      <div className='d-flex flex-column align-items-start'>
                        <div className="card1-card-text text-left">{item?.label}</div>
                        <Skeleton loading={item.loading} active paragraph={{ rows: 0 }} style={{ marginTop: "10px" }}>
                          <div className="card1-card-value text-left">
                            {item?.value < 10 ? `0${item?.value}` : item?.value}
                          </div>
                        </Skeleton>

                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </Card>

        {/* Order summary card */}

        <div className="row mt-4 pb-5" style={{ display: "flex" }}>
          <div className="col-12 col-lg-8 mb-3" style={{ display: "flex", flexDirection: "column" }}>
            <Card bordered={true} style={{ width: "100%", flex: 1 }} className='stats-cards'>
              <div className="p-1">
                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <div className="card1-title">Order Summary</div>
                    <div>
                      <Select
                        defaultValue={{ value: "today", label: "Today" }}
                        onChange={handleRangeChange}
                        style={{ width: 120 }}
                        options={[
                          { value: "today", label: "Today" },
                          { value: "weekly", label: "Weekly" },
                          { value: "bi-weekly", label: "Bi-Weekly" },
                          { value: "monthly", label: "Monthly" },
                          { value: "yearly", label: "Yearly" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between daily-order-summary-card-bar">
                  {report !== "" ?
                    <div className='d-flex align-items-center gap-1'>
                      <NewOrderIcon />
                      <div
                        className="order-notification-text"
                        style={{ fontSize: "13px" }}
                      >
                        You have {report.total_pending_orders === 0 ? "no" : report.total_pending_orders} new orders to attend to!
                      </div>
                    </div> :
                    <div className='d-flex align-items-center gap-1'>
                      <NewOrderIcon />
                      <div
                        className="order-notification-text"
                        style={{ fontSize: "13px" }}
                      >
                        fetching your orders today 🍞...
                      </div>
                    </div>}
                  <Button
                    onClick={handleMangeOrder}
                    className="fw-bold text-secondary manage-orders-btn"
                  >
                    <span className='d-flex align-items-center gap-1'>
                      Manage Orders
                      <ArrowRightIcon />
                    </span>
                  </Button>
                </div>

                <div className="row mt-3">
                  <div className="col-12 col-md-6 text-center mb-3 mb-md-0">
                    <div style={{ width: '100%', height: '300px' }}>
                      {loadingChart ? <Skeleton.Avatar loading={true} paragraph={false} active size={150} style={{ marginTop: "50px" }} /> : <PolarAreaChart
                        className="col-12 col-md-6"
                        dataset={chartData}
                        options={{ maintainAspectRatio: false }}
                      />}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-5">
                    <div className="fw-bold" style={{ color: "#6C737F" }}>
                      Total {totalLabel} Orders
                    </div>
                    <Skeleton loading={loadingChart} active paragraph={{ rows: 0 }} style={{ marginTop: "10px" }}>
                      <div className="card1-card-value">
                        {areaChart.total_pending_orders + areaChart.total_orders_in_progress + areaChart.total_completed_orders + areaChart.total_confirmed_orders + areaChart.total_declined_orders}
                      </div>
                    </Skeleton>

                    <div className="mt-4 d-flex justify-content-between">
                      <div className="spiral-chart-legend-text" >
                        <span
                          className="px-2 rounded-1"
                          style={{ background: "#4CAF50", marginRight: "5px" }}
                        ></span>{" "}
                        Incoming Orders
                      </div>
                      <Skeleton className='order-notification-text' loading={loadingChart} active paragraph={{ rows: 0 }} style={{ position: "absolute", right: 0, width: "50px" }}>
                        <div className="order-notification-text">{areaChart.total_pending_orders}</div>
                      </Skeleton>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="spiral-chart-legend-text">
                        <span
                          className="px-2 rounded-1"
                          style={{ background: "#FF9800", marginRight: "5px" }}
                        ></span>{" "}
                        Processing Orders
                      </div>
                      <Skeleton className='order-notification-text' loading={loadingChart} active paragraph={{ rows: 0 }} style={{ position: "absolute", right: 0, width: "50px" }}>
                        <div className="order-notification-text">{areaChart.total_orders_in_progress}</div>
                      </Skeleton>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="spiral-chart-legend-text">
                        <span
                          className="rounded-1 px-2"
                          style={{ background: "#2196F3", marginRight: "5px" }}
                        ></span>{" "}
                        Orders Completed
                      </div>

                      <Skeleton className='order-notification-text' loading={loadingChart} active paragraph={{ rows: 0 }} style={{ position: "absolute", right: 0, width: "50px" }}>
                        <div className="order-notification-text">{areaChart.total_completed_orders}</div>
                      </Skeleton>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="spiral-chart-legend-text">
                        <span
                          className="px-2 rounded-1"
                          style={{ background: "#F44336", marginRight: "5px" }}
                        ></span>{" "}
                        Rejected Orders
                      </div>
                      <Skeleton className='order-notification-text' loading={loadingChart} active paragraph={{ rows: 0 }} style={{ position: "absolute", right: 0, width: "50px" }}>
                        <div className="order-notification-text">{areaChart.total_declined_orders}</div>
                      </Skeleton>

                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 col-lg-4 mb-3" style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Card bordered={true} style={{ width: "100%", height: "60%", boxSizing: "border-box", }} className="refund-card p-2">
              <div className="refund-card-title mb-4">
                <span style={{ marginRight: "10px" }}><RefundRequestIcon /></span>
                Refund Requests
              </div>

              <div className="refund-card-text-white no-request my-4">
                You have{" "}
                <span className="d-inline-block d-xl-inline">no refund requests to attend to at the moment 🎉</span>
              </div>
            </Card>
            <Card bordered={true} style={{ width: "100%", height: "40%" }} className='stats-cards'>
              <div className="p-1">
                <div className="adminCountTitle">
                  Total Number of Admin Staff
                </div>
                <Skeleton loading={adminStaff === ""} active paragraph={{ rows: 0 }} style={{ marginTop: "30px" }}>
                  <div className="adminCountNo my-3">
                    {adminStaff.meta?.total}
                  </div>
                </Skeleton>

                <div className="d-flex justify-content-between">
                  <Tooltip title="View Nibbles Bakery Staff" placement="bottom">
                    <Button
                      onClick={handleAminStaff}
                      className="fw-bold text-secondary border-0 p-0 mx-1"
                    >
                      <ViewStaffIcon />
                    </Button>
                  </Tooltip>

                  <div>
                    <Staffavatars />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>




        {/* Sales revenue card */}
        <div className="row d-flex align-items-stretch">
          <div className="col-12 col-lg-8 mb-3 d-flex flex-column">
            <Card bordered={true} className="flex-grow-1 stats-cards">
              <div className="p-1 d-flex flex-column h-100">
                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <div className="card1-title">Sales Revenue</div>
                    <div>
                      {/* Uncomment and adjust the Select component as needed */}
                      <Select
                  defaultValue={{ value: "2024", label: "2024" }}
                  onChange={handleYearChange}
                  style={{ width: 120 }}
                  options={[
                    { value: "2021", label: "2021" },
                    { value: "2022", label: "2022" },
                    { value: "2023", label: "2023" },
                    { value: "2024", label: "2024" },
                    { value: "2025", label: "2025" },
                    { value: "2026", label: "2026" },
                    { value: "2027", label: "2027" },
                    { value: "2028", label: "2028" },
                    { value: "2029", label: "2029" },
                    { value: "2030", label: "2030" },
                   
      
                   
                  ]}
                />
                    </div>
                  </div>
                </div>
                <div className="row mt-2 mb-3 flex-grow-1">
                  <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <div className="card1-card-value">
                      ₦{item?.total_sales}
                    </div>
                    <div>
                      <span className="revenue-percentage">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.21967 11.7803C4.94931 11.51 4.92851 11.0845 5.15728 10.7903L5.21967 10.7197L8.96967 6.96967C9.24003 6.69931 9.66546 6.67851 9.95967 6.90728L10.0303 6.96967L13.7803 10.7197C14.0732 11.0126 14.0732 11.4874 13.7803 11.7803C13.51 12.0507 13.0845 12.0715 12.7903 11.8427L12.7197 11.7803L9.5 8.56125L6.28033 11.7803C6.00997 12.0507 5.58454 12.0715 5.29033 11.8427L5.21967 11.7803Z"
                            fill="#06A561"
                          />
                        </svg>{" "}
                        22.45%
                      </span>
                      <span className="revenue-text mx-1">annually</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-8 d-flex flex-column">
                    <div style={{ height: "100%", minHeight: "200px" }}>
                      <LineChart
                        labels={labels}
                        dataset={lineChartData}
                        borderWidth={borderWidth}
                        showLabels={showLabels}
                        style={{ height: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <Card
                      bordered={false}
                      className="border border-1 h-100"
                    >
                      <div className="row">
                        <div className="col-5">
                          <div className="revenue-text">
                            Avg Income Weekly
                          </div>
                          <div className="avg-income-card-amount">
                            ₦300,000.00
                          </div>
                          <div>
                            <span className="revenue-percentage">
                              22.45%
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.21967 11.7803C4.94931 11.51 4.92851 11.0845 5.15728 10.7903L5.21967 10.7197L8.96967 6.96967C9.24003 6.69931 9.66546 6.67851 9.95967 6.90728L10.0303 6.96967L13.7803 10.7197C14.0732 11.0126 14.0732 11.4874 13.7803 11.7803C13.51 12.0507 13.0845 12.0715 12.7903 11.8427L12.7197 11.7803L9.5 8.56125L6.28033 11.7803C6.00997 12.0507 5.58454 12.0715 5.29033 11.8427L5.21967 11.7803Z"
                                  fill="#06A561"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div className="col-7 d-flex align-items-center">
                          <img src={avgIncome} alt="glass-tick" />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="col-12 col-md-6">
                    <Card
                      bordered={false}
                      className="border border-1 h-100"
                    >
                      <div className="row">
                        <div className="col-5">
                          <div className="revenue-text">
                            Avg Income Monthly
                          </div>
                          <div className="avg-income-card-amount">
                            ₦2,250,000.00
                          </div>
                          <div>
                            <span className="revenue-percentage">
                              22.45%
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.21967 11.7803C4.94931 11.51 4.92851 11.0845 5.15728 10.7903L5.21967 10.7197L8.96967 6.96967C9.24003 6.69931 9.66546 6.67851 9.95967 6.90728L10.0303 6.96967L13.7803 10.7197C14.0732 11.0126 14.0732 11.4874 13.7803 11.7803C13.51 12.0507 13.0845 12.0715 12.7903 11.8427L12.7197 11.7803L9.5 8.56125L6.28033 11.7803C6.00997 12.0507 5.58454 12.0715 5.29033 11.8427L5.21967 11.7803Z"
                                  fill="#06A561"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div className="col-7 d-flex align-items-center">
                          <img src={avgIncome} alt="glass-tick" />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 col-lg-4 mb-3 d-flex flex-column">
            <Card bordered={true} className="flex-grow-1 p-0 stats-cards">
              <div className="d-flex justify-content-between align-items-center gap-2">
                <div className="card1-title">Customers</div>
                <div className="statTabContainer">
                  <div className="d-flex tab-stats">
                    <div className={activeTab === 0 ? 'tab-stats-active' : "tab-stats"} onClick={() => handleActiveTab(0)}>Statistics</div>
                    <div className={activeTab === 1 ? 'tab-stats-active' : "tab-stats"} onClick={() => handleActiveTab(1)}>Analytics</div>
                  </div>
                </div>
              </div>

              <div className="my-5 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    height: "250px",
                    width: "250px"
                  }}
                >
                {activeTab === 1?  <DoughnutChart
                    labels={labelsDough}
                    dataValues={dataValues}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    total={totalDataValues}
                  />: loadingReport ? <Skeleton.Avatar loading={true} paragraph={false} active size={220} style={{}} /> : <DoughnutChart
                  labels={labelsDoughUserType}
                  dataValues={customersType}
                  backgroundColor={statsBgColor}
                  total={parseInt(report?.total_retailer_users) +
                    parseInt(report.total_distributor_users) +
                    parseInt(report.total_exclusive_users)}
                />}
                </div>

              </div>
              <div className="totCust my-3">
                Total Customers:{" "}
                <span style={{ fontWeight: "800", fontSize: "14px" }}>
                  {parseInt(report?.total_retailer_users) +
                    parseInt(report.total_distributor_users) +
                    parseInt(report.total_exclusive_users)}
                </span>
              </div>
              <div className='d-flex gap-3'>
                {(activeTab===0?labelsDoughUserType:labelsDough)?.map((item, index)=>(
                    <div key={index} className="spiral-chart-legend-text" >
                    <span
                      className="px-2 rounded-4"
                      style={{ background: activeTab===0?statsBgColor[index]:backgroundColor[index], marginRight: "5px" }}
                    ></span>{" "}
                    {item}
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-between mb-4">
                {/* Add any additional content here */}
              </div>
            </Card>
          </div>
        </div>



        {/* Top customers table */}
        <div className="row d-flex align-items-stretch my-5">
          <div className="col-12 col-lg-8 mb-3 d-flex flex-column">
            <Card bordered={true} className="flex-grow-1 stats-cards">
              <div className="p-1 d-flex flex-column h-100">
                <div className='d-flex justify-content-between'>
                  <div className="card1-title mb-4">{totalLabelTopCus} Top Customers</div>
                  <div>
                    <Select
                      defaultValue={{ value: "today", label: "Today" }}
                      onChange={handleRangeChangeTopCus}
                      style={{ width: 120 }}
                      options={[
                        { value: "today", label: "Today" },
                        { value: "weekly", label: "Weekly" },
                        { value: "bi-weekly", label: "Bi-Weekly" },
                        { value: "monthly", label: "Monthly" },
                        { value: "yearly", label: "Yearly" },
                      ]}
                    />
                  </div>
                </div>

                <div className="flex-grow-1 d-flex flex-column" style={{ height: "300px", overflowY: "auto" }}>
                  <SimpleBar style={{ maxHeight: "100%" }} className="custom-scroll2">
                    <TopCustomers data={topCustomers} loading={isLoadingTopCustomers}/>
                  </SimpleBar>

                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 col-lg-4 mb-3 d-flex flex-column">
            <Card bordered={true} className="flex-grow-1 p-1 stats-cards">
              <div className="d-flex justify-content-between">
                <div className="card1-title">
                  App Usage
                </div>
                <div>
                  <Tooltip title="See which platform customers use the most">  <AppUsageIcon /></Tooltip>
                </div>
              </div>
              <div
                className="my-4 d-flex justify-content-center align-items-center">
                <div
                  className="my-4 d-flex justify-content-center align-items-center"
                  style={{
                    height: "250px",
                    width: "250px"
                  }}
                >
                  <DoughnutChart
                    labels={labelsDoughAppUsage}
                    dataValues={dataValuesAppUsage}
                    backgroundColor={backgroundColorAppUsage}
                    borderColor={borderColorAppUsage}
                  />
                </div>
              </div>


              <div className="d-flex justify-content-between mb-2 mt-4">
                <div className="totCust">
                  <span className="orangeDot"></span>Website
                </div>
                <div className="totCust">Mobile App</div>
              </div>
            </Card>
          </div>
        </div>

      </div>


    </>

  );
}

export default AdminDashboardContent;


