// import { toastr } from "react-redux-toastr";
import api from "../../Service/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const successmessagecompliance = "Document Uploaded Sucessfully"

const successmessageaddbusinessarea = "Location Added Successfully"

export const GetSignUpApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/auth/register`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetOtpVerificationApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/otp-verifications/verify`, payload);
            if (response.data.status === true) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else if (response.data.status === false) {
                toast.error(response.data.message);
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            // throw ex;
        }
    }
};

export const GetResendOtpApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/otp-verifications/resend`, payload);
            if (response.data.status === true) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else if (response.data.status === false) {
                toast.error(response.data.message);
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error("Error:", error.response.data.message);
            console.log(error.response.data.message)
            // throw ex;
        }
    }
};

export const GetSiginApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/auth/login`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetForgotPasswordApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/auth/reset`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                if (response.data.status === false) {
                    toast.error(response.data.message);
                } else {
                    toast.success(response.data.message);
                }
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetNewPasswordApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/auth/password-update`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error("Error Response", response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetChangePasswordApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/auth/password-change`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error("Error Response", response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetUserTypeApi = async () => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/user-types?limit=5`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetRelationshipTypeApi = async () => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/relationship-types`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetNatureOfBusinessApi = async () => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/utility/industries`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetStateApi = async () => {
    // debugger
    if (navigator.onLine === false) {
        // toastr.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/states?country_id=127`);
            if (response.status === 200) {
                // debugger
                // toastr.success("Get Country", "success");
                return response.data.data;
            } else {
                // toastr.error("Get Country", response.error);
            }
        } catch (ex) {
            // toastr.error("Get Country", "An error occurred");
            throw ex;
        }
    }
};

export const GetLocalGovernmentByStateIdApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        // toastr.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/lgas?state_id=${payload}`);
            if (response.status === 200) {
                // debugger
                // toastr.success("Get Country", "success");
                return response.data.data;
            } else {
                // toastr.error("Get Country", response.error);
            }
        } catch (ex) {
            // toastr.error("Get Country", "An error occurred");
            throw ex;
        }
    }
};

export const PostUserTypeApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        // toastr.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/user-type`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                // toast.success(response.data.message);
                return response.data;
            } else {
                // toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            // console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostBusinessDetailsApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/business/details`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostBusinessLocationApi = async (payload) => {
    //   debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/business/location`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostBusinessHistoryApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/business/history`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostBusinessContactApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/business/contact`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data.message;
            } else {
                //   toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostUserOnboardingDetailsApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/details`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostBusinessDocumentApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/business-document`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(successmessagecompliance);
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const UpdateBusinessDocumentApi = async (body, payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/business-document/${body}`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(successmessagecompliance);
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetBusinessDocumentApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/business-document?business_id=${payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                // toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const PostBusinessAreapi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/business-areas`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(successmessageaddbusinessarea);
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const UpdateBusinessAreapi = async (payload) => {
    debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/business-areas/${payload.id}`, payload.body);
            if (response.status === 200) {
                debugger
                console.log(response)
                toast.success(successmessageaddbusinessarea);
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetBusinessAreaApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/business-areas?business_id=${payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                // toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetAllCustomerApi = async () => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users/all/customers`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                // toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetAllSystemAdminApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users/all/employees?page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetAllIndependentDistributorshipApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users?user_type_id=${payload.user_type_id}&page=${payload.page === undefined ? 1 : payload.page}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetIndependentDistributorNearMeApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users/all/distributors/`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetUserByIdApi = async (body, payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/users/${body}`, payload !== undefined ? payload : "");
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetPendingApplicationApi = async (payload) => {
    debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users?user_type_id=3&status=0&is_rejected=0&page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetApprovedApplicationApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users?user_type_id=3&status=1&is_rejected=0&page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
               
                
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};
export const GetRejectedApplicationApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users?user_type_id=3&is_rejected=1&page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const PostAssignedDistributorApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/assign/distributor`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetUserProfileDetailsApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users/${payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const UpdateProfilePictureApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/users/update/avatar`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetAllRolesApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/roles?page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const PostCreateEmployeeApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/add/staff`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostCreateDistributorApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/add/distributor`, payload);
            if (response.status === 200) {
                if (response?.data?.status === false) {
                    toast.error(response?.data?.message);
                    console.log(response)
                } else {
                    // debugger
                    console.log(response)
                    toast.success(response.data.message);
                    // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                }
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostCreateRetailerApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/add/retailer`, payload);
            if (response.status === 200) {
                // debugger
                if (response?.data?.status === false) {
                    toast.error(response?.data?.message);
                    console.log(response)
                } else {
                    // debugger
                    console.log(response)
                    toast.success(response.data.message);
                    // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                }
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const PostCreateExclusiveApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/add/exclusive`, payload);
            if (response.status === 200) {
                // debugger
                if (response?.data?.status === false) {
                    toast.error(response?.data?.message);
                    console.log(response)
                } else {
                    // debugger
                    console.log(response)
                    toast.success(response.data.message);
                    // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                }
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};


export const UpdateEmployeeDetailsApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/update/staff`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                toast.success(response.data.message);
                return response.data;
            } else {
                toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};


export const GetEmployeeDataUnpaginatedApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        // toastr.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/users/all/employees/dropdown`);
            if (response.status === 200) {
                // debugger
                console.log(response)
                // toast.success(response.data.message);
                return response.data?.data;
            } else {
                // toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            // console.log(error.response.data.message)
            throw error;
        }
    }
};


export const UpdateRoleDetailsApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/roles/${payload?.roleid}`, payload?.body);
            if (response.status === 200) {
                toast.success("Updated Role Details Successfully!");
                return response.data;
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            throw error;
        }
    }
};

export const GetGooglePlacesApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`https://places.googleapis.com/v1/places/GyuEmsRBfy61i59si0?fields=ikej&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};
export const GoogleSignInApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post('/auth/google-login', { accessToken: payload });
            if (response.status === 200) {
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};


export const UpdateUserStatusApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/users/${payload}?status=1`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.log(error.response.data.message)
            throw error;
        }
    }
};
export const UpdateUserDataApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/users/${payload.id}`,payload.body);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.log(error.response.data.message)
            throw error;
        }
    }
};
export const UpdateBusinessDataApi = async (payload) => {
    debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/business/${payload.id}`, payload.body);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.log(error.response.data.message)
            throw error;
        }
    }
};


export const DeleteUserAccountApi = async (userId) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.delete(`/users/${userId}`);
            if (response.status === 200) {
                // debugger
                toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};


export const RejectApplicationApi = async (body) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/users/reject/account`,body);
            if (response.status === 200) {
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};
