import React, { useState, useEffect } from 'react';
// import nibblesuserdashblogo from "../../asset/nibblesuserdashblogo.jpg";
import { ReactComponent as Nibblesuserdashblogo } from "../../asset/nibbles-user-logo.svg";
// import avatardashbimage from "../../asset/avatardashbimage.png";
import { Layout, Menu, theme, Row, Col, Divider, Avatar, Badge, notification, message, Button, Drawer, Alert, Space, Dropdown, Grid, Tooltip } from 'antd';
import { DownOutlined, UserOutlined, ToolOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { CartIcon, DashboardIcon, HandWave, LocationIcon, LogoutIcon, NotificationIcon, OrderHistoryIcon, PendingOrdersIcon, WarningIcon } from '../../asset/icons/icons';
import "./dashboard.css";
import UserDashboardContent from '../../Components/UserDashboardContent';
import PendingOrdersDashboard from './pendingOrders';
import OrderHistoryDashboard from './orderHistoryDashboard';
import ComplianseSettings from './complianceSettings';
import { Logout } from '../../Utils/logout';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../../redux/Reducer/authReducer';
import { getCartReducer } from '../../redux/Reducer/cartReducer';
import { addItemsInCart, getAllProduct, getItemsInCart, getUserProfileDetails } from '../../redux/Actions';
import NewCart from '../../Components/newCart';
import GetHelp from './getHelp';
import SimpleBar from 'simplebar-react';
const { useBreakpoint } = Grid;

const { Header, Content, Footer, Sider } = Layout;

// const items = [UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
//     (icon, index) => ({
//         key: String(index + 1),
//         icon: React.createElement(icon),
//         label: `nav ${index + 1}`,
//     }),
// );

const UserDashboard = (props) => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { setConfettiActive } = props;
    const { authUser, userProfileDetails, } = useSelector(getAuthReducer)
    const { allProducts, isloadingallProducts, cartItems, addCartItems, isloadingAddedCartItem } = useSelector(getCartReducer)
    const dispatch = useDispatch()
    const [selectedKey, setSelectedKey] = useState('1');
    //   const [collapsed, setCollapsed] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    // const [cart, setCart] = useState([]);
    const [detailsQtyAdded, setDetailQtyAdded] = useState(50);
    const [cart, setCart] = useState({
        user_id: authUser.user.id,
        product_id: null,
        qty: detailsQtyAdded
    });
    const screens = useBreakpoint();
    const isMd = screens.lg;
    // const isMobile = screens.sm || screens.xs;
    const [open, setOpen] = useState(false);
    const handleVerifyStatus = () => {
        // debugger
        setSelectedKey("6"); // Update selected key when menu item is clicked
    };

    const items = [
        {
            key: '1',
            label: (
                <Button type='text' target="_blank" rel="noopener noreferrer" className='fw-bold'>
                    {userProfileDetails?.data?.name}
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button type='text' target="_blank" rel="noopener noreferrer" onClick={handleVerifyStatus}>
                    Settings
                </Button>
            ),
        },
        {
            key: '3',
            label: (
                <Button type='text' target="_blank" rel="noopener noreferrer" onClick={Logout}>
                    Log Out
                </Button>
            ),
            //   icon: <SmileOutlined />,
            //   disabled: true,
        },
        // {
        //     key: '3',
        //     label: (
        //         <Button type='text' target="_blank" rel="noopener noreferrer" href="#">
        //             3rd menu item
        //         </Button>
        //     ),
        //     //   disabled: true,
        // },
    ];
    const openNotification = () => {
        api.open({
            message: 'Nibbles Bakery',
            description:
                'Welcome back.',
            duration: 4,
        });
    };

    const handleAddToCart = (product) => {
        // console.log(product)
        // setCart([...cart, product]);
        setCart({ ...cart, product_id: product.id, qty: detailsQtyAdded })
        message.success(
            "Item added to cart successfully"
        );
    };

    const handleMenuSelect = (key) => {
        // debugger
        setSelectedKey(key); // Update selected key when menu item is clicked
    };


    const showDrawer = () => {
        setOpen(true);
        // dispatch(getItemsInCart(authUser.user.id))
    };
    const onClose = () => {
        setOpen(false);
        dispatch(getItemsInCart(authUser.user.id))
    };

    const useMatchMedia = (mediaQuery, initialValue) => {
        const [isMatching, setIsMatching] = useState(initialValue)
        useEffect(() => {
            const watcher = window.matchMedia(mediaQuery)
            setIsMatching(watcher.matches)
            const listener = (matches) => {
                setIsMatching(matches.matches)
            }
            if (watcher.addEventListener) {
                watcher.addEventListener('change', listener)
            } else {
                watcher.addListener(listener)
            }
            return () => {
                if (watcher.removeEventListener) {
                    return watcher.removeEventListener('change', listener)
                } else {
                    return watcher.removeListener(listener)
                }
            }
        }, [mediaQuery])

        return isMatching
    }

    const isSmallDesktopResolution = useMatchMedia('(max-width:750px)', true)
    const isMobileDevice = useMatchMedia('(max-width:450px)', true)

    const renderContent = () => {
        switch (selectedKey) {
            case '1':
                return <UserDashboardContent
                    cart={cart}
                    setCart={setCart}
                    setDetailQtyAdded={setDetailQtyAdded}
                    detailsQtyAdded={detailsQtyAdded}
                    handleAddToCart={handleAddToCart}
                    authuserdetails={authUser?.user}
                    allProducts={allProducts}
                    isloadingallProducts={isloadingallProducts}
                    cartItems={cartItems}
                    isloadingAddedCartItem={isloadingAddedCartItem}
                    handleVerifyStatus={handleVerifyStatus}
                    userProfileDetails={userProfileDetails}
                    setConfettiActive={setConfettiActive}
                />;
            case '2':
                return <PendingOrdersDashboard authuserdetails={authUser?.user} />;
            case '3':
                return <OrderHistoryDashboard authuserdetails={authUser?.user} />;
            case '4':
                return <GetHelp userProfileDetails={userProfileDetails} />;
            case '6':
                return <ComplianseSettings userProfileDetails={userProfileDetails} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        dispatch(getAllProduct())
        dispatch(getItemsInCart(authUser?.user?.id))
        dispatch(getUserProfileDetails(authUser?.user?.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

 

    useEffect(() => {
        if (cart.product_id !== null) {
            dispatch(addItemsInCart(cart))
        } else {

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    useEffect(() => {
        // console.log(addCartItems)

        if (addCartItems.status === true) {
            // debugger
            dispatch(getItemsInCart(authUser.user.id))
            setDetailQtyAdded(50)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCartItems]);

    useEffect(() => {
        // dispatch(getAllIndependentDistributorship({ user_type_id }));
        if (userProfileDetails.status === true) {
            localStorage.setItem('userprofile', JSON.stringify(userProfileDetails.data));
        } else {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileDetails]);

    // console.log(cartItems, "cartmgt")
    // console.log(addCartItems, "addedcartmgt")
    // console.log(userProfileDetails, "userProfileDetails")

    return (
        <Layout style={{ marginLeft: isMd ? 280 : 0, minHeight: "100vh" }}>
            {contextHolder}
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width="280"
                onBreakpoint={(broken) => {
                    // console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    // console.log(collapsed, type);
                }}
                style={{
                    minHeight: '100vh',
                    position: 'fixed',
                    background: "#F9F9F9",
                    left: 0,
                    zIndex: 1000,
                    bottom: 0,
                    top: 0
                }}
            >
                <SimpleBar style={{ maxHeight: "100%" }} className="custom-scroll-user-dashboard">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2em 3em 3em 2em" }}>
                        <Nibblesuserdashblogo />
                        {/* <img
                        src={nibblesuserdashblogo}
                        alt="NibblesLogo"
                    // style={{ width: collapsed ? "80px" : "", marginBottom: "8px" }}
                    /> */}
                    </div>
                    <div style={{ padding: "0 16px" }} />
                    <Menu
                        theme="default"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        selectedKeys={[selectedKey]} // Set the selected key
                        onSelect={({ key }) => handleMenuSelect(key)} // Handle menu item selection
                        style={{ padding: "0em 2em 2em 2em" }}

                    >
                        {/* <div> */}
                        <Menu.Item
                            key="1"
                            icon={<DashboardIcon color={selectedKey === "1" ? "#FFF" : "#53545C"} />}
                            style={{ backgroundColor: selectedKey === "1" ? "#2F355B" : "#F9F9F9", color: selectedKey === "1" ? "#FFF" : "#53545C", padding: "2em", gap: "9.167px" }}
                            className='typography'
                        >
                            Dashboard
                        </Menu.Item>
                        <Menu.Item
                            key="2"
                            icon={<PendingOrdersIcon color={selectedKey === "2" ? "#FFF" : "#53545C"} />}
                            style={{ backgroundColor: selectedKey === "2" ? "#2F355B" : "#F9F9F9", color: selectedKey === "2" ? "#FFF" : "#53545C", padding: "2em", gap: "9.167px" }}
                            className='typography'
                        >
                            Pending Orders
                        </Menu.Item>
                        <Menu.Item
                            key="3"
                            icon={<OrderHistoryIcon color={selectedKey === "3" ? "#FFF" : "#53545C"} />}
                            style={{ backgroundColor: selectedKey === "3" ? "#2F355B" : "#F9F9F9", color: selectedKey === "3" ? "#FFF" : "#53545C", padding: "2em", gap: "9.167px" }}
                            className='typography'
                        >
                            Order History
                        </Menu.Item>
                        <Menu.Item
                            key="4"
                            icon={<Tooltip title="Complaint / Feedback"><QuestionCircleOutlined color={selectedKey === "4" ? "#1C1D22" : "#53545C"} /></Tooltip>}
                            style={{ backgroundColor: selectedKey === "4" ? "#2F355B" : "#F9F9F9", color: selectedKey === "4" ? "#1C1D22" : "#53545C", padding: "2em", gap: "9.167px", marginTop: "40vh" }}
                            className='typography'
                        >
                            <Tooltip title="Complaint / Feedback">
                                Complaint / Feedback
                            </Tooltip>
                        </Menu.Item>
                        <Menu.Item
                            key="5"
                            icon={<LogoutIcon color={selectedKey === "5" ? "#1C1D22" : "#53545C"} />}
                            style={{ backgroundColor: selectedKey === "5" ? "#2F355B" : "#F9F9F9", color: selectedKey === "5" ? "#1C1D22" : "#53545C", padding: "2em", gap: "9.167px" }}
                            className='typography'
                            onClick={Logout}
                        >
                            Log Out
                        </Menu.Item>
                    </Menu>
                </SimpleBar>
            </Sider>
            <Layout>
                <Header
                    style={{
                        background: colorBgContainer,
                        position: "fixed",
                        width: isMd ? `calc(100% - 260px)` : "100%",
                        left: isMd ? 260 : 0,
                        zIndex: 999,
                        padding: '0 40px 0 8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '64px',
                    }}
                >
                    <Row style={{ width: '100%' }} align="middle">
                        <Col xs={{ span: 18 }} sm={{ span: 17 }} md={{ span: 12 }} lg={{ span: 17 }} className='headercol1' style={{ justifyContent: "start" }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <div className='d-flex align-items-center gap-1'>
                                    <p className='headercoldtext1' style={{ margin: 0 }}>
                                        {`Welcome, ${authUser?.user.name}`}
                                    </p>
                                    <HandWave />
                                </div>
                                <Divider type="vertical" style={{ height: 'auto', margin: '0 8px' }} />
                                {isMobileDevice ? "" :
                                    <p className='headercoldtext2' style={{ margin: 0 }}><LocationIcon /> {userProfileDetails?.data?.business?.state}, Nigeria</p>}
                            </div>
                        </Col>
                        <Col xs={{ span: 6 }} sm={{ span: 7 }} md={{ span: 12 }} lg={{ span: 7 }} className='headercol2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {!isSmallDesktopResolution && (
                                <>
                                    <Button type="text" onClick={handleVerifyStatus}>
                                        <ToolOutlined style={{ fontSize: "20px" }} />
                                    </Button>
                                    <Button type="text" onClick={openNotification}>
                                        <NotificationIcon />
                                    </Button>
                                    <Button type="text">
                                        <div>
                                            <Badge count={cartItems?.length === 0 ? "" : cartItems?.length} onClick={showDrawer} color="#BDC167">
                                                <CartIcon />
                                            </Badge>
                                            <Drawer width={450} title="" onClose={onClose} open={open}>
                                                <NewCart
                                                    initialCartItems={cartItems}
                                                    authuserdetails={authUser?.user}
                                                    handleVerify={handleVerifyStatus}
                                                    userProfileDetails={userProfileDetails}
                                                    setSelectedKey={setSelectedKey}
                                                    setOpen={setOpen}
                                                    setConfettiActive={setConfettiActive}
                                                    onClose={onClose}
                                                />
                                            </Drawer>
                                        </div>
                                    </Button>

                                    <Button type="text" onClick={handleVerifyStatus} style={{ height: "fit-content" }}>
                                        <Avatar
                                            size="medium"
                                            icon={userProfileDetails?.data?.avatar ? <img src={userProfileDetails?.data?.avatar} alt="userprofilepicture" /> : <UserOutlined />}
                                        />
                                    </Button>

                                </>
                            )}
                            {isSmallDesktopResolution && (
                                <>
                                    <Button type="text">
                                        <div>
                                            <Badge count={cartItems?.length === 0 ? "" : cartItems?.length} onClick={showDrawer} color="#BDC167">
                                                <CartIcon />
                                            </Badge>
                                            <Drawer title="" onClose={onClose} open={open}>
                                                <NewCart
                                                    initialCartItems={cartItems}
                                                    authuserdetails={authUser?.user}
                                                    handleVerify={handleVerifyStatus}
                                                    userProfileDetails={userProfileDetails}
                                                    setSelectedKey={setSelectedKey}
                                                    setOpen={setOpen}
                                                />
                                            </Drawer>
                                        </div>
                                    </Button>
                                    <Dropdown menu={{ items }}>
                                        <Button type="text" onClick={(e) => e.preventDefault()} style={{ height: "fit-content", padding: 0 }}>
                                            <Avatar size="large" icon={userProfileDetails?.data?.avatar ? <img src={userProfileDetails?.data?.avatar} alt="userprofilepicture" /> : <UserOutlined />} />
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>

                                </>
                            )}

                        </Col>


                    </Row>
                </Header>

                <Content
                    style={{
                        margin: '0px 1px 0',
                        overflowX: "hidden",
                        paddingTop: 64
                    }}
                >
                    {selectedKey === "6" || userProfileDetails?.data?.status === 1 || userProfileDetails?.data?.user_type_id === 5 ? "" :
                        <Alert
                            message={<p className='typography pt-3 accountverificationtext'>Account verification required to place orders. Verify now to place your orders</p>}
                            type="warning"
                            icon={<WarningIcon className="verifyicon" />}
                            showIcon
                            action={
                                <Space>
                                    <Button size="small" className='verifyaccountbtn' onClick={handleVerifyStatus}>
                                        Verify Account
                                    </Button>
                                </Space>
                            }
                            className='verifyalert'
                            // closable
                            banner
                            style={{ height: "70px" }}

                        />
                    }
                    <div
                        style={{
                            padding: 24,
                            minHeight: "80vh",
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {/* <UserDashboardContent /> */}
                        {renderContent()}
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Nibbles Bakery ©{new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};
export default UserDashboard;