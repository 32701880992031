import React, { useState } from "react";
import { Button, Modal, Spin, Table } from "antd";
import moment from "moment";
import { getOrderReducer } from "../redux/Reducer/orderReducer";
import { useDispatch, useSelector } from "react-redux";
import { getAdminOrderById } from "../redux/Actions";
import MyPendingOrders from "./MyPendingOrders";
import { FormatNumber } from "../Utils/cartmgt";
// import { useDispatch, useSelector } from "react-redux";
// import { getOrderReducer } from "../../redux/Reducer/orderReducer";
// import { getAllUserOrders } from "../../redux/Actions";

const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};

const OrderHistoryTable = ({ allUserOrders, isloadingAllUserOrders }) => {
  const [open, setOpen] = useState(false);
  const { adminOrderById, isloadingAdminOrderById } =
    useSelector(getOrderReducer);
  const dispatch = useDispatch();
  const handleShowUserOrderDetails = (orderid) => {
    // console.log(orderid,"orderid")
    dispatch(getAdminOrderById(orderid));
    // debugger
    setOpen(true); // Update selected key when menu item is clicked
  };

  const columns = [
    {
      title: "ORDER ID",
      dataIndex: "id",
      render: (text) => <span className="orderHistory-text">{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "start_date",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text).format("MMM Do YYYY")} <br />{" "}
          <span className="orderHistory-time">
            At {moment(text).format("h:mm A")}
          </span>
        </span>
      ),
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Total No Of Items",
      dataIndex: "qty",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: {
        compare: (a, b) => a.noofItems - b.noofItems,
        multiple: 2,
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => <span className="orderHistory-text">{text === undefined || null ? "N/A" : FormatNumber(text) }</span>,
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Status",
      dataIndex: "order_status",
      render: (text, record) => {
        let progressClass = "";
        switch (text.id) {
          case 1:
            progressClass = "orderHistory in-progress";
            break;
          case 2:
            progressClass = "orderHistory completed";
            break;
          case 0:
            progressClass = "orderHistory rejected";
            break;
          default:
            progressClass = "orderHistory completed";
        }
        return (
          <span key={text.id} className={progressClass}>
            {text.name === "Initial" ? "Incoming" : text.name}
          </span>
        );
      },
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 1,
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <Button
            type="text"
            onClick={() => handleShowUserOrderDetails(text)}
            className="orderHistory-view-details"
          >
            View Details
          </Button>
          <Modal
            title="Order Details"
            // centered
            style={{
              top: 20,
            }}
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={1000}
          >
            <Spin spinning={isloadingAdminOrderById} tip="Loading...">
              <MyPendingOrders orderDetailsData={adminOrderById} />
            </Spin>
          </Modal>
        </>
      ),
    },
  ];

  // console.log(allUserOrders, "Azzzgard")
  return (
    <>
      <div className="row">
        <div className="col"></div>
        <div className="col"></div>
      </div>
      <div className="row mt-5 mb-4">
        <Spin spinning={isloadingAllUserOrders} tip="Loading...">
          <Table
            columns={columns}
            dataSource={allUserOrders.data}
            onChange={onChange}
            scroll={{ x: 1000 }}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </Spin>
      </div>
    </>
  );
};
export default OrderHistoryTable;
