import React from 'react';

const indicators = {
  green: {
    backgroundColor: "rgba(45, 149, 86, 0.10)",
    color: "#1E874C",
  },
  red: {
    backgroundColor: "#FFF2F2",
    color: "#FF404A"
  }
};

const Indicator = ({ status, name }) => {
  const styles = indicators[status] || {};

  return (
    <div className='d-flex align-items-center gap-2'>
      <div className='d-inline-flex justify-content-center align-items-center rounded-2' style={{ backgroundColor: styles.backgroundColor, padding: '0.5rem', borderRadius: '4px', fontWeight:500 }}>
        <span style={{ color: styles.color }}>{name}</span>
      </div>
    </div>
  );
};

export default Indicator;
