import { Form, Input } from 'antd'
import React from 'react'

const RenderInputText = ({colConfig, type}) => {
  return (
    <Form.Item name={colConfig.name}>
    <Input
      type={"text"}
      placeholder=""
      readOnly={type === "view" || colConfig.readOnly}
      style={{ cursor: (type === "view" || colConfig.readOnly) && "not-allowed" }}
    />
  </Form.Item>
  )
}

export default RenderInputText