// src/LineChart.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip } from 'chart.js';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const LineChart = ({ labels, dataset, borderWidth, showLabels }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: dataset,
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: '#7152F3',
        borderWidth: borderWidth, // Thinner line width as a prop
        pointBackgroundColor: '#7152F3',
        pointBorderColor: '#7152F3',
        pointHoverBackgroundColor: '#7152F3',
        pointHoverBorderColor: '#7152F3',
        tension: 0.4 // Smoother line curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Remove legend
      },
      title: {
        display: false, // Remove title
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove gridlines for x-axis
        },
        ticks: {
          display: showLabels, // Control the display of x-axis labels
        },
      },
      y: {
        grid: {
          display: false, // Remove gridlines for y-axis
        },
        ticks: {
          display: showLabels, // Control the display of y-axis labels
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
