import React, { useState } from 'react'
import { Modal } from "antd";
import CollectionCreateForm from './CollectionCreateForm';


const ViewOrderDetailsModal = (props) => {
    const {open, onCreate, setOpen, onCancel, initialValues, orderRejectedError, isArchived, orderStatus, orderRejected, initialStage, processingStage, loadingStage, pickUpStage, id, handleAllAdminOrderByStatusIdApi} = props
    const [formInstance, setFormInstance] = useState();
    return (
      <Modal
        open={open}
        title="Order Details"
        okText="Ok"
        cancelText="Cancel"
        width={1100}
        okButtonProps={{
          autoFocus: true,
        }}
        onCancel={onCancel}
        destroyOnClose
        onOk={async () => {
          try {
            const values = await formInstance?.validateFields();
            formInstance?.resetFields();
            onCreate(values);
          } catch (error) {
            console.log("Failed:", error);
          }
        }}
      >
        <CollectionCreateForm
          orderRejectedError={orderRejectedError}
          orderStatus={orderStatus}
          orderRejected={orderRejected}
          initialStage={initialStage}
          processingStage={processingStage}
          loadingStage={loadingStage}
          pickUpStage={pickUpStage}
          initialValues={initialValues}
          isArchived={isArchived}
          setOpen={setOpen}
          id = {id}
          onFormInstanceReady={(instance) => {
            setFormInstance(instance);
          }}
          handleAllAdminOrderByStatusIdApi={handleAllAdminOrderByStatusIdApi}
        />
      </Modal>
    );
}

export default ViewOrderDetailsModal