import React, { useState } from "react";
import {
  Col,
  Button,
  Card,
  Carousel,
  Modal,
  InputNumber,
  Row,
  Tag,
  Divider,
} from "antd";
import { ShoppingOutlined } from "@ant-design/icons";
import { FormatNumber } from "../Utils/cartmgt";

const ProductCard = ({
  item,
  onAddToCart,
  isloadingAddedCartItem,
  // setCart,
  setDetailQtyAdded,
  detailsQtyAdded,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { images, name, amount, description, status, product_type } = item;

  const handleAddToCart = () => {
    // debugger
    onAddToCart(item);
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const increaseQty = () => {
    setDetailQtyAdded(detailsQtyAdded + 1);
  };

  const decreaseQty = () => {
    if (detailsQtyAdded > 1) {
      setDetailQtyAdded(detailsQtyAdded - 1);
    }
  };

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      md={{ span: 8 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
      key={item.id}
    >
      <Card hoverable className="productcard">
        <div onClick={showModal} className="carousel-wrapper">
          <Carousel
            autoplay
            autoplaySpeed={2500}
            dots={false}
            className="carousel-container"
          >
            {images.map((image, index) => (
              <img key={index} alt={`product-imag-${index}`} src={image.url} />
            ))}
          </Carousel>
        </div>
        <div className="dashboardcardcontent">
          {product_type.id === 1 ? (
            <Button
              type="text"
              className="typography dashboardnibblescardbutton1"
            >
              <p className="dashboardnibblesbutton1typog">{product_type.name}</p>
            </Button>
          ) : (
            <Button
              type="text"
              className="typography dashboardnibblescardbutton2"
            >
              <p className="dashboardnibblesbutton2typog">{product_type.name}</p>
            </Button>
          )}
          <p className="dashboardtitlescardtypog">{name}</p>
          <div className="dashboardcardpriceandcartdiv">
            <p className="dashboardcarddescriptiontypog">
              {FormatNumber(amount)}
            </p>
            <Button
              className="typography addtocartbutton"
              onClick={handleAddToCart}
              disabled={isloadingAddedCartItem}
            >
              +
            </Button>
          </div>
        </div>
      </Card>
      <Modal
        title={""}
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 9 }}
            lg={{ span: 9 }}
            xl={{ span: 9 }}
          >
            <Carousel
              autoplay
              autoplaySpeed={2500}
              dots={true}
              className="carousel-container-modal"
              // style={{maxHeight: "20px"}}
            >
              {images.map((image, index) => (
                <img
                  key={index}
                  alt={`product-imag-${index}`}
                  src={image.url}
                />
              ))}
            </Carousel>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 15 }}
            lg={{ span: 15 }}
            xl={{ span: 15 }}
          >
            <div className="ml-4">
              <p className="fw-bold productdescriptionmodaltitle">
                {name}{" "}
                <span>
                  {status === 1 ? (
                    <Tag color="green">In Stock</Tag>
                  ) : (
                    <Tag color="red">Not Available</Tag>
                  )}
                </span>
              </p>
              <p className="productdescriptionmodalpriceview">
                <strong>{FormatNumber(amount)}</strong>
              </p>
              <Divider />
              <p className="productdescriptionmodaldescriptionview">
                {description}
              </p>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div className="productdescriptionmodalqtybtndiv">
                  <Button
                    shape="circle"
                    onClick={decreaseQty}
                    style={{ marginRight: "10px" }}
                    className="productdescriptionmodalqtybtn"
                  >
                    -
                  </Button>
                  <InputNumber
                    min={1}
                    value={detailsQtyAdded}
                    onChange={(value) => setDetailQtyAdded(value)}
                  />
                  <Button
                    shape="circle"
                    onClick={increaseQty}
                    style={{ marginLeft: "10px" }}
                    className="productdescriptionmodalqtybtn"
                  >
                    +
                  </Button>
                </div>
                <Button
                  type="primary"
                  onClick={handleAddToCart}
                  disabled={isloadingAddedCartItem}
                  // style={{ marginTop: "10px" }}
                  size="large"
                  className="productdescriptionmodaladdtocartbtn"
                  iconPosition={"start"}
                  icon={<ShoppingOutlined />}
                >
                  Add to Cart
                </Button>
              </div>
              
            </div>
            {/* <Divider /> */}
              <small><span className="fw-bold"> Product Type:</span> {product_type.name}</small>
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};

export default ProductCard;
