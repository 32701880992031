import React, { useEffect} from 'react';
import { Spin } from 'antd';
// import nibbleslogo from "../asset/nibbleslogo.jpg"
// import { CarIcon, RetailChain } from '../asset/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../redux/Reducer/authReducer';
import { getUserType } from '../redux/Actions';
import RadioOptionCard from './radioOptionCard';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const RadioCard = ({ setSelectedOption, selectedOption }) => {
    const { userTypeData, isloadingUserType } = useSelector(getAuthReducer)
    const dispatch = useDispatch()
    // const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        dispatch(getUserType())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const handleRadioChange = (e) => {
    //     setSelectedOption(e.target.value);
    // };
    // console.log(selectedOption)
    return (
        <div className='radioCarddiv'>
            {isloadingUserType ? (
                // Show loading spinner while data is being fetched
                <Spin size="large" />
            ) : (
                // Render the mapped cards when data is fetched
                userTypeData?.slice(2, 4).map((item, i) =>
                    <RadioOptionCard item={item} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
                )
            )}
        </div>

    );
};

export default RadioCard;
