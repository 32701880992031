import React, { useEffect, useState } from "react";
import { Table, Tabs, Spin, Input, Dropdown, Space, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOrderReducer } from "../../../redux/Reducer/orderReducer";
import { getAllTransactionLog } from "../../../redux/Actions";
import { FormatNumber } from "../../../Utils/cartmgt";
import moment from "moment";
import { SearchIcon, ViewPersonIcon } from "../../../asset/icons/icons";
import {MoreOutlined } from '@ant-design/icons';
import ViewTransactionLogs from "../components/TransactionLogs/ViewTransactionLogs";





const usermanText = {
  width: '189px',
  flexShrink: 0,
  color: '#45464E',
  fontFamily: '"MADE TOMMY"',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px',
  marginBottom: "25px"
};

// const filter = {
//   color: "var(--Black-80, #52575C)",
//   fontFamily: "Inter",
//   fontSize: "13.138px",
//   fontStyle: "normal",
//   fontWeight: 500,
//   lineHeight: "22.992px", // 175%
//   letterSpacing: "-0.145px",
// };

// const filterDiv = {
//   display: "flex",
//   width: "588.756px",
//   justifyContent: "flex-end",
//   alignItems: "flex-start",
//   gap: "16.423px",
//   flexShrink: 0
// };
// const filterCount = {
//   borderRadius: "410.569px",
//   background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
//   display: "flex",
//   padding: "3.285px 6.138px 4.423px 6.57px",
//   justifyContent: "center",
//   alignItems: "center",
//   color: "var(--Black-80, #52575C)",
//   textAlign: "center",
//   fontFamily: "Inter",
//   fontSize: "9.854px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "normal"
// };



const tag = {
  padding: "3.867px 7.734px",
  alignItems: "center",
  gap: "0px",
  borderRadius: "348.021px",
  background: "#F4F1FD",
}


const TransactionLog = () => {
  const { allTransaction, isloadingAllTransaction } = useSelector(getOrderReducer);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = allTransaction?.data?.filter((item) =>
    Object.keys(item).some((key) =>
      key !== 'created_at'
        ? item[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        : moment(item.created_at).format('MMM Do YYYY').toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };
  useEffect(() => {
    dispatch(getAllTransactionLog())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(getAllTransactionLog(pagination.current));
  };
  console.log(allTransaction, "alltransaction")

  const [open, setOpen] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleMenuClick = (e) => {
    if (e.key === '0') {
      setOpen(true)
      console.log(selectedRecord, "MEEEEEEEEEEEEEE");
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const menuItems = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0">
        <div className="d-flex gap-2 align-items-center">
          <ViewPersonIcon />
          <span>
            View
          </span>
        </div>
      </Menu.Item>
      {/* <Menu.Item key="1">
        <div className="d-flex gap-2 align-items-center">
          <QuerySearchIcon />
          <span>
            Query
          </span>
        </div>
      </Menu.Item> */}
    </Menu>
  );


  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.order_id - b.order_id,
    },
    {
      title: "Transanction Reference",
      dataIndex: ["order", "reference"],
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.order?.reference.localeCompare(b.order?.reference),
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.payment_method.localeCompare(b.payment_method),
    },
    {
      title: "Amount Paid",
      dataIndex: "amount",
      render: (text, record) => <span className="orderHistory-text">{text === undefined || null ? "N/A" : FormatNumber((Number(text)+Number(record?.tax)+Number(record?.fee))-Number(record?.discount))}</span>,
      sorter: (a, b) => a.amount - b.amount,
    },
    // {
    //   title: "Service Fee",
    //   dataIndex: "tax",
    //   render: (text) => <span className="orderHistory-text">{text === undefined || null ? "N/A" : FormatNumber(text)}</span>,
    //   sorter: {
    //     compare: (a, b) => a.amount - b.amount,
    //     multiple: 1,
    //   },
    // },
    {
      title: "Time",
      dataIndex: "created_at",
      render: (text) => <span className="orderHistory-text">{moment(text).format('h:mm A')}</span>,
      sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      render: (text) => <span className="orderHistory-text">{moment(text).format('MMM Do YYYY')}</span>,
      sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        let statusClass = "";
        switch (text) {
          case 1:
            statusClass = "orderHistory received";
            break;
          case 2:
            statusClass = "orderHistory confirmed";
            break;
          case 3:
            statusClass = "orderHistory processing";
            break;
          case 4:
            statusClass = "orderHistory rejected";
            break;
          default:
            statusClass = "orderHistory approved";
        }
        return (
          <span key={text} className={statusClass}>
            {text === 0 ? "success" : "success"}
          </span>
        );
      },
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Actions",
      render: (text, record) => {
        return (
          <Dropdown overlay={menuItems} trigger={['click']} dropdownRender={(menu) => (
            <div>
              {React.cloneElement(menu, {
                style: { width: "100px" },
              })}

            </div>
          )}


            placement="bottomLeft" arrow onVisibleChange={(visible) => {
              if (visible) {
                setSelectedRecord(record);
              }
            }}>
            <span onClick={(e) => e.preventDefault()}>
              <Space>
                <MoreOutlined />
              </Space>
            </span>
          </Dropdown>
        )
      },
    },
  ];


  const items = [
    {
      key: "1",
      label: (
        <div style={{ color: "#252A4D" }}>
          All Transactions
          <span className="mx-2" style={tag}>{allTransaction?.meta?.total}</span>
        </div>
      ),
      children: (
        <div className="row mb-4">
          <Spin spinning={isloadingAllTransaction} tip="Loading...">
            <Table
              columns={columns}
              // dataSource={allTransaction?.data}
              dataSource={filteredData}
              onChange={onChange}
              scroll={{ x: 1000 }}
              pagination={{
                pageSize: allTransaction?.meta?.per_page,
                showSizeChanger: false,
                pageSizeOptions: ["10", "20", "30", "40"],
                showQuickJumper: true,
                total: allTransaction?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          </Spin>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div style={{ color: "#252A4D" }}>
    //       Pending Transactions
    //       <span className="mx-2" style={tag}>{4}</span>
    //     </div>
    //   ),
    //   children: (
    //     <div className="row mt-5 mb-4">
    //       <Table
    //         columns={columns}
    //         dataSource={data}
    //         onChange={onChange}
    //         pagination={{
    //           pageSize: 10,
    //           showSizeChanger: true,
    //           pageSizeOptions: ["10", "20", "30", "40"],
    //           showQuickJumper: true,
    //           showTotal: (total, range) =>
    //             `${range[0]}-${range[1]} of ${total} items`,
    //           style: {
    //             display: "flex",
    //             justifyContent: "center",
    //             marginTop: "20px",
    //           },
    //         }}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div style={{ color: "#252A4D", }}>
    //       Canceled/Failed Transactions
    //       <span className="mx-2" style={tag}>{5}</span>
    //     </div>
    //   ),
    //   children: (
    //     <div className="row mt-5 mb-4">
    //       <Table
    //         columns={columns}
    //         dataSource={data}
    //         onChange={onChange}
    //         pagination={{
    //           pageSize: 10,
    //           showSizeChanger: true,
    //           pageSizeOptions: ["10", "20", "30", "40"],
    //           showQuickJumper: true,
    //           showTotal: (total, range) =>
    //             `${range[0]}-${range[1]} of ${total} items`,
    //           style: {
    //             display: "flex",
    //             justifyContent: "center",
    //             marginTop: "20px",
    //           },
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];


  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Transaction Logs</span>
            </div>
          </div>
        </div>

        {/* <div className="row mt-5">
          <div className="d-flex justify-content-start">
            <div className="col-3">
              <Input placeholder="Basic usage" />
            </div>
            <div className="col d-flex justify-content-end" style={filterDiv}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                    fill="#52575C"
                  />
                </svg>
              </span>{" "}
              <span style={filter} className="">
                Filter
              </span>
              <span style={filterCount}>0</span>
            </div>
          </div>
        </div> */}
        <div className="d-flex justify-content-start">
          <div className="col-12 col-sm-6 col-md-5">
            <Input
              placeholder="Search by transaction ID or payment method"
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <Tabs className="border p-2 mt-3 overflow-x-hidden" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      <ViewTransactionLogs open={open} handleClose={handleClose} record={selectedRecord} />
    </>
  );
};
export default TransactionLog;
