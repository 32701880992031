import {all} from "redux-saga/effects"
import countrySaga from "./countrySaga";
import authSaga from "./authSaga";
import cartSaga from "./cartSaga";
import orderSaga from "./orderSaga";
import permissionSaga from "./permissionSaga";
export default function* rootSaga(getState) {
    yield all([
        countrySaga(),
        authSaga(),
        cartSaga(),
        orderSaga(),
        permissionSaga()
    ])
  };