import React, { useState, useEffect } from "react";
import { Col, Row, Typography, Button, Form, Input, Divider } from "antd";
import nibbleslogo from "../../asset/nibbleslogo.jpg";
import "./onboarding.css";
import { ArrowLeftIcon, GoogleIcon } from "../../asset/icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getSignup, googleSignUp } from "../../redux/Actions";
import { useGoogleLogin } from "@react-oauth/google";

const { Title, Text } = Typography;

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

export default function SignUp() {
  const navigate = useNavigate();
  const { signupdetail, signingup, signupmsg } = useSelector(getAuthReducer);
  const dispatch = useDispatch();

  const [signupdetails, setSignUpForm] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [signinErrors, setSigninErrors] = useState({
    email: "",
    password: "",
  });
  console.log(signupmsg);
  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setSignUpForm({ ...signupdetails, email: emailInput });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailInput || !emailRegex.test(emailInput)) {
      setSigninErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address.",
      }));
    } else {
      setSigninErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handleNameChange = (e) => {
    setSignUpForm({ ...signupdetails, name: e.target.value });
  };

  const handlePasswordChange = (e) => {
    const passwordInput = e.target.value;
    setSignUpForm({ ...signupdetails, password: passwordInput });
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    if (!passwordInput || !passwordRegex.test(passwordInput)) {
      let errorMsg = "Password must meet the following criteria:";
      if (passwordInput && passwordInput.length < 6) {
        errorMsg += " be at least 6 characters long,";
      }
      if (passwordInput && !/(?=.*[!@#$%^&*()_+])/.test(passwordInput)) {
        errorMsg += " include at least one symbol,";
      }
      if (passwordInput && !/(?=.*[a-z])/.test(passwordInput)) {
        errorMsg += " include at least one lowercase letter,";
      }
      if (passwordInput && !/(?=.*[A-Z])/.test(passwordInput)) {
        errorMsg += " include at least one uppercase letter,";
      }
      if (passwordInput && !/(?=.*\d)/.test(passwordInput)) {
        errorMsg += " include at least one digit,";
      }
      errorMsg = errorMsg.slice(0, -1); // Remove the last comma
      setSigninErrors((prevErrors) => ({ ...prevErrors, password: errorMsg }));
    } else {
      setSigninErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const submitSignUp = () => {
    dispatch(getSignup(signupdetails));
  };

  const login = useGoogleLogin({
    onSuccess: (response) => handleSignUpSuccess(response),
    onError: (error) => console.log('Sign up Failed:', error),
  });

  const handleSignUpSuccess = async (response) => {
    const accessToken = response.access_token;
    dispatch(googleSignUp(accessToken))
  };
  useEffect(() => {
    if (signupdetail === true) {
      navigate("/enterotp");
    }
  }, [signupdetail, navigate]);

  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          className="getstartedbackground"
        ></Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          className="getstartefirstrowlogo"
        >
          <Row className="getstartedrowlogo">
            <Col span={24} className="getstartedcollogo">
              <img alt="nibbleslogo" src={nibbleslogo} />
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpad">
              <Title
                className="typography getstartedtext"
                style={{ marginBottom: "0px" }}
                level={1}
              >
                Get Started
              </Title>
              <Text className="joinbakery typography">
                Join our bakery's community of happy customers. Sign up today!
              </Text>
            </Col>

            <Col span={24} className="getstartedcolpad">
              <Form
                {...formLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size={"large"}
                className="typography"
                initialValues={signupdetails}
              >
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <label className="typography fw-bold">
                        Full Name{" "}
                        <span className="typography fw-normal">
                          (Surname First)
                        </span>
                        <span style={{ color: 'red', marginBottom: '2em' }}> *</span>
                      </label>
                    }
                    name="name"
                    rules={[
                      {
                        // required: true,
                        message: "Please enter your full name.",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim().split(" ").length >= 2) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Please enter at least two names."
                          );
                        },
                      },
                    ]}
                  >
                    <Input
                      type="name"
                      value={signupdetails.name}
                      onChange={handleNameChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <label className="typography fw-bold">
                        Email Address <span style={{ color: 'red', marginBottom: '2em' }}>*</span>
                      </label>
                    }
                    name="email"
                    rules={[
                      {
                        // required: true,
                        message: "Please enter your email address.",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address.",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      value={signupdetails.email}
                      onChange={handleEmailChange}
                    />
                  </Form.Item>
                  {signinErrors.email && (
                    <p className="error typography">{signinErrors.email}</p>
                  )}
                </Col>

                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <label className="typography fw-bold">Password <span style={{ color: 'red', marginBottom: '2em' }}>*</span></label>
                    }
                    name="password"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your password!",
                      },
                      {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/,
                        message:
                          "Password must meet the following criteria: be at least 6 characters long, include at least one symbol, include at least one lowercase letter, include at least one uppercase letter, include at least one digit.",
                      },
                    ]}
                  >
                    <Input.Password
                      value={signupdetails.password}
                      onChange={handlePasswordChange}
                    />
                  </Form.Item>
                  {signinErrors.password && (
                    <p className="error typography">{signinErrors.password}</p>
                  )}
                </Col>

                <Col xs={{ span: 24 }}>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        onClick={submitSignUp}
                        className="signupbutton typography"
                        htmlType="submit"
                        disabled={
                          !form.isFieldsTouched(true) ||
                          form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length > 0 ||
                          signingup
                        }
                        block
                      >
                        {signingup ? "Loading..." : "Sign Up"}
                      </Button>
                    )}
                  </Form.Item>
                </Col>

                <Divider>or</Divider>

                <Button block size={"large"} className="buttonicons typography" onClick={() => login()}>
                  <p>
                    <GoogleIcon />{" "}
                    <span style={{ marginLeft: "1em" }}>
                      Sign up with Google
                    </span>{" "}
                  </p>
                  <p className="buttonendicons">
                    <ArrowLeftIcon />
                  </p>
                </Button>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpad">
              <Text className="joinbakery typography">
                Already have an account?{" "}
                <Link
                  to={"/signin"}
                  style={{ color: "#998C16", textDecoration: "underline" }}
                  className="signinlink"
                >
                  Sign in
                </Link>
              </Text>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpadterms">
              <Text className="terms">
                By signing up, you agree to the{" "}
                <Link
                  to={"#"}
                  style={{ color: "#252A4D" }}
                  className="signinlink"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  to={"#"}
                  style={{ color: "#252A4D" }}
                  className="signinlink"
                >
                  {" "}
                  Privacy Policy{" "}
                </Link>
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
