import React from "react";
import { Switch, Row, Col, Card, Typography } from "antd";
import { useSelector } from "react-redux";
import { getPermissionReducer } from "../../redux/Reducer/permissionReducer";

const { Text } = Typography;

const RolePermissionSettings = ({ handleRoleChange, handleEnableAllRoles, roles, text, subtext, presets, type }) => {
  const { permissionData } = useSelector(getPermissionReducer);

  return (
    <>
      <div>
        <p className="typography roledetailstext mt-3">{text ?? "Set Permissions"}</p>
        <p className="typography roledetailsdescription">
          {subtext??"Modify what individuals on this role can do"}
        </p>
      </div>

      <div>
        <Card>
          <Row gutter={[16, 16]}>
            {type === "view"? null:
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Text style={{ marginLeft: 8 }}>Admin Access</Text>
                </Col>
                <Col span={12} style={{ textAlign: "end" }}>
                  <Switch
                    checked={permissionData && Object.values(roles || {}).every((role) => role)}
                    onChange={handleEnableAllRoles}
                  />
                  <Text style={{ marginLeft: 8 }}>Enable All Roles</Text>
                </Col>
              </Row>
            </Col>}
            {presets && presets?.length!==0 ?
            presets && presets?.map(({ permission_id, permission, status }) => (
              <Col
                span={12}
                key={permission_id}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 20
                }}
              >
               <Switch
                  checked={roles[permission_id]}
                  onChange={() => handleRoleChange(permission_id)}
                  disabled={type==="view"}
                />
                <div>
                  <div>
                    <Text>{`Allow ${permission}`}</Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      {permission}
                    </Text>
                  </div>
                </div>
              </Col>
            )):
            permissionData && permissionData?.map(({ id, name, description }) => (
              <Col
                span={12}
                key={id}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 20
                }}
              >
               <Switch
                  checked={roles ? roles[id] || false : false}
                  onChange={() => handleRoleChange(id)}
                  disabled={text}
                />
                <div>
                  <div>
                    <Text>{`Allow ${name}`}</Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      {description}
                    </Text>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
      </div>
    </>
  );
};

export default RolePermissionSettings;
