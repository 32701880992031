import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Avatar,
  Button,
  message,
  Upload,
  Alert,
  Spin,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { InfoIcon } from "../../asset/icons/icons";
import fileicon2 from "../../asset/fileicon2.jpg";
import ChooseAreaOfCoverageModal from "./chooseAOCModal";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getUserProfileDetails,
  postProfilePictureAvatar,
} from "../../redux/Actions";
import ComplianceDocumentsUpload from "./comDocUpload";

const ComplianceForm = () => {
  // eslint-disable-next-line no-unused-vars
  const [invalidImage, setInvalidImage] = useState(false);
  const {
    userProfileDetails,
    authUser,
    postingbusinessDocument,
    postProfilePicAvatar,
    postingProfilePicAvatar,
    updatingbusinessDocument,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  // const [image, setImage] = useState();
  const [businessAreasForm, setBusinessAreasForm] = useState({
    business_id: userProfileDetails?.data?.business?.id,
    country: "Nigeria",
    state: "",
    lga: "",
    address: "",
  });

  const checkFileSize = (file) => {
    const alaterror = () => {
      messageApi.open({
        type: "error",
        content: `${file.name} is not a png, jpg, jpeg or gif file`,
        duration: 10,
      });
    };

    const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    // console.log(file.type)
    // const isPNG = file.type === 'image/png' && 'image/jpg' && 'image/jpeg' && 'image/gif';
    if (file.size > maxSize) {
      message.error("File size must be less than 2MB.");
      return true; // Prevent upload
    }
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!allowedFormats.includes(file.type)) {
      alaterror();
      setInvalidImage(true); // Assuming you have state for this
      return true; // Prevent upload
    } else {
      setInvalidImage(false);
    }
    return false; // Allow upload
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    if (!checkFileSize(file)) {
      onError("File size exceeds limit.");
      return;
    }
  };

  const props = {
    beforeUpload: checkFileSize,
    customRequest: customRequest,
    maxCount: 1,
    onChange: async (info) => {
      // e.preventDefault();
      console.log(info.fileList);
      let reader = new FileReader();
      let file = info.file;

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY
      );
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData
        );
        if (response.status === 200) {
          // debugger
          console.log(response.data.secure_url);
          // setImage(response.data.secure_url)
          info.file.url = response.data.secure_url; // Assign the Cloudinary URL to the file object
          dispatch(
            postProfilePictureAvatar({
              // business_id: userProfileDetails?.data?.business?.id,
              // document_type_id: 1,
              avatar: response.data.secure_url,
            })
          );
        } else {
          console.log(response);
        }
      } catch (err) {
        console.log(err);
      }

      reader.onloadend = () => {
        // setStanbicFormInput({ ...stanbicFormInput, photo: file });
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    },
  };

  useEffect(() => {
    dispatch(getUserProfileDetails(authUser?.user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (postProfilePicAvatar.status === true) {
      dispatch(getUserProfileDetails(authUser?.user.id));
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postProfilePicAvatar]);
  console.log(userProfileDetails, "userProfileDetails");
  console.log(postProfilePicAvatar, "postProfilePicAvatar");
  return (
    <>
      {contextHolder}
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={24}>
          <Card>
            <Spin
              spinning={postingbusinessDocument || updatingbusinessDocument}
              tip="Uploading Document..."
            >
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 6 }}
                >
                  <div>
                    <Spin spinning={postingProfilePicAvatar} tip="Loading...">
                      <Avatar
                        shape="square"
                        size={180}
                        icon={
                          userProfileDetails?.data?.avatar ? (
                            <img
                              src={userProfileDetails?.data?.avatar}
                              alt="userprofilepicture"
                            />
                          ) : (
                            <UserOutlined />
                          )
                        }
                      />
                    </Spin>
                    <div>
                      <Upload {...props}>
                        <Button className="mt-2 uploadComplianceImage" block>
                          {userProfileDetails?.data?.avatar
                            ? "Change Passport Image"
                            : "Upload Passport Image"}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 18 }}
                >
                  <Card
                    title={
                      <p className="typography compliancecardtitletext mt-3">
                        Compliance
                      </p>
                    }
                    extra={
                      <Button className="compliancestatusbtn" size="small">
                        {userProfileDetails?.data?.status === 1
                          ? "Verified"
                          : "Unverified"}
                      </Button>
                    }
                  >
                    
                    <ComplianceDocumentsUpload />

                    <div className="uploadcaccard mt-4">
                      <div>
                        <div className="innerUploadcacdiv">
                          <img
                            src={fileicon2}
                            alt="fileicon"
                            style={{ height: "100%" }}
                            className="mt-2 fileiconcac"
                          />
                          <div style={{ marginLeft: "2em" }}>
                            <p
                              style={{ color: "#000" }}
                              className="mt-2 mb-0 typography"
                            >
                              Choose Area of Coverage
                            </p>
                            <p
                              style={{ color: "#9C9AA5" }}
                              className="mt-0 typography"
                            >
                              Pick or View geographical areas your business
                              caters for
                            </p>
                          </div>
                        </div>
                      </div>
                      <ChooseAreaOfCoverageModal
                        userProfileDetails={userProfileDetails}
                        setBusinessAreasForm={setBusinessAreasForm}
                        businessAreasForm={businessAreasForm}
                      />
                    </div>

                    <Alert
                      message={
                        <p className="typography pt-3">
                          You cannot edit compliance documents once they have
                          been verified.
                        </p>
                      }
                      type="info"
                      icon={<InfoIcon className="verifyicon" />}
                      showIcon
                      className="compliancealert mt-4"
                      // closable
                      banner
                    />
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ComplianceForm;
