import React, { useEffect, useState, useRef } from "react";
import { Button, Upload, Modal, Select, Spin } from "antd";
// import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import fileicon from "../../asset/fileicon.jpg";
import fileicon2 from "../../asset/fileicon2.jpg";
// import samplepdffile from "../../asset/sample-pdf-file.pdf";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getUserProfileDetails,
  postBusinessDocument,
  updateBusinessDocument,
} from "../../redux/Actions";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const { Option } = Select;

const ComplianceDocumentsUpload = () => {
  const {
    postBusinessDocuments,
    userProfileDetails,
    authUser,
    updateBusinessDocuments,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  // const [numPages, setNumPages] = useState(null);
  const uploadInProgress = useRef(false);
  const [isChangingIdentification, setIsChangingIdentification] =
    useState(false);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [docTypeId, setDocTypeId] = useState(null);
  const [existingDocId, setExistingDocId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const meansOfIdentificationOptions = [
    { id: 2, name: "National ID" },
    { id: 3, name: "Driver License" },
    { id: 4, name: "Voters ID" },
    { id: 5, name: "International Passport" },
  ];

  const showModal = (url, fileType) => {
    setModalContent(url);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // };

  // const onDocumentLoadError = (error) => {
  //   console.error("Failed to load PDF file:", error);
  // };

  const uploadProps = (documentTypeId, existingDocId) => ({
    showUploadList: false,
    maxCount: 1,
    onChange: async (info) => {
      if (uploadInProgress.current) return;
      uploadInProgress.current = true;

      const file = info.file.originFileObj;
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY
      );

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/raw/upload`,
          formData
        );
        if (response.status === 200) {
          const fileUrl = response.data.secure_url;
          if (docTypeId !== null) {
            if (docTypeId !== documentTypeId) {
              const newdoctype =
                documentTypeId === null ? docTypeId : documentTypeId;
              dispatch(
                postBusinessDocument({
                  business_id: userProfileDetails?.data?.business?.id,
                  document_type_id: newdoctype,
                  url: fileUrl,
                })
              );
            } else {
              dispatch(
                updateBusinessDocument(existingDocId, {
                  url: fileUrl,
                })
              );
            }
          } else {
            if (existingDocId) {
              dispatch(
                updateBusinessDocument(existingDocId, {
                  url: fileUrl,
                })
              );
            } else {
              dispatch(
                postBusinessDocument({
                  business_id: userProfileDetails?.data?.business?.id,
                  document_type_id: documentTypeId,
                  url: fileUrl,
                })
              );
            }
          }
        } else {
          console.log(response);
        }
      } catch (err) {
        console.log(err);
      } finally {
        uploadInProgress.current = false;
      }
    },
  });

  useEffect(() => {
    if (postBusinessDocuments?.status === true) {
      dispatch(getUserProfileDetails(authUser?.user.id));
      setIsChangingIdentification(false);
      setDocTypeId(null);
    }
    if (updateBusinessDocuments?.status === true) {
      dispatch(getUserProfileDetails(authUser?.user.id));
      setIsChangingIdentification(false);
      setDocTypeId(null);
    }
  }, [postBusinessDocuments, authUser, updateBusinessDocuments, dispatch]);

  const uniqueDocumentsMap = new Map();

  userProfileDetails?.data?.business?.documents.forEach((doc) => {
    uniqueDocumentsMap.set(doc.document_type.id, doc);
  });

  const requiredDocuments = [
    { id: 1, name: "CAC Certificate" },
    { id: 2, name: "Means of Identification" }, // This will be dynamic
    { id: 6, name: "Utility Bill" },
  ];

  const existingIdentificationDoc = meansOfIdentificationOptions.find(
    (option) => uniqueDocumentsMap.has(option.id)
  );

  const renderModalContent = () => {
    if (!modalContent) return null;
    debugger;
    const fileExtension = modalContent.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      return (
        // <DocViewer
        //   documents={[{ uri: modalContent }]}
        //   pluginRenderers={[PDFRenderer]}
        //   style={{ width: "100%", height: "100vh" }}
        //   disableHeader={true}
        //   disableFileName={true}
        // />
        <Spin spinning={isLoading} tip="Loading..." className="mt-4">
          <iframe
            onLoad={handleIframeLoad}
            src={modalContent}
            // src="https://dash.nibblesbakery.ng/"
            style={{
              width: "100%",
              height: "100vh",
              border: "none",
              overflow: "hidden",
              display: isLoading ? "none" : "block",
            }}
            // frameBorder="0"
            title="PDF Viewer"
          ></iframe>
        </Spin>
      );
    } else if (
      ["doc", "docx", "xls", "xlsx", "txt", "ppt", "pptx"].includes(
        fileExtension
      )
    ) {
      return (
        <DocViewer
          documents={[{ uri: modalContent }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    } else {
      return (
        <DocViewer
          documents={[{ uri: modalContent }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    }
  };

  const handleDocTypeChange = (value) => {
    setSelectedDocType(value);
    const existingDoc = uniqueDocumentsMap.get(value);
    setExistingDocId(existingDoc ? existingDoc.id : null);
  };

  const handleMeansOfIdentificationChange = () => {
    setIsChangingIdentification(true);
  };

  return (
    <>
      <p className="typography">Documents</p>
      {requiredDocuments.map((docType) => {
        let existingDoc = uniqueDocumentsMap.get(docType.id);
        if (docType.id === 2 && existingIdentificationDoc) {
          existingDoc = uniqueDocumentsMap.get(existingIdentificationDoc.id);
        }
        return (
          <div className="mb-3" key={docType.id}>
            <p className="typography">
              {`Upload ${docType.name}`} <span style={{ color: "red" }}>*</span>
            </p>
            {docType.id === 2 && existingDoc ? (
              <>
                <div className="uploadcaccard">
                  <div>
                    <div className="innerUploadcacdiv">
                      <img
                        src={fileicon2}
                        alt="fileicon"
                        style={{ height: "100%" }}
                        className="mt-2 fileiconcac"
                      />
                      <div style={{ marginLeft: "2em" }}>
                        <p
                          style={{ color: "#000" }}
                          className="mt-2 mb-0 typography"
                        >
                          {existingIdentificationDoc.name}
                        </p>
                        <Button
                          type="text"
                          onClick={() =>
                            showModal(
                              existingDoc.url,
                              existingDoc.url.split(".").pop().toLowerCase()
                            )
                          }
                          style={{ color: "#1347F5" }}
                          className="mt-0 typography"
                        >
                          View Document
                        </Button>
                        <Modal
                          title="Compliance Document"
                          open={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          width={800}
                        >
                          {renderModalContent()}
                        </Modal>
                      </div>
                    </div>
                  </div>
                  {docTypeId !== null ? (
                    <Upload {...uploadProps(selectedDocType, existingDocId)}>
                      <Button className="uploadcacbtn">
                        {existingDoc ? "Change Document" : "Upload file"}
                      </Button>
                    </Upload>
                  ) : (
                    <Button
                      className="uploadcacbtn"
                      onClick={handleMeansOfIdentificationChange}
                    >
                      Change Document
                    </Button>
                  )}
                </div>
                {isChangingIdentification && (
                  <div className="mb-3 mt-2">
                    <Select
                      placeholder="Select Means of Identification"
                      onChange={handleDocTypeChange}
                      style={{ width: 200, marginBottom: 10 }}
                    >
                      {meansOfIdentificationOptions.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                    {selectedDocType && (
                      <Upload {...uploadProps(selectedDocType, existingDocId)}>
                        <Button className="uploadcacbtn ml-2">
                          Upload new document
                        </Button>
                      </Upload>
                    )}
                  </div>
                )}
              </>
            ) : docType.id === 2 && !existingDoc ? (
              <div className="uploadcaccard">
                <div>
                  <div className="innerUploadcacdiv">
                    <img
                      src={fileicon}
                      alt={`Upload ${docType.name}`}
                      style={{ height: "100%" }}
                      className="fileiconcac"
                    />
                    <div style={{ marginLeft: "2em" }}>
                      <p
                        style={{ color: "#000" }}
                        className="mt-2 mb-0 typography"
                      >
                        {selectedDocType
                          ? "Select an image, pdf or doc to upload"
                          : "Select type of identification"}
                      </p>
                      {selectedDocType && (
                        <Upload
                          {...uploadProps(selectedDocType, existingDocId)}
                        >
                          <Button className="uploadcacbtn">Upload file</Button>
                        </Upload>
                      )}
                      {!selectedDocType && (
                        <Select
                          placeholder="Select Means of Identification"
                          onChange={handleDocTypeChange}
                          style={{ width: 200, marginTop: 10 }}
                        >
                          {meansOfIdentificationOptions.map((option) => (
                            <Option key={option.id} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="uploadcaccard">
                <div>
                  <div className="innerUploadcacdiv">
                    {existingDoc ? (
                      <img
                        src={fileicon2}
                        alt="fileicon"
                        style={{ height: "100%" }}
                        className="mt-2 fileiconcac"
                      />
                    ) : (
                      <img
                        src={fileicon}
                        alt={`Upload ${docType.name}`}
                        className="fileiconcac"
                      />
                    )}
                    <div style={{ marginLeft: "2em" }}>
                      <p
                        style={{ color: "#000" }}
                        className="mt-2 mb-0 typography"
                      >
                        {existingDoc
                          ? docType.name
                          : "Select an image, pdf or doc to upload"}
                      </p>
                      {existingDoc && (
                        <Button
                          type="text"
                          onClick={() =>
                            showModal(
                              existingDoc.url,
                              existingDoc.url.split(".").pop().toLowerCase()
                            )
                          }
                          style={{ color: "#1347F5" }}
                          className="mt-0 typography"
                        >
                          View Document
                        </Button>
                      )}
                      <Modal
                        title="Compliance Document"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={800}
                      >
                        {renderModalContent()}
                      </Modal>
                    </div>
                  </div>
                </div>
                <Upload {...uploadProps(docType.id, existingDoc?.id)}>
                  <Button className="uploadcacbtn">
                    {existingDoc ? "Change Document" : "Upload file"}
                  </Button>
                </Upload>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ComplianceDocumentsUpload;
