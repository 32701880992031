import React from "react";
import moment from "moment";
import { Col, Row } from "antd";
import PickupTable from "./OrdersAdmin/OrderSummary/PickupTable";
import CustomerTable from "./OrdersAdmin/OrderSummary/CustomerTable";
import ProductTable from "./OrdersAdmin/OrderSummary/ProductTable";
import ProgressCard from "./OrdersAdmin/OrderSummary/progressCard";
import Indicator from "./StatusIndicator/Indicator";

const Card = (content) => <div className="card-summary">{content}</div>;



const MyPendingOrders = (props) => {
  const { orderDetailsData } = props;
  const PaymentDetails = [
    {
      subtotal: orderDetailsData?.amount,
      discount: 0,
      total: orderDetailsData?.amount,
    },
  ];

  const OrderStatusDetails = {
    orderstatusid: orderDetailsData?.order_status_id,
    orderenddate: orderDetailsData?.end_date,
  };

  const OrderPickupLocation = {
    location: orderDetailsData?.location?.name,
  };
  // console.log(orderDetailsData)

  return (
    <div className="card-summary">
      <div className="d-flex justify-content-between">
        <p className="d-inline-flex align-items-center gap-3">
          <span className="order-id-title">
            Order #{orderDetailsData?.id}
          </span>{" "}
          •
          <span className="order-title-info">
            {moment(orderDetailsData?.start_date).format("MMM Do YYYY")}  {" , "}
            {moment(orderDetailsData?.start_date).format("h:mm A")}
          </span>{" "}
         •
          <span className="order-title-info">{orderDetailsData?.items?.length} Products</span>{" "}
          •
          <span className="order-title-info">Transaction Ref: {orderDetailsData?.reference}</span>{" "}
        </p>
        <span>
         <Indicator status={"green"} name={orderDetailsData?.order_status?.name === "Initial"
            ? "In Progress"
            : orderDetailsData?.order_status?.name}/>
        </span>
      </div>
      <div>
        {/* <div className='basic-text mt-5 mb-2'>ORDER DETAILS</div> */}
        <div className="d-flex gap-4 flex-column">
          <Row gutter={[16, 16]} align="top">
            <Col lg={16} sm={24} md={24}>
              {Card(<ProgressCard details={OrderStatusDetails} />)}
            </Col>
            <Col lg={8} sm={24} md={24} style={{ width: "100%" }}>
              {Card(<CustomerTable details={PaymentDetails} />)}
              <div>{Card(<PickupTable details={OrderPickupLocation} />)}</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} align="top">
            <Col lg={24} sm={24} md={24}>
              {Card(<ProductTable data={orderDetailsData?.items} />)}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MyPendingOrders;
