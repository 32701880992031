import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Typography, Card, Form, Input, Select, Spin } from "antd";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { LoadScript } from "@react-google-maps/api";

import {
  getCountry,
  getLocalGovernmentByStateId,
  getState,
} from "../redux/Actions";
import { getCountryReducer } from "../redux/Reducer/CountryReducer";
import { getAuthReducer } from "../redux/Reducer/authReducer";

const { Text } = Typography;

const BusinessLocationOnb = (props) => {
  const {
    setBusinessLocationForm,
    businessLocationForm,
    usertypeid,
    postingbusinessLocation,
  } = props;

  const { countryData } = useSelector(getCountryReducer);
  const {
    stateData,
    isloadingStateData,
    localGovernmentData,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("NG");
  const [form] = Form.useForm();
  const autocompleteServiceRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleStateChange = (value, statename) => {
    dispatch(getLocalGovernmentByStateId(value));
    setBusinessLocationForm({ ...businessLocationForm, state: statename });
  };

  const handleLocalGovernmentChange = (value, lganame) => {
    setBusinessLocationForm({ ...businessLocationForm, lga: lganame });
  };

  const handleLocationAddressChange = (value) => {
    setBusinessLocationForm({ ...businessLocationForm, address: value });
    setSearchValue(value);
  };

  const handleNumberofBranchesChange = (e) => {
    setBusinessLocationForm({
      ...businessLocationForm,
      branch: e.target.value,
    });
  };

  const handleAddressSearch = (value) => {
    setSearchValue(value);
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptions(results);
        }
      );
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    dispatch(getCountry());
    dispatch(getState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryData) {
      // console.log(stateData, "data");
    }
  }, [countryData, stateData]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Spin spinning={postingbusinessLocation} tip="Loading...">
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Text className="bestdescribetext">
              Where is your business located?
            </Text>
          </Col>
          <Col span={24} className="welcomebestdescriberow">
            <Text className="welcomebestdescribetext">
              We will use your location to provide localized and special
              services based on your region
            </Text>
          </Col>

          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Card className="aboutbusinesscard">
              <Form
                {...formLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size={"large"}
                className="typography"
              >
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Country <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <ReactFlagsSelect
                        selected={selected}
                        countries={[`${countryData.cca2}`]}
                        onSelect={(code) => setSelected(code)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          State <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      {isloadingStateData ? (
                        <Spin size="default" />
                      ) : (
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Select a state"
                          onChange={(value, option) =>
                            handleStateChange(value, option?.label)
                          }
                          filterOption={filterOption}
                          options={stateData.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Local Government <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  <Select
                    showSearch
                    placeholder="Select Local Government"
                    filterOption={filterOption}
                    onChange={(value, option) =>
                      handleLocalGovernmentChange(value, option.label)
                    }
                    options={localGovernmentData.map((localgovernment) => ({
                      value: localgovernment.id,
                      label: localgovernment.name,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Business Address <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                >
                  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["places"]}>
                    <Select
                      showSearch
                      placeholder="Enter your business address"
                      onSearch={handleAddressSearch}
                      onChange={handleLocationAddressChange}
                      options={options}
                      filterOption={false}
                      // value={searchValue === "" ? businessLocationForm.address}
                      value={searchValue === "" ? businessLocationForm.address : searchValue}
                      notFoundContent={null}
                    />
                  </LoadScript>
                </Form.Item>
                {usertypeid === 3 ? (
                  ""
                ) : (
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel aboutbusinessformitemmob">
                        "No. of stores"
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    }
                    className="aboutbusinessformitem"
                  >
                    <Input
                      type="number"
                      placeholder="e.g 10"
                      value={businessLocationForm.branch}
                      onChange={handleNumberofBranchesChange}
                    />
                  </Form.Item>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default connect()(BusinessLocationOnb);
