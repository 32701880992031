import React from "react";
import RoleBasicInfo from "./roleBasicInfo";
import RolePermissionSettings from "./rolePermissionSetting";


const ReviewDetailsUserPermission = (props) => {
  const { handleRoleNameChange, handleEmployeesChange , roles,setRoles} = props;
 const readOnly = true

  return (
    <>
      <div>
        <RoleBasicInfo
          // data={{ roleName, description, userAssigned }}
          handleRoleNameChange={handleRoleNameChange}
          // roleInformation={roleInformation} 
          handleEmployeesChange={handleEmployeesChange}
        readOnly={readOnly}
        />
      </div>
      <div>
        <RolePermissionSettings  roles={roles}
          setRoles={setRoles}
        
        />
      </div>
    </>
  );
};

export default ReviewDetailsUserPermission;
