import { Button, Form, Input, Modal } from 'antd';
import React from 'react'
import "../../Pages/Dashboard/dashboard.css";


const RejectModal = ({ openRejectModal, handleCloseRejectModal, handleReject, applicant, loading }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            className='application-modal'
            open={openRejectModal}
            title="Reject Application"
            width={800}
            centered
            styles={{ body: { height: 200, overflowY: "auto", overflowX: "hidden" } }}
            okButtonProps={{
                autoFocus: true,
            }}
            onCancel={() => handleCloseRejectModal()}
            maskClosable={false}
            footer={[
                <Button
                    className="typography rolecancelButton nibbles-button"
                    onClick={() => handleCloseRejectModal()}
                >
                    cancel
                </Button>,
                <Button
                    className="typography rejectBtn nibbles-button"
                    closable={true}
                    onClick={async () => {
                        const values = await form.validateFields();
                        handleReject(applicant?.id, values)
                        form.resetFields();
                    }}
                    loading={loading}
                >
                    Reject Application
                </Button>
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="rejectionInput"

                className="mt-3"
            >
                <Form.Item
                    name={"message"}
                    label={'Please state reason for rejection'}
                    style={{ fontSize: "30px" }}
                    rules={[{ required: true, message: "Kindly give a reason for rejection!" }]}
                >
                    <Input.TextArea
                        placeholder="Please enter reason"
                        rows={5}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RejectModal