// import { toastr } from "react-redux-toastr";
import api from "../../Service/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GetAllAdminOrdersApi = async (payload) => {
    // debugger
console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAllAdminOrdersByStatusIdApi = async (payload) => {
    // debugger
console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders?order_status_id=${payload.order_status_id}&is_archived=${payload.is_archived === undefined ? 0 : payload.is_archived}&page=${payload.page === undefined ? "" : payload.page}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex)
            throw ex;
        }
    }
};

export const GetAllUserOrdersApi = async (body, payload) => {
    // debugger
// console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders?user_id=${body}&page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAdminArchiveOrdersApi = async (payload) => {
    // debugger
// console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders/${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAdminOrderByIdApi = async (payload) => {
    // debugger
// console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders/${payload === undefined ? 103 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const UpdateOrderStatusApi = async (payload) => {
    // debugger
  if (navigator.onLine === false) {
      toast.error("No Internet Connection", "Try again");
  } else {
      try {
          const response = await api.post(`/orders/status`, payload);
          if (response.status === 200) {
              // debugger
              console.log(response)
            //   toast.success(response.data.message);
              // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
              return response.data;
          } else {
            //   toast.error(response.data.message);
              console.log(response)
          }
      } catch (error) {
        //   toast.error(error.response.data.message);
          console.log(error.response.data.message)
          throw error;
      }
  }
};

export const CreateOrderApi = async (payload) => {
    // debugger
  if (navigator.onLine === false) {
      toast.error("No Internet Connection", "Try again");
  } else {
      try {
          const response = await api.post(`/orders`, payload);
          if (response.status === 200) {
              // debugger
              console.log(response)
            //   toast.success(response.data.message);
              // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
              return response.data;
          } else {
            //   toast.error(response.data.message);
              console.log(response)
          }
      } catch (error) {
        //   toast.error(error.response.data.message);
          console.log(error.response.data.message)
          throw error;
      }
  }
};

export const GetAllTransactionLogApi = async (payload) => {
    // debugger
// console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/transactions?page=${payload===undefined?1:payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAllQueueOrdersApi = async (payload) => {
    // debugger
console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/queue/orders`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};