import React, { useRef, useState, useEffect } from "react";
import { Col, Row, Typography, Button, Form, Input } from "antd";
import nibbleslogo from "../../asset/nibbleslogo.jpg";
import "./onboarding.css";
// import { ArrowLeftIcon, GoogleIcon, PhoneIcon, SendIcon } from "../../asset/icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getEnterOtp, getResendOtp } from "../../redux/Actions";

const { Title, Text } = Typography;

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

export default function EnterOtp() {
  const navigate = useNavigate();
  const { otpcode, verifyingotp } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [otpCodeDetails, setOtpCodeDetails] = useState({
    code: "",
    email: ""
  });
  const initialTimer = 600; // 10 minutes in seconds
  const [timer, setTimer] = useState(initialTimer);
  const [counting, setCounting] = useState(true)

  const inputRefs = useRef([]);

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const usersignupemail = JSON.parse(localStorage.getItem("usersignupemail"))

  // Function to move focus to the next input
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const updatedOtp = otpCodeDetails.code.substr(0, index) + value + otpCodeDetails.code.substr(index + 1);
    setOtpCodeDetails({ ...otpCodeDetails, code: updatedOtp, email: usersignupemail });

    // Move focus to the next input field
    if (value && /^\d*$/.test(value) && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move focus to the previous input field on backspace
    if (e.key === "Backspace" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleInputs = (e, index) => {
    if (
      !/^\d*$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    } else {
      handleKeyDown(e, index);
    }
  };

  //   useEffect(() => {
  //     if (usersignupemail) {
  //         // debugger
  //         // navigate("/enterotp")
  //         setOtpCodeDetails({...otpCodeDetails, email: usersignupemail })
  //     } 
  // }, [otpCodeDetails, usersignupemail])

  const verityOTP = () => {
    // debugger
    dispatch(getEnterOtp(otpCodeDetails))
  };

  const handleResendOTP = () => {
    // Logic to resend OTP
    // You can add your API call or resend function here
    console.log('Resending OTP...');
    const email = usersignupemail
    dispatch(getResendOtp({email: email}))
    setTimer(10); // Reset timer to 10 minutes
    setCounting(true); // Start countdown
  };

  useEffect(() => {
    let interval;
    if (timer > 0 && counting) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setCounting(false);
    }
    return () => clearInterval(interval);
  }, [timer, counting]);

  useEffect(() => {
    if (!counting && timer === 0) {
      // Timer finished, enable resend OTP button
      setTimer(initialTimer); // Reset timer for the next OTP
    }
  }, [counting, timer, initialTimer]);

  useEffect(() => {
    if (otpcode?.status === true) {
      // debugger
      navigate("/signin")
    }
    // return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpcode])

  // useEffect(() => {
  //   handleResendOTP();
  //   // return null
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  // console.log(usersignupemail, "otpCodeDetails")

  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          className="getstartedbackground"
        ></Col>

        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartefirstrowlogo">
          <Row className="getstartedrowlogo">
            <Col span={24} className="getstartedcollogo">
              <img alt="nibbleslogo" src={nibbleslogo} />
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpadotp">
              {/* <div> */}
              <Title
                className="typography getstartedtext"
                style={{ marginBottom: "0px" }}
                level={1}
              >
                Enter Code
              </Title>
              <Text className="joinbakery typography">
                We sent an OTP code to your email m**l@example.com. Kindly fill
                in the code below.
              </Text>
            </Col>

            <Col span={24} className="getstartedcolpadotp">
              <Form
                {...formLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size={"large"}
                // onValuesChange={handleFormValuesChange}
                className="typography"
              >
                <Col
                  xs={{ span: 24 }}
                  className="otpLayoutinputs"
                  style={{ marginBottom: "2em" }}
                >
                  {/* <Form.Item label="" name="otp" className="otpLayoutinputs"> */}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Input
                      key={index}
                      type=""
                      ref={(el) => (inputRefs.current[index] = el)}
                      value={otpCodeDetails.code[index]}
                      maxLength={1}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleInputs(e, index)}
                      className="otpinputs"
                    />
                  ))}
                  {/* </Form.Item> */}
                </Col>

                <Col xs={{ span: 24 }}>
                  {/* <Form.Item> */}
                  {/* <Link to={"/onboarding"} style={{textDecoration: "none"}}> */}
                  <Button
                    className="signupbutton typography"
                    htmlType="submit"
                    block
                    disabled={otpCodeDetails.code.length === 5 || otpCodeDetails.code === "" || verifyingotp}
                    onClick={verityOTP}
                  >
                    {/* Verify Email */}
                    {verifyingotp ? 'Loading...' : 'Verify Email'}
                  </Button>
                  {/* </Link> */}
                  {/* </Form.Item> */}
                </Col>

                <Col xs={{ span: 24 }}>
                  {/* <Form.Item> */}
                    {/* <Link to={"/onboarding"} style={{textDecoration: "none"}}> */}
                    <Button
                      className="resendotpbutton typography"
                      // type="primary"
                      block
                      // disabled={true}
                      // onClick={verityOTP}
                      onClick={handleResendOTP}
                      disabled={counting}
                    >
                      {counting ? `Resend OTP in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}` : 'Resend OTP'}
                      {/* {verifyingotp ? 'Loading...' : 'Resend OTP'} */}
                    </Button>
                  {/* </Form.Item> */}
                </Col>

                {/* <Col span={24} className="getstartedcolpadterms">
                  <Text className=" typography">
                    <Link
                      to={"#"}
                      style={{ color: "#998C16", textDecoration: "underline" }}
                      className="signinlink"
                    >
                      Change your email
                    </Link>
                  </Text>
                </Col> */}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
