import { Col, Row } from 'antd';
import React from 'react'
import ProductTable from './OrderSummary/ProductTable';
import CustomerTable from './OrderSummary/CustomerTable';
import PickupTable from './OrderSummary/PickupTable';
import ExtraNotesTable from './OrderSummary/ExtraNotesTable';

const Card = (content) => (
    <div className='card-summary'>
        {content}
    </div>
)



const OrderSummary = (props) => {
  const {data, allData, location} = props
    

  const PaymentDetails = [
    {
        subtotal: allData.amount,
        discount: 0,
        total: allData.amount,

    }
]

const OrderPickupLocation = {
    location: location,
  };

    return (
        <div className='pt-4'>
        <div className='basic-text mt-5 mb-2'>ORDER DETAILS</div>
        <div className='d-flex gap-4 flex-column'>
            <Row gutter={[16, 16]} align="top">
                <Col lg={16} sm={24} md={24}>
                    {Card(<ProductTable data = {data} allData ={allData} />)}
                </Col>
                <Col lg={8} sm={24} md={24} style={{width:"100%"}}>
                    {Card(<CustomerTable details={PaymentDetails} />)}
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="top">
                <Col lg={16} sm={24} md={24}>
                    {Card(<PickupTable details={OrderPickupLocation} />)}
                </Col>
                <Col lg={8} sm={24} md={24} style={{width:"100%"}}>
                    {Card(<ExtraNotesTable details={PaymentDetails} />)}
                </Col>
            </Row>
        </div>
    </div>
    )
}

export default OrderSummary