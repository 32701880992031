import { all, call, fork, takeEvery } from "redux-saga/effects";
import requestFunction from "../../Utils/sagaGenericFunction";
import { getCountrySuccess, getCountryFailure} from "../Actions";
import { GET_COUNTRY } from "../ActionTypes";
import { GetCountryApi } from "../Api";
// =================Country sagas================
const getCountryWorker = function* ({ payload }) {
  // debugger
    yield call(requestFunction, getCountrySuccess, getCountryFailure, GetCountryApi, payload);
  };
  export const getCountryWatcher = function* () {
    // debugger
    yield takeEvery(GET_COUNTRY, getCountryWorker);
  };
  export default function* countrySaga() {
    yield all([
        fork(getCountryWatcher),
    ]);
}