import React from 'react';
import { Table } from 'antd';

const TopCustomers = (props) => {

  // Utility function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(value);
};


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) =>formatCurrency(text),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
  ];

  // const data = [
  //   {
  //     key: '1',
  //     name: 'ABC Corp',
  //     type: 'Distributor',
  //     totalAmount: 120000,
  //     location: 'New York, NY',
  //   },
  //   {
  //     key: '2',
  //     name: 'XYZ Retailers',
  //     type: 'Retailer',
  //     totalAmount: 90000,
  //     location: 'Los Angeles, CA',
  //   },
  //   {
  //     key: '3',
  //     name: 'Mega Distributors',
  //     type: 'Distributor',
  //     totalAmount: 150000,
  //     location: 'Chicago, IL',
  //   },
  //   {
  //     key: '4',
  //     name: 'Retail Heaven',
  //     type: 'Retailer',
  //     totalAmount: 75000,
  //     location: 'Houston, TX',
  //   },
  //   {
  //     key: '5',
  //     name: 'Global Traders',
  //     type: 'Distributor',
  //     totalAmount: 200000,
  //     location: 'San Francisco, CA',
  //   },
  // ];

  return <Table columns={columns} dataSource={props.data} pagination={false} loading={props.loading}/>;
};

export default TopCustomers;
