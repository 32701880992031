import React from "react";
import { Card, Radio } from "antd";
import { DialIcon, EmailIcon } from "../asset/icons/icons";
// import nibbleslogo from "../asset/nibbleslogo.jpg"
// import { CarIcon, RetailChain } from '../asset/icons/icons';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const RadioRetailerOptionCard = ({ setSelectedOption, selectedOption, item}) => {

  const handleRadioChange = (e, distid) => {
    // debugger
    setSelectedOption(e.target.value);
  };

  // const handleRadioChange = (e) => {
  //     setSelectedOption(e.target.value);
  // };
  console.log(item, "item")
  return (
    <Card
      style={{
        border:
          selectedOption === item.id
            ? "0.818px solid #998C16"
            : "1px solid #d9d9d9",
        cursor: "pointer", // Add cursor pointer for clickable effect
      }}
      onClick={() => setSelectedOption(item.id)} // Use arrow function to pass parameters
      className="radioOnbCard"
      key={item.id}
    >
      <div className="radioImageDescdiv">
        <div
          className="radioImageIcondiv"
          style={{
            background: selectedOption === item.id ? "#998C16" : "#F8F7F7",
          }}
        >
          <img src={item?.user_type?.icon} alt={item?.business?.name} />
        </div>
        <div className="radiotitledescdiv">
          <p className="bestdescribeoptiontitle">{item?.business?.name === "Independent Distributorship" ? "Independent Distributor" : item?.business?.name }</p>
          <p className="bestdescribeoptiondescription">{item?.business?.address + ", " + item?.business?.lga + ", " + item?.business?.state}</p>
          {
            item?.phone !== undefined ? 
            <p className="bestdescribeoptiondescription" style={{marginTop: "-1em"}}><span><DialIcon/> </span>{item?.business?.office_phone}</p>
            :
            ""
          }
          {
            item.email !== undefined ? 
            <p className="bestdescribeoptiondescription" style={{marginTop: "-1em"}}><span><EmailIcon/> </span>{item?.business?.contact_email}</p>
            :
            ""
          }
        </div>
      </div>
      <Radio.Group
        className="radiogrouponbdiv"
        type="solid"
        value={selectedOption}
        onChange={e => handleRadioChange(e, item.id)}
      >
        <Radio value={item.id}></Radio>
      </Radio.Group>
    </Card>
  );
};

export default RadioRetailerOptionCard;
