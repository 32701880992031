import { toast } from "react-toastify";
import api from "../../Service/api";

export const GetPermissionApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get('/permissions');
            if (response.status === 200) {
                console.log(response)
                return response.data.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};
export const CreateRoleWithPermissionApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post('/roles/assign/permissions', payload);
            if (response.status === 200) {
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};
export const UpdateRolePermissionApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post('/roles/update/permissions', payload);
            if (response.status === 200) {
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};
