import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Modal, Form, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getLocalGovernmentByStateId,
  getState,
  updateBusinessArea,
} from "../../redux/Actions";
import { LoadScript } from "@react-google-maps/api";
import { LocationIcon } from "../../asset/icons/icons";

// Define libraries array outside of the component to keep it static
const libraries = ["places"];

const EditAdminAOCModal = (props) => {
  const {
    stateData,
    isloadingStateData,
    localGovernmentData,
    loadedStateData,
    updatingbusinessAreas,
  } = useSelector(getAuthReducer);

  const { onCancel, aocData, open } = props;
  const [stateValue, setStateValue] = useState(null);
  const [lgaValue, setLgaValue] = useState(null);
  const dispatch = useDispatch();
  const [optionsEdit, setOptionsEdit] = useState([]);
  const autocompleteServiceRef = useRef(null);
  const [updatedFormValues, setUpdatedFormValues] = useState({
    country: "Nigeria",
    state: "",
    lga: "",
    address: [],
  });

  useEffect(() => {
    if (open) {
      setUpdatedFormValues({
        country: "Nigeria",
        state: aocData?.state,
        lga: aocData?.lga,
        address: aocData?.address_list,
      });
      setLgaValue(aocData?.lga);
      if (aocData?.state && stateData?.length) {
        const selectedState = stateData?.find(
          (item) => item.name === aocData.state
        );
        if (selectedState) {
          setStateValue(selectedState?.id);
          dispatch(getLocalGovernmentByStateId(selectedState.id));
        }
      }
    }
  }, [open, aocData, stateData, dispatch]);

  useEffect(() => {
    if (aocData?.lga && localGovernmentData?.length > 0) {
      const selectedLga = localGovernmentData?.find(
        (item) => item?.name === aocData?.lga
      );
      if (selectedLga) {
        setLgaValue(selectedLga?.id);
      }
    }
  }, [localGovernmentData, aocData]);

  useEffect(() => {
    if (!loadedStateData) {
      dispatch(getState());
    }
  }, [loadedStateData, dispatch]);

  const handleStateChange = (value, option) => {
    setStateValue(value);
    setUpdatedFormValues({ ...updatedFormValues, state: option.label });
    dispatch(getLocalGovernmentByStateId(value));
  };

  const handleLocalGovernmentChange = (value, option) => {
    setLgaValue(value);
    setUpdatedFormValues({ ...updatedFormValues, lga: option.label });
  };

  const handleStreetAddressChangeEdit = (addresses) => {
    setUpdatedFormValues({ ...updatedFormValues, address: addresses });
  };

  const handlePlaceChangedEdit = (value) => {
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptionsEdit(results);
        }
      );
    } else {
      setOptionsEdit([]);
    }
  };

  const handleUpdateLocation = () => {
    dispatch(updateBusinessArea({ id: aocData?.id, body: updatedFormValues }));
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal
        title={`Edit coverage (${aocData?.state}, ${aocData?.country}).`}
        open={open}
        width={600}
        centered
        onCancel={onCancel}
        style={{
          body: { height: 300, overflowY: "auto", overflowX: "hidden" },
        }}
        footer={[
          <Button
            key="submit"
            className="addcomplianceloc"
            icon={<LocationIcon color={"#FFF"} />}
            onClick={handleUpdateLocation}
            disabled={
              updatedFormValues.state === "" ||
              updatedFormValues.lga === "" ||
              updatedFormValues.address?.length === 0 ||
              updatingbusinessAreas
            }
          >
            {updatingbusinessAreas ? "Loading..." : "Update Location"}
          </Button>,
        ]}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <Form layout="vertical">
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      State
                    </label>
                  }
                  className="aboutbusinessformitem"
                  rules={[{ required: true }]}
                >
                  {isloadingStateData ? (
                    <Spin size="default" />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select a state"
                      onChange={handleStateChange}
                      value={stateValue}
                      filterOption={filterOption}
                      options={stateData?.map((state) => ({
                        value: state.id,
                        label: state.name,
                      }))}
                      size="large"
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      LGA your business covers
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  {localGovernmentData.length === 0 ? (
                    <Spin size="default" />
                  ) : (
                    <Select
                      showSearch
                      placeholder="Select Local Government"
                      filterOption={filterOption}
                      value={lgaValue}
                      onChange={handleLocalGovernmentChange}
                      options={localGovernmentData?.map((localgovernment) => ({
                        value: localgovernment.id,
                        label: localgovernment.name,
                      }))}
                      size="large"
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Areas you cover in your LGA (Type and select suggestions)
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  {/* LoadScript should only be loaded once */}
                  {window.google ? (
                    <Select
                      mode="multiple"
                      value={updatedFormValues?.address}
                      placeholder="Enter a location"
                      notFoundContent={null}
                      onSearch={handlePlaceChangedEdit}
                      onChange={(values) => handleStreetAddressChangeEdit(values)}
                      style={{ width: "100%" }}
                      options={optionsEdit}
                    />
                  ) : (
                    <LoadScript
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                      libraries={libraries}
                      onLoad={() => console.log('Google Maps API script loaded')}
                      onError={(error) => console.log('Error loading Google Maps API script', error)}
                    />
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditAdminAOCModal;
