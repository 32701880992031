import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Input } from "antd";
// import nibbleslogo from "../../asset/nibbleslogo.jpg"
// import "./onboarding.css";
// import { ArrowLeftIcon, GoogleIcon, PhoneIcon, SendIcon } from "../../asset/icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getChangePassword, getUserProfileDetails } from "../../redux/Actions";

// const { Title, Text } = Typography;

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const ComSecurity = () => {
  const navigate = useNavigate();
  const {
    authUser,
    userProfileDetails,
    changePassworddetail,
    changePasswordLoading,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();

  const [newPasswordDetails, setNewPasswordDetails] = useState({
    old_password: "",
    password: "",
    email: userProfileDetails?.data?.email,
  });
  const [newPasswordErrors, setNewPasswordErrors] = useState({
    old_password: "",
    password: "",
    confirmPassword: "",
  });
  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    dispatch(getUserProfileDetails(authUser?.user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOldPasswordChange = (e) => {
    const passwordInput = e.target.value;
    setNewPasswordDetails({
      ...newPasswordDetails,
      old_password: passwordInput,
    });

    // Validate password field
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    if (!passwordInput || !passwordRegex.test(passwordInput)) {
      let errorMsg = "Password must meet the following criteria:";
      if (passwordInput && passwordInput.length < 6) {
        errorMsg += " be at least 6 characters long,";
      }
      if (passwordInput && !/(?=.*[!@#$%^&*()_+])/.test(passwordInput)) {
        errorMsg += " include at least one symbol,";
      }
      if (passwordInput && !/(?=.*[a-z])/.test(passwordInput)) {
        errorMsg += " include at least one lowercase letter,";
      }
      if (passwordInput && !/(?=.*[A-Z])/.test(passwordInput)) {
        errorMsg += " include at least one uppercase letter,";
      }
      if (passwordInput && !/(?=.*\d)/.test(passwordInput)) {
        errorMsg += " include at least one digit,";
      }
      errorMsg = errorMsg.slice(0, -1); // Remove the last comma
      setNewPasswordErrors((prevErrors) => ({
        ...prevErrors,
        old_password: errorMsg,
      }));
    } else {
      setNewPasswordErrors((prevErrors) => ({
        ...prevErrors,
        old_password: "",
      }));
    }
  };

  const handlePasswordChange = (e) => {
    const passwordInput = e.target.value;
    setNewPasswordDetails({ ...newPasswordDetails, password: passwordInput });

    // Validate password field
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    if (!passwordInput || !passwordRegex.test(passwordInput)) {
      let errorMsg = "Password must meet the following criteria:";
      if (passwordInput && passwordInput.length < 6) {
        errorMsg += " be at least 6 characters long,";
      }
      if (passwordInput && !/(?=.*[!@#$%^&*()_+])/.test(passwordInput)) {
        errorMsg += " include at least one symbol,";
      }
      if (passwordInput && !/(?=.*[a-z])/.test(passwordInput)) {
        errorMsg += " include at least one lowercase letter,";
      }
      if (passwordInput && !/(?=.*[A-Z])/.test(passwordInput)) {
        errorMsg += " include at least one uppercase letter,";
      }
      if (passwordInput && !/(?=.*\d)/.test(passwordInput)) {
        errorMsg += " include at least one digit,";
      }
      errorMsg = errorMsg.slice(0, -1); // Remove the last comma
      setNewPasswordErrors((prevErrors) => ({
        ...prevErrors,
        password: errorMsg,
      }));
    } else {
      setNewPasswordErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  // const handleConfirmPasswordChange = (event) => {
  //     const newConfirmPassword = event.target.value;

  //     // Validate the confirm password
  //     const newErrors = validateConfirmPassword(newConfirmPassword);

  //     // Set the confirm password and errors state
  //     setNewPasswordDetails({ ...newPasswordDetails, confirmPassword: newConfirmPassword })
  //     setNewPasswordErrors((prevErrors) => ({ ...prevErrors, confirmPassword: newErrors }));
  // };

  // const validateConfirmPassword = (confirmPassword) => {
  //     return confirmPassword !== newPasswordDetails.password ? "Passwords does not match." : "";
  // };

  const handleNewPassword = (e) => {
    // debugger
    dispatch(getChangePassword(newPasswordDetails));

    // if (authUser=== undefined) {
    //     // debugger
    //     return null
    // } else if (authUser?.user?.user_type === null ) {
    //     navigate("/onboarding")
    // } else if (authUser?.user?.user_type !== null ) {
    //     navigate("/dashboard")
    // } else {
    //     return null
    // }
    // console.log(newPasswordDetails, "inhandlesignin")
  };

  useEffect(() => {
    if (changePassworddetail === true) {
      navigate("/dashboard");
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassworddetail]);

  return (
    <>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={24}>
          <Col className="dashboardtitlescol" span={24}>
            <p className="dashboardtitlestypog">Security</p>
          </Col>

          <Col span={24} className="getstartedcolpad">
            <Form
              {...formLayout}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size={"large"}
              // onValuesChange={handleFormValuesChange}
              className="typography"
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 16 }}
                xl={{ span: 16 }}
              >
                <Form.Item
                  label={
                    <label className="typography fw-bold">
                      Old Password{" "}
                      <span style={{ color: "red", marginBottom: "2em" }}>
                        *
                      </span>
                    </label>
                  }
                  name="old_password"
                  rules={[
                    {
                      // required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    value={newPasswordDetails.old_password}
                    onChange={handleOldPasswordChange}
                    placeholder="Enter your old password here"
                  />
                </Form.Item>
                {newPasswordErrors.old_password && (
                  <p className="error typography mb-3">
                    Password: {newPasswordErrors.old_password}
                  </p>
                )}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 16 }}
                xl={{ span: 16 }}
              >
                <Form.Item
                  label={
                    <label className="typography fw-bold">
                      New Password{" "}
                      <span style={{ color: "red", marginBottom: "2em" }}>
                        *
                      </span>
                    </label>
                  }
                  name="password"
                  rules={[
                    {
                      // required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    value={newPasswordDetails.password}
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                  />
                </Form.Item>
                {newPasswordErrors.password && (
                  <p className="error typography mb-3">
                    Password: {newPasswordErrors.password}
                  </p>
                )}
              </Col>

              {/* <Col xs={{ span: 16 }}>
                                    <Form.Item
                                        label={<label className="typography fw-bold">Confirm Password</label>}
                                        name="confirmPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            type='confirmpassword'
                                            value={newPasswordDetails.confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            placeholder="Enter your password"
                                        />
                                    </Form.Item>
                                    {newPasswordErrors.confirmPassword && <p className="error">{newPasswordErrors.confirmPassword}</p>}
                                </Col> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 16 }}
                xl={{ span: 16 }}
              >
                {/* <Form.Item> */}
                {/* <Link to={"/enterotp"} style={{textDecoration: "none"}}> */}
                <Button
                  onClick={handleNewPassword}
                  className="signupbutton typography"
                  htmlType="submit"
                  disabled={
                    newPasswordDetails.password === "" ||
                    newPasswordErrors.password !== "" ||
                    changePasswordLoading
                  }
                  block
                >
                  {changePasswordLoading ? "Loading..." : "Change Password"}
                </Button>
                {/* </Link> */}
                {/* </Form.Item> */}
              </Col>
            </Form>
          </Col>
        </Col>
      </Row>
    </>
  );
};
export default ComSecurity;
