import React, { useEffect, useState } from "react";
import { Input, Button, message, Steps, Select, Form, InputNumber } from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { getAuthReducer } from "../../../../redux/Reducer/authReducer";

const { Option } = Select;

// Utility function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(value);
};

const CreateOrderStep = ({ onFinish, setConfettiActive, confettiActive }) => {
  const { authUser } = useSelector(getAuthReducer);
  const [current, setCurrent] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const [initialProducts, setInitialProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: "",
    name: "",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    id: authUser?.user?.location_id,
    name: "",
  });
  const [customerList, setCustomerList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  // const [orderType, setOrderType] = useState('');
  // const [isExpressOrder, setIsExpressOrder] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false);


  useEffect(() => {
    if (confettiActive === true) {
      setCurrent(0);
    }
  }, [confettiActive, current]);

  const products = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/products`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setInitialProducts(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const handleCustomerChange = (value) => {
    const customer = customerList.find((cus) => cus.id === value);
    setSelectedCustomer({
      id: customer.id,
      name: customer.name,
    });
  };

  const handleLocationChange = (value) => {
    const location = locationList.find((loc) => loc.id === value);
    setSelectedLocation({
      id: location.id,
      name: location.address,
    });
  };

  console.log(locationList, "locationList");

  const independentDistributors = () => {
    const bearerToken = authUser.access_token;
    fetch(
      `${process.env.REACT_APP_API_BASE_DEV_URL}/users?user_type_id=3&status=1`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setCustomerList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const locations = () => {
    const bearerToken = authUser.access_token;
    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/locations`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setLocationList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  useEffect(() => {
    products();
    independentDistributors();
    locations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = async () => {
    // debugger;
    setIsLoadingCreateOrder(true);
    const payload = {
      user_id: selectedCustomer.id,
      location_id: selectedLocation.id,
      is_internal_order: true,
      is_approved: false,
      // "is_express": isExpressOrder,
      items: orderItems.map((item) => ({
        product_id: item.id,
        qty: item.quantity,
      })),
    };

    try {
      const bearerToken = authUser.access_token;
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_DEV_URL}/orders`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        }
      );

      setOrderItems([]);
      setInitialProducts([]);
      // setSelectedCustomer([]);
      setSelectedCustomer({});
      setSelectedLocation({});

      const responseData = await response.json();
      console.log("Order submitted successfully", responseData);
      if (responseData?.status === true) {
        message.success("Order created successfully!");
        setConfettiActive(true);
        setCurrent(0);
      } else {
        message.error(
          `Failed to Create Order, Kindly check your order details and try again!`
        );
      }
      onFinish();
      setIsLoadingCreateOrder(false);
    } catch (error) {
      console.error("Failed to submit order. Please try again", error);
      setIsLoadingCreateOrder(false);
    }
  };

  const handleAddProduct = () => {
    if (selectedProduct && quantity > 0) {
      const newItem = {
        ...selectedProduct,
        quantity,
        subtotal: +Number(selectedProduct?.amount ?? 0)?.toFixed(2) * quantity,
      };
      setOrderItems([...orderItems, newItem]);
      setSelectedProduct(null);
      setQuantity(1);
    }
  };

  const handleProductSelect = (productId) => {
    const product = initialProducts?.find((product) => product.id === productId);
    setSelectedProduct(product);
  };

  const handleQuantityChange = (newQuantity, index) => {
    const updatedItems = orderItems?.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          quantity: newQuantity,
          subtotal: item?.amount * newQuantity,
        };
      }
      return item;
    });
    setOrderItems(updatedItems);
  };

  const handleDeleteProduct = (index) => {
    const newItems = orderItems.filter((_, idx) => idx !== index);
    setOrderItems(newItems);
  };

  const totalAmount = orderItems.reduce((acc, item) => acc + item.subtotal, 0);

  const steps = [
    {
      title: "Customer",
      content: (
        <>
          <div>
            <div className="row">
              <div className="row">
                <div className="col mt-4">
                  <Form.Item label="Select Customer">
                    <Select
                      value={selectedCustomer.id}
                      onChange={handleCustomerChange}
                    >
                      {customerList.map((cus) => (
                        <Option key={cus?.id} value={cus?.id}>
                          {cus?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Select Location">
                    <Select
                      value={selectedLocation?.id}
                      onChange={handleLocationChange}
                      defaultValue={selectedLocation?.id}
                      disabled={authUser?.user?.roles?.name !== "Super Admin" ? true : false}
                    >
                      {locationList.map((loc) => (
                        <Option key={loc?.id} value={loc?.id}>
                         {loc?.name} - {loc?.address}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  {/* <div className="col-6">
                    <Form.Item label="Order Type">
                      <Select
                        placeholder="Select order type"
                        value={orderType}
                        onChange={setOrderType}
                      >
                        <Option value="regular">Regular</Option>
                        <Option value="special">Special</Option>
                      </Select>
                    </Form.Item>
                  </div> */}
                  {/* <div className="col-6">
                    <Form.Item label="Express Order">
                      <Checkbox
                        checked={isExpressOrder}
                        onChange={(e) => setIsExpressOrder(e.target.checked)}
                      >
                        Express Order
                      </Checkbox>
                    </Form.Item>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Product",
      content: (
        <>
          <div className="mt-4">
            <h6>Choose product and quantity</h6>
            <div className="border rounded-2 py-2">
              <div className="row bg-light mx-1">
                <div className="col-5 fw-bold">PRODUCT</div>
                <div className="col-2 fw-bold">QUANTITY</div>
                <div className="col-2 fw-bold">PRICE</div>
                <div className="col-2 fw-bold">SUBTOTAL</div>
                <div className="col-1 fw-bold"></div>
              </div>

              {orderItems.map((item, index) => (
                <div className="row mx-1 mt-3" key={index}>
                  <div className="col-5">
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={item?.images[0]?.url}
                          width={40}
                          height={35}
                          className="border border-1 rounded-2"
                          alt="product"
                        />{" "}
                      </div>
                      <div className="col-10">{item?.name}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <InputNumber
                      min={1}
                      value={item?.quantity}
                      onChange={(value) => handleQuantityChange(value, index)}
                    />
                  </div>
                  <div className="col-2">{formatCurrency(item?.amount)}</div>
                  <div className="col-2">{formatCurrency(item?.subtotal)}</div>
                  <div className="col-1">
                    <span
                      className="text-danger mx-1"
                      onClick={() => handleDeleteProduct(index)}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>
                </div>
              ))}
              <hr className="mb-3" />

              <div className="row mx-1">
                <div className="col-6">
                  <Form.Item>
                    <Select
                      value={selectedProduct?.id || ""}
                      onChange={handleProductSelect}
                    >
                      {initialProducts.map((product) => (
                        <Option key={product?.id} value={product?.id}>
                          {product?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-2">
                  <Form.Item>
                    <InputNumber
                      min={1}
                      value={quantity}
                      onChange={setQuantity}
                    />
                  </Form.Item>
                </div>
                <div className="col-2">
                  {formatCurrency(
                    +Number(selectedProduct?.amount ?? 0).toFixed(2) || 0.0
                  )}
                </div>
                <div className="col-2">
                  {formatCurrency(
                    (
                      +Number(selectedProduct?.amount ?? 0).toFixed(2) *
                      quantity
                    ).toFixed(2) || 0.0
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  className="mx-2 mb-3 addOrder"
                  style={{ backgroundColor: "#131523", color: "white" }}
                  onClick={handleAddProduct}
                >
                  Click to add product <PlusOutlined />
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-end my-5">
              <span>
                TOTAL AMOUNT:{" "}
                <span className="fw-bold">
                  {formatCurrency(totalAmount?.toFixed(2))}
                </span>
              </span>
            </div>

            <div className="d-flex justify-content-start my-5">
              <Form.Item label="Apply discount/Promos" style={{ width: 600 }}>
                <Input placeholder="Input percentage (%) or amount (N) if applicable" />
              </Form.Item>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Review Order",
      content: (
        <>
          <div>
            <div className="row">
              <div className="row">
                <div className="col mt-4">
                  <Form.Item label="Customer">
                    <Input value={selectedCustomer?.name} disabled />
                  </Form.Item>

                  <Form.Item label="Customer">
                    <Input value={selectedLocation?.name} disabled />
                  </Form.Item>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  {/* <div className="col-6">
                    <Form.Item label="Order Type">
                      <Input value={orderType} readOnly />
                    </Form.Item>
                  </div> */}
                  {/* <div className="col-6">
                    <Form.Item label="Express Order">
                      <Checkbox checked={isExpressOrder} disabled />
                    </Form.Item>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h6>Product details</h6>
            <div className="border rounded-2 py-2">
              <div className="row bg-light mx-1">
                <div className="col-6 fw-bold">PRODUCT</div>
                <div className="col-2 fw-bold">QUANTITY</div>
                <div className="col-2 fw-bold">PRICE</div>
                <div className="col-2 fw-bold">SUBTOTAL</div>
              </div>

              {orderItems.map((item, index) => (
                <div className="row mx-1 mt-3" key={index}>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={item?.images[0]?.url}
                          width={40}
                          height={35}
                          className="border border-1 rounded-2"
                          alt="product"
                        />{" "}
                      </div>
                      <div className="col-10">{item?.name}</div>
                    </div>
                  </div>
                  <div className="col-2">x{item?.quantity}</div>
                  <div className="col-2">{formatCurrency(item?.amount)}</div>
                  <div className="col-2">{formatCurrency(item?.subtotal)}</div>
                </div>
              ))}

              <div
                className="mt-4 d-flex justify-content-end"
                style={{
                  backgroundColor: "#fffff4",
                  border: "1px 1px 1px solid black",
                }}
              >
                <div className="m-2 mx-3 ">
                  <div className="mb-1">
                    Subtotal:{" "}
                    <span className="" style={{ marginLeft: "57px" }}>
                      {formatCurrency(totalAmount)}
                    </span>
                  </div>
                  <div className="mb-1">
                    Discount: <span style={{ marginLeft: "55px" }}>0%</span>
                  </div>
                  <div>
                    Total Amount{" "}
                    <span className="fw-bold" style={{ marginLeft: "30px" }}>
                      {formatCurrency(totalAmount)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps
        current={current}
        items={steps.map((step) => ({ key: step.title, title: step.title }))}
      />
      <div>{steps[current].content}</div>
      <div className="d-flex justify-content-end" style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button onClick={prev} className="mx-3">
            Back
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={next}>
            Continue
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={handleFinish}
            disabled={isLoadingCreateOrder}
          >
            Done
          </Button>
        )}
        {/* <Button onClick={onCancel} className='mx-3'>Cancel</Button> */}
      </div>
    </>
  );
};

const CreateAdminOrder = (props) => {
  const { setConfettiActive, confettiActive } = props;

  const usermanText = {
    width: "189px",
    flexShrink: 0,
    color: "#45464E",
    fontFamily: '"MADE TOMMY"',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px",
  };

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px", margin: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Orders &gt; Create Order</span>
            </div>
            <div className="col d-flex justify-content-end">
              {/* <button className='mx-2' style={newEmpButton} onClick={() => setModalVisible(true)}>
              {isMobileDevice?"New Order" :"Create new order"}
              </button>
              <button onClick={1} style={exportButton}>
                Export
              </button>
              <pre>{JSON.stringify(formValues, null, 2)}</pre> */}
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <Form layout="vertical">
            <CreateOrderStep
              setConfettiActive={setConfettiActive}
              confettiActive={confettiActive}
            />
          </Form>
        </div>
      </div>
    </>
  );
};
export default CreateAdminOrder;
