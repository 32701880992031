import { all, call, fork, takeEvery } from "redux-saga/effects";
import requestFunction from "../../Utils/sagaGenericFunction";
import { CREATE_ROLE_WITH_PERMISSION, GET_PERMISSION, UPDATE_ROLE_PERMISSION } from "../ActionTypes";
import { createRoleWithPermissionFailure, createRoleWithPermissionSuccess, getPermissionFailure, getPermissionSuccess, updateRolePermissionFailure, updateRolePermissionSuccess } from "../Actions";
import { CreateRoleWithPermissionApi, GetPermissionApi, UpdateRolePermissionApi } from "../Api";

// =================Permission sagas================
const getPermissionWorker = function* ({ payload }) {
  // debugger
    yield call(requestFunction, getPermissionSuccess, getPermissionFailure, GetPermissionApi, payload);
  };
  export const getPermissionWatcher = function* () {
    // debugger
    yield takeEvery(GET_PERMISSION, getPermissionWorker);
  };

  const createdRoleRequest = function* ({ payload }) {
    // debugger
    yield call(requestFunction, createRoleWithPermissionSuccess, createRoleWithPermissionFailure, CreateRoleWithPermissionApi, payload);
};
export const createdRoleWatcher= function* () {
    // debugger
    yield takeEvery(CREATE_ROLE_WITH_PERMISSION, createdRoleRequest);
};
  const updatedRolePermissionRequest = function* ({ payload }) {
    // debugger
    yield call(requestFunction, updateRolePermissionSuccess, updateRolePermissionFailure, UpdateRolePermissionApi, payload);
};
export const updatedRolePermissionWatcher= function* () {
    // debugger
    yield takeEvery(UPDATE_ROLE_PERMISSION, updatedRolePermissionRequest);
};

  export default function* permissionSaga() {
    yield all([
        fork(getPermissionWatcher),
        fork(createdRoleWatcher),
        fork(updatedRolePermissionWatcher),
    ]);
}