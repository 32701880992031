import { ADD_ITEMS_IN_CART, ADD_ITEMS_IN_CART_FAILURE, ADD_ITEMS_IN_CART_SUCCESS, CLEAR_CART_ITEMS, CLEAR_CART_ITEMS_FAILURE, CLEAR_CART_ITEMS_SUCCESS, DELETE_ITEMS_IN_CART, DELETE_ITEMS_IN_CART_FAILURE, DELETE_ITEMS_IN_CART_SUCCESS, GET_ALL_AVAILABLE_LOCATION, GET_ALL_AVAILABLE_LOCATION_FAILURE, GET_ALL_AVAILABLE_LOCATION_SUCCESS, GET_ALL_PRODUCT, GET_ALL_PRODUCT_FAILURE, GET_ALL_PRODUCT_SUCCESS, GET_ITEMS_IN_CART, GET_ITEMS_IN_CART_FAILURE, GET_ITEMS_IN_CART_SUCCESS, POST_VERIFY_TRANSACTION, POST_VERIFY_TRANSACTION_FAILURE, POST_VERIFY_TRANSACTION_SUCCESS, UPDATE_ITEMS_IN_CART, UPDATE_ITEMS_IN_CART_FAILURE, UPDATE_ITEMS_IN_CART_SUCCESS } from "../ActionTypes";

export const getItemsInCart = (payload) => ({
    type: GET_ITEMS_IN_CART,
    payload
});
export const getItemsInCartSuccess = (payload) => ({
    type: GET_ITEMS_IN_CART_SUCCESS,
    payload
});
// To get state
export const getItemsInCartFailure = (payload) => ({
    type: GET_ITEMS_IN_CART_FAILURE,
    payload
});

export const addItemsInCart = (payload) => ({
    type: ADD_ITEMS_IN_CART,
    payload
});
export const addItemsInCartSuccess = (payload) => ({
    type: ADD_ITEMS_IN_CART_SUCCESS,
    payload
});
// To add state
export const addItemsInCartFailure = (payload) => ({
    type: ADD_ITEMS_IN_CART_FAILURE,
    payload
});

export const updateItemsInCart = (body, payload) => ({
    type: UPDATE_ITEMS_IN_CART,
    body,
    payload
});
export const updateItemsInCartSuccess = (payload) => ({
    type: UPDATE_ITEMS_IN_CART_SUCCESS,
    payload
});
// To update state
export const updateItemsInCartFailure = (payload) => ({
    type: UPDATE_ITEMS_IN_CART_FAILURE,
    payload
});

export const deleteItemsInCart = (payload) => ({
    type: DELETE_ITEMS_IN_CART,
    payload
});
export const deleteItemsInCartSuccess = (payload) => ({
    type: DELETE_ITEMS_IN_CART_SUCCESS,
    payload
});
// To delete state
export const deleteItemsInCartFailure = (payload) => ({
    type: DELETE_ITEMS_IN_CART_FAILURE,
    payload
});

export const getAllAvailableLocation = (payload) => ({
    type: GET_ALL_AVAILABLE_LOCATION,
    payload
});
export const getAllAvailableLocationSuccess = (payload) => ({
    type: GET_ALL_AVAILABLE_LOCATION_SUCCESS,
    payload
});
// To get user type
export const getAllAvailableLocationFailure = (payload) => ({
    type: GET_ALL_AVAILABLE_LOCATION_FAILURE,
    payload
});

export const getAllProduct = (payload) => ({
    type: GET_ALL_PRODUCT,
    payload
});
export const getAllProductSuccess = (payload) => ({
    type: GET_ALL_PRODUCT_SUCCESS,
    payload
});
// To get user type
export const getAllProductFailure = (payload) => ({
    type: GET_ALL_PRODUCT_FAILURE,
    payload
});

export const postVerifyTransaction = (payload) => ({
    type: POST_VERIFY_TRANSACTION,
    payload
});
export const postVerifyTransactionSuccess = (payload) => ({
    type: POST_VERIFY_TRANSACTION_SUCCESS,
    payload
});
// To add state
export const postVerifyTransactionFailure = (payload) => ({
    type: POST_VERIFY_TRANSACTION_FAILURE,
    payload
});

export const clearCartItems = (payload) => ({
    type: CLEAR_CART_ITEMS,
    payload
});
export const clearCartItemsSuccess = (payload) => ({
    type: CLEAR_CART_ITEMS_SUCCESS,
    payload
});
// To add state
export const clearCartItemsFailure = (payload) => ({
    type: CLEAR_CART_ITEMS_FAILURE,
    payload
});