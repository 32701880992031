/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table, Spin, Button, Tooltip, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserAccount,
  getAllIndependentDistributorship,
  getBusinessArea,
  getState,
  postCreateDistributorCustomer,
  restoreDistributorCreation,
  updateBusinessData,
  updateUserData,
} from "../../../redux/Actions";
import moment from "moment";
import { getAuthReducer } from "../../../redux/Reducer/authReducer";
import AddCustomerModal from "../../../Components/AddCustomersModal/addCustomersModal";
import AvatarImg from "../../../Components/AvatarWithImage/AvatarImg";
import { AreaLocationIcon, DeleteIcon, SearchIcon, ViewIcon, } from "../../../asset/icons/icons";
import { EditOutlined } from "@ant-design/icons";
import ViewCustomerModal from "../../../Components/AddCustomersModal/viewCustomerModal";
import AdminChooseAOCModal from "../../../Components/AdminEditAOC/adminChooseAOCModal";
import StatusIndicator from "../../../Components/StatusIndicator/StatusIndicator";
import { useMatchMedia } from "../../../Utils/media";
import DeleteModal from "../../../Components/DeleteCustomersModal/DeleteModal";
// import ViewCustomerModal from "../../../Components/AddCustomersModal/viewCustomerModal";

const usermanText = {
  width: "189px",
  flexShrink: 0,
  color: "#45464E",
  fontFamily: '"MADE TOMMY"',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "28px",
};


export const tag = {
  padding: "3.867px 7.734px",
  alignItems: "center",
  gap: "0px",
  borderRadius: "348.021px",
  background: "#F4F1FD",
};
// const exportButton = {
//   display: "flex",
//   height: "44px",
//   padding: "10px 16px",
//   justifyContent: "center",
//   alignItems: "center",
//   gap: "8px",
//   borderRadius: "8px",
//   border: "1px solid var(--Gray-100, #E6E7EC)",
//   background: "var(--Backgrounds-Background-White, #FFF)",
//   // color: "#FFF",
//   fontFamily: '"MADE TOMMY"',
//   fontSize: "14px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "20px",
//   color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
//   marginRight: "5px",
// };

const newEmpButton = {
  display: "flex",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#252A4D",
  color: "#FFF",
  fontFamily: '"MADE TOMMY"',
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

// const filter = {
//   color: "var(--Black-80, #52575C)",
//   fontFamily: "Inter",
//   fontSize: "13.138px",
//   fontStyle: "normal",
//   fontWeight: 500,
//   lineHeight: "22.992px", // 175%
//   letterSpacing: "-0.145px",
// };

// const filterDiv = {
//   display: "flex",
//   width: "588.756px",
//   justifyContent: "flex-end",
//   alignItems: "flex-start",
//   gap: "16.423px",
//   flexShrink: 0,
// };
// const filterCount = {
//   borderRadius: "410.569px",
//   background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
//   display: "flex",
//   padding: "3.285px 6.138px 4.423px 6.57px",
//   justifyContent: "center",
//   alignItems: "center",
//   color: "var(--Black-80, #52575C)",
//   textAlign: "center",
//   fontFamily: "Inter",
//   fontSize: "9.854px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "normal",
// };




const DistributorCustomers = () => {
  // eslint-disable-next-line no-unused-vars
  const [user_type_id, setUserTypeId] = useState(3);
  const {
    allApplicant,
    isloadingAllApplicant,
    authUser,
    // userProfileDetails, 
    createDistributor,
    postingCreateDistributor,
    userDataUpdated,
    businessDataUpdated,
    userAccountDeleted,
    isdeletingUserAccount
    // isloadingUserProfileDetails
  } =
    useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [isViewCustomerModalOpen, setViewCustomerModalOpen] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState("");
  const [type, setType] = useState("");
  const [openDoc, setOpenDoc] = useState("");
  const [link, setLink] = useState("");
  const userRole = authUser?.user?.roles || {}
  const isSuperAdmin = (userRole?.id === 29 || userRole?.name === "Super Admin")
  const [addIndependentDistributor, setAddIndependentDistCustomer] = useState({
    name: "",
    avatar: null,
    nin: "",
    country: "Nigeria",
    state: "",
    lga: "",
    phone: "",
    location_id: 1,
    date_of_birth: "",
    gender: "",
    address: "",
    whatsapp_phone: "",
    distribute_loaf: null,
    business: {
      name: "",
      description: "The best bakery in-town",
      years_of_experience: 0,
      cac_reg_number: "",
      office_phone: "",
      nature_of_business: "",
      branch: "",
      country: "Nigeria",
      state: "",
      lga: "",
      address: "",
      strategy_brief: "",
      is_new_partner: null,
      annual_turnover: null,
      min_turnover_per_month: null,
      has_delivery_van: null,
      contact_name: "",
      contact_email: "",
      contact_phone: "",
    },
    next_of_kin: [
      {
        relationship_type_id: null,
        name: "",
        phone: "",
        address: "",
      },
    ],
    socials: [
      {
        icon: "",
        link: "",
      },
    ],
  });
  const [isAdminChangeAOCModalOpen, setIsAdminChangeAOCModalOpen] = useState(false);
  const [aocDetails, setAOCdetails] = useState();
  const [businessAreasForm, setBusinessAreasForm] = useState({
    // business_id: aocDetails?.business?.id,
    country: "Nigeria",
    state: "",
    lga: "",
    address: [],
  });
  const [image, setImage] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const filteredData = allApplicant?.data?.filter((item) => {
    // debugger
    const matchesSearchQuery = Object.keys(item).some((key) => {
      const value = item[key];
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).some((nestedValue) =>
          nestedValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
    });

    if (searchQuery.toLowerCase() === 'pending') {
      return item.status === 0;
    }
    if (searchQuery.toLowerCase() === 'approved') {
      return item.status === 1;
    }

    return matchesSearchQuery;
  });

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const showModal = () => {
    setAddCustomerModalOpen(true);
  };
  const showAdminChangeAOCModal = (record) => {
    // debugger
    // console.log(record, "record");
    setAOCdetails(record)
    setIsAdminChangeAOCModalOpen(true);
    dispatch(getBusinessArea(record?.business?.id));
  };
  const handleOpenDeleteModal = (value) => {
    setOpenDeleteModal(true)
    setDistributorDetails(value)
  }
  const handleCancelDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteAccount = () => {
    dispatch(deleteUserAccount(distributorDetails?.id))
  }
  const handleOk = (values) => {
    debugger
    dispatch(postCreateDistributorCustomer({
      ...addIndependentDistributor,
      avatar: image,
      address: values.residentAddress,
    }));

    // console.log({ ...addIndependentDistributor }, "Pls work!!!!");
  };
  const handleCancel = () => {
    setAddCustomerModalOpen(false);
  };

  const showDocModal = (link) => {
    setOpenDoc(true);
    setLink(link)
  };

  const handleDocCancel = () => {
    setOpenDoc(false);
  };

  const showViewCustomerModal = (type, value) => {
    setType(type)
    setDistributorDetails(value)
    setViewCustomerModalOpen(true);
    console.log(value,"Minimee");
    
  };
  // eslint-disable-next-line no-unused-vars
  const handleViewCustomerOk = () => {
    setViewCustomerModalOpen(false);
  };
  // eslint-disable-next-line no-unused-vars
  const handleViewCustomerCancel = () => {
    setViewCustomerModalOpen(false);
  };


  const handleUpdateValues = async (values) => {



    const userPayload = {
      id: distributorDetails?.id,
      body: {
        user_type_id: 3,
        name: values.fullName,
        avatar: image,
        email: values.email,
        date_of_birth: values.DOB,
        gender: values.gender,
        state: values.stateOfOrigin,
        lga: values.lgaOrigin,
        address: values.residentAddress,
        phone: values.phoneNumber,
        whatsapp_phone: values.whatsappNumber
      }
    }
    const businessPayload = {
      id: distributorDetails?.business?.id,
      body: {
        name: values.company_name,
        address: values.businessAddress,
        country: values.country,
        state: values.state,
        lga: values.localGovernment,
        nature_of_business: values.business_category,
        office_phone: values.businessPhoneNumber,
        cac_reg_number: values.cac_reg_no,
        year_of_experience: values.year_of_experience,
        annual_turnover: values.annual_turnover,
        strategy_brief: values.strategy_brief,
        distribute_loaf: values.distribute_loaf,
        has_delivery_van: values.has_delivery_van,
        is_new_partner: values.is_new_partner,
        next_of_kin: [
          {
            relationship_type_id: values.nextOfKinRelationship,
            name: values.nextOfKin,
            phone: values.nextofKinPhoneNumber,
            address: values.nextOfKinContactAddress,
          },
        ],
        socials: values.socialMediaHandles,

      }
    }

    await dispatch(updateBusinessData(businessPayload))
    await dispatch(updateUserData(userPayload))
    // console.log(values, "Business Payload");

  }

  useEffect(() => {
    dispatch(getAllIndependentDistributorship({ user_type_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_type_id]);



  useEffect(() => {
    dispatch(getState());
  }, []);

  useEffect(() => {
    if (createDistributor === true) {
      setAddCustomerModalOpen(false);
      dispatch(getAllIndependentDistributorship({ user_type_id }));
    } else {

    }
    return () => {
      dispatch(restoreDistributorCreation())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDistributor]);


  const onChange = (pagination, filters, sorter, extra) => {
    // debugger
    console.log("params", pagination, filters, sorter, extra);
    const payload = {
      user_type_id: user_type_id,
      page: pagination.current
    }
    dispatch(getAllIndependentDistributorship(payload));
  };


  const RenderEditButton = ({ record }) => {
    return (
      <>
        {userRole?.id === 29 || userRole?.name === "Super Admin" ?
          <Button
            type="text"
            className="d-inline-flex p-2"
            onClick={() => showViewCustomerModal('edit', record)}
          >
            <EditOutlined style={{ padding: "0px 5px", height: "fit-content", fontSize: "17px" }} />
          </Button>
          : null}
      </>
    )
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AvatarImg src={record?.avatar} name={record?.business?.name} />
          <span className="orderHistory-text" style={{ marginLeft: 8 }}>{record?.business?.name ?? 'NIL'}</span>
        </div>
      ),
      sorter: (a, b) => a.business?.name?.localeCompare(b?.business?.name),
    },
    {
      title: "Location",
      dataIndex: ["business", "state"],
      render: (text) => <span className="orderHistory-text">{text ?? "-"}</span>,
      sorter: (a, b) => a.state?.localeCompare(b?.state),
    },
    // {
    //   title: "Nature of Business",
    //   dataIndex: ["business","nature_of_business"],
    //   render: (text) => (
    //     <span className="orderHistory-text">
    //       {text ?? "NIL"}
    //     </span>
    //   ),
    //   sorter: {
    //     compare: (a, b) => a.amount - b.amount,
    //     multiple: 1,
    //   },
    // },

    {
      title: "Customer Status",
      dataIndex: "status",
      render: (text) => <StatusIndicator status={text} />,
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Total Amount Spent",
      dataIndex: "phone",
      render: (text) => <div className="d-flex justify-content-end"><span className="orderHistory-text">{"₦0"}</span></div>,
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
    },
    {
      title: "No Of Retailers Assigned",
      dataIndex: "email_verified_at",
      render: (text) => <div className="d-flex justify-content-end"><span className="orderHistory-text">{"0"}</span></div>,
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
    },
    // {
    //   title: "Date Joined",
    //   dataIndex: ["business", "created_at"],
    //   render: (text) => (
    //     <span className="orderHistory-text">
    //       {text !== null ? moment(text).format("MMM Do YYYY") : "NIL"}
    //     </span>
    //   ),
    //   sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    // },
    {
      title: "Date Joined",
      dataIndex: ["business", "created_at"],
      render: (text) => (
        <span className="orderHistory-text">
          {text !== null ? moment(text).format("MMM Do YYYY") : "NIL"}
        </span>
      ),
      sorter: (a, b) => {
        const dateA = new Date(a?.created_at);
        const dateB = new Date(b?.created_at);
        return dateA - dateB;
      },
    },
    {
      title: "Actions",
      dataIndex: "user_type_id",
      render: (text, record) => (
        <div>
          <Button
            type="text"
            className="d-inline-flex p-2"
            onClick={() => showViewCustomerModal("view", record)}
          >
            <ViewIcon />
          </Button>
          <RenderEditButton record={record} />
          <Tooltip title="Change area of distribution coverage">
            <Button
              type="text"
              className="d-inline-flex p-2"
              onClick={() => showAdminChangeAOCModal(record)}
            >
              <AreaLocationIcon />
            </Button>
          </Tooltip>
          {isSuperAdmin ?
            <Button onClick={() => handleOpenDeleteModal(record)} size="large" icon={<DeleteIcon />} type="text" className="orderHistory-view-details">
            </Button> : null}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (businessDataUpdated || userDataUpdated || userAccountDeleted) {
      setViewCustomerModalOpen(false);
      setOpenDeleteModal(false);
      dispatch(getAllIndependentDistributorship({ user_type_id }));
    }
  }, [businessDataUpdated, userDataUpdated, userAccountDeleted, dispatch])
  const isMobileDevice = useMatchMedia('(max-width:450px)', true)
  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px" }}>
        <div className="row mb-4">
          <div className={isMobileDevice ? "d-flex flex-column" : "d-flex justify-content-start"}>
            <div className="col">
              <span style={usermanText}>
                Customers {">"} Independent Distributors
                <span className="mx-2" style={tag}>
                  {allApplicant?.meta?.total}
                </span>

              </span>
            </div>
            <div className="col d-flex justify-content-end">
              {/* <button onClick={1} style={exportButton}>
                Export
              </button> */}
              <button style={newEmpButton} onClick={showModal}>
                Add Distributor
              </button>
              <AddCustomerModal
                isModalOpen={isAddCustomerModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                usertypeid={3}
                setAddCustomerField={setAddIndependentDistCustomer}
                addCustomerField={addIndependentDistributor}
                setImage={setImage}
                image={image}
                postingCustomer={postingCreateDistributor}
              />
            </div>
            <AdminChooseAOCModal
              userProfileDetails={aocDetails}
              setBusinessAreasForm={setBusinessAreasForm}
              businessAreasForm={businessAreasForm}
              isAdminChangeAOCModalOpen={isAdminChangeAOCModalOpen}
              setIsAdminChangeAOCModalOpen={setIsAdminChangeAOCModalOpen}
            />
          </div>
        </div>

        {/* <div className="row mt-5 mb-4">
          <div className="d-flex justify-content-start">
            <div className="col-3">
              <Input placeholder="Basic usage" />
            </div>
            <div className="col d-flex justify-content-end" style={filterDiv}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                    fill="#52575C"
                  />
                </svg>
              </span>{" "}
              <span style={filter} className="">
                Filter
              </span>
              <span style={filterCount}>0</span>
            </div>
          </div>
        </div> */}
        <div className="d-flex justify-content-start mb-3">
          <div className="col-12 col-sm-6 col-md-5 mt-3">
            <Input
              placeholder={"Search distributors"}
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <Spin spinning={isloadingAllApplicant} tip="Loading...">
          <Table
            columns={columns}
            // dataSource={allApplicant?.data}
            dataSource={filteredData}
            onChange={onChange}
            scroll={{ x: 1000 }}
            pagination={{
              pageSize: allApplicant?.meta?.per_page,
              showSizeChanger: false,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              total: allApplicant?.meta?.total,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </Spin>
      </div>
      <ViewCustomerModal
        isViewCustomerModalOpen={isViewCustomerModalOpen}
        usertypeid={3}
        handleViewCustomerCancel={handleViewCustomerCancel}
        details={distributorDetails}
        showDocModal={showDocModal}
        handleDocCancel={handleDocCancel}
        openDoc={openDoc}
        link={link}
        type={type}
        setImage={setImage}
        image={image}
        handleUpdateValues={handleUpdateValues}

      />
      <DeleteModal open={openDeleteModal} handleDelete={handleDeleteAccount} onCancel={handleCancelDeleteModal} name={distributorDetails?.business?.name} loading={isdeletingUserAccount} type={"Distributor"} />
    </>
  );
};
export default DistributorCustomers;
