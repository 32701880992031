import React, { useState } from "react";
import { Alert, Col, Row, Spin } from "antd";

const GetHelp = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <Row className="mt-0">
        <Col className="gutter-row" span={24}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                // top: "50%",
                marginTop: "20%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <Spin tip="Loading...">
                <Alert
                  message="Please be patient while loading help desk"
                  description="This might take few seconds. Thank you"
                  type="info"
                />
              </Spin>
            </div>
          )}
          <iframe
            src="https://sundryfood-help.freshdesk.com/"
            title="Embedded HelpDesk"
            onLoad={handleIframeLoad}
            style={{
              width: "100%",
              height: 800,
              border: "none",
              overflow: "hidden",
              display: isLoading ? "none" : "block",
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default GetHelp;
