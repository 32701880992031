import React, { useState } from 'react';
import { Avatar } from 'antd';

// Helper function to generate a random light color
const getRandomLightColor = () => {
    const letters = 'BCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
};

// Helper function to get a darker shade of the given color
const getDarkerShade = (color) => {
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);

    r = Math.floor(r * 0.7);
    g = Math.floor(g * 0.7);
    b = Math.floor(b * 0.7);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const AvatarImg = ({ src, name }) => {
    // eslint-disable-next-line no-unused-vars
    const [avatarBackground, setAvatarBackground] = useState(getRandomLightColor());
    const textColor = getDarkerShade(avatarBackground);

    return (
        <Avatar src={src} style={{ backgroundColor: src ? undefined : avatarBackground, color: src ? undefined : textColor }}>
            {!src && <b style={{ color: textColor }}>{name?.charAt(0)?.toUpperCase()}</b>}
        </Avatar>
    );
};


export default AvatarImg;