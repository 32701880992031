import React, { useEffect } from "react";
import { Col, Row, Typography, Card, Form, Input, Select, Spin } from "antd";
// import { MinusCircleOutlined } from '@ant-design/icons';
import PhoneInput from "react-phone-number-input";
// import { countries } from 'react-phone-number-input/input';
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../redux/Reducer/authReducer";
import { getNatureOfBusiness } from "../redux/Actions";

const { Text } = Typography;

// Array containing only Nigeria country code
// const nigeriaCountry = [{ code: 'NG', label: 'Nigeria', dialCode: '+234' }];

const AboutBusinessOnb = (props) => {
  const {
    businessDetailsForm,
    setBusinessDetailsForm,
    usertypeid,
    postingbusinessDetails,
  } = props;
  const {
    // eslint-disable-next-line no-unused-vars
    natureOfBusinessData,
    // isloadingNatureOfBusiness,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleBusinessNameChange = (e) => {
    setBusinessDetailsForm({ ...businessDetailsForm, name: e.target.value });
  };

  const handleCACRegistrationChange = (e) => {
    setBusinessDetailsForm({
      ...businessDetailsForm,
      cac_reg_number: e.target.value,
    });
  };

  const handleBusinessYearsExperienceChange = (value) => {
    // console.log(`selected ${value}`);
    setBusinessDetailsForm({
      ...businessDetailsForm,
      years_of_experience: value,
    });
  };

  const handleCategoryofBusChange = (value) => {
    // console.log(`selected ${value}`);
    // debugger
    setBusinessDetailsForm({
      ...businessDetailsForm,
      nature_of_business: value,
    });
  };

  const phonehandler = (e) => {
    // debugger
    // console.log(e);
    setBusinessDetailsForm({ ...businessDetailsForm, office_phone: e });
  };

  useEffect(() => {
    dispatch(getNatureOfBusiness());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(natureOfBusinessData, "natureOfBusinessData")

  return (
    <>
      <Spin spinning={postingbusinessDetails} tip="Loading...">
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Text className="bestdescribetext">
              Tell us about your business
            </Text>
          </Col>
          <Col span={24} className="welcomebestdescriberow">
            <Text className="welcomebestdescribetext">
              We use this information to give your business the spotlight it
              deserves
            </Text>
          </Col>

          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Card className="aboutbusinesscard">
              <Form
                {...formLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size={"large"}
                // onValuesChange={handleFormValuesChange}
                className="typography"
              >
                <Form.Item
                  hasFeedback={usertypeid === 4 ? false : false}
                  validateStatus={usertypeid === 4 ? "" : "validating"}
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Business / Company Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                >
                  <Input
                    placeholder="e.g Kilimanjaro"
                    id="success"
                    value={businessDetailsForm.name}
                    onChange={handleBusinessNameChange}
                  />
                </Form.Item>

                <Form.Item
                  hasFeedback={usertypeid === 4 ? false : false}
                  validateStatus={usertypeid === 4 ? "" : "validating"}
                  label={
                    <label className="typography aboutbusinessformlabel">
                      CAC Registration Number{" "}
                      {usertypeid === 4 ? "(optional)" : ""}{" "}
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  <Input
                    placeholder="Input your CAC registration number"
                    id="success"
                    value={businessDetailsForm.cac_reg_number}
                    onChange={handleCACRegistrationChange}
                  />
                </Form.Item>

                {usertypeid === 4 ? (
                  ""
                ) : (
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel">
                        Years of Experience In Bread Sales{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    }
                    className="aboutbusinessformitem"
                  >
                    <Select
                      placeholder="Select Range"
                      onChange={handleBusinessYearsExperienceChange}
                      options={[
                        {
                          value: 1,
                          label: `1 year`,
                        },
                        {
                          value: 2,
                          label: `2 years`,
                        },
                        {
                          value: 3,
                          label: `3 years`,
                        },
                        {
                          value: 4,
                          label: `4 years`,
                        },
                        {
                          value: 5,
                          label: `5 years and above`,
                        },
                      ]}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Business Phone Number{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                  className="aboutbusinessformitem"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <div className="phoneInputDiv">
                    <PhoneInput
                      // inputComponent
                      //   style={{ backgroundColor: 'white', height: '7vh' }}
                      international
                      defaultCountry="NG"
                      autoComplete="tel"
                      type="tel"
                      value={businessDetailsForm.office_phone}
                      // className=""
                      onChange={phonehandler}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Nature of Business <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  {usertypeid === 3 ? (
                    <Select
                      // defaultValue="Bakery"
                      onChange={handleCategoryofBusChange}
                      placeholder="e.g Wholesaler"
                      // options={natureOfBusinessData.map((business) => ({
                      //   value: business.name,
                      //   // label: business.name,
                      // }))}
                      options={[
                        {
                          value: "Wholesaler",
                          label: `Wholesaler`,
                        },
                        {
                          value: "Distributor",
                          label: `Distributor`,
                        },
                      ]}
                    />
                  ) : (
                    <Select
                      // defaultValue="Bakery"
                      onChange={handleCategoryofBusChange}
                      placeholder="e.g Retailer"
                      // options={natureOfBusinessData.map((business) => ({
                      //   value: business.name,
                      //   // label: business.name,
                      // }))}
                      options={[
                        {
                          value: "retailer",
                          label: `Retailer`,
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
export default AboutBusinessOnb;
