/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Input,
  Modal,
  Form,
  Upload,
  Select,
  Avatar,
  Button,
  message,
} from "antd";
import {
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { getCartReducer } from "../../redux/Reducer/cartReducer";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getAllAvailableLocation, getAllRoles, getAllSystemAdmin } from "../../redux/Actions";


const nonInteractiveStyle = {
  position: 'relative',
  display: 'inline-block',
  pointerEvents: "none"
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  pointerEvents: 'none',
  cursor: "not-allowed"
};
const EmployeeFormModal = (props) => {
  const { open, onCreate, onCancel, image, setImage, type, employeeData, formValues, onUpdate } = props;

  const {allRoles, createEmployee } = useSelector(getAuthReducer);
  const { availableLocations, isloadingAvailableLocations } = useSelector(getCartReducer);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [invalidImage, setInvalidImage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formInstance, setFormInstance] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllAvailableLocation())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createEmployee.status === true) {
      dispatch(getAllSystemAdmin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEmployee]);

  const checkFileSize = (file) => {
    const alaterror = () => {
      messageApi.open({
        type: "error",
        content: `${file.name} is not a png, jpg, jpeg or gif file`,
        duration: 10,
      });
    };

    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSize) {
      message.error("File size must be less than 2MB.");
      return true; // Prevent upload
    }
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!allowedFormats.includes(file.type)) {
      alaterror();
      setInvalidImage(true);
      return true; // Prevent upload
    } else {
      setInvalidImage(false);
    }
    return false; // Allow upload
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    if (!checkFileSize(file)) {
      onError("File size exceeds limit.");
      return;
    }
  };

  const avatarprops = {
    beforeUpload: checkFileSize,
    customRequest: customRequest,
    maxCount: 1,
    name: "avatar",
    onChange: (info) => {
      let reader = new FileReader();
      let file = info.file;

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY
      );
      axios
        .post(
          `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData
        )
        .then((res) => setImage(res.data.secure_url))
        .catch((err) => console.log(err));

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    },
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const phonehandler = (e) => {
    console.log(e);
  };

  useEffect(() => {
    if ((type === "view" || type === "edit") && open) {
      // debugger
      form.setFieldsValue({
        name: formValues?.name,
        email: formValues?.email,
        phone: formValues?.phone,
        role_id: formValues?.roles?.id,
        location_id: formValues?.location_id,
      });
      setImage(formValues?.avatar);
    }else if(type==="new"){
      form.setFieldsValue({
        name: "",
        email: "",
        phone: null,
        role_id: null,
        location_id: null,
      })
    }
  }, [type, setImage, formValues, form, open]);

  useEffect(() => {
    onFormInstanceReady(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormInstanceReady = (instance) => {
    setFormInstance(instance);
  }

  return (
    <>
      <Modal
        open={open}
        title={type === "new" ? "Create New Employee" : type==="view" ? "View Employee":"Update Employee Details"}
        okText={type === "new" ? "Create New Employee" : type==="edit"?"Update":null}
        cancelText="Cancel"
        okButtonProps={{
          style: { display: type==="view"&&'none', backgroundColor:"#252A4D" },
          autoFocus: true,
        }}
        onCancel={onCancel}
         afterClose={() => form.resetFields()}
        destroyOnClose
        onOk={async () => {
          try {
            const values = await form.validateFields();
            form.resetFields();
            if(type==="new"){
              onCreate(values);
            }else{
              onUpdate(values)
            }
          } catch (error) {
            // console.log('Failed:', error);
          }
        }}
      >
        {contextHolder}
        <Form
          layout="vertical"
          form={form}
          initialValues={type === "edit" ? {
            name: employeeData?.name,
            email: employeeData?.email,
            phone: employeeData?.phone,
            role_id: employeeData?.role_id,
            location_id: employeeData?.location_id,
          } : {}}
          name="form_in_modal"
          className="mt-3"
        >
          <div className="row mb-4">
            <div>
              <Avatar
                shape="square"
                className="upload-image-avatar"
                size={115}
                icon={image ? <img src={image} alt="" /> : <UserOutlined />}
                style={{backgroundColor:"#F2F4FE"}}
              />
             {type==="view"?null: <div>
                <Upload {...avatarprops}>
                  <Button className="mt-2 avatar-upload-btn" block>
                    Upload Picture
                  </Button>
                </Upload>
              </div>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Form.Item name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
                <Input type="text" placeholder="Full Name" readOnly={type==="view"} style={{ cursor: type === "view" ? "not-allowed" : "default" }}/>
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Form.Item name="email" rules={[{ required: true, message: 'Please input the email!', type: 'email' }]}>
                <Input type="email" placeholder="Email Address"  readOnly={type==="view"} style={{ cursor: type === "view" ? "not-allowed" : "default" }}/>
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item name="phone" rules={[{ required: true, message: 'Please input the phone number!' }]}>
              <div style={type === "view" ? nonInteractiveStyle : undefined}>
                <div className="phoneInputDiv">
                  <PhoneInput
                    international
                    defaultCountry="NG"
                    autoComplete="tel"
                    type="tel"
                    onChange={phonehandler}
                    value={formValues?.phone}
                  />
                   {type === "view"  && <div style={overlayStyle} />}
                </div>
                </div>
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Form.Item
                name="role_id"
                rules={[{ required: true, message: 'Please select a role!' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder="Select role"
                  options={allRoles?.data?.filter((role)=>role.id!==4).map((role)=>(
                  {
                    value: role.id,
                    label: role.name,
                  }  
                  ))}
                  disabled={type==="view"}
                />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item
                name="location_id"
                rules={[{ required: true, message: 'Please select a branch!' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder="Select Branch"
                  options={!isloadingAvailableLocations && availableLocations!==undefined&& availableLocations?.length!==0 && availableLocations?.map((location) => ({
                    value: location?.id,
                    label: `${location?.address}`,
                  }))}
                  disabled ={type==="view"}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default EmployeeFormModal;
