// src/DoughnutChart.js

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

// Register the components
ChartJS.register(ArcElement, Tooltip);

const DoughnutChart = ({ labels, dataValues, backgroundColor, borderColor, total }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Customer Distribution',
        data: dataValues, // Adjusted data for distinct size
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
        hoverOffset: 4, // Make the sections pop out on hover
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: '70%', // Make the doughnut slimmer
    plugins: {
      legend: {
        display: false, // Remove legend
      },
      title: {
        display: false, // Remove title
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            // const percentage = Math.round((value / total) * 100);
            // const formattedPercentage = percentage % 1 === 0 ? parseInt(percentage) : percentage;

            return `${context.label}: ${value}`;
            // return `${context.label}: ${value} (${formattedPercentage}%)`;
          },
        },
      },
  }
  };

  return <Doughnut data={data} options={options} />;
};

export default DoughnutChart;
