// src/PolarAreaChart.js

import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip } from 'chart.js';

// Register the components
ChartJS.register(RadialLinearScale, ArcElement, Tooltip);

const PolarAreaChart = ({dataset}) => {
  const data = {
    labels: ['Incoming Orders', 'Processing Orders', 'Orders Picked Up', 'Rejected Orders'],
    datasets: [
      {
        label: 'Order Status',
        data: dataset,
        backgroundColor: [
          '#4CAF50',
          '#FF9800',
          '#2196F3',
          '#F44336'
        ],
        borderColor: [
          '#4CAF50',
          '#FF9800',
          '#2196F3',
          '#F44336'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };

  return <PolarArea data={data} options={options} />;
};

export default PolarAreaChart;
