import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Button,
  Spin,
  Form,
  Input,
  // Tooltip,
  // Input,
} from "antd";
import {
  PlusCircleOutlined,
  EditOutlined, EyeOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../../redux/Reducer/authReducer";
import { addRoleDetails, createRoleWithPermission, getAllAvailableLocation, getAllRoles, getAllSystemAdmin, getEmployeeUnpaginatedData, getPermission, postCreateEmployee, restoreEmployeeDetails, restoreRoleDetails, updateEmployeeDetails, updateRoleDetails, updateRolePermission } from "../../../redux/Actions";
import RoleModal from "../../../Components/userManagement/roleModal";
import RoleBasicInfo from "../../../Components/userManagement/roleBasicInfo";
import RolePermissionSettings from "../../../Components/userManagement/rolePermissionSetting";
import ReviewDetailsUserPermission from "../../../Components/userManagement/reviewDetails";
import EmployeeFormModal from "../../../Components/EmployerFormModal/employeeFormModal";
// import { LockIcon} from "../../../asset/icons/icons";
import { SearchIcon, ViewIcon } from "../../../asset/icons/icons";
import { getPermissionReducer } from "../../../redux/Reducer/permissionReducer";
import UpdateRoleModal from "../../../Components/userManagement/UpdateRoleModal";
// import EmployeePermissionsModal from "../../../Components/EmployerFormModal/employeePermissionsModal";
// import { Ro } from "react-flags-select";



const useMatchMedia = (mediaQuery, initialValue) => {
  const [isMatching, setIsMatching] = useState(initialValue)
  useEffect(() => {
      const watcher = window.matchMedia(mediaQuery)
      setIsMatching(watcher.matches)
      const listener = (matches) => {
          setIsMatching(matches.matches)
      }
      if (watcher.addEventListener) {
          watcher.addEventListener('change', listener)
      } else {
          watcher.addListener(listener)
      }
      return () => {
          if (watcher.removeEventListener) {
              return watcher.removeEventListener('change', listener)
          } else {
              return watcher.removeListener(listener)
          }
      }
  }, [mediaQuery])

  return isMatching
}



const tag = {
  padding: "3.867px 7.734px",
  alignItems: "center",
  gap: "0px",
  borderRadius: "348.021px",
  background: "#F4F1FD",
};




// const exportButton = {
//   display: "flex",
//   height: "44px",
//   padding: "10px 16px",
//   justifyContent: "center",
//   alignItems: "center",
//   gap: "8px",
//   borderRadius: "8px",
//   border: "1px solid var(--Gray-100, #E6E7EC)",
//   background: "var(--Backgrounds-Background-White, #FFF)",
//   // color: "#FFF",
//   fontFamily: '"MADE TOMMY"',
//   fontSize: "14px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "20px",
//   color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
//   marginRight: "5px",
// };

const EmployeeManagement = () => {
  const isMobileDevice = useMatchMedia('(max-width:450px)', true)
  const newEmpButton = {
    display: "flex",
    height: "44px",
    padding: isMobileDevice?"5px": "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobileDevice?"0px":"8px",
    borderRadius: "8px",
    background: "#252A4D",
    color: "#FFF",
    fontFamily: '"MADE TOMMY"',
    fontSize: isMobileDevice?"10px":"14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  };

  const usermanText = {
    width: "189px",
    flexShrink: 0,
    color: "#45464E",
    fontFamily: '"MADE TOMMY"',
    fontSize: isMobileDevice?"16px":"20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px",
  };
  

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "id",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <span className="orderHistory-text">
          {text} <br />
        </span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Role",
      dataIndex: ["roles", "name"],
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.roles?.name?.localeCompare(b?.roles?.name),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.email?.localeCompare(b?.email),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.phone - b.phone,
    },
    {
      title: "Branch",
      dataIndex: ["location", "name"],
      render: (text, record) => <span className="orderHistory-text">{text} - {record?.location?.address}</span>,
      sorter: (a, b) => a.location?.name?.localeCompare(b?.location?.name),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        let statusClass = "";
        switch (text) {
          case 0:
            statusClass = "orderHistory rejected";
            break;
          case 1:
            statusClass = "orderHistory approved";
            break;
          default:
            statusClass = "orderHistory approved";
        }
        return (
          <span key={text} className={statusClass}>
            {text === 0 ? "Inactive" : "active"}
          </span>
        );
      },
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Actions",
      width:120,
      render: (text, record) => (
        <>
          <Button type="text" className="orderHistory-view-details" onClick={() => handleClickOpen("view", record)} style={{padding:"0px 5px", height:"fit-content"}}>
            <ViewIcon />
          </Button>
          {isSuperAdmin?
          <Button type="text" className="orderHistory-view-details" onClick={() => handleClickOpen("edit", record)} style={{padding:"0px 5px", height:"fit-content"}}>
            <EditOutlined />
          </Button>:null}
          {/* <Tooltip title="View Permission">
         <Button type="text" className="orderHistory-view-details" onClick={() => handleOpenPermission(record)} style={{padding:"0px 5px", height:"fit-content"}}>
            <LockIcon size={16}/>
          </Button>
         </Tooltip> */}

        </>
      ),
    },
  ];

  const columnsRole = [
    {
      title: "Role",
      dataIndex: "name",
      render: (text) => <span className="orderHistory-text">{text}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => <span className="orderHistory-text">{text}</span>,
    },
    {
      title: "No Of Users",
      dataIndex: "total_users",
      render: (text) => (
        <span className="orderHistory-text">
          {text} <br />
        </span>
      ),
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div>
          <Button
            onClick={()=>showUpdateRoleModal('view',record)}
            className="orderHistory-view-details"
            type="text"
          >
            <EyeOutlined />
          </Button>
          <Button
            onClick={(e) => showUpdateRoleModal("update",record)}
            className="orderHistory-view-details"
            type="text"
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const { allSystemAdmin, isloadingAllSystemAdmin,isloadingAllRoles, allRoles, createEmployee, employeeDetailsUpdated, roleUpdated, postingCreateEmployee, roleUpdating,isUpdatingEmployee, authUser } =
    useSelector(getAuthReducer);
  const { permissionData, roleCreated, permissions, roleName,
    description, isCreatingRole, isUpdatingRolePermission,
    userAssigned, rolePermissionUpdated } = useSelector(getPermissionReducer);

  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedRowKeysRole, setSelectedRowKeysRole] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedProductAction, setSelectedProductAction] = useState("Add");
  const [image, setImage] = useState(null);
  const [type, setType] = useState("");
  const [typeUpdate, setTypeUpdate] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [roles, setRoles] = useState([]);
  const [isPermissionUpdated, setIsPermissionUpdated] = useState(false);
  const userRole = authUser?.user?.roles || {}
  const isSuperAdmin = (userRole?.id===29 || userRole?.name==="Super Admin")


  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    dispatch(getAllSystemAdmin(pagination.current));
  };
  const onChangeRole = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    dispatch(getAllRoles(pagination.current));
  };
  const formatRolesForSending = () => {
    return Object.keys(roles)?.map((roleId) => ({
      permission_id: parseInt(roleId),
      status: roles[roleId],
    }));
  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = allSystemAdmin?.data?.filter((item) =>
    Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchQuery.toLowerCase()) : false
    )
  );
  const filteredRoleData = allRoles?.data?.filter((item) =>
    Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchQuery.toLowerCase()) : false
    )
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };
  const handleRoleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };



  useEffect(() => {
    dispatch(getAllSystemAdmin());
    dispatch(getAllRoles());
    dispatch(getAllAvailableLocation())
    dispatch(getEmployeeUnpaginatedData())
    dispatch(getPermission())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onTabChange = (key) => {
    // debugger
    setSelectedTab(parseInt(key));
  };

  useEffect(() => {
    if (createEmployee.status === true) {
      dispatch(getAllSystemAdmin());
      dispatch(getEmployeeUnpaginatedData())
      setImage(null)
    }
  }, [createEmployee, dispatch]);



  useEffect(() => {
    if (employeeDetailsUpdated) {
      dispatch(getAllSystemAdmin())
      dispatch(getEmployeeUnpaginatedData())
      dispatch(getAllRoles())
      setImage(null)
    } return () => {
      dispatch(restoreEmployeeDetails())
    }
  }, [employeeDetailsUpdated, dispatch])

  const hasSelected = selectedRowKeys.length > 0;
  const hasSelectedRole = selectedRowKeysRole.length > 0;

  const items = [
    {
      key: "1",
      label: (
        <div style={{ color: "#252A4D" }}>
          All Employees
          <span className="mx-2" style={tag}>
            {allSystemAdmin?.meta?.total}
          </span>
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Spin spinning={isloadingAllSystemAdmin || postingCreateEmployee || isUpdatingEmployee} tip="Loading...">
            <Table
              // rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: 1000 }}
              onChange={onChange}
              pagination={{
                pageSize: allSystemAdmin?.meta?.per_page,
                showSizeChanger: false,
                pageSizeOptions: ["10", "20", "30", "40"],
                showQuickJumper: true,
                total: allSystemAdmin?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ color: "#252A4D" }}>
          Roles
          <span className="mx-2" style={tag}>
            {allRoles?.meta?.total}
          </span>
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelectedRole
              ? `Selected ${selectedRowKeysRole.length} items`
              : ""}
          </span>
          <Table
            // rowSelection={rowSelectionRole}
            columns={columnsRole}
            // dataSource={allRoles?.data}
            loading={isloadingAllRoles || roleUpdating || isUpdatingRolePermission || isCreatingRole}
            scroll={{ x: 1000 }}
            dataSource={filteredRoleData}
            onChange={onChangeRole}
            pagination={{
              pageSize: allRoles?.meta?.per_page,
              showSizeChanger: false,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              total: allRoles?.meta?.total,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </div>
      ),
    },
  ];

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  // const [openPermission, setOpenPermission] = useState(false);
  const [newRoleOpen, setNewRoleOpen] = useState(false);
  const [updateRole, setUpdateRole] = useState(false);
  const [current, setCurrent] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [roleValues, setRoleValues] = useState({});
  // const [uniquePermissions, setUniquePermissions] = useState({});
  const [roleInformation, setRoleInformation] = useState({
    roleName: "",
    description: "",
    userAssigned: [],
  });
  const onCreate = (values) => {
    dispatch(postCreateEmployee({ ...values, avatar: image }))
    setOpen(false);
  };
  const onUpdate = (values) => {
    dispatch(updateEmployeeDetails({ ...values, avatar: image, user_id: formValues?.id }))
    setOpen(false);
  };
  const onRoleUpdate = async(values, updatedPermissions) => {
    const payload = {
      roleid: roleValues?.id,
      body:values
    };
     dispatch(updateRoleDetails(payload));
    if(typeUpdate==="update" && isPermissionUpdated){
    const rolePermissions ={
      role_id:roleValues?.id,
      permissions:updatedPermissions
    }
    dispatch(updateRolePermission(rolePermissions))
  }
    setOpen(false);
  };

  const handleCancelEmployeeFormModal = () => {
    setOpen(false);
    setImage(null)
  }

  const handleRoleChange = (roleId) => {
    setRoles((prevRoles) => ({
      ...prevRoles,
      [roleId]: !prevRoles[roleId],
    }));
  };

  const handleEnableAllRoles = (checked) => {
    const newRolesState = permissionData.reduce((acc, role) => {
      acc[role.id] = checked;
      return acc;
    }, {});
    setRoles(newRolesState);
  };



  const hasValues = (role) => {
    return role.roleName !== "" && role.description !== "";
  };

  const hasNoPermissions = useCallback(() => {
    if (Object.values(roles).some((status) => status)) {
      return false; // Return false if at least one status is true
    }
    return true; // Return true if all statuses are false
  }, [roles]);

  // const handleViewRoleActionChange = (e) => {
  //   setSelectedProductAction("View");
  //   setNewRoleOpen(true);
  // };

  // const handleEditRoleActionChange = (e) => {
  //   setSelectedProductAction("Edit");
  //   setNewRoleOpen(true);
  // };

  const handleRoleNameChange = (e) => {
    const { name, value } = e.target;
    setRoleInformation({ ...roleInformation, [name]: value });
  };

  const handleEmployeesChange = (values) => {
    // debugger;
    setRoleInformation({ ...roleInformation, userAssigned: values });
  };

  const onFinish = () => {
    const values = form.getFieldsValue();
    dispatch(addRoleDetails(values))
  }
  const onNext = () => {
    const permissionData = Object.keys(roles)?.map((roleId) => ({
      permission_id: parseInt(roleId, 10),
      status: roles[roleId],
    }));

    dispatch(addRoleDetails({
      permissions: permissionData
    }));
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onSubmit = () => {
    const toSendApiValues = {
      name: roleName,
      description: description,
      user_ids: userAssigned,
      permissions: permissions
    }
    dispatch(createRoleWithPermission(toSendApiValues))
  }

  const steps = [
    {
      id: 1,
      title: <p className="typography rolesteptitle mt-1">Basic Info</p>,
      content: (
        <RoleBasicInfo
          handleRoleNameChange={handleRoleNameChange}
          roleInformation={roleInformation}
          handleEmployeesChange={handleEmployeesChange}
          selectedProductAction={selectedProductAction}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          current={current}
        />
      ),
    },
    {
      id: 2,
      title: <p className="typography rolesteptitle mt-1">Set Permissions</p>,
      content: (
        <RolePermissionSettings
          roleInformation={roleInformation}
          selectedProductAction={selectedProductAction}
          roles={roles}
          setRoles={setRoles}
          formatRolesForSending={formatRolesForSending}
          handleEnableAllRoles={handleEnableAllRoles}
          handleRoleChange={handleRoleChange}
          form={form}

        />
      ),
    },
    {
      id: 3,
      title: <p className="typography rolesteptitle mt-1">Review Details</p>,
      content: (
        <ReviewDetailsUserPermission
          handleRoleNameChange={handleRoleNameChange}
          roleInformation={roleInformation}
          handleEmployeesChange={handleEmployeesChange}
          selectedProductAction={selectedProductAction}
          roles={roles}
          setRoles={setRoles}

        />
      ),
    },
  ];

  const handleCurrentStepChange = (value) => {
    setCurrent(value);
  };

  const showRoleModal = () => {
    setSelectedProductAction("Add");
    setNewRoleOpen(true);
  };
  const showUpdateRoleModal = (type,record) => {
    setUpdateRole(true)
    setRoleValues(record)
    if(type==="update"){
    setTypeUpdate("update")
    }else setTypeUpdate("view")
  };
  const handleCancelUpdateRoleModal = () => {
    setUpdateRole(false)
  };

  useEffect(() => {
    if (permissions?.length !== 0) {
      const roles = permissions.reduce((acc, curr) => {
        acc[curr.permission_id] = curr.status;
        return acc;
      }, {});
      setRoles(roles)
    } else {
      if (permissionData && permissionData.length > 0) {
        const initialRoles = permissionData.reduce((acc, role) => {
          acc[role.id] = false;
          return acc;
        }, {});
        setRoles(initialRoles);
      }
    }
  }, [permissionData, permissions, setRoles]);

  //  for presets update
  // useEffect(() => {
  //     if (roleValues?.permissions && roleValues?.permissions?.length > 0) {
  //       const initialRoles = roleValues?.permissions?.reduce((acc, role) => {
  //         acc[role.id] = false;
  //         return acc;
  //       }, {});
  //       setRoles(initialRoles);
  //     }
  // }, [roleValues?.permissions, setRoles]);


  // const resetUpdateForms = () => {
  //   if (roleValues?.permissions && roleValues?.permissions?.length > 0) {
  //     const initialRoles = roleValues?.permissions.reduce((acc, role) => {
  //       acc[role.permission_id] = role.status; 
  //       return acc;
  //     }, {});
  //     setRoles(initialRoles);
  //   }
  // };
  

  useEffect(() => {
    if (hasValues(roleInformation)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [roleInformation])

  useEffect(() => {
    if (roleCreated || roleUpdated || rolePermissionUpdated) {
      setNewRoleOpen(false);
      setCurrent(0);
      dispatch(getAllRoles());
      dispatch(getAllSystemAdmin())
      setUpdateRole(false)
      setRoles({})
      form.resetFields()

    } return () => {
      dispatch(restoreRoleDetails())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleCreated, dispatch, roleUpdated, rolePermissionUpdated])

  useEffect(() => {
    if (current === 1) {
      if (hasValues(roleInformation) && hasNoPermissions()) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
      return () => { }
    }
    if (current === 2) {
      setDisabled(false)
    }
  }, [current, hasNoPermissions, roleInformation])

  const handleRoleModalOk = async () => {
    if (current === steps.length - 1) {
      // setNewRoleOpen(false);
      // setCurrent(0);
      onSubmit()
    } else {
      try {
        await form.validateFields();
        if (current === 0 && hasValues(roleInformation)) {
          setCurrent(current + 1);
          onFinish();
        } else {
          setDisabled(true)
          onFinishFailed({ errorFields: form.getFieldsError() });
        }
        if (current === 1) {
          setCurrent(current + 1);
          onNext()
        } else {
          setDisabled(true)
          onFinishFailed({ errorFields: form.getFieldsError() });
        }
      } catch (error) {
        setDisabled(true)
        onFinishFailed(error);
      }
    }
  };


  const handleRoleModalCancel = () => {
    if (current !== 0) {
      setCurrent(current - 1);
      setDisabled(false)
    } else {
      setCurrent(0)
      setNewRoleOpen(false);
      form.resetFields()
    }
  };

  const handleModalCancel = () => {
    setNewRoleOpen(false);
    form.resetFields()
    dispatch(restoreRoleDetails())
    setCurrent(0)
  };

  const handleClickOpen = (type, record) => {
    setType(type)
    setOpen(true)
    if (type === "view" || type === "edit") {
      setFormValues(record)
    } else {
      setFormValues({})
    }

  }

  // const handleOpenPermission = (record) => {
  //   setOpenPermission(true)
  //   setUniquePermissions(record)

  // }
  // const handleClosePermission = (type, record) => {
  //   setOpenPermission(false)
  //   setUniquePermissions({})
  // }

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px", margin: "0px" }}>
        <div className="row">
          <div className={"d-flex justify-content-start"}>
            <div className="col-6 col-xs-12">
              <span style={usermanText}>Employee Management</span>
            </div>

            <div className="col-6 col-xs-12 d-flex justify-content-end">
              {/* <button onClick={1} style={exportButton}>
                Export
              </button> */} 
              {/* This button does not work */}
              {selectedTab === 1 ? (
                isSuperAdmin?
                <Button
                  icon={<PlusCircleOutlined />}
                  style={newEmpButton}
                  onClick={() => handleClickOpen("new")}
                >
                  New Employee
                </Button>:null
              ) : (
                <Button
                  icon={<PlusCircleOutlined />}
                  style={newEmpButton}
                  onClick={showRoleModal}
                >
                  New Role
                </Button>
              )}

              <EmployeeFormModal
                open={open}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onCancel={handleCancelEmployeeFormModal}
                setImage={setImage}
                image={image}
                type={type}
                formValues={formValues}
              />

              <RoleModal
                showRoleModal={showRoleModal}
                handleRoleModalOk={handleRoleModalOk}
                handleRoleModalCancel={handleRoleModalCancel}
                newRoleOpen={newRoleOpen}
                setCurrent={setCurrent}
                current={current}
                steps={steps}
                handleCurrentStepChange={handleCurrentStepChange}
                selectedProductAction={selectedProductAction}
                disabled={disabled}
                handleModalCancel={handleModalCancel}
              />
              <UpdateRoleModal open={updateRole} onCancel={handleCancelUpdateRoleModal} formValues={roleValues} onUpdate={onRoleUpdate}  resetForm={() => setRoleValues(null)} type={typeUpdate} setIsPermissionUpdated={setIsPermissionUpdated}/>
              {/* <EmployeePermissionsModal open={openPermission} onCancel={handleClosePermission} formValues={uniquePermissions} /> */}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="col-12 col-md-5 col-sm-5">
            <Input
              placeholder="Search Employee by name, role, ID or any related keywords"
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={selectedTab === 1 ? handleSearch : handleRoleSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="border mt-2 p-2 overflow-x-hidden"
          items={items}
          onChange={onTabChange}
        />
      </div>
    </>
  );
};
export default EmployeeManagement;
