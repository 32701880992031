import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  message,
  Avatar,
  Upload,
  Radio,
  DatePicker,
  Checkbox,
} from "antd";
import PhoneInput from "react-phone-number-input";
import {
  getCountry,
  getLocalGovernmentByStateId,
  getNatureOfBusiness,
  getRelationshipType,
  getState,
} from "../../redux/Actions";
import {
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import ReactFlagsSelect from "react-flags-select";
import { getCountryReducer } from "../../redux/Reducer/CountryReducer";
import axios from "axios";
import '../../Pages/Dashboard/dashboard.css'
import { Facebook, InstagramIcon, Tiktok, TwitterIcon } from "../../asset/icons/icons";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse } from 'antd';
import Cleave from "cleave.js/react";
import { LoadScript } from "@react-google-maps/api";

const { Panel } = Collapse;


const AddCustomerModal = (props) => {
  const {
    isModalOpen,
    handleOk,
    handleCancel,
    usertypeid,
    addCustomerField,
    setAddCustomerField,
    image,
    setImage,
    postingCustomer,
  } = props;
  const {
    stateData,
    isloadingStateData,
    localGovernmentData,
    relationshipTypeData
  } = useSelector(getAuthReducer);
  const { countryData } = useSelector(getCountryReducer);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("NG");
  const [meansofId, setMeansofId] = useState("");

  const [messageApi, contextHolder] = message.useMessage();
  // eslint-disable-next-line no-unused-vars
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [invalidImage, setInvalidImage] = useState(false);
  const [emailErrors, setEmailErrors] = useState("");
  const [showStrategy, setShowStrategy] = useState(false);
  const [showSocials, setShowSocials] = useState(false);
  const [showRep, setShowRep] = useState(false);
  const [options, setOptions] = useState([]);
  const autocompleteServiceRef = useRef(null);

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    debugger
    console.log('Success:', values);
    handleOk(values)
    form.resetFields()
    // Handle successful form submission
  };

  const onFinishFailed = (errorInfo) => {
    debugger
    console.log('Failed:', errorInfo);
    // form.resetFields()
    // Handle form submission failure
  };

  const checkFileSize = (file) => {
    const alaterror = () => {
      messageApi.open({
        type: "error",
        content: `${file.name} is not a png, jpg, jpeg or gif file`,
        duration: 10,
      });
    };

    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSize) {
      message.error("File size must be less than 2MB.");
      return true; // Prevent upload
    }
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!allowedFormats.includes(file.type)) {
      alaterror();
      setInvalidImage(true); // Assuming you have state for this
      return true; // Prevent upload
    } else {
      setInvalidImage(false);
    }
    return false; // Allow upload
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    if (!checkFileSize(file)) {
      onError("File size exceeds limit.");
      return;
    }
    onSuccess("ok");
  };

  const avatarprops = {
    beforeUpload: checkFileSize,
    customRequest: customRequest,
    maxCount: 1,
    name: "avatar",
    onChange: (info) => {
      console.log(info?.fileList);
      let reader = new FileReader();
      let file = info?.file;

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY
      );
      axios
        .post(
          `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData
        )
        .then((res) => setImage(res.data.secure_url))
        .catch((err) => console.log(err));

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    },
  };


  const hasOtherBusinesses = (e) => {
    if (e.target.value === true) {
      setShowStrategy(true);
    } else setShowStrategy(false)
  }
  const haveSocials = (e) => {
    if (e.target.value === true) {
      setShowSocials(true);
    } else setShowSocials(false)
  }

  const handleBusinessChange = (e, field) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        [field]: e.target.value,
      },
    }));
  };


  const handleMaxTurnOverchange = (e) => {
    const valueWithoutCommas = e.target.value.replace(/,/g, "");
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        annual_turnover: valueWithoutCommas,
      },
    }));
  };

  const onGenderChange = (e) => {
    setAddCustomerField({
      ...addCustomerField,
      gender: e.target.value
    });
  };
  const handleDOBChange = (date, dateString) => {
    setAddCustomerField({
      ...addCustomerField,
      date_of_birth: dateString
    });
  };

  const handleMeansOfIdentification = (value) => {
    setMeansofId(value);
  };

  const businessPhonehandler = (e, field) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        [field]: e
      },
    }));
  };
  const phonehandler = (e, field) => {
    setAddCustomerField({
      ...addCustomerField,
      [field]: e,
    });
  };
  const nextOfKinPhonehandler = (e) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      next_of_kin: [{
        ...prevState.next_of_kin[0],
        phone: e,
      }],
    }));
  };

  const handleStateChange = (value, statename) => {
    dispatch(getLocalGovernmentByStateId(value));
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        state: statename,
      },
    }));
  };

  const handleStateOfOriginChange = (value, statename) => {
    dispatch(getLocalGovernmentByStateId(value));
    setAddCustomerField({
      ...addCustomerField,
      state: statename,
    });
  }

  const handleLocalGovernmentChange = (value, lganame) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        lga: lganame,
      },
    }));
  };
  const handleLocalGovernmentOfOriginChange = (value, lganame) => {
    setAddCustomerField({
      ...addCustomerField,
      lga: lganame,
    });
  };

  const handleBusinessYearsExperienceChange = (value) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        years_of_experience: value
      },
    }));
  };

  const handleCategoryofBusChange = (value) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      business: {
        ...prevState.business,
        nature_of_business: value
      },
    }));
  };


  const handleChange = (e, field) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handlePersonalInfoAdChange = (value, field) => {
    debugger
    setAddCustomerField((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    form.setFieldsValue({
      [field]: value,
    });
  };

  const handleNextOfKinChange = (value) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      next_of_kin: [{
        ...prevState.next_of_kin[0],
        relationship_type_id: value,
      }],
    }))
  }

  const handleNextOfKinData = (e, fieldName) => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      next_of_kin: [{
        ...prevState.next_of_kin[0],
        [fieldName]: e.target.value,
      }],
    }))
  }

  const handleNextOfKinAdData = (value, fieldName) => {
    debugger
    setAddCustomerField((prevState) => ({
      ...prevState,
      next_of_kin: [{
        ...prevState.next_of_kin[0],
        [fieldName]: value,
      }],
    }));
    form.setFieldsValue({
      [fieldName]: value,
    });
  }

  const handleAddressSearch = (value, type) => {
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptions((prevState) => ({
            ...prevState,
            [type]: results,
          }));
        }
      );
    } else {
      setOptions((prevState) => ({
        ...prevState,
        [type]: [],
      }));
    }
  };

  const handleAddressChange = (value, type, field) => {
    debugger
    setAddCustomerField((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [field]: value,
      },
    }));
    form.setFieldsValue({
      [`${type}Address`]: value,
    });
  };


  const handleAddSocial = () => {
    setAddCustomerField((prevState) => ({
      ...prevState,
      socials: [...prevState.socials, { icon: "", link: "" }],
    }));
  };

  const handleSocialsChange = (index, fieldName, value) => {
    const updatedSocials = [...addCustomerField.socials];
    updatedSocials[index][fieldName] = value;
    setAddCustomerField((prevState) => ({
      ...prevState,
      socials: updatedSocials,
    }));
  };

  const handleRemoveSocial = (index) => {
    const updatedSocials = addCustomerField.socials.filter((_, i) => i !== index);
    setAddCustomerField((prevState) => ({
      ...prevState,
      socials: updatedSocials,
    }));
  };



  const handleNINChange = (e) => {
    setAddCustomerField({
      ...addCustomerField,
      nin: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setAddCustomerField({
      ...addCustomerField,
      email: emailInput === "" ? "" : emailInput,
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailInput || !emailRegex.test(emailInput)) {
      setEmailErrors("Please enter a valid email address.");
    } else {
      setEmailErrors("");
    }
  };





  useEffect(() => {
    dispatch(getNatureOfBusiness());
    dispatch(getCountry());
    dispatch(getState());
    dispatch(getRelationshipType());
  }, [dispatch]);

  useEffect(() => {
    if (
      countryData) {
      console.log(stateData, "data");
    }
  }, [countryData, stateData]);

  // useEffect(() => {
  //   if (usertypeid === 3 || usertypeid === 5) {
  //     form.setFieldsValue({
  //       businessAddress: addCustomerField?.business?.address,
  //       residentAddress: addCustomerField?.address,
  //       nextOfKinContactAddress: addCustomerField?.next_of_kin[0]?.address,
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       businessAddress: addCustomerField?.business?.address,
  //       residentAddress: addCustomerField?.address,
  //     });
  //   }
   
  // }, [addCustomerField, form, usertypeid]);

  // useEffect(() => {
  //   if (usertypeid === 3 || usertypeid === 5) {
  //     form.setFieldsValue({
  //       businessAddress: addCustomerField?.business?.address,
  //       residentAddress: addCustomerField?.address,
  //       nextOfKinContactAddress: addCustomerField?.next_of_kin[0]?.address,
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       businessAddress: addCustomerField?.business?.address,
  //       residentAddress: addCustomerField?.resident?.address,
  //     });
  //   }
  // }, [addCustomerField, form, usertypeid]);

  useEffect(() => {
    const initialValues = {
      businessAddress: addCustomerField?.business?.address,
      residentAddress: addCustomerField?.residentAddress,
    };

    if (usertypeid === 3) {
      initialValues.nextOfKinContactAddress = addCustomerField?.next_of_kin?.[0]?.address || '';
    }

    form.setFieldsValue(initialValues);
  }, [addCustomerField, form, usertypeid]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal
        title={
          usertypeid === 3
            ? "Add Distributor"
            : usertypeid === 4
              ? "Add Retailer"
              : usertypeid === 5
                ? "Add Direct Customer"
                : "Add Customer"
        }
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        centered
        width={1000}
        styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
        footer={[
          <Button
            key="cancel"
            className="typography rolecancelButton nibbles-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="typography createproducttbutton nibbles-button"
            onClick={() => form.submit()}
          >
            {usertypeid === 3
              ? "Add Distributor"
              : usertypeid === 4
                ? "Add Retailer"
                : usertypeid === 5
                  ? "Add Direct Customer"
                  : "Add Customer"}
          </Button>,
        ]}
      >
        {contextHolder}
        <Spin spinning={postingCustomer} tip="Loading...">
          <Form
            {...formLayout}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size={"large"}
            className="typography p-3"

          >
            <div className="row mb-4">
              <div>
                <Avatar
                  shape="square"
                  size={115}
                  icon={image ? <img src={image} alt="" /> : <UserOutlined />}
                />
                <div>
                  <Upload {...avatarprops}>
                    <Button className="mt-2 uploadComplianceImage" block>
                      Upload Avatar
                    </Button>
                  </Upload>
                </div>
              </div>
            </div>
            <div className="fs-5 mt-3">Company Information</div>

            <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: usertypeid === 5 ? 12 : 24 }} lg={{ span: usertypeid === 5 ? 12 : 24 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      {usertypeid === 5 ? "Store Name" : "Business / Company Name"} <span style={{ color: "red" }}></span>
                    </label>
                  }
                  name="businessName"
                  rules={[{ required: true, message: usertypeid === 5 ? "Please input store name!" : "Please input business name!" }]}
                >
                  <Input
                    placeholder="e.g Kilimanjaro"
                    value={addCustomerField?.business?.name}
                    onChange={(e) => handleBusinessChange(e, "name")}
                  />
                </Form.Item>
              </Col>
              {usertypeid === 5 && <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      {usertypeid === 5 ? "Manager's Full Name" : "Full Name (Surname first)"} <span style={{ color: "red" }}></span>
                    </label>
                  }
                  name="name"
                  rules={[{ required: true, message: "Please input the Customer's full name!" }]}
                  colon={false}
                >
                  <Input
                    placeholder="Eunice Nwachukwu"
                    value={addCustomerField?.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </Form.Item>
              </Col>}

            </Row>
            <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={<label className="typography aboutbusinessformlabel">{usertypeid === 5 ? "Store's Email Address" : "Customer's Email Address"}</label>}
                  name="email"
                  rules={[
                    { type: "email", message: "The input is not valid email!" },
                    { required: true, message: "Please input a vaild email!" },
                  ]}
                >
                  <Input
                    placeholder="e.g johndoe@gmail.com"
                    type="email"
                    value={addCustomerField?.email}
                    onChange={handleEmailChange}
                  />
                </Form.Item>
                {emailErrors && <p className="error">{emailErrors}</p>}
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      {usertypeid === 5 ? "Store's Phone Number" : "Business Phone Number"} <span style={{ color: "red" }}></span>
                    </label>
                  }
                  name={usertypeid === 5 ? "phone" : "office_phone"}
                  rules={[{ required: true, message: usertypeid === 5 ? "Please input phone number of store" : "Please input business phone number!" }]}
                >
                  <div className="phoneInputDiv">
                    <PhoneInput
                      international
                      defaultCountry="NG"
                      autoComplete="tel"
                      onChange={(e) => { usertypeid === 5 ? phonehandler(e, "phone") : businessPhonehandler(e, "office_phone") }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            {usertypeid === 4 || usertypeid === 3 ?
              <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel">
                        Years of Experience in Bread Sales <span style={{ color: "red" }}></span>
                      </label>
                    }
                    name="years_of_experience"
                    rules={[{ required: true, message: "Select years of expereince" }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Range"
                      filterOption={filterOption}
                      onChange={handleBusinessYearsExperienceChange}
                      options={[
                        {
                          value: 1,
                          label: `1 year`,
                        },
                        {
                          value: 2,
                          label: `2 years`,
                        },
                        {
                          value: 3,
                          label: `3 years`,
                        },
                        {
                          value: 4,
                          label: `4 years`,
                        },
                        {
                          value: 5,
                          label: `5 years and above`,
                        },
                      ]}
                    />
                  </Form.Item>

                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel">
                        Nature of Business <span style={{ color: "red" }}></span>
                      </label>
                    }
                    name="nature_of_business"
                    rules={[{ required: true, message: "Please select nature of business!" }]}
                  >
                    {usertypeid === 3 ?
                      <Select
                        onChange={handleCategoryofBusChange}
                        placeholder="e.g Wholesaler"
                        options={[
                          {
                            value: "Wholesaler",
                            label: `Wholesaler`,
                          },
                          {
                            value: "Distributor",
                            label: `Distributor`,
                          },
                        ]}
                      />
                      : usertypeid === 4 ?
                        <Select
                          onChange={handleCategoryofBusChange}
                          placeholder="e.g Retail"
                          options={[
                            {
                              value: "retailer",
                              label: `Retailer`,
                            },
                          ]}
                        />
                        :
                        <Select
                          onChange={handleCategoryofBusChange}
                          placeholder="e.g Direct Customer"
                          options={[
                            {
                              value: "direct",
                              label: `Direct Customer`,
                            },
                          ]}
                        />
                    }
                  </Form.Item>

                </Col>
              </Row> : null}

            <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
              <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      {usertypeid === 5 ? "Country where store is located" : "Country where business resides"}<span style={{ color: "red" }}></span>
                    </label>
                  }
                  name="country"
                >
                  <ReactFlagsSelect
                    selected={selected ?? 'NG'}
                    countries={[`${countryData?.cca2}`]}
                    onSelect={(code) => setSelected(code)}
                  />
                </Form.Item>


              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      State <span style={{ color: "red" }}></span>
                    </label>
                  }
                  name="state"
                  rules={[{ required: true, message: "Please select state where business is located!" }]}
                >
                  {isloadingStateData ? (
                    <Spin size="default" />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select a state where the business resides"
                      onChange={(value, option) =>
                        handleStateChange(value, option?.label)
                      }
                      filterOption={filterOption}
                      options={stateData?.map((state) => ({
                        value: state.id,
                        label: state.name,
                      }))}
                    />
                  )}
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
              <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Local Government <span style={{ color: "red" }}></span>
                    </label>
                  }
                  name="localGovernment"
                  rules={[{ required: true, message: "Please select a local government!" }]}
                >
                  <Select
                    showSearch
                    placeholder="Select Local Government of the business"
                    filterOption={filterOption}
                    onChange={(value, option) =>
                      handleLocalGovernmentChange(value, option.label)
                    }
                    options={localGovernmentData?.map((localgovernment) => ({
                      value: localgovernment.id,
                      label: localgovernment.name,
                    }))}
                  />
                </Form.Item>

              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      {usertypeid === 5 ? "Store's Address" : "Business Address"} <span style={{ color: "red" }}></span>
                    </label>
                  }
                  name="businessAddress"
                  rules={[{ required: true, message: "Please enter the address of the store" }]}
                >
                  {/* <Input
                    placeholder="enter a street address "
                    type="businessAddress"
                    value={addCustomerField?.business?.address}
                    onChange={(e) => handleBusinessChange(e, "address")}
                  /> */}
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    libraries={["places"]}
                  >
                    <Select
                      showSearch
                      placeholder="Enter a street address"
                      type="businessAddress"
                      onSearch={(value) => handleAddressSearch(value, 'business')}
                      onChange={(value) => handleAddressChange(value, 'business', 'address')}
                      options={options.business}
                      filterOption={false}
                      value={addCustomerField?.business?.address}
                      notFoundContent={null}
                    />
                  </LoadScript>
                </Form.Item>
              </Col>
            </Row>

            {usertypeid !== 5 && (
              <>
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Means of Identification {usertypeid === 4 ? "(Optional)" : <span style={{ color: "red" }}></span>}
                    </label>
                  }
                  name="meansOfId"
                  rules={[
                    {
                      required: usertypeid !== 4,
                      message: "Please select a means of identification!",
                    },
                  ]}
                >
                  <Select
                    onChange={handleMeansOfIdentification}
                    placeholder="Choose a means of identification"
                    options={[
                      { value: "NIN", label: `National Identity Number (NIN)` },
                      { value: "passport", label: `International Passport` },
                      { value: "driverslicense", label: `Drivers License` },
                      { value: "voterscard", label: `Voters card` },
                    ]}
                  />
                </Form.Item>
                {meansofId && (
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel">
                        {meansofId === "NIN"
                          ? "NIN"
                          : meansofId === "driverslicense"
                            ? "Drivers License"
                            : meansofId === "voterscard"
                              ? "Voters card"
                              : "International Passport"}
                        <span style={{ color: "red" }}> </span>
                      </label>
                    }
                    name="idNumber"
                    rules={[
                      {
                        required: true,
                        message: `Please enter the ${meansofId === "NIN"
                          ? "NIN"
                          : meansofId === "driverslicense"
                            ? "Drivers License"
                            : meansofId === "voterscard"
                              ? "Voters card"
                              : "International Passport"
                          } number!`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={
                        meansofId === "NIN"
                          ? "Enter the NIN here"
                          : meansofId === "driverslicense"
                            ? "Enter the License Id."
                            : meansofId === "voterscard"
                              ? "Enter the Voters Id here"
                              : "Enter the Passport No."
                      }
                      type="number"
                      onChange={handleNINChange}
                    />
                  </Form.Item>
                )}
              </>
            )}

            {usertypeid !== 5 &&
              <>
                <div className="fs-5 mt-3">Personal Information</div>
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Full Name (Surname first) <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="name"
                      rules={[{ required: true, message: "Please input the Customer's full name!" }]}
                      colon={false}
                    >
                      <Input
                        placeholder="Eunice Nwachukwu"
                        value={addCustomerField?.name}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Gender <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="gender"
                      rules={[{ required: true, message: "Please answer this question!" }]}
                    >
                      <Radio.Group
                        onChange={onGenderChange}
                        value={addCustomerField?.gender}
                      >

                        <Radio value={"male"} style={{ fontSize: "18px" }} >Male</Radio>
                        <Radio value={"female"} style={{ fontSize: "18px" }}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                </Row>
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Date of birth <span style={{ color: "red" }}></span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      name={"date_of_birth"}
                    >
                      <DatePicker
                        size="large"
                        style={{ width: "100%" }}
                        onChange={handleDOBChange}
                        format={"DD/MM/YYYY"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          State of Origin <span style={{ color: "red" }}></span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      name={"stateOfOrigin"}
                    >
                      {isloadingStateData ? (
                        <Spin size="default" />
                      ) : (
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Select a state"
                          onChange={(value, option) =>
                            handleStateOfOriginChange(value, option?.label)
                          }
                          filterOption={filterOption}
                          options={stateData?.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Local Government of Origin <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="lgaOrigin"
                      rules={[{ required: true, message: "Please select your local government of origin!" }]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Local Government- of Origin"
                        filterOption={filterOption}
                        onChange={(value, option) =>
                          handleLocalGovernmentOfOriginChange(value, option.label)
                        }
                        options={localGovernmentData?.map((localgovernment) => ({
                          value: localgovernment.id,
                          label: localgovernment.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Resident Address <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="residentAddress"
                      rules={[{ required: true, message: "Please enter the customer's street address" }]}
                    >
                      {/* <Input
                        placeholder="street address of where the customer currently resides"
                        type="residentAddress"
                        value={addCustomerField?.residentAddress}
                        onChange={(e) => handleChange(e, "address")}
                      /> */}
                      <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        libraries={["places"]}
                      >
                        <Select
                          showSearch
                          placeholder="Enter a street address"
                          onSearch={(value) => handleAddressSearch(value, 'resident')}
                          onChange={(value) => handlePersonalInfoAdChange(value, 'residentAddress')}
                          options={options.resident}
                          filterOption={false}
                          value={addCustomerField?.resident?.address}
                          notFoundContent={null}
                        />
                      </LoadScript>
                    </Form.Item>
                  </Col>
                </Row>


                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          WhatsApp Number <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="whatsapp_phone"
                    // rules={[{ required: true, message: "Please input customer's WhatsApp number!" }]}
                    >
                      <div className="phoneInputDiv">
                        <PhoneInput
                          international
                          defaultCountry="NG"
                          autoComplete="tel"
                          onChange={(e) => phonehandler(e, "whatsapp_phone")}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Phone Number <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="phone"
                      rules={[{ required: true, message: "Please input customer's phone number!" }]}
                    >
                      <div className="phoneInputDiv">
                        <PhoneInput
                          international
                          defaultCountry="NG"
                          autoComplete="tel"
                          onChange={(e) => phonehandler(e, "phone")}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }

            {usertypeid !== 4 && usertypeid !== 5 &&
              <>
                <div className="fs-5 mt-3">Next of Kin Information</div>
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Next of Kin (Surname first)  <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="nextOfKin"
                      rules={[{ required: true, message: "Please input the Next of Kin's full name!" }]}
                      colon={false}
                    >
                      <Input
                        placeholder="e.g. John Doe"
                        value={addCustomerField?.name}
                        onChange={(e) => handleNextOfKinData(e, "name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      name="nextOfKinRelationship"
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Relationship to Next of Kin{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                      }
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Relationship"
                        onChange={(value) =>
                          handleNextOfKinChange(
                            value
                          )
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        value={addCustomerField?.nextOfKinRelationship}
                        options={Array?.isArray(relationshipTypeData) && relationshipTypeData?.map(
                          (relationshiptypes) => ({
                            value: relationshiptypes?.id,
                            label: relationshiptypes?.name,
                          })
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Next of Kin Phone Number <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="nextofKinPhoneNumber"
                      rules={[{ required: true, message: "Please input Next of Kin's Phone Number!" }]}
                    >
                      <div className="phoneInputDiv">
                        <PhoneInput
                          international
                          defaultCountry="NG"
                          autoComplete="tel"
                          onChange={(e) => nextOfKinPhonehandler(e, "phone")}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Contact Address of Next of Kin  <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="nextOfKinContactAddress"
                      rules={[{ required: true, message: "Please enter the contact address of Next of Kin " }]}
                    >
                      {/* <Input
                        placeholder="enter a street address of the next of kin"
                        type="nextOfKinContactAddress"
                        value={addCustomerField?.nextOfKinContactAddress}
                        onChange={(e) => handleNextOfKinData(e, "address")}
                      /> */}
                        <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        libraries={["places"]}
                      >
                        <Select
                          showSearch
                          type="nextOfKinContactAddress"
                          placeholder="Enter a street address"
                          onSearch={(value) => handleAddressSearch(value, 'next_of_kin')}
                          onChange={(value) => handleNextOfKinAdData(value, 'address')}
                          options={options.next_of_kin}
                          filterOption={false}
                          value={addCustomerField?.next_of_kin[0]?.address}
                          notFoundContent={null}
                        />
                      </LoadScript>
                    </Form.Item>
                  </Col>
                </Row>
              </>}

            {usertypeid !== 4 && usertypeid !== 5 &&
              <>
                <div className="fs-5 mt-3">Additional Information</div>
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Are you able to distribute a minimum of 100 loaves a week?<span style={{ color: "red" }}></span>
                        </label>
                      }
                      rules={[{ required: true, message: "Please answer this question" }]}
                      name="distribute_loaf"
                    >
                      <Radio.Group
                        onChange={(e) => handleChange(e, "distribute_loaf")}
                        value={addCustomerField?.distribute_loaf}
                      >

                        <Radio value={true} style={{ fontSize: "18px" }} >Yes</Radio>
                        <Radio value={false} style={{ fontSize: "18px" }}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          What is your annual turnover? <span style={{ color: "red" }}></span>
                        </label>
                      }
                      rules={[{ required: true, message: "Please answer this question" }]}
                      name="annual_turnover"
                    >
                      <Cleave
                        placeholder="e.g 200000"
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "3%",
                          // background: 'transparent',
                          border: "0.5px solid #C6C7C9",
                          borderRadius: "7px",
                          backgroundColor: "white",
                        }}
                        fullWidth
                        value={addCustomerField?.business?.annual_turnover}
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: "thousand",
                        }}
                        onChange={handleMaxTurnOverchange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Do you do business with another Company? <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="hasOtherBusinesses"
                      rules={[{ required: true, message: "Please answer this question!" }]}
                    >
                      <Radio.Group
                        onChange={hasOtherBusinesses}
                      // value={addCustomerField?.gender}
                      >

                        <Radio value={true} style={{ fontSize: "18px" }} >Yes</Radio>
                        <Radio value={false} style={{ fontSize: "18px" }}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {showStrategy && <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          If yes, please list the businesses and products <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="strategy_brief"
                      rules={[{ required: showStrategy ? true : false, message: "Please answer this question" }]}
                    >
                      <Input.TextArea
                        placeholder="List businesses and products"
                        value={addCustomerField?.business?.strategy_brief}
                        rows={5}
                        onChange={(e) => handleBusinessChange(e, "strategy_brief")}

                      />
                    </Form.Item>
                  </Col>
                </Row>}
                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Do you own a suitable delivery van with an insurance policy that meets the bakery requirements <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="has_delivery_van"
                      rules={[{ required: true, message: "Please answer this question" }]}
                    >
                      <Radio.Group
                        onChange={(e) => handleBusinessChange(e, "has_delivery_van")}
                        value={addCustomerField?.business?.has_delivery_van}
                      >

                        <Radio value={1} style={{ fontSize: "18px" }} >Yes</Radio>
                        <Radio value={0} style={{ fontSize: "18px" }}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Does your business have social media accounts? <span style={{ color: "red" }}></span>
                        </label>
                      }
                      name="haveSocials"
                      rules={[{ required: true, message: "Please answer this question!" }]}
                    >
                      <Radio.Group
                        onChange={haveSocials}
                      // value={addCustomerField?.gender}
                      >

                        <Radio value={true} style={{ fontSize: "18px" }} >Yes</Radio>
                        <Radio value={false} style={{ fontSize: "18px" }}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                {showSocials &&
                  <Collapse accordion defaultActiveKey={['1']}>
                    <Panel header="Add Social Media Links" key="1" className="p-3">
                      {addCustomerField?.socials?.map((social, index) => (
                        <Row key={index} gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                          <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 22 }} lg={{ span: 22 }}>
                            <Form.Item
                              label={
                                <label className="typography aboutbusinessformlabel">
                                  Social Media Handles
                                </label>
                              }
                              className="aboutbusinessformitem"
                            >
                              <Input
                                addonBefore={
                                  <Select
                                    value={social?.icon}
                                    onChange={(value) => handleSocialsChange(index, "icon", value)}
                                    style={{ width: "60px" }}
                                  >
                                    <Select.Option value="Instagram">
                                      <InstagramIcon />
                                    </Select.Option>
                                    <Select.Option value="Facebook">
                                      <Facebook />
                                    </Select.Option>
                                    <Select.Option value="Twitter">
                                      <TwitterIcon />
                                    </Select.Option>
                                    <Select.Option value="Tiktok">
                                      <Tiktok />
                                    </Select.Option>
                                  </Select>
                                }
                                style={{ width: "100%" }}
                                value={social.link}
                                onChange={(e) => handleSocialsChange(index, "link", e.target.value)}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }}>
                            <Form.Item className="mt-4 addanothersocialbuttonfi">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => handleRemoveSocial(index)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}

                      {usertypeid === 4 ? (
                        ""
                      ) : (
                        <Form.Item className="addanothersocialbuttonfi">
                          <Button
                            type="text"
                            className="addanotherphonebutton"
                            onClick={handleAddSocial}
                            icon={<PlusOutlined />}
                          >
                            Add Social Link
                          </Button>
                        </Form.Item>
                      )}
                    </Panel>
                  </Collapse>
                }
              </>}

            {usertypeid !== 4 && usertypeid !== 5 &&
              <>
                <Row className="mt-5" gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col span={24}>
                    <Form.Item>
                      <Checkbox
                        checked={showRep}
                        onChange={(e) => setShowRep(e.target.checked)}
                      >
                        <b>Have other team representatives?</b>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                {showRep && <>

                  <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                    <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item
                        label={
                          <label className="typography aboutbusinessformlabel">
                            Name of Representative (Surname first) <span style={{ color: "red" }}></span>
                          </label>
                        }
                        name="contact_name"
                        rules={[{ required: showRep ? true : false, message: "Please input the Customer's full name!" }]}
                        colon={false}
                      >
                        <Input
                          placeholder="e.g. John Doe"
                          value={addCustomerField?.business?.name}
                          onChange={(e) => handleBusinessChange(e, "contact_name")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item
                        label={<label className="typography aboutbusinessformlabel">Email Address of Representative</label>}
                        name="representativeEmail"
                        rules={[
                          { type: "email", message: "The input is not valid email!" },
                          { required: showRep ? true : false, message: "Please input a vaild email!" },
                        ]}
                      >
                        <Input
                          placeholder="e.g johndoe@gmail.com"
                          type="contact_email"
                          value={addCustomerField?.business?.contact_email}
                          onChange={(e) => handleBusinessChange(e, "contact_email")}
                        />
                      </Form.Item>
                      {emailErrors && <p className="error">{emailErrors}</p>}
                    </Col>


                  </Row>
                  <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                    <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item
                        label={
                          <label className="typography aboutbusinessformlabel">
                            Phone Number of Representative <span style={{ color: "red" }}></span>
                          </label>
                        }
                        name="contact_phone"
                        rules={[{ required: showRep ? true : false, message: "Please input business phone number!" }]}
                      >
                        <div className="phoneInputDiv">
                          <PhoneInput
                            international
                            defaultCountry="NG"
                            autoComplete="tel"
                            onChange={(e) => businessPhonehandler(e, "contact_phone")}
                          />
                        </div>
                      </Form.Item>
                    </Col>

                  </Row>
                </>}
              </>}
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
