// import { toastr } from "react-redux-toastr";
import api from "../../Service/api";

export const GetCountryApi = async () => {
  // debugger
    if (navigator.onLine === false) {
      // toastr.error("No Internet Connection", "Try again");
    } else {
      try {
        const response = await api.get(`/country?name=Nigeria`);
          if (response.status === 200) {
            // debugger
            // toastr.success("Get Country", "success");
            return response.data.data[0];
          } else {
            // toastr.error("Get Country", response.error);
          }
      } catch (ex) {
        // toastr.error("Get Country", "An error occurred");
        throw ex;
      }
    }
  };