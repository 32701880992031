export const yearsOfExperienceInBreadSales = [
      {
        value: 1,
        label: `1 year`,
      },
      {
        value: 2,
        label: `2 years`,
      },
      {
        value: 3,
        label: `3 years`,
      },
      {
        value: 4,
        label: `4 years`,
      },
      {
        value: 5,
        label: `5 years and above`,
      },
];