import React, {useEffect, useState } from 'react';
import { Table, Tabs, Input, Modal, Form, message, Upload, Radio, Checkbox  } from "antd";
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;
const props = {
  name: 'file',
  multiple: true,
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    // console.log('Dropped files', e.dataTransfer.files);
  },
};



  const data = [
    {
        key: "1",
        coupon_name: "Easter Discount 20% off",
        usage: "20 times",
        date: "Apr 05, 2020 - Apr 15, 2024",
        status: "success",
      },
      {
        key: "2",
        coupon_name: "Spring Savings Event",
        usage: "100 times",
        date: "Mar 20, 2024 - Apr 01, 2024",
        status: "Active"
    },
    {
        key: "3",
        coupon_name: "Summer Clearance Sale",
        usage: "50 times",
        date: "Jun 15, 2024 - Jun 30, 2024",
        status: "Expired"
    },
    {
        key: "4",
        coupon_name: "Back to School Discount",
        usage: "75 times",
        date: "Aug 01, 2024 - Aug 15, 2024",
        status: "Active"
    },
    {
        key: "5",
        coupon_name: "Fall Flash Sale",
        usage: "200 times",
        date: "Oct 10, 2024 - Oct 20, 2024",
        status: "Expired"
    },
    {
        key: "6",
        coupon_name: "Holiday Gift Special",
        usage: "Unlimited",
        date: "Dec 01, 2024 - Dec 25, 2024",
        status: "Active"
    },
    {
        key: "7",
        coupon_name: "New Year New Savings",
        usage: "150 times",
        date: "Jan 01, 2025 - Jan 15, 2025",
        status: "Expired"
    },
    {
        key: "8",
        coupon_name: "Valentine's Day Romance Deal",
        usage: "50 times",
        date: "Feb 01, 2025 - Feb 14, 2025",
        status: "Active"
    },
    {
        key: "9",
        coupon_name: "Spring into Action Sale",
        usage: "Unlimited",
        date: "Mar 15, 2025 - Mar 31, 2025",
        status: "Expired"
    },
    {
        key: "10",
        coupon_name: "Easter Eggstravaganza",
        usage: "100 times",
        date: "Apr 01, 2025 - Apr 15, 2025",
        status: "Active"
    },
    {
        key: "11",
        coupon_name: "Spring Cleaning Clearance",
        usage: "75 times",
        date: "May 01, 2025 - May 15, 2025",
        status: "Expired"
    },
    {
        key: "12",
        coupon_name: "Summer Fun Savings",
        usage: "200 times",
        date: "Jun 15, 2025 - Jun 30, 2025",
        status: "Active"
    },
    {
        key: "13",
        coupon_name: "Independence Day Blowout",
        usage: "Unlimited",
        date: "Jul 01, 2025 - Jul 07, 2025",
        status: "Expired"
    },
    {
        key: "14",
        coupon_name: "Back to School Bargains",
        usage: "150 times",
        date: "Aug 01, 2025 - Aug 15, 2025",
        status: "Active"
    },
    {
        key: "15",
        coupon_name: "Fall Harvest Savings",
        usage: "50 times",
        date: "Sep 01, 2025 - Sep 15, 2025",
        status: "Expired"
    },
    {
        key: "16",
        coupon_name: "Halloween Spooktacular",
        usage: "Unlimited",
        date: "Oct 15, 2025 - Oct 31, 2025",
        status: "Active"
    },
    {
        key: "17",
        coupon_name: "Thanksgiving Feast Sale",
        usage: "100 times",
        date: "Nov 01, 2025 - Nov 15, 2025",
        status: "Expired"
    },
    {
        key: "18",
        coupon_name: "Holiday Joy Extravaganza",
        usage: "75 times",
        date: "Dec 01, 2025 - Dec 25, 2025",
        status: "Active"
    },
    {
        key: "19",
        coupon_name: "Year-End Clearance Blowout",
        usage: "200 times",
        date: "Dec 26, 2025 - Jan 01, 2026",
        status: "Expired"
    },
    {
        key: "20",
        coupon_name: "New Year New Deals",
        usage: "Unlimited",
        date: "Jan 01, 2026 - Jan 15, 2026",
        status: "Active"
    }
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
  };

  const PromoManagement = () => {

    const columns = [
      {
        title: "Key",
        dataIndex: "key",
        render: (text) => <span className="orderHistory-text">{text}</span>,
      },
      {
        title: "Coupon Name",
        dataIndex: "coupon_name",
        render: (text) => (
          <span className="orderHistory-text">
            {text} <br />
          </span>
        ),
        sorter: {
          compare: (a, b) => a.date - b.date,
          multiple: 3,
        },
      },
      {
        title: "Usage",
        dataIndex: "usage",
        render: (text) => <span className="orderHistory-text">{text}</span>,
        sorter: {
          compare: (a, b) => a.noofItems - b.noofItems,
          multiple: 2,
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (text) => <span className="orderHistory-text">{text}</span>,
        sorter: {
          compare: (a, b) => a.amount - b.amount,
          multiple: 1,
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let statusClass = "";
          switch (text.toLowerCase()) {
            case "Active":
              statusClass = "orderHistory approved";
              break;
            case "Expired":
              statusClass = "orderHistory rejected";
              break;
            default:
              statusClass = "orderHistory approved";
          }
          return (
            <span key={record.key} className={statusClass}>
              {text}
            </span>
          );
        },
        sorter: {
          compare: (a, b) => a.status - b.status,
          multiple: 1,
        },
      },
      {
        title: "Actions",
        render: (text, record) => (
          <span className="orderHistory-view-details">
            {/* <a href={''} onClick={() => setOpen(true)}>View Details</a> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
            >
              <path
                d="M8.3198 0.504883C10.0075 0.504883 11.6075 1.0904 13.0007 2.1744C14.3938 3.25048 15.5799 4.82504 16.4317 6.79523C16.4954 6.94556 16.4954 7.11964 16.4317 7.26206C14.7281 11.2024 11.6951 13.5603 8.3198 13.5603H8.31184C4.9445 13.5603 1.91149 11.2024 0.20792 7.26206C0.144235 7.11964 0.144235 6.94556 0.20792 6.79523C1.91149 2.85486 4.9445 0.504883 8.31184 0.504883H8.3198ZM8.3198 3.86765C6.5605 3.86765 5.13555 5.28396 5.13555 7.0326C5.13555 8.77332 6.5605 10.1896 8.3198 10.1896C10.0711 10.1896 11.4961 8.77332 11.4961 7.0326C11.4961 5.28396 10.0711 3.86765 8.3198 3.86765ZM8.32076 5.05276C9.41136 5.05276 10.303 5.93895 10.303 7.03086C10.303 8.11486 9.41136 9.00104 8.32076 9.00104C7.22219 9.00104 6.3306 8.11486 6.3306 7.03086C6.3306 6.89635 6.34652 6.76975 6.3704 6.64315H6.41021C7.29384 6.64315 8.01029 5.94686 8.04214 5.0765C8.1297 5.06068 8.22523 5.05276 8.32076 5.05276Z"
                fill="#45464E" onClick={() => setOpenView(true)}
              />
            </svg>
          </span>
        ),
      },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
      // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
   
const usermanText = {
  width: '189px',
  flexShrink: 0,
  color: '#45464E',  
  fontFamily: '"MADE TOMMY"',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '28px'  
};

// const tag={
//     padding: "3.867px 7.734px",
//     alignItems: "center",
//     gap: "0px",
//     borderRadius: "348.021px",
//     background: "#F4F1FD",
//   }

const newEmpButton = {
  display: "flex",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#252A4D",
  color: "#FFF",
  fontFamily: '"MADE TOMMY"',
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};


  const filter = {
    color: "var(--Black-80, #52575C)",
    fontFamily: "Inter",
    fontSize: "13.138px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22.992px", // 175%
    letterSpacing: "-0.145px",
};

const filterDiv = {
    display: "flex",
    width: "588.756px",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "16.423px",
    flexShrink: 0
};
const filterCount = {
    borderRadius: "410.569px",
    background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
    display: "flex",
    padding: "3.285px 6.138px 4.423px 6.57px",
    justifyContent: "center",
    alignItems: "center",
    color: "var(--Black-80, #52575C)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "9.854px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
};

const styles = {
    text: {
      color: '#5A607F',
    //   textAlign: 'center',
      fontFamily: '"MADE TOMMY", sans-serif',
      fontSize: '12.919px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '25.839px' /* 200% */
    }
  };


  const items = [
    {
      key: "1",
      label: (
        <div style={{ color: "#252A4D" }}>
          All Coupons
          {/* <span className="mx-2" style={tag}>
            {10}
          </span> */}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChange}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ color: "#252A4D" }}>
          Active Coupons
          {/* <span className="mx-2" style={tag}>
            {4}
          </span> */}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
         
         <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChange}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ color: "#252A4D" }}>
          Expired Coupons
          {/* <span className="mx-2" style={tag}>
            {4}
          </span> */}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
         
         <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChange}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </div>
      ),
    },
  ];




const CollectionCreateForm = ({ initialValues, onFormInstanceReady }) => {
    const [form] = Form.useForm();
    useEffect(() => {
      onFormInstanceReady(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <Form
        layout="vertical"
        form={form}
        name="form_in_modal"
        initialValues={initialValues}
        className="mt-3"
      >
        <div className="row">
          <div className="col-12">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                (Max. File size: 1MB), File must be pdf, jpeg or png
              </p>
            </Dragger>
          </div>
        </div>

        <div className="fs-5 mb-4 mt-3">Promo Information</div>

        <div className="row">
          <div className="col">
            <Form.Item name="promo_name">
              <Input type="text" placeholder="Promo Name" />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Form.Item name="promo_code">
              <Input type="text" placeholder="Promo Code" />
            </Form.Item>
          </div>
        </div>

        <div className="fs-5 mb-4">Promo Type</div>

        <div className="mb-4" style={styles.text}>Type of coupon you want to create</div>

        <div>
          <Form.Item>
            <Radio.Group>
              <div className="row">
                <div className="d-flex justify-content-start">
                  <div className=" d-flex justify-content-start">
                    <Radio value="percentage "> <span style={styles.text}>Percentage Discount</span> </Radio>
                  </div>
                  <div className=" d-flex justify-content-end">
                    <Radio value="price"> <span style={styles.text}>Price Discount</span> </Radio>
                  </div>
                </div>
              </div>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="row">
          <div className="col">
            <Form.Item name="discount_value">
              <Input type="text" placeholder="Discount Value" />
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item name="applies_to">
              <Input type="text" placeholder="Applies to" />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Form.Item name="duration">
              <Input type="text" placeholder="duration" />
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item name="usage_limits">
              <Input type="text" placeholder="Usage Limits" />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Form.Item name="disabled" valuePropName="isDurationNotSet">
              <Checkbox> <span style={styles.text}>Don't set duration</span></Checkbox>
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item name="disabled" valuePropName="isNotlimitUser">
              <Checkbox> <span style={styles.text}>Don't limit amount of users</span></Checkbox>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const CollectionCreateFormModal = ({
    open,
    onCreate,
    onCancel,
    initialValues,
  }) => {
    const [formInstance, setFormInstance] = useState();
    return (
      <Modal
        open={open}
        title="Create Promo"
        okText="Create Promo"
        cancelText="Cancel"
        okButtonProps={{
          autoFocus: true,
        }}
        onCancel={onCancel}
        destroyOnClose
        onOk={async () => {
          try {
            const values = await formInstance?.validateFields();
            formInstance?.resetFields();
            onCreate(values);
          } catch (error) {
            // console.log("Failed:", error);
          }
        }}
      >
        <CollectionCreateForm
          initialValues={initialValues}
          onFormInstanceReady={(instance) => {
            setFormInstance(instance);
          }}
        />
      </Modal>
    );
  };

  const [formValues, setFormValues] = useState();
  const [open, setOpen] = useState(false);
  
  const [openView, setOpenView] = useState(false);


  const onCreate = (values) => {
    // console.log("Received values of form: ", values);
    setFormValues(values);
    setOpen(false);
  };



  
const CollectionCreateFormView = () => {
  return (
   <>
   View promo
   </>
  );
};

const CollectionCreateFormModalView = ({
  openView,
  onCancel,
}) => {
  return (
    <Modal
      open={openView}
      title="View Promo"
      okText="Ok"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <CollectionCreateFormView
      />
    </Modal>
  );
};


  


  
  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px", margin: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Promos</span>
            </div>
            <div className="col d-flex justify-content-end">
              <button style={newEmpButton} onClick={() => setOpen(true)}>
                New Promo
              </button>

              <pre>{JSON.stringify(formValues, null, 2)}</pre>

              <CollectionCreateFormModal
                open={open}
                onCreate={onCreate}
                onCancel={() => setOpen(false)}
                initialValues={{
                  modifier: "public",
                }}
              />

               <CollectionCreateFormModalView
                open={openView}
                onCancel={() => setOpenView(false)}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="d-flex justify-content-start">
            <div className="col-3">
              <Input placeholder="Basic usage" />
            </div>
            <div className="col d-flex justify-content-end" style={filterDiv}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                    fill="#52575C"
                  />
                </svg>
              </span>{" "}
              <span style={filter} className="">
                Filter
              </span>
              <span style={filterCount}>0</span>
            </div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="border mt-2 p-2"
          items={items}
          onChange={onChange}
        />
      </div>
    </>
  );
};
export default PromoManagement;