import React, { useEffect, useState } from "react";
import { Button, Card, Col, Layout, Row } from "antd";
import nibbleslogo from "../../asset/nibblesuserdashblogo.jpg";
import retailerupgradeimg from "../../asset/retailerupgradeimg.jpg";
import {
  BuildingIcon,
  DialIcon,
  EarPhoneIcon,
  EmailIcon,
  // Facebook,
  // InstagramIcon,
  LocationIcon,
  // Tiktok,
} from "../../asset/icons/icons";
import "./dashboard.css";
import RadioRetailerOptionCard from "../../Components/radioRetailerOptionCard";
import { Logout } from "../../Utils/logout";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getIndependentDistributorNearMe,
  getUserProfileDetails,
  postAssignedDistributor,
} from "../../redux/Actions";
import { useNavigate } from "react-router-dom";
import GetHelp from "./getHelp";
// eslint-disable-next-line no-unused-vars
const { Header, Footer } = Layout;

const RetailerDashboard = () => {
  const {
    independentDistributorNearMe,
    userProfileDetails,
    // isloadingIndependentDistributorNearMe,
    assignDistributor,
    authUser,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [changeDistributorView, setChangeDistributorView] = useState(false);
  const [contactSupportView, setContactSupportView] = useState(false);
  const [user_type_id, setSelectedOption] = useState("");
  const handleChangeDistributor = () => {
    // debugger;
    setChangeDistributorView(!changeDistributorView);
  };
  const handleContactSupport = () => {
    // debugger;
    setContactSupportView(!contactSupportView);
  };
  // console.log(changeDistributorView, "changeDistributorView")
  // console.log(user_type_id, "user_type_id");
  useEffect(() => {
    // dispatch(getAllIndependentDistributorship({ user_type_id }));

    dispatch(getIndependentDistributorNearMe());
    dispatch(getUserProfileDetails(authUser?.user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // dispatch(getAllIndependentDistributorship({ user_type_id }));
    if (assignDistributor === true) {
      dispatch(getIndependentDistributorNearMe());
      dispatch(getUserProfileDetails(authUser?.user.id));
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignDistributor]);

  useEffect(() => {
    // dispatch(getAllIndependentDistributorship({ user_type_id }));
    if (userProfileDetails.status === true) {
      localStorage.setItem(
        "userprofile",
        JSON.stringify(userProfileDetails.data)
      );
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileDetails]);

  console.log(userProfileDetails, "userProfileDetails");
  console.log(assignDistributor, "assignDistributor");

  const handleChooseDistributor = () => {
    // debugger;
    dispatch(
      postAssignedDistributor({
        distributor_id: user_type_id,
        user_id: authUser?.user.id,
      })
    );
    setChangeDistributorView(!changeDistributorView);
    dispatch(getUserProfileDetails(authUser?.user.id));
  };

  const handleApplyForUpgrade = () => {
    // debugger;
    navigate("/onboarding");
  };

  console.log(independentDistributorNearMe, "independentDistributorNearMe");

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        {/* <Row> */}
        <div>
          <div className="queueheader">
            <div className="queueheaderlogo">
              <img
                src={nibbleslogo}
                alt="NibblesLogo"
                // style={{ width: collapsed ? "80px" : "", marginBottom: "8px" }}
              />
            </div>
            <div
            // className="headercol1"
            >
              <p className="headercoldtext2">
                <LocationIcon /> {userProfileDetails?.data?.business?.state},
                Nigeria
              </p>
            </div>
          </div>
          {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <SearchBox
                placeholder={"Search here"}
                onChange={(e) => handleSearch(e.target.value)}
                value={searchValue}
                width="100%"
                hoverwidth={"35em"}
              />
            </div> */}
        </div>
        {/* </Row> */}
      </Header>
      <Card>
        {contactSupportView === true ? (
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 45,
            }}
          >
            <Col span={12}>
              <p className="retailwelcometext mt-4">
                Welcome 👋, {userProfileDetails?.data?.name}.
              </p>
              <p className="mb-4">
                {userProfileDetails?.data?.distributor !== null ? (
                  <span>
                    You have been assigned to the distributor below to place
                    your orders.
                  </span>
                ) : (
                  <span>
                    Kindly choose a distributor to start placing your orders.
                  </span>
                )}
              </p>
              {/* <Switch checked={checked} onChange={onChange} className="mb-4" /> */}
            </Col>

            <Col span={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="default"
                  className="queueorderbuttonsselected"
                  shape="round"
                  // size={"large"}
                  onClick={Logout}
                >
                  Logout
                </Button>

                <Button
                  type="default"
                  className="retailbutton"
                  style={{ marginLeft: "1em" }}
                  shape="round"
                  // size={"large"}
                  onClick={handleContactSupport}
                >
                  Return to Dashboard
                </Button>
              </div>
              {/* <Switch checked={checked} onChange={onChange} className="mb-4" /> */}
            </Col>

            <Col span={24}>
              <GetHelp />
            </Col>
          </Row>
        ) : (
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 45,
            }}
          >
            <Col span={12}>
              <p className="retailwelcometext mt-4">
                Welcome 👋, {userProfileDetails?.data?.name}.
              </p>
              <p className="mb-4">
                {userProfileDetails?.data?.distributor !== null ? (
                  <span>
                    You have been assigned to the distributor below to place
                    your orders.
                  </span>
                ) : (
                  <span>
                    Kindly choose a distributor to start placing your orders.
                  </span>
                )}
              </p>
              {/* <Switch checked={checked} onChange={onChange} className="mb-4" /> */}
            </Col>

            <Col span={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="default"
                  className="queueorderbuttonsselected"
                  shape="round"
                  // size={"large"}
                  onClick={Logout}
                >
                  Logout
                </Button>
              </div>
              {/* <Switch checked={checked} onChange={onChange} className="mb-4" /> */}
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              {changeDistributorView === true ? (
                <Card>
                  <div className="mt-2">
                    <p>
                      <BuildingIcon />
                    </p>
                  </div>
                  {independentDistributorNearMe?.data?.length !== 0 ? (
                    <div>
                      <div>
                        <p className="retailwelcometext">
                          Choose the Nearest Distributor to you
                        </p>
                        <p>
                          Below is a list of all distributors to place your
                          order from
                        </p>
                      </div>
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 16 }}
                          lg={{ span: 16 }}
                          xl={{ span: 16 }}
                        >
                          <div>
                            {independentDistributorNearMe?.data?.map(
                              (item, i) => (
                                <RadioRetailerOptionCard
                                  setSelectedOption={setSelectedOption}
                                  selectedOption={user_type_id}
                                  item={item}
                                />
                              )
                            )}
                          </div>
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}
                          xl={{ span: 8 }}
                        >
                          <div className="retailsupportdiv">
                            <Button
                              type="default"
                              className="queueorderbuttonsselected"
                              shape="round"
                              // size={"large"}
                              onClick={handleChooseDistributor}
                            >
                              Choose Distributor
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <p className="fs-small mt-4">
                        Contact this provider through email, phone number or
                        social media handles to start placing your bread orders
                        🍞
                      </p>
                    </div>
                  ) : (
                    <p className="retailwelcometext">
                      No distributor available for your business location
                    </p>
                  )}
                </Card>
              ) : (
                <Card>
                  <div className="retailsupportdiv">
                    <Button
                      type="default"
                      className="queueorderbuttonsselected"
                      shape="round"
                      // size={"large"}
                      icon={<EarPhoneIcon />}
                      iconPosition={"end"}
                      onClick={handleContactSupport}
                    >
                      Complaint / Feedback
                    </Button>
                  </div>
                  <div className="mt-2">
                    <p>
                      <BuildingIcon />
                    </p>
                  </div>
                  {userProfileDetails?.data?.distributor !== null ? (
                    <div>
                      <div>
                        <p className="retailwelcometext">
                          {
                            userProfileDetails?.data?.distributor?.business
                              ?.name
                          }
                        </p>
                        <p>
                          {
                            userProfileDetails?.data?.distributor?.business
                              ?.address
                          }
                        </p>
                      </div>
                      <div className="firstqueuecardcontentdiv">
                        <Button
                          type="default"
                          className="queueorderbuttonsselected"
                          shape="round"
                          // size={"large"}
                          icon={<EmailIcon />}
                        >
                          {userProfileDetails?.data?.distributor?.email}
                        </Button>
                        <Button
                          type="default"
                          className="queueorderbuttonsselected"
                          shape="round"
                          // size={"large"}
                          icon={<DialIcon />}
                        >
                          {
                            userProfileDetails?.data?.distributor?.business
                              ?.office_phone
                          }
                        </Button>
                        <Button
                          hover={false}
                          shape="round"
                          className="retailbutton"
                        >
                          See location on map
                        </Button>
                        {/* <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<InstagramIcon />}
                      >
                        @dazzlingcakesndcream
                      </Button> */}
                      </div>
                      {/* <div className="firstqueuecardcontentdiv mt-3">
                      <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<Facebook />}
                      >
                        @dazzlingcakesndcream
                      </Button>
                      <Button
                        type="default"
                        className="queueorderbuttonsselected"
                        shape="round"
                        // size={"large"}
                        icon={<Tiktok />}
                      >
                        @dazzlingcakesndcream
                      </Button>
                      <Button
                        hover={false}
                        shape="round"
                        className="retailbutton"
                      >
                        See location on map
                      </Button>
                    </div> */}
                      <p className="fs-small mt-4">
                        Contact this provider through email, phone number or
                        social media handles to start placing your bread orders
                        🍞
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="retailwelcometext">
                        Click the Choose Distributor button to communicate with
                        a distributor around you and place your order.
                      </p>
                    </div>
                  )}

                  <Button
                    onClick={handleChangeDistributor}
                    shape="round"
                    className="retailbutton"
                  >
                    {userProfileDetails?.data?.distributor !== null
                      ? "Change Distributor"
                      : "Choose Distributor"}
                  </Button>
                </Card>
              )}
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Card>
                <div>
                  <img
                    src={retailerupgradeimg}
                    alt="retailerupgrade"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="mt-4">
                  <p className="fw-bold processingqueuecardorderid">
                    Upgrade Account to Distributor
                  </p>
                  <p className="processingqueuecardname">
                    Ordering 85 items or more? Become a distributor with us!
                  </p>
                </div>
                <Button
                  hover={false}
                  shape="round"
                  onClick={handleApplyForUpgrade}
                  className="retailbutton"
                >
                  Apply for Upgrade
                </Button>
              </Card>
            </Col>
          </Row>
        )}
      </Card>
      {/* <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Nibbles Bakery ©{new Date().getFullYear()} Powered by Danval Technologies
                </Footer> */}
    </Layout>
  );
};
export default RetailerDashboard;
