import React, {useEffect } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderReducer } from '../../../../redux/Reducer/orderReducer';
import { getAllAdminOrders } from '../../../../redux/Actions';


  
  // const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
  // };





const OrderLogs = ({rowSelection, onChange, data}) => {


  // eslint-disable-next-line no-unused-vars
  const { allAdminOrders, isloadingAllAdminOrders } = useSelector(getOrderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAdminOrders())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

// useEffect(()=>{
//   // console.log(allAdminOrders,"Admin orders");
// },[allAdminOrders])

  // console.log(allAdminOrders, "alladminorders")


  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      render: (text) => <span className="orderHistory-text">{text ?? ''}</span>,
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "user",
      render: (user) => (
          <span className="orderHistory-text">{user ? user?.name : 'N/A'}</span>
      ),
      sorter: {
        compare: (a, b) => a?.date - b?.date,
        multiple: 3,
      },
    },
    {
      title: "Date of Action",
        dataIndex: "approved_at",
        key: "approved_at",
        render: (approved_at) => (
            <span className="orderHistory-text">{moment(approved_at)?.format('MMM Do YYYY')}</span>
        )
    },
    {
      title: "Time of Action",
      dataIndex: "approved_at",
      key: "approved_at",
      render: (approved_at) => (
          <span>{moment(approved_at)?.format('h:mm A')}</span>
      )
    },
    {
      title: "Order Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let statusClass = "";
        switch (status ? status?.name?.toLowerCase(): null) {
          case "Active":
            statusClass = "orderHistory approved";
            break;
          case "rejected":
            statusClass = "orderHistory rejected";
            break;
          default:
            statusClass = "orderHistory approved";
        }
        return (
          <span className={statusClass}>
            {status ? status?.name : 'N/A'}
          </span>
        );
      },
      sorter: {
        compare: (a, b) => a?.status - b?.status,
        multiple: 1,
      },
    },
    // {
    //   title: "Total Amount",
    //   dataIndex: "amount",
    //   render: (text) => <span className="orderHistory-text">{text === undefined || null ? "N/A" : FormatNumber(text) }</span>,
    //   sorter: {
    //     compare: (a, b) => a.amount - b.amount,
    //     multiple: 1,
    //   },
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "id",
    //   render: (text, record) => (
    //     <Button type='text' onClick={() => handleOrderAction(text)} className="orderHistory-view-details">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="17"
    //         height="14"
    //         viewBox="0 0 17 14"
    //         fill="none"
    //       >
    //         <path
    //           d="M8.3198 0.504883C10.0075 0.504883 11.6075 1.0904 13.0007 2.1744C14.3938 3.25048 15.5799 4.82504 16.4317 6.79523C16.4954 6.94556 16.4954 7.11964 16.4317 7.26206C14.7281 11.2024 11.6951 13.5603 8.3198 13.5603H8.31184C4.9445 13.5603 1.91149 11.2024 0.20792 7.26206C0.144235 7.11964 0.144235 6.94556 0.20792 6.79523C1.91149 2.85486 4.9445 0.504883 8.31184 0.504883H8.3198ZM8.3198 3.86765C6.5605 3.86765 5.13555 5.28396 5.13555 7.0326C5.13555 8.77332 6.5605 10.1896 8.3198 10.1896C10.0711 10.1896 11.4961 8.77332 11.4961 7.0326C11.4961 5.28396 10.0711 3.86765 8.3198 3.86765ZM8.32076 5.05276C9.41136 5.05276 10.303 5.93895 10.303 7.03086C10.303 8.11486 9.41136 9.00104 8.32076 9.00104C7.22219 9.00104 6.3306 8.11486 6.3306 7.03086C6.3306 6.89635 6.34652 6.76975 6.3704 6.64315H6.41021C7.29384 6.64315 8.01029 5.94686 8.04214 5.0765C8.1297 5.06068 8.22523 5.05276 8.32076 5.05276Z"
    //           fill="#45464E"
    //           />
    //       </svg>
              
    //     </Button>
    //   ),
    // },
  ];

  return (
    <div className="row mt-4 mb-4 border rounded py-3">
      {/* <Spin spinning={isloadingAllAdminOrders} tip="Loading..."> */}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40"],
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          style: {
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          },
        }}
      />
      {/* </Spin> */}
    </div>
  );
};

export default OrderLogs;
