import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import MyPendingOrders from "../../Components/MyPendingOrders";
import { useDispatch, useSelector } from "react-redux";
import { getOrderReducer } from "../../redux/Reducer/orderReducer";
import { getAllUserOrders } from "../../redux/Actions";
import noorderhistory from "../../asset/noorderhistory.png";

const PendingOrdersDashboard = (props) => {
  const { authuserdetails } = props;
  const { allUserOrders, isloadingAllUserOrders } =
    useSelector(getOrderReducer);
  const dispatch = useDispatch();
  const [pendingOrder, setPendingOrder] = useState({});

  useEffect(() => {
    dispatch(getAllUserOrders(authuserdetails?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const dataArray = allUserOrders?.data;
  //   const firstItem = dataArray[0];
  useEffect(() => {
    if (allUserOrders && allUserOrders.data && allUserOrders.data.length > 0) {
      // console.log(allUserOrders.data[0], "orderDetailsData");
      setPendingOrder(allUserOrders.data[0]);
    }
  }, [allUserOrders]);
  return (
    <>
      <Row>
        <Col className="gutter-row" span={24}>
          <Col className="dashboardtitlescol" span={24}>
            <Spin spinning={isloadingAllUserOrders} tip="Loading...">
              {allUserOrders && allUserOrders?.data?.length !== 0 ? (
                <MyPendingOrders orderDetailsData={pendingOrder} />
              ) : (
                <p style={{ textAlign: "center" }}>
                  <img src={noorderhistory} alt="no order history"  height={"260px"} draggable={false} />
                </p>
              )}
            </Spin>
          </Col>
        </Col>
      </Row>
    </>
  );
};
export default PendingOrdersDashboard;
