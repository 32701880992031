import { Table } from 'antd';
import React from 'react';
import { FormatNumber } from '../../../Utils/cartmgt';

// const quantity = (no) => {
//   return `x ${no}`
// }
const renderProduct = (img, title) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <img src={img} alt={`${title}`} style={{width: "3vw", height: "3vh"}}/>
      <div>{title}</div>
    </div>
  )
}

const ProductTable = (props) => {
  const {data} = props;
  console.log(data)
  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text) => (
        renderProduct(text?.images[0]?.url, text?.name)
      ),
      sorter: (a, b) => a.product.name?.localeCompare(b?.product.name),
    },
    {
      title: 'Price',
      dataIndex: 'product',
      key: 'price',
      render: (product) => <span className="orderHistory-text">{FormatNumber(product?.amount)}</span>,
      sorter: (a, b) => a.product?.amount - b.product?.amount,
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      render: (text) => (
        <span>
          {text} <br />
        </span>
      ),
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: 'Subtotal',
      dataIndex: 'product',
      key: 'subtotal',
      render: (product, record) => <span className="orderHistory-text">{FormatNumber(parseInt(product?.amount * record?.qty))}</span>,
      sorter: {
        compare: (a, b) => (a?.product?.amount * a?.product?.qty) - (b?.product?.amount * b?.product?.qty),
        multiple: 1,
      },
    },
  ];
  return (
    <>
      <Table dataSource={data} columns={columns} className='custom-table' pagination={false}/>
    </>
  )
}

export default ProductTable
