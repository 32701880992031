import { all, call, fork, takeEvery } from "redux-saga/effects";
import requestFunction from "../../Utils/sagaGenericFunction";
import {getItemsInCartSuccess, getItemsInCartFailure, addItemsInCartSuccess, addItemsInCartFailure, updateItemsInCartSuccess, updateItemsInCartFailure, deleteItemsInCartSuccess, deleteItemsInCartFailure, getAllProductSuccess, getAllProductFailure, postVerifyTransactionSuccess, postVerifyTransactionFailure, clearCartItemsSuccess, clearCartItemsFailure, getAllAvailableLocationSuccess, getAllAvailableLocationFailure } from "../Actions";
import { ADD_ITEMS_IN_CART, CLEAR_CART_ITEMS, DELETE_ITEMS_IN_CART, GET_ALL_AVAILABLE_LOCATION, GET_ALL_PRODUCT, GET_ITEMS_IN_CART, POST_VERIFY_TRANSACTION, UPDATE_ITEMS_IN_CART } from "../ActionTypes";
import { AddItemsInCartApi, ClearCartItemsApi, DeleteItemsInCartApi, GetAllAvailableLocationApi, GetAllProductApi, GetItemsInCartApi, PostVerifyTransactionApi, UpdateItemsInCartApi } from "../Api";
// =================Cart sagas================

const getItemsInCartWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getItemsInCartSuccess, getItemsInCartFailure, GetItemsInCartApi, payload);
};
export const getItemsInCartWatcher = function* () {
    // debugger
    yield takeEvery(GET_ITEMS_IN_CART, getItemsInCartWorker);
};

const addItemsInCartWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, addItemsInCartSuccess, addItemsInCartFailure, AddItemsInCartApi, payload);
};
export const addItemsInCartWatcher = function* () {
    // debugger
    yield takeEvery(ADD_ITEMS_IN_CART, addItemsInCartWorker);
};

const updateItemsInCartWorker = function* ({ body, payload }) {
    // debugger
    yield call(requestFunction, updateItemsInCartSuccess, updateItemsInCartFailure, UpdateItemsInCartApi, body, payload);
};
export const updateItemsInCartWatcher = function* () {
    // debugger
    yield takeEvery(UPDATE_ITEMS_IN_CART, updateItemsInCartWorker);
};

const deleteItemsInCartWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, deleteItemsInCartSuccess, deleteItemsInCartFailure, DeleteItemsInCartApi, payload);
};
export const deleteItemsInCartWatcher = function* () {
    // debugger
    yield takeEvery(DELETE_ITEMS_IN_CART, deleteItemsInCartWorker);
};

const getAllAvailableLocationWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAllAvailableLocationSuccess, getAllAvailableLocationFailure, GetAllAvailableLocationApi, payload);
};
export const getAllAvailableLocationWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_AVAILABLE_LOCATION, getAllAvailableLocationWorker);
};

const getAllProductWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAllProductSuccess, getAllProductFailure, GetAllProductApi, payload);
};
export const getAllProductWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_PRODUCT, getAllProductWorker);
};

const postVerifyTransactionWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, postVerifyTransactionSuccess, postVerifyTransactionFailure, PostVerifyTransactionApi, payload);
};
export const postVerifyTransactionWatcher = function* () {
    // debugger
    yield takeEvery(POST_VERIFY_TRANSACTION, postVerifyTransactionWorker);
};

const clearCartItemsWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, clearCartItemsSuccess, clearCartItemsFailure, ClearCartItemsApi, payload);
};
export const clearCartItemsWatcher = function* () {
    // debugger
    yield takeEvery(CLEAR_CART_ITEMS, clearCartItemsWorker);
};

export default function* cartSaga() {
    yield all([
        fork(getItemsInCartWatcher),
        fork(addItemsInCartWatcher),
        fork(updateItemsInCartWatcher),
        fork(deleteItemsInCartWatcher),
        fork(getAllAvailableLocationWatcher),
        fork(getAllProductWatcher),
        fork(postVerifyTransactionWatcher),
        fork(clearCartItemsWatcher),
    ]);
}