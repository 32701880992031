import React, { useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Form, Input, Radio } from 'antd';

const AdditionalInfo = ({ label, value, type, name, setShowStrategyBrief }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleShowStrategyBrief = (e) => {
        const selectedOption = e.target.value;
        setSelectedValue(selectedOption);

        if (name === "otherPartners" && selectedOption === 1) {
            setShowStrategyBrief(true);
        } else {
            setShowStrategyBrief(false);
        }
    };

    const isTrue = selectedValue !== 0;

    return (
        <div className='row mb-4'>
            <div className='col-9'>
                {label}
            </div>
            {type === "view" ? (
                <div className='col-3 d-flex align-items-center'>
                    <div style={{ width: "25px", textTransform: "capitalize" }}>
                        {isTrue ? "Yes" : "No"}
                    </div>
                    {isTrue ? (
                        <CheckOutlined style={{ color: "green" }} />
                    ) : (
                        <CloseOutlined style={{ color: "red" }} />
                    )}
                </div>
            ) : (
                <>
                    <Form.Item name={name}>
                        <Radio.Group
                            name={name}
                            onChange={handleShowStrategyBrief}
                            value={selectedValue} // Ensure the Radio.Group is controlled
                        >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {selectedValue === 1 && name === "otherPartners" && (
                        <Form.Item
                            style={{ fontWeight: 600 }}
                            label="List of other businesses and products involved in:"
                            name="strategy_brief"
                        >
                            <Input.TextArea
                                placeholder="Businesses and products involved in"
                                readOnly={type === "view"}
                                rows={5}
                                style={{ cursor: type === "view" && "not-allowed" }}
                            />
                        </Form.Item>
                    )}
                </>
            )}
        </div>
    );
};

export default AdditionalInfo;
