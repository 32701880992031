import React from 'react'
import { LocationOutlineIcon } from '../../../asset/icons/icons'

const PickupTable = (props) => {
  const {details} = props

    return (
        <div className='pt-3'>
            <p className='card-title'>Pickup Location</p>
            <hr />
            <div className='d-flex align-items-center'>
                <LocationOutlineIcon />
                <div className='pickup-title'> Nibbles Bakery - {details?.location} Branch</div>
            </div>
          <p className='pickup-desc'>Nibbles bakery street address goes here and more description </p>
        </div>
    )
}

export default PickupTable