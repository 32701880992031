import { call, put } from "redux-saga/effects";
function* requestFunction(successHandler, errorHandler, apiCall, ...args) {
  if (!navigator.onLine) {
    alert("There is no internet connection")
  } else {
  try {
    const response = yield call(apiCall, ...args)
    yield put(successHandler(response))
  } catch (error) {
    yield put(errorHandler(error))
  }
}
}
export default requestFunction;
// import { call, put } from "redux-saga/effects";
// function* requestFunction(successFunction, ...aPIRequestFunction) {
//   try {
//     const response = yield call(...aPIRequestFunction);
//     if (typeof response !== "undefined") {
//       if (typeof successFunction === "function")
//         yield put(successFunction(response));
//     } else {
//     }
//   } catch (ex) {
//   }
// }
// export default requestFunction;
// function* requestFunction(successHandler, errorHandler, apiCall, ...args) {
//   if (!navigator.onLine) {
//     toastr.error("No Internet Connection", "Try again");
//   } else {
//     try {
//       const response = yield call(apiCall, ...args);
//       yield put(successHandler(response));
//     } catch (error) {
//       yield put(errorHandler(error))
//     }
//   }
// }
// export default requestFunction;