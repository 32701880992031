import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Spin } from "antd";
import {
  NiblesBreadDashboardButtonicon,
  PizzaShapeIcon,
} from "../asset/icons/icons";
// import SearchBox from './SearchBox';
// import nibblesbradcardimage1 from "../asset/nibblesbreadcardimage1.jpg"
// import noorderhistory from "../asset/noorderhistory.jpg";
import noorderhistory from "../asset/noorderhistory.png";
import ProductCard from "./productcard";
// import Cart from './myCartComponent';
import { useDispatch, useSelector } from "react-redux";
import { getAllUserOrders } from "../redux/Actions";
import { getOrderReducer } from "../redux/Reducer/orderReducer";
import OrderHistoryTable from "./OrderHistoryTable";

// const ProductItems = [
//     {
//         id: 1,
//         productImage: nibblesbradcardimage1,
//         productBrand: "Nibbles bread",
//         productName: "Family Loaf",
//         productPrice: "1800",
//         quantity: 1
//     },
//     {
//         id: 2,
//         productImage: nibblesbradcardimage1,
//         productBrand: "Nibbles bread",
//         productName: "Tea-buns",
//         productPrice: "1800",
//         quantity: 1
//     },
//     {
//         id: 3,
//         productImage: nibblesbradcardimage1,
//         productBrand: "Sun crust",
//         productName: "Mini size",
//         productPrice: "1800",
//         productStatus: "Outof stock",
//         quantity: 1
//     },
//     {
//         id: 4,
//         productImage: nibblesbradcardimage1,
//         productBrand: "Sun crust",
//         productName: "Jumbo Size",
//         productPrice: "1800",
//         quantity: 1
//     },
//     // {
//     //     id: 5,
//     //     productImage: nibblesbradcardimage1,
//     //     productBrand: "Sun crust",
//     //     productName: "Jumbo Size",
//     //     productPrice: "1800"
//     // },
//     // {
//     //     id: 6,
//     //     productImage: nibblesbradcardimage1,
//     //     productBrand: "Sun crust",
//     //     productName: "Jumbo Size",
//     //     productPrice: "1800"
//     // },
// ]

const UserDashboardContent = (props) => {
  // const [searchValue, setSearchValue] = useState("");
  // const [cart, setCart] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const {
    setCart,
    cart,
    setDetailQtyAdded,
    handleAddToCart,
    allProducts,
    isloadingallProducts,
    // cartItems,
    authuserdetails,
    detailsQtyAdded,
    isloadingAddedCartItem,
    // handleVerifyStatus,
    // userProfileDetails,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const currentItems = allProducts?.slice(startIndex, endIndex);
  const { allUserOrders, isloadingAllUserOrders } =
    useSelector(getOrderReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUserOrders(authuserdetails?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(allProducts, "allProducts");
  console.log(currentItems, "Current Items");

  const userLocation = authuserdetails?.location_id;
  const userType = authuserdetails?.user_type_id;
  const filteredProducts = allProducts?.filter(item => item?.user_type_id === userType && item?.location_id === userLocation)

  // console.log(userLocation, "userLocation");
  // console.log(userType, "userType");
  // console.log(filteredProducts, "filteredProducts");

  // const handleSearch = (value) => {
  //     // debugger;
  //     setSearchValue(value);
  // };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  // const useMatchMedia = (mediaQuery, initialValue) => {
  //     const [isMatching, setIsMatching] = useState(initialValue)
  //     useEffect(() => {
  //         const watcher = window.matchMedia(mediaQuery)
  //         setIsMatching(watcher.matches)
  //         const listener = (matches) => {
  //             setIsMatching(matches.matches)
  //         }
  //         if (watcher.addEventListener) {
  //             watcher.addEventListener('change', listener)
  //         } else {
  //             watcher.addListener(listener)
  //         }
  //         return () => {
  //             if (watcher.removeEventListener) {
  //                 return watcher.removeEventListener('change', listener)
  //             } else {
  //                 return watcher.removeListener(listener)
  //             }
  //         }
  //     }, [mediaQuery])

  //     return isMatching
  // }

  // const isSmallDesktopResolution = useMatchMedia('(max-width:750px)', true)
  return (
    <>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
        >
          <Col className="dashboardtitlescol" span={24}>
            <p className="dashboardtitlestypog">Available Bread in stock</p>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 14 }}
                md={{ span: 14 }}
                lg={{ span: 16 }}
                xl={{ span: 10 }}
              >
                <Button
                  type="text"
                  size={"large"}
                  className="typography dashboardnibblesbutton1Heading1"
                >
                  <p className="dashboardnibblesbutton1typogHeading1">
                    Nibbles Bread
                    <span
                      style={{ marginLeft: ".8em", }}
                      className="buttonendicons"
                    >
                      <NiblesBreadDashboardButtonicon />
                    </span>
                  </p>
                </Button>
                <Button
                  type="text"
                  size={"large"}
                  className="typography dashboardnibblesbutton2Heading2"
                >
                  <p className="dashboardnibblesbutton2typogHeading2">
                    Sun Crust
                    <span
                      style={{ marginLeft: ".8em" }}
                      className="buttonendicons"
                    >
                      <PizzaShapeIcon />
                    </span>
                  </p>
                </Button>
                {/* <Button size={"large"} style={{marginLeft: "1em"}} className="typography">Sun crust<span className="buttonendicons"><ArrowLeftIcon /></span></Button> */}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 10 }}
                md={{ span: 10 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
              >
                {/* <SearchBox
                    placeholder={"Search here"}
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchValue}
                    width="100%"
                    hoverwidth={"35em"}
                /> */}
              </Col>
            </Row>
          </Col>
          <Col className="dashboardcardscol" span={24}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              {isloadingallProducts ? (
                <div className="spinner-containers">
                  <Spin size="large" />
                </div>
              ) : (

                filteredProducts?.map((items, i) => (
                  <ProductCard
                    item={items}
                    key={i}
                    onAddToCart={handleAddToCart}
                    isloadingAddedCartItem={isloadingAddedCartItem}
                    setCart={setCart}
                    cart={cart}
                    setDetailQtyAdded={setDetailQtyAdded}
                    detailsQtyAdded={detailsQtyAdded}
                  />

                ))
              )}
            </Row>
            {/* <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={allProducts?.length >= 4 ? allProducts?.length : 1}
              onChange={handlePageChange}
              className="pagination"
            /> */}
          </Col>

          <Col className="dashboardcardscol" span={24}>
            <Card
              title="View Order History"
              bordered={false}
              style={
                {
                  // width: 300,
                }
              }
            >
              {allUserOrders && allUserOrders?.data?.length !== 0 ? (
                <OrderHistoryTable
                  allUserOrders={allUserOrders}
                  isloadingAllUserOrders={isloadingAllUserOrders}
                />
              ) : (
                <p style={{ textAlign: "center" }}>
                  <img src={noorderhistory} alt="no order history" height={"260px"} draggable={false} />
                </p>
              )}

              {/* <p>Card content</p>
                        <p>Card content</p> */}
            </Card>
          </Col>
        </Col>
        {/* {isSmallDesktopResolution ? "" :
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                        <Cart cart={cartItems} setCart={setCart} cartItems={cartItems} authuserdetails={authuserdetails} handleVerifyStatus={handleVerifyStatus} />
                    </Col>
                } */}
      </Row>
    </>
  );
};
export default UserDashboardContent;
