import { combineReducers } from "redux";
// import { reducer as toastrReducer } from "react-redux-toastr";
import CountryReducer from "./CountryReducer.js";
import AuthReducer from "./authReducer.js";
import CartReducer from "./cartReducer.js";
import OrderReducer from "./orderReducer.js";
import PermissionReducer from "./permissionReducer.js";
const rootReducer = () =>
  combineReducers({
    // toastr: toastrReducer,
    countryReducer: CountryReducer,
    authReducer: AuthReducer,
    cartReducer: CartReducer,
    orderReducer: OrderReducer,
    permissionReducer: PermissionReducer,
  });

  export default rootReducer
  