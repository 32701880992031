import React from "react";
import { Button, Modal, Steps } from "antd";
import { useSelector } from "react-redux";
import { getPermissionReducer } from "../../redux/Reducer/permissionReducer";

const RoleModal = (props) => {
  // const { token } = theme.useToken();
  const { 
    isCreatingRole,} = useSelector(getPermissionReducer)
  const {
    handleRoleModalOk,
    handleRoleModalCancel,
    newRoleOpen,
    current,
    steps,
    handleCurrentStepChange,
    selectedProductAction,
    disabled,
    handleModalCancel
  } = props;

  const items = steps.map((item) => ({
    key: item.id,
    title: item.title,
  }));

  return (
    <>
      <Modal
        title={
          selectedProductAction === "Add"
            ? "Create New Role"
            : selectedProductAction + " Role"
        }
        centered
        open={newRoleOpen}
        onCancel={handleModalCancel}
        width={900}
        maskClosable={false}
        styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
        footer={[
          <Button
            className="typography rolecancelButton"
            closable={true}
            onClick={handleRoleModalCancel}
          >
           {current ===0 ? "Cancel":"Go back"}
          </Button>,
          selectedProductAction === "View" ? (
            ""
          ) : (
            <Button
              className="typography createproducttbutton"
              closable={true}
              onClick={handleRoleModalOk}
              disabled = {disabled}
              loading={isCreatingRole}
            >
             {current === steps.length-1? "Done" :"Continue"}
            </Button>
          ),
        ]}
      >
        <Steps
          onchange={handleCurrentStepChange}
          current={current}
          items={items}
        />
        <div>{steps[current].content}</div>
      </Modal>
    </>
  );
};
export default RoleModal;
