import { ADD_ITEMS_IN_CART, ADD_ITEMS_IN_CART_FAILURE, ADD_ITEMS_IN_CART_SUCCESS, CLEAR_CART_ITEMS, CLEAR_CART_ITEMS_FAILURE, CLEAR_CART_ITEMS_SUCCESS, DELETE_ITEMS_IN_CART, DELETE_ITEMS_IN_CART_FAILURE, DELETE_ITEMS_IN_CART_SUCCESS, GET_ALL_AVAILABLE_LOCATION, GET_ALL_AVAILABLE_LOCATION_FAILURE, GET_ALL_AVAILABLE_LOCATION_SUCCESS, GET_ALL_PRODUCT, GET_ALL_PRODUCT_FAILURE, GET_ALL_PRODUCT_SUCCESS, GET_ITEMS_IN_CART, GET_ITEMS_IN_CART_FAILURE, GET_ITEMS_IN_CART_SUCCESS, POST_VERIFY_TRANSACTION, POST_VERIFY_TRANSACTION_FAILURE, POST_VERIFY_TRANSACTION_SUCCESS, UPDATE_ITEMS_IN_CART, UPDATE_ITEMS_IN_CART_FAILURE, UPDATE_ITEMS_IN_CART_SUCCESS} from "../ActionTypes";
const INIT_STATE = {

    cartItems: [],
    isloadingCartItems: false,
    cartItemsDatamsg: "",
    addCartItems: [],
    isloadingAddedCartItem: false,
    addedCartItemdatamsg: "",
    updateCartItems: [],
    isloadingUpdatedCartItem: false,
    updatedCartItemdatamsg: "",
    deleteCartItems: [],
    isloadingDeletedCartItem: false,
    deleteCartItemdatamsg: "",
    availableLocations: [],
    isloadingAvailableLocations: false,
    availableLocationsDatamsg: "",
    allProducts: [],
    isloadingallProducts: false,
    allProductsDatamsg: "",
    verifyTransaction: {},
    isloadingverifyTransaction: false,
    verifyTransactionDatamsg: "",
    clearCart: {},
    isloadingClearCart: false,
    clearCartDatamsg: "",
};
function CartReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_ITEMS_IN_CART: {
            // debugger
            return {
                ...state,
                cartItems: [],
                isloadingCartItems: true,
                cartItemsDatamsg: "",
            }
        }
        case GET_ITEMS_IN_CART_SUCCESS: {
            // debugger
            return {
                ...state,
                cartItems: action.payload,
                isloadingCartItems: false,
                cartItemsDatamsg: "",
            };
        }
        case GET_ITEMS_IN_CART_FAILURE: {
            return {
                ...state,
                cartItems: action.payload,
                isloadingCartItems: false,
                cartItemsDatamsg: "",
            };
        }
        case ADD_ITEMS_IN_CART: {
            // debugger
            return {
                ...state,
                addCartItems: action.payload,
                isloadingAddedCartItem: true,
                addedCartItemdatamsg: "",
            }
        }
        case ADD_ITEMS_IN_CART_SUCCESS: {
            // debugger
            return {
                ...state,
                addCartItems: action.payload,
                isloadingAddedCartItem: false,
                addedCartItemdatamsg: "",
            };
        }
        case ADD_ITEMS_IN_CART_FAILURE: {
            return {
                ...state,
                addCartItems: action.payload,
                isloadingAddedCartItem: false,
                addedCartItemdatamsg: "",
            };
        }
        case UPDATE_ITEMS_IN_CART: {
            // debugger
            return {
                ...state,
                updateCartItems: action.payload,
                isloadingUpdatedCartItem: true,
                updatedCartItemdatamsg: "",
            }
        }
        case UPDATE_ITEMS_IN_CART_SUCCESS: {
            // debugger
            return {
                ...state,
                updateCartItems: action.payload,
                isloadingUpdatedCartItem: false,
                updatedCartItemdatamsg: "",
            };
        }
        case UPDATE_ITEMS_IN_CART_FAILURE: {
            return {
                ...state,
                updateCartItems: action.payload,
                isloadingUpdatedCartItem: false,
                updatedCartItemdatamsg: "",
            };
        }
        case DELETE_ITEMS_IN_CART: {
            // debugger
            return {
                ...state,
                deleteCartItems: action.payload,
                isloadingDeletedCartItem: true,
                deleteCartItemdatamsg: "",
            }
        }
        case DELETE_ITEMS_IN_CART_SUCCESS: {
            // debugger
            return {
                ...state,
                deleteCartItems: action.payload,
                isloadingDeletedCartItem: false,
                deleteCartItemdatamsg: "",
            };
        }
        case DELETE_ITEMS_IN_CART_FAILURE: {
            return {
                ...state,
                deleteCartItems: action.payload,
                isloadingDeletedCartItem: false,
                deleteCartItemdatamsg: "",
            };
        }
        case GET_ALL_AVAILABLE_LOCATION: {
            // debugger
            return {
                ...state,
                availableLocations: action.payload,
                isloadingAvailableLocations: true,
                availableLocationsDatamsg: "",
            }
        }
        case GET_ALL_AVAILABLE_LOCATION_SUCCESS: {
            // debugger
            return {
                ...state,
                availableLocations: action.payload,
                isloadingAvailableLocations: false,
                availableLocationsDatamsg: "",
            };
        }
        case GET_ALL_AVAILABLE_LOCATION_FAILURE: {
            return {
                ...state,
                availableLocations: action.payload,
                isloadingAvailableLocations: false,
                availableLocationsDatamsg: "",
            };
        }
        case GET_ALL_PRODUCT: {
            // debugger
            return {
                ...state,
                allProducts: [],
                isloadingallProducts: true,
                allProductsDatamsg: "",
            }
        }
        case GET_ALL_PRODUCT_SUCCESS: {
            // debugger
            return {
                ...state,
                allProducts: action.payload,
                isloadingallProducts: false,
                allProductsDatamsg: "",
            };
        }
        case GET_ALL_PRODUCT_FAILURE: {
            return {
                ...state,
                allProducts: action.payload,
                isloadingallProducts: false,
                allProductsDatamsg: "",
            };
        }
        case POST_VERIFY_TRANSACTION: {
            // debugger
            return {
                ...state,
                verifyTransaction: action.payload,
                isloadingverifyTransaction: true,
                verifyTransactionDatamsg: "",
            }
        }
        case POST_VERIFY_TRANSACTION_SUCCESS: {
            // debugger
            return {
                ...state,
                verifyTransaction: action.payload,
                isloadingverifyTransaction: false,
                verifyTransactionDatamsg: "",
            };
        }
        case POST_VERIFY_TRANSACTION_FAILURE: {
            return {
                ...state,
                verifyTransaction: action.payload,
                isloadingverifyTransaction: false,
                verifyTransactionDatamsg: "",
            };
        }
        case CLEAR_CART_ITEMS: {
            // debugger
            return {
                ...state,
                clearCart: action.payload,
                isloadingClearCart: true,
                clearCartDatamsg: "",
            }
        }
        case CLEAR_CART_ITEMS_SUCCESS: {
            // debugger
            return {
                ...state,
                clearCart: action.payload,
                isloadingClearCart: false,
                clearCartDatamsg: "",
            };
        }
        case CLEAR_CART_ITEMS_FAILURE: {
            return {
                ...state,
                clearCart: action.payload,
                isloadingClearCart: false,
                clearCartDatamsg: "",
            };
        }

        default:
            return state;
    }
}
export default CartReducer;

export const getCartReducer = state => state.cartReducer