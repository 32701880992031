import React, { useEffect, useState } from "react";
import { Input, Steps, Button, Form, Alert } from "antd";
import RenderSide from "./ViewOrderModalSidebar";
import OrderSummary from "./OrderSummary";
import { getAuthReducer } from "../../redux/Reducer/authReducer";

import { notification } from "antd";
import OrderLogs from "../../Pages/admin_module/dashboard/orderProcessing/orderLogs";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const description1 = "";
const description2 = "";
const description = <></>;

const CollectionCreateForm = (props) => {
  const {
    onFormInstanceReady,
    setOpen,
    orderRejectedError,
    orderStatus,
    isArchived,
    orderRejected,
    initialStage,
    processingStage,
    loadingStage,
    pickUpStage,
    id,
    handleAllAdminOrderByStatusIdApi
  } = props;
  const [showSummary, setShowSummary] = useState(true);
  const [form] = Form.useForm();
  const [showPipeline, setShowPipeline] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isApproveModalVisibleLoading, setIsApproveModalVisibleLoading] =
    useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isRejectModalVisibleLoading, setIsRejectModalVisibleLoading] =
    useState(false);
    // const [isApproveModalVisibleLoading, setIsApproveModalVisibleLoading] = useState(false);
    // const [isRejectModalVisibleLoading, setIsRejectModalVisibleLoading] = useState(false);

  const [orderConfirmedComment, setOrderConfirmedComment] = useState({});
  const [orderProcessingComment, setOrderProcessingComment] = useState({});
  const [orderLoadingComment, setOrderLoadingComment] = useState({});
  const [orderPickupComment, setOrderPickupComment] = useState({});
  const [orderArchivedComment, setOrderArchivedComment] = useState({});
  const [orderRejectedComment, setOrderRejectedComment] = useState({});

  const { authUser } = useSelector(getAuthReducer);
  const [item, setItem] = useState("");

  const [comment, setComment] = useState("");


  const handleAPICall = () =>{

    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/orders/${id}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setItem(data.data);
     
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  useEffect(() => {

    handleAPICall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (item) {

      for (let i = 0; i < item.order_logs?.length; i++) {
        const log = item.order_logs[i];

        if (log?.order_status_id === 2) {
          setOrderConfirmedComment(log);
        } else if (log?.order_status_id === 3) {
          setOrderProcessingComment(log);
        } else if (log?.order_status_id === 4) {
          setOrderLoadingComment(log);
        } else if (log?.order_status_id === 5) {
          setOrderPickupComment(log);
        } else if (log?.order_status_id === 6) {
          setOrderArchivedComment(log);          
        } else if (log?.order_status_id === 0) {
          setOrderRejectedComment(log);
        } else {
          console.log("Order not found");
        }
      }
    }
  }, [item]);

  // Now you can use the 'item' variable elsewhere in your code

  const callAgain = () => {
    const bearerToken = authUser.access_token;

    fetch(`${process.env.REACT_APP_API_BASE_DEV_URL}/orders/${id}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch item details");
        }
      })
      .then((data) => {
        setItem(data.data);
       
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  };

  const updateOrderStatus = (orderId, orderStatusId, comments) => {
    try {
      const bearerToken = authUser.access_token;
      // Define headers for the request
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      // Convert comments to string if it's not already a string and not undefined
      const commentsString = comments !== undefined ? String(comments) : "";

      // Construct the request body
      const requestBody = {
        order_id: orderId,
        order_status_id: orderStatusId,
        comments: commentsString,
      };

      // Make a POST request to the endpoint using fetch
      const response = fetch(
        `${process.env.REACT_APP_API_BASE_DEV_URL}/orders/status`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody),
        }
      );

      // Parse the response as JSON
      const responseData = response.json();

      // Handle response if needed
      console.log("Order status updated successfully:", responseData);

      callAgain();
    } catch (error) {
      // Handle errors
      console.error("Error updating order status:", error);
    }
  };


  const archiveOrder = async () => {
    try {
      const bearerToken = authUser.access_token;
      // Define headers for the request
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
  
      // Construct the request body
      const requestBody = {
        is_archived: true,
      };
  
      // Make a PUT request to the endpoint using fetch
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_DEV_URL}/orders/${id}`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(requestBody),
        }
      );
  
      // Parse the response as JSON
      const responseData = await response.json();
  
      openNotification("top");
  
      // Delay setIsApproveModalVisible(false) by 2 seconds
      setTimeout(() => {
        setIsApproveModalVisible(false);
        handleAllAdminOrderByStatusIdApi();
        setOpen(false);
      }, 1000);
      callAgain();
  
      // Handle response if needed
      console.log("Order has been archived successfully:", responseData);
  
    } catch (error) {
      // Handle errors
      console.error("Error updating order:", error);
    }
  };
  



  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.success({
      message: `Success`,
      description: "Action successful",
      placement,
    });
  };

  useEffect(() => {
    onFormInstanceReady(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showApproveModal = () => {
    setIsApproveModalVisible(true);
  };

  const showRejectModal = () => {
    setIsRejectModalVisible(true);
  };

  const hideshowApproveModal = () => {
    setIsApproveModalVisible(false);
  };

  const hideshowRejectModal = () => {
    setIsRejectModalVisible(false);
  };

  const succshowApproveModal = (id, status, comment) => {
    updateOrderStatus(id, status, comment);
    openNotification("top");
    
    // Delay setIsApproveModalVisible(false) by 2 seconds
    setTimeout(() => {
      setIsApproveModalVisible(false);
      handleAPICall();
      handleAllAdminOrderByStatusIdApi();
      setOpen(false);
    }, 1000);
  };
      

  useEffect(() => {
    onFormInstanceReady(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
    

  const approved = (
    <>
      <div className="col" style={{ textAlign: "left" }}>
        <div className="confirm-order" style={{ width: "", marginLeft: "0px" }}>
          Order Confirmed
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {orderConfirmedComment.approved_at}
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          Approved By:{" "}
          <span className="">{orderConfirmedComment?.user?.name}</span>
        </div>
        <div
          className="approve-comment mb-3 border rounded p-1 mt-1"
          style={{ width: "300px" }}
        >
          {orderConfirmedComment.details}
        </div>
      </div>
    </>
  );

  const rejected = (
    <>
      <div className="col" style={{ textAlign: "left" }}>
        <div className="confirm-order" style={{ width: "", marginLeft: "0px" }}>
          Order Rjected
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {orderRejectedComment?.approved_at}
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {/* Rejected By: <span className=''>{orderRejectedComment?.user.name}</span> */}
        </div>
        <div
          className="reject-comment mb-3 border rounded p-1 mt-1"
          style={{ width: "300px" }}
        >
          {orderRejectedComment?.details}
        </div>
      </div>
    </>
  );

  const initialStageDiv = (
    <>
      <div className="col">
        <div
          className="confirm-order"
          style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
        >
          Order Confirmation{" "}
          <span>(Please confirm this order to begin production.)</span>
        </div>
        <TextArea
          className="mt-1"
          placeholder="Please enter your comment"
          autoSize={{ minRows: 2, maxRows: 6 }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div />
        <div>
          <div className="d-flex justify-content-end mt-1">
            <Button
              type="primary"
              className="reject-button mx-2"
              onClick={showRejectModal}
            >
              Reject
            </Button>
            <Button
              type="primary"
              className="approve-button"
              onClick={showApproveModal}
            >
              Approve
            </Button>
          </div>
          <div>
            {isApproveModalVisible && (
              <Alert
                className="mt-1"
                message="Are you sure you want to approve this order?"
                type="success"
                showIcon
                action={
                  <Button
                    size="small"
                    className="bg-success mx-3 text-light"
                    type="text"
                    onClick={() => succshowApproveModal(id, 2, comment)}
                  >
                    Yes
                  </Button>
                }
                closable
                onClose={hideshowApproveModal}
              />
            )}
            {isRejectModalVisible && (
              <Alert
                className="mt-1"
                message="Are you sure you want to reject this order?"
                type="error"
                showIcon
                action={
                  <Button
                  size="small"
                  className="bg-danger mx-3 text-light"
                  type="text"
                   onClick={() => succshowApproveModal(id, 6, comment)}
                >
                  Yes
                </Button>
                }
                closable
                onClose={hideshowRejectModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );

  const availableForPickupDiv = (
    <>
      <div className="col" style={{ textAlign: "left" }}>
        <div className="confirm-order" style={{ width: "", marginLeft: "0px" }}>
          Avaialble for pickup
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {orderPickupComment?.approved_at}
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          Approved By:{" "}
          <span className="">{orderPickupComment?.user?.name}</span>
        </div>
        <div
          className="approve-comment mb-3 border rounded p-1 mt-1"
          style={{ width: "300px" }}
        >
          {orderPickupComment?.details}
        </div>
        <div>
          

        <div className="col">
        <div
          className="confirm-order"
          style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
        >
          Pickup Confirmation{" "}
          <span>(Please confirm that this order has been picked up and click the button to move it to archived)</span>
        </div>
        <div />
        <div>
          <div className="d-flex justify-content-end mt-1 ">
            <Button
              type="primary"
              className="approve-button px-2"
              onClick={showApproveModal}
            >
              Archive Order
            </Button>
          </div>
          <div>
            {isApproveModalVisible && (
              <Alert
                className="mt-2"
                message="Are you sure you want to archive this order?"
                type="success"
                showIcon
                action={
                  <Button
                    size="small"
                    className="bg-success mx-3 text-light"
                    type="text"
                    onClick={archiveOrder}
                  >
                    Yes
                  </Button>
                }
                closable
                onClose={hideshowApproveModal}
              />
            )}
          </div>
        </div>
      </div>
        </div>
      </div>
    </>
  );

  const processingAutopproved = (
    <>
      <div className="col" style={{ textAlign: "left" }}>
        <div className="confirm-order" style={{ width: "", marginLeft: "0px" }}>
          Processing
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {orderProcessingComment?.approved_at}
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          Approved By: <span className="">Auto Approved</span>
        </div>
        <div
          className="approve-comment mb-3 border rounded p-1 mt-1"
          style={{ width: "300px" }}
        >
          {orderProcessingComment?.details}
        </div>
      </div>
    </>
  );

  const orderProductApprovalStatus = (
    <>
      <div
        className="order-flow-step-title mb-2 mt-2"
        style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
      >
        Confirm Order
      </div>
      <div className="d-flex justify-content-between">
        <div className="row">
          {initialStage ? <>{initialStageDiv}</> : null}
          {processingStage ? <>{approved}</> : null}
          {loadingStage ? <>{approved}</> : null}
          {pickUpStage ? <>{approved}</> : null}
          {orderRejected ? <>{rejected}</> : null}
          {isArchived ? <>{approved}</> : null}
        </div>
      </div>
    </>
  );

  const archivedDiv = (
    <>
      <div className="col" style={{ textAlign: "left" }}>
        <div className="confirm-order" style={{ width: "", marginLeft: "0px" }}>
          Archived
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {orderArchivedComment?.approved_at}
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          Archived By: <span className="">Auto Archived</span>
        </div>

        <div
          className="approve-comment mb-3 border rounded p-1 mt-1"
          style={{ width: "300px" }}
        >
          {orderArchivedComment?.details}
        </div>
      </div>
    </>
  );

  const orderApprovalStatusAutoApproval = (
    <>
      <div
        className="order-flow-step-title mb-2 mt-2"
        style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
      >
        Processing Order
      </div>
      <div className="d-flex justify-content-between">
        <div className="row">
          {loadingStage ? <>{processingAutopproved}</> : null}
          {pickUpStage ? <>{processingAutopproved}</> : null}
          {isArchived ? <>{processingAutopproved}</> : null}
        </div>
      </div>
    </>
  );

  const loadingStageDivInitial = (
    <>
      <div className="col">
        <div
          className="confirm-order"
          style={{ width: "100%", marginLeft: "0px" }}
        >
          <div className="col">
            <div
              className="confirm-order"
              style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
            >
              Order completed{" "}
              <span>
                (Please comfirm that this order has been completed and it's
                being loaded.)
              </span>
            </div>
            <TextArea
              className="mt-1"
              placeholder="Please enter your comment"
              autoSize={{ minRows: 2, maxRows: 6 }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div />
            <div>
              <div className="d-flex justify-content-end mt-1">
                <Button
                  type="primary"
                  className="approve-button"
                  onClick={() => showApproveModal()}
                >
                  Approve
                </Button>
              </div>

              <div>
                {isApproveModalVisible ? (
                  <>
                    <Alert
                      className="mt-1"
                      message="Are you sure this order is available for pickup?"
                      type="success"
                      showIcon
                      action={
                        <Button
                          size="small"
                          className="bg-success mx-3 text-light"
                          type="text"
                          onClick={() => succshowApproveModal(id, 5, comment)}
                        >
                          Yes
                        </Button>
                      }
                      closable
                      onClose={hideshowApproveModal}
                    />
                  </>
                ) : null}

                {isRejectModalVisible ? (
                  <>
                    <Alert
                      className="mt-1"
                      message="Are you sure this order is available for pickup?"
                      type="error"
                      showIcon
                      action={
                        <Button
                          size="small"
                          className="bg-danger mx-3 text-light"
                          type="text"
                          onClick={() => succshowApproveModal(id, 0, comment)}
                        >
                          Yes
                        </Button>
                      }
                      closable
                      onClose={hideshowRejectModal}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div />
        <div></div>
      </div>
    </>
  );

  const loadingAAutopproved = (
    <>
      <div className="col" style={{ textAlign: "left" }}>
        <div className="confirm-order" style={{ width: "", marginLeft: "0px" }}>
          Loading
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          {orderLoadingComment?.approved_at}
        </div>
        <div className="confirm-order-tag" style={{ width: "" }}>
          Approved By:{" "}
          <span className="">{orderLoadingComment?.user?.name}</span>
        </div>
        <div
          className="approve-comment mb-3 border rounded p-1 mt-1"
          style={{ width: "300px" }}
        >
          {orderLoadingComment?.details}
        </div>
      </div>
    </>
  );

  const orderApprovalStatusAutoApprovalToLoading = (
    <>
      <div
        className="order-flow-step-title mb-2 mt-2"
        style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
      >
        Loading Order
      </div>
      <div className="d-flex justify-content-between">
        <div className="row">
          {loadingStage ? <>{loadingStageDivInitial}</> : null}
          {pickUpStage ? <>{loadingAAutopproved}</> : null}
          {isArchived ? <>{loadingAAutopproved}</> : null}
        </div>
      </div>
    </>
  );

  const orderIsAvailabaleForPickup = (
    <>
      <div
        className="order-flow-step-title mb-2 mt-2"
        style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
      >
        Available for Pickup
      </div>
      <div className="d-flex justify-content-between">
        <div className="row">
          {pickUpStage ? <>{availableForPickupDiv}</> : null}
          {isArchived ? <>{availableForPickupDiv}</> : null}
        </div>
      </div>
    </>
  );

  const orderArchived = (
    <>
      <div
        className="order-flow-step-title mb-2 mt-2"
        style={{ width: "100%", marginLeft: "0px", textAlign: "left" }}
      >
        Archive Order
      </div>
      <div className="d-flex justify-content-between">
        <div className="row">{isArchived ? <>{archivedDiv}</> : null}</div>
      </div>
    </>
  );

  const showOrderLogs = () => {
    setShowPipeline(false);
    setShowSummary(false);
    setShowLogs(true);
  };

  const showPipeLine = () => {
    setShowPipeline(true);
    setShowLogs(false);
    setShowSummary(false);
  };

  const showSumDetails = () => {
    setShowPipeline(false);
    setShowLogs(false);
    setShowSummary(true);
  };

  return (
    <>
      {contextHolder}

      <div className="row my-5 mx-1">
        <div className="col-3 hidden-mobile">
          <RenderSide statusid={orderStatus} item={item} />
        </div>

        <div className="col-12 col-md-9 border rounded p-5">
          <div className="d-flex ">
            <span
              className={showSummary ? "approvalflow-tag" : "summary-tag"}
              style={{ marginRight: "10px" }}
              onClick={() => showSumDetails()}
            >
              Summary
            </span>
            <span
              className={showPipeline ? "approvalflow-tag" : "summary-tag"}
              style={{ marginRight: "10px" }}
              onClick={() => showPipeLine()}
            >
              Approval Flow
            </span>
            <span
              className={showLogs ? "approvalflow-tag" : "summary-tag"}
              onClick={() => showOrderLogs()}
            >
              Order Logs
            </span>
          </div>

          {showPipeline ? (
            <>
              <div className="basic-text mt-5 mb-2">ORDER PROCESS FLOW</div>
              <Steps
                className="mt-3"
                direction="vertical"
                current={orderStatus}
                status={orderRejectedError}
                items={[
                  {
                    title: orderProductApprovalStatus,
                    description1,
                  },
                  {
                    title: orderApprovalStatusAutoApproval,
                    description2,
                  },
                  {
                    title: orderApprovalStatusAutoApprovalToLoading,
                    description2,
                  },
                  {
                    title: orderIsAvailabaleForPickup,
                    description,
                  },
                  {
                    title: orderArchived,
                    description,
                  },
                ]}
              />
            </>
          ) : null}

          {showSummary ? (
            <>
              <>
                <div
                  className="col-12 hidden-desktop"
                  style={{ paddingTop: "10px" }}
                >
                  <RenderSide item={item} />
                </div>
                <OrderSummary
                  data={item.items}
                  allData={item}
                  location={item?.location?.name}
                />
              </>
            </>
          ) : null}

          {showLogs ? (
            <>
              <OrderLogs
                data={item?.order_logs}
                style={{ width: "100px" }}
                // Pass other necessary props here
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CollectionCreateForm;
