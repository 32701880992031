import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { LoadScript } from "@react-google-maps/api";
import ReactFlagsSelect from "react-flags-select";
import { InfoIcon, LocationIcon } from "../../asset/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getBusinessArea,
  getLocalGovernmentByStateId,
  getState,
  postBusinessArea,
} from "../../redux/Actions";
import AOCemptystateimg from "../../asset/AOCemptystateimg.jpg";
import { EditOutlined } from "@ant-design/icons";
import EditAdminAOCModal from "../AdminEditAOC/editAdminAOCModal";

const ChooseAreaOfCoverageModal = (props) => {
  const {
    stateData,
    isloadingStateData,
    localGovernmentData,
    postBusinessAreas,
    postingbusinessAreas,
    businessAreasCompliance,
    isloadingbusinessAreasCompliance,
    updateBusinessAreas,
  } = useSelector(getAuthReducer);
  const { userProfileDetails, businessAreasForm, setBusinessAreasForm } = props;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("NG");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  const [editAOCModal, setEditAOCModal] = useState(false);
  const [aocData, setAocData] = useState({});
  const [options, setOptions] = useState([]);
  const autocompleteServiceRef = useRef(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setBusinessAreasForm({ ...businessAreasForm, state: "" });
    setBusinessAreasForm({ ...businessAreasForm, lga: null });
    setBusinessAreasForm({ ...businessAreasForm, address: "" });
  };

  const handleStateChange = (value, statename) => {
    dispatch(getLocalGovernmentByStateId(value));
    setBusinessAreasForm({ ...businessAreasForm, state: statename });
  };

  const handleLocalGovernmentChange = (value, lganame) => {
    setBusinessAreasForm({ ...businessAreasForm, lga: lganame });
  };

  const handleStreetAddressChange = (addresses) => {
    setBusinessAreasForm({ ...businessAreasForm, address: addresses });
  };

  const handleAddLocation = () => {
    dispatch(postBusinessArea(businessAreasForm));
  };

  const handleEditAOC = (data) => {
    setEditAOCModal(true);
    setAocData(data);
  };

  const handleCancelAOC = () => {
    setEditAOCModal(false);
  };

  useEffect(() => {
    if (postBusinessAreas.status === true) {
      dispatch(getBusinessArea(userProfileDetails?.data?.business?.id));
      setBusinessAreasForm({ ...businessAreasForm, state: "" });
      setBusinessAreasForm({ ...businessAreasForm, lga: null });
      setBusinessAreasForm({ ...businessAreasForm, address: "" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postBusinessAreas, dispatch, userProfileDetails]);

  useEffect(() => {
    if (updateBusinessAreas?.status === true) {
      setEditAOCModal(false);
      dispatch(getBusinessArea(userProfileDetails?.data?.business?.id));
    }
  }, [updateBusinessAreas?.status, dispatch, userProfileDetails]);

  useEffect(() => {
    dispatch(getState());
    dispatch(getBusinessArea(userProfileDetails?.data?.business?.id));
  }, [dispatch, userProfileDetails]);

  const columns = [
    {
      title: "State",
      dataIndex: "state",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Local Government",
      dataIndex: "lga",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Address",
      dataIndex: "address_list",
      key: "addresses",
      render: (addresses) => (
        <ul>
          {addresses.map((address, index) => (
            <li key={index}>{address}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <Tooltip title="Edit area of coverage">
          <Button
            onClick={() => handleEditAOC(record)}
            type="text"
            className="orderHistory-view-details"
          >
            <EditOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const handlePlaceChanged = (value) => {
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptions(results);
        }
      );
    } else {
      setOptions([]);
    }
  };

  return (
    <>
      <Button className="chooseareaofcovbtn" onClick={showModal}>
        Choose / View Area
      </Button>
      <Modal
        title="Choose Areas of Intended Distribution Coverage."
        open={isModalOpen}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="typography text-xxs">
          Add the geographical areas your business/company covers
        </p>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 9 }}
            lg={{ span: 9 }}
            xl={{ span: 9 }}
          >
            <p className="typography" style={{ color: "#8B8D97" }}>
              Add States and Local governments{" "}
              <span>
                <InfoIcon color={"#D1D1DB"} />
              </span>
            </p>
            <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <Form layout="vertical">
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Country
                    </label>
                  }
                  className="aboutbusinessformitem"
                  rules={[{ required: true }]}
                >
                  <ReactFlagsSelect
                    selected={selected}
                    countries={[`NG`]}
                    onSelect={(code) => setSelected(code)}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      State
                    </label>
                  }
                  className="aboutbusinessformitem"
                  rules={[{ required: true }]}
                >
                  {isloadingStateData ? (
                    <Spin size="default" />
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select a state"
                      onChange={(value, option) =>
                        handleStateChange(value, option?.label)
                      }
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={stateData.map((state) => ({
                        value: state.id,
                        label: state.name,
                      }))}
                      size="large"
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      LGA your business covers
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  <Select
                    showSearch
                    placeholder="Select Local Government"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value, option) =>
                      handleLocalGovernmentChange(value, option.label)
                    }
                    options={localGovernmentData.map((localgovernment) => ({
                      value: localgovernment.id,
                      label: localgovernment.name,
                    }))}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel mb-1">
                      Areas you cover in your LGA (Type and select suggestions)
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    libraries={["places"]}
                  >
                    <Select
                      mode="multiple"
                      value={businessAreasForm.address}
                      placeholder="Enter a location"
                      notFoundContent={null}
                      onSearch={handlePlaceChanged}
                      onChange={(values) => handleStreetAddressChange(values)}
                      style={{ width: "100%" }}
                      options={options}
                    />
                  </LoadScript>
                </Form.Item>

                <div className="text-center">
                  <Button
                    className="addcomplianceloc"
                    icon={<LocationIcon color={"#FFF"} />}
                    onClick={handleAddLocation}
                    disabled={
                      businessAreasForm.state === "" ||
                      businessAreasForm.lga === "" ||
                      businessAreasForm.address.length === 0 ||
                      postingbusinessAreas
                    }
                  >
                    {postingbusinessAreas ? "Loading..." : "Add Location"}
                  </Button>
                </div>
              </Form>
            </Col>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 15 }}
            lg={{ span: 15 }}
            xl={{ span: 15 }}
          >
            <p className="typography">Business Area of Coverage</p>
            <Spin spinning={isloadingbusinessAreasCompliance} tip="Loading...">
              <Table
                columns={columns}
                dataSource={businessAreasCompliance.data}
                onChange={(_, filters, sorter, extra) =>
                  console.log("params", filters, sorter, extra)
                }
                locale={{
                  emptyText: (
                    <img src={AOCemptystateimg} alt="No Data" width={200} />
                  ),
                }}
              />
            </Spin>
          </Col>
        </Row>
      </Modal>
      <EditAdminAOCModal
        open={editAOCModal}
        onCancel={handleCancelAOC}
        aocData={aocData}
        businessAreasForm={businessAreasForm}
      />
    </>
  );
};

export default ChooseAreaOfCoverageModal;
