import { ADD_ROLE_DETAILS, CREATE_ROLE_WITH_PERMISSION, CREATE_ROLE_WITH_PERMISSION_FAILURE, CREATE_ROLE_WITH_PERMISSION_SUCCESS, GET_PERMISSION, GET_PERMISSION_FAILURE, GET_PERMISSION_SUCCESS, RESTORE_ROLE_DETAILS, UPDATE_ROLE_PERMISSION, UPDATE_ROLE_PERMISSION_FAILURE, UPDATE_ROLE_PERMISSION_SUCCESS } from "../ActionTypes";

export const getPermission = () => ({
    type: GET_PERMISSION,
});
export const getPermissionSuccess = (payload) => ({
    type: GET_PERMISSION_SUCCESS,
    payload
});
export const getPermissionFailure = (payload) => ({
    type: GET_PERMISSION_FAILURE,
    payload
});

export const addRoleDetails = (payload) => ({
    type: ADD_ROLE_DETAILS,
    payload
});
export const restoreRoleDetails = () => ({
    type: RESTORE_ROLE_DETAILS,
});
export const createRoleWithPermission = (payload) => ({
    type: CREATE_ROLE_WITH_PERMISSION,
    payload
});
export const createRoleWithPermissionSuccess = (payload) => ({
    type: CREATE_ROLE_WITH_PERMISSION_SUCCESS,
    payload
});
export const createRoleWithPermissionFailure = (payload) => ({
    type: CREATE_ROLE_WITH_PERMISSION_FAILURE,
    payload
});

export const updateRolePermission = (payload) => ({
    type: UPDATE_ROLE_PERMISSION,
    payload
});
export const updateRolePermissionSuccess = (payload) => ({
    type: UPDATE_ROLE_PERMISSION_SUCCESS,
    payload
});
export const updateRolePermissionFailure = (payload) => ({
    type: UPDATE_ROLE_PERMISSION_FAILURE,
    payload
});


