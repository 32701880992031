import React, { useEffect, useState } from "react";
import { Table, Spin, Button, Tooltip, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserAccount,
  getAllIndependentDistributorship,
  getIndependentDistributorNearMe,
  postCreateRetailerCustomer,
  restoreRetailerCreation,
  updateBusinessData,
  updateUserData,
} from "../../../redux/Actions";
import moment from "moment";
import { getAuthReducer } from "../../../redux/Reducer/authReducer";
import AddCustomerModal from "../../../Components/AddCustomersModal/addCustomersModal";
import { DeleteIcon, ReassignIcon, SearchIcon, ViewIcon } from "../../../asset/icons/icons";
import ViewCustomerModal from "../../../Components/AddCustomersModal/viewCustomerModal";
import { tag } from "./distributorCustomers";
import AvatarImg from "../../../Components/AvatarWithImage/AvatarImg";
import ReAssignRetailerModal from "../../../Components/AddCustomersModal/reAssignRetailerModal";
import { EditOutlined } from "@ant-design/icons";
import DeleteModal from "../../../Components/DeleteCustomersModal/DeleteModal";
const usermanText = {
  width: "189px",
  flexShrink: 0,
  color: "#45464E",
  fontFamily: '"MADE TOMMY"',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "28px",
};

// const exportButton = {
//   display: "flex",
//   height: "44px",
//   padding: "10px 16px",
//   justifyContent: "center",
//   alignItems: "center",
//   gap: "8px",
//   borderRadius: "8px",
//   border: "1px solid var(--Gray-100, #E6E7EC)",
//   background: "var(--Backgrounds-Background-White, #FFF)",
//   fontFamily: '"MADE TOMMY"',
//   fontSize: "14px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "20px",
//   color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
//   marginRight: "5px",
// };

const newEmpButton = {
  display: "flex",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#252A4D",
  color: "#FFF",
  fontFamily: '"MADE TOMMY"',
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

// const filter = {
//   color: "var(--Black-80, #52575C)",
//   fontFamily: "Inter",
//   fontSize: "13.138px",
//   fontStyle: "normal",
//   fontWeight: 500,
//   lineHeight: "22.992px",
//   letterSpacing: "-0.145px",
// };

// const filterDiv = {
//   display: "flex",
//   width: "588.756px",
//   justifyContent: "flex-end",
//   alignItems: "flex-start",
//   gap: "16.423px",
//   flexShrink: 0
// };
// const filterCount = {
//   borderRadius: "410.569px",
//   background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
//   display: "flex",
//   padding: "3.285px 6.138px 4.423px 6.57px",
//   justifyContent: "center",
//   alignItems: "center",
//   color: "var(--Black-80, #52575C)",
//   textAlign: "center",
//   fontFamily: "Inter",
//   fontSize: "9.854px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "normal"
// };

const RetailerCustomers = () => {
  const {
    allApplicant,
    isloadingAllApplicant,
    createRetailer,
    postingCreateRetailer,
    independentDistributorNearMe,
    authUser,
    userDataUpdated,
    businessDataUpdated,
    userAccountDeleted,
    isdeletingUserAccount
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [isViewCustomerModalOpen, setViewCustomerModalOpen] = useState(false);
  const [reAssignRetailerModalOpen, setReAssignRetailerModalOpen] = useState(false);
  // const [reAssignRetailerModal, setReAssignRetailerModal] = useState(false);
  const [changeDistributorView, setChangeDistributorView] = useState(false);
  const [retailerDetails, setRetailerDetails] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [addRetailer, setAddRetailerCustomer] = useState({
    name: "",
    avatar: "The best bakery in-town",
    nin: "",
    country: "Nigeria",
    state: "",
    lga: "",
    phone: "",
    location_id: 1,
    business: {
      name: "",
      description: "The best bakery in-town",
    },
  });
  const [image, setImage] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const userRole = authUser?.user?.roles || {}
  const isSuperAdmin = (userRole?.id === 29 || userRole?.name === "Super Admin")

  const filteredData = allApplicant?.data?.filter((item) => {
    // debugger;
    const matchesSearchQuery = Object.keys(item).some((key) => {
      const value = item[key];
      if (typeof value === "object" && value !== null) {
        return Object.values(value).some((nestedValue) =>
          nestedValue
            ?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
      return value
        ?.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });

    if (searchQuery.toLowerCase() === "pending") {
      return item.status === 0;
    }
    if (searchQuery.toLowerCase() === "approved") {
      return item.status === 1;
    }

    return matchesSearchQuery;
  });

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const showModal = () => {
    setAddCustomerModalOpen(true);
  };
  const handleOk = (values) => {
    dispatch(
      postCreateRetailerCustomer({
        ...addRetailer,
        avatar: image,
        address: values.residentAddress,
      })
    );
    setAddCustomerModalOpen(false);
  };

  const handleReAssignRetailerOk = () => {
    // dispatch(
    //   postCreateRetailerCustomer({
    //     ...addRetailer,
    //     avatar: image,
    //   })
    // );
    setReAssignRetailerModalOpen(false);
    setChangeDistributorView(false);
  };
  const handleCancel = () => {
    setAddCustomerModalOpen(false);
  };


  const showViewCustomerModal = (type, value) => {
    setType(type)
    setRetailerDetails(value);
    setViewCustomerModalOpen(true);
  };

  const showReAssignRetailerModal = (value) => {
    console.log(value, "valueforviewapproval");
    setRetailerDetails(value);
    dispatch(getIndependentDistributorNearMe());
    setReAssignRetailerModalOpen(true);
  };

  const handleOpenDeleteModal = (value) => {
    setOpenDeleteModal(true)
    setRetailerDetails(value)
  }
  const handleCancelDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteAccount = () => {
    dispatch(deleteUserAccount(retailerDetails?.id))
  }
  const handleViewCustomerCancel = () => {
    setViewCustomerModalOpen(false);

  };

  const handleReAssignRetailerCancel = () => {
    setReAssignRetailerModalOpen(false);
    setChangeDistributorView(false);
  };

  const handleUpdateValues = async (values) => {

    const businessPayload = {
      id: retailerDetails?.business?.id,
      body: {
        name: values.company_name,
        address: values.businessAddress,
        country: values.country,
        state: values.state,
        lga: values.localGovernment,
        nature_of_business: values.business_category,
        office_phone: values.businessPhoneNumber,
        cac_reg_number: values.cac_reg_no,
        branch: values.branch,
      }
    }
    const userPayload = {
      id: retailerDetails?.id,
      body: {
        user_type_id: 4,
        name: values.fullName,
        avatar: image,
        email: values.email,
        date_of_birth: values.DOB,
        gender: values.gender,
        state: values.stateOfOrigin,
        lga: values.lgaOrigin,
        address: values.residentAddress,
        phone: values.phoneNumber,
        whatsapp_phone: values.whatsappNumber
      }
    }
    await dispatch(updateBusinessData(businessPayload))
    await dispatch(updateUserData(userPayload))
    // console.log(businessPayload, "Business Payload");
    // console.log(userPayload, "User Payload");
  }


  useEffect(() => {
    if (businessDataUpdated || userDataUpdated || userAccountDeleted) {
      setViewCustomerModalOpen(false);
      setOpenDeleteModal(false);
      const payload = {
        user_type_id: 4,
        page: currentPage,
      };
      // dispatch(getAllIndependentDistributorship({ user_type_id }));
      dispatch(getAllIndependentDistributorship(payload));
    }
  }, [businessDataUpdated, userDataUpdated, userAccountDeleted, currentPage, dispatch])

  const RenderEditButton = ({ record }) => {
    return (
      <>
        {userRole?.id === 29 || userRole?.name === "Super Admin" ?
          <Button
            type="text"
            className="d-inline-flex p-2"
            onClick={() => showViewCustomerModal('edit', record)}
          >
            <EditOutlined style={{ height: "fit-content", fontSize: "18px" }} />
          </Button>
          : null}
      </>
    )
  }


  const user_type_id = 4;
  useEffect(() => {
    //   dispatch(getAllCustomer())
    dispatch(getAllIndependentDistributorship({ user_type_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // debugger;
    if (createRetailer === true) {
      setAddCustomerModalOpen(false);
      dispatch(getAllIndependentDistributorship({ user_type_id }));
    }
    return () => {
      dispatch(restoreRetailerCreation());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRetailer]);

  console.log(createRetailer, "createDistributor");
  // console.log(allcustomer, "allcustomer")
  const onChange = (pagination, filters, sorter, extra) => {
    // debugger
    setCurrentPage(pagination.current)
    console.log("params", pagination, filters, sorter, extra);
    const payload = {
      user_type_id: 4,
      page: pagination.current,
    };
    dispatch(getAllIndependentDistributorship(payload));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["business", "name"],
      // render: (text) => <span className="orderHistory-text">{text}</span>,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <AvatarImg src={record?.avatar} name={record?.business?.name} />
          <span className="orderHistory-text" style={{ marginLeft: 8 }}>
            {record?.business?.name ?? "NIL"}
          </span>
        </div>
      ),
      sorter: (a, b) => a.business?.name?.localeCompare(b?.business?.name),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.email?.localeCompare(b?.email),
    },
    {
      title: "Location",
      dataIndex: ["business", "state"],
      render: (text) => <span className="orderHistory-text">{text ?? "-"}</span>,
      sorter: (a, b) => a.state?.localeCompare(b?.state),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.phone - b.phone,
    },
    {
      title: "Distributor Assigned",
      dataIndex: "distributor",
      render: (distributor) => (
        <span className="orderHistory-text">
          {distributor ? distributor.business?.name : "N/A"}
        </span>
      ),
      sorter: (a, b) => a?.distributor?.business?.name?.localeCompare(b?.distributor?.business?.name),
    },
    {
      title: "Date Joined",
      dataIndex: "email_verified_at",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text).format("MMM Do YYYY")}
        </span>
      ),
      sorter: (a, b) => a.email_verified_at?.localeCompare(b?.email_verified_at),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <>
          <Button
            type="text"
            className="d-inline-flex p-2"
            // onClick={() => showViewCustomerModal(record)}
            onClick={() => showViewCustomerModal("view", record)}
          >
            <ViewIcon />
          </Button>
          <RenderEditButton record={record} />
          <Tooltip title="Reassign Retailer to Distributor">
            <Button
              type="text"
              onClick={() => showReAssignRetailerModal(record)}
              className="d-inline-flex p-2 mb-20"
              icon={<ReassignIcon />}
              size="large"
            >

            </Button>
          </Tooltip>
          {isSuperAdmin ?
            <Button onClick={() => handleOpenDeleteModal(record)} size="large" icon={<DeleteIcon />} type="text" className="orderHistory-view-details">
            </Button> : null}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px" }}>
        <div className="row mb-4">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>
                Customers {">"} Retailers
                <span className="mx-2" style={tag}>
                  {allApplicant?.meta?.total}
                </span>
              </span>
            </div>
            <div className="col d-flex justify-content-end">
              {/* <button onClick={1} style={exportButton}>
                Export
              </button> */}
              <button style={newEmpButton} onClick={showModal}>
                Add Retailer
              </button>

              <AddCustomerModal
                isModalOpen={isAddCustomerModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                usertypeid={user_type_id}
                setAddCustomerField={setAddRetailerCustomer}
                addCustomerField={addRetailer}
                setImage={setImage}
                image={image}
                postingCustomer={postingCreateRetailer}
              />
            </div>
          </div>
        </div>

        {/* <div className="row mt-5 mb-4">
            <div className="d-flex justify-content-start">
              <div className="col-3">
                <Input placeholder="Basic usage" />
              </div>
              <div className="col d-flex justify-content-end" style={filterDiv}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                      fill="#52575C"
                    />
                  </svg>
                </span>{" "}
                <span style={filter} className="">
                  Filter
                </span>
                <span style={filterCount}>0</span>
              </div>
            </div>
          </div> */}
        <div className="d-flex justify-content-start mb-3">
          <div className="col-12 col-sm-6 col-md-5 mt-3">
            <Input
              placeholder={"Search retailer"}
              style={{
                width: "100%",
                border: "none",
                color: "#BECOCA",
                backgroundColor: "#F7F7F7",
                cursor: "text",
              }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <Spin spinning={isloadingAllApplicant} tip="Loading...">
          <Table
            columns={columns}
            // dataSource={allApplicant?.data}
            dataSource={filteredData ?? []}
            scroll={{ x: 1000 }}
            onChange={onChange}
            pagination={{
              pageSize: allApplicant?.meta?.per_page,
              showSizeChanger: false,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              total: allApplicant?.meta?.total,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
          />
        </Spin>
      </div>
      <ViewCustomerModal
        isViewCustomerModalOpen={isViewCustomerModalOpen}
        usertypeid={user_type_id}
        handleViewCustomerCancel={handleViewCustomerCancel}
        details={retailerDetails}
        type={type}
        image={image}
        setImage={setImage}
        handleUpdateValues={handleUpdateValues}
      />

      <ReAssignRetailerModal
        reAssignRetailerModalOpen={reAssignRetailerModalOpen}
        details={retailerDetails}
        handleReAssignRetailerCancel={handleReAssignRetailerCancel}
        handleReAssignRetailerOk={handleReAssignRetailerOk}
        independentDistributorNearMe={independentDistributorNearMe}
        setChangeDistributorView={setChangeDistributorView}
        changeDistributorView={changeDistributorView}
      />
      <DeleteModal open={openDeleteModal} handleDelete={handleDeleteAccount} onCancel={handleCancelDeleteModal} name={retailerDetails?.business?.name} loading={isdeletingUserAccount} type={"Retailer"} />
    </>
  );
};
export default RetailerCustomers;
