import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Button, Form, Input } from "antd";
import nibbleslogo from "../../asset/nibbleslogo.jpg";
import "./onboarding.css";
// import { ArrowLeftIcon, GoogleIcon, PhoneIcon, SendIcon } from "../../asset/icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getSignin, googleSignIn } from "../../redux/Actions";
import { GoogleIcon } from "../../asset/icons/icons";
import { useGoogleLogin } from "@react-oauth/google";

const { Text } = Typography;

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

export default function SignIn() {
  const navigate = useNavigate();
  const { authUser, isLoading } = useSelector(getAuthReducer);
  const dispatch = useDispatch();

  const [signindetails, setSigninDetails] = useState({
    email: "",
    password: "",
  });
  const [signinErrors, setSigninErrors] = useState({
    email: "",
    password: "",
  });
  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setSigninDetails({ ...signindetails, email: emailInput });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailInput || !emailRegex.test(emailInput)) {
      setSigninErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address.",
      }));
    } else {
      setSigninErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const passwordInput = e.target.value;
    setSigninDetails({ ...signindetails, password: passwordInput });

    // Validate password field
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    if (!passwordInput || !passwordRegex.test(passwordInput)) {
      let errorMsg = "Password must meet the following criteria:";
      if (passwordInput && passwordInput.length < 6) {
        errorMsg += " be at least 6 characters long,";
      }
      if (passwordInput && !/(?=.*[!@#$%^&*()_+])/.test(passwordInput)) {
        errorMsg += " include at least one symbol,";
      }
      if (passwordInput && !/(?=.*[a-z])/.test(passwordInput)) {
        errorMsg += " include at least one lowercase letter,";
      }
      if (passwordInput && !/(?=.*[A-Z])/.test(passwordInput)) {
        errorMsg += " include at least one uppercase letter,";
      }
      if (passwordInput && !/(?=.*\d)/.test(passwordInput)) {
        errorMsg += " include at least one digit,";
      }
      errorMsg = errorMsg.slice(0, -1); // Remove the last comma
      setSigninErrors((prevErrors) => ({ ...prevErrors, password: errorMsg }));
    } else {
      setSigninErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleSignInUser = (e) => {
    // debugger
    dispatch(getSignin(signindetails));

    // if (authUser=== undefined) {
    //     // debugger
    //     return null
    // } else if (authUser?.user?.user_type === null ) {
    //     navigate("/onboarding")
    // } else if (authUser?.user?.user_type !== null ) {
    //     navigate("/dashboard")
    // } else {
    //     return null
    // }
    // console.log(authUser, "inhandlesignin")
  };

  useEffect(() => {
    // debugger;
    if (authUser) {
      if (authUser?.message === "Login successful!") {
        console.log("Login Successful");
        if (authUser?.user.gender === null) {
          if (
            authUser?.user.user_type_id === 1 ||
            (authUser?.user.user_type_id === 2 && authUser?.user.status === 1)
          ) {
            navigate("/admindashboard");
          } else if (authUser?.user.user_type_id === 5) {
            navigate("/dashboard");
          } else if (
            authUser?.user.user_type_id === 2 &&
            authUser?.user?.status === 0
          ) {
            navigate("/changePassword");
          } else {
            console.log("Redirect to onboarding");
            navigate("/onboarding");
          }
        } else if (
          authUser?.user.user_type_id === 1 ||
          authUser?.user.user_type_id === 2
        ) {
          navigate("/admindashboard");
        } else if (authUser?.user.user_type_id === 4) {
          navigate("/retaildashboard");
        } else if (
          authUser?.user.user_type_id !== 1 ||
          authUser?.user.user_type_id !== 2 ||
          authUser?.user.user_type_id !== 4
        ) {
          navigate("/dashboard");
        }
      } else {
      }
    }

    //   return () => {
    //     second
    //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  // console.log(authUser?.user?.user_type, 'signindetailsred')

  const login = useGoogleLogin({
    onSuccess: (response) => handleLoginSuccess(response),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleLoginSuccess = async (response) => {
    const accessToken = response.access_token;
    dispatch(googleSignIn(accessToken));
  };

  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          className="getstartedbackground"
        ></Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          className="getstartefirstrowlogo"
        >
          <Row className="getstartedrowlogo">
            <Col span={24} className="getstartedcollogo">
              <img alt="nibbleslogo" src={nibbleslogo} />
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpad">
              {/* <div> */}
              <p
                className="typography getstartedtext"
                style={{ marginBottom: "0px" }}
              >
                Get Started
              </p>
              <Text className="joinbakery typography">
                Join our bakery's community of happy customers. Sign up today!
              </Text>
            </Col>

            <Col span={24} className="getstartedcolpad">
              <Form
                {...formLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size={"large"}
                // onValuesChange={handleFormValuesChange}
                className="typography"
              >
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <label className="typography fw-bold">
                        Email Address
                      </label>
                    }
                    name="email"
                  >
                    <Input
                      type="email"
                      value={signindetails.email}
                      onChange={handleEmailChange}
                    />
                  </Form.Item>
                  {signinErrors.email && (
                    <p className="error typography">{signinErrors.email}</p>
                  )}
                </Col>

                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={
                      <label className="typography fw-bold">
                        Password{" "}
                        <span style={{ color: "red", marginBottom: "2em" }}>
                          *
                        </span>
                      </label>
                    }
                    name="password"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      type="password"
                      value={signindetails.password}
                      onChange={handlePasswordChange}
                    />
                  </Form.Item>
                  {signinErrors.password && (
                    <p className="error typography">{signinErrors.password}</p>
                  )}

                  <p className="typography text-end mb-4">
                    <Link
                      to={"/forgotpassword"}
                      style={{ textDecoration: "none", color: "#252A4D" }}
                    >
                      Forgot Password?
                    </Link>
                  </p>
                </Col>

                <Col xs={{ span: 24 }}>
                  {/* <Form.Item> */}
                  {/* <Link to={"/enterotp"} style={{textDecoration: "none"}}> */}
                  <Button
                    onClick={handleSignInUser}
                    className="signupbutton typography"
                    htmlType="submit"
                    disabled={
                      signindetails.password === "" ||
                      signindetails.email === "" ||
                      signinErrors.email !== "" ||
                      signinErrors.password !== "" ||
                      isLoading
                    }
                    block
                  >
                    {isLoading ? "Loading..." : "Sign In"}
                  </Button>
                  {/* </Link> */}
                  {/* </Form.Item> */}
                </Col>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="getstartedcolpad">
              <Button
                block
                size={"large"}
                className="buttonicons typography justify-content-center"
                onClick={() => login()}
              >
                <p>
                  <GoogleIcon />{" "}
                  <span style={{ marginLeft: "1em" }}>Sign in with Google</span>{" "}
                </p>
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpad">
              <Text className="joinbakery typography">
                Don't have an account?{" "}
                <Link
                  to={"/signup"}
                  style={{ color: "#998C16", textDecoration: "underline" }}
                  className="signinlink"
                >
                  Sign Up
                </Link>
              </Text>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="getstartedcolpadterms">
              <Text className="terms">
                By signing in, you already agree to the{" "}
                <Link
                  to={"#"}
                  style={{ color: "#252A4D" }}
                  className="signinlink"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  to={"#"}
                  style={{ color: "#252A4D" }}
                  className="signinlink"
                >
                  {" "}
                  Privacy Policy{" "}
                </Link>
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
