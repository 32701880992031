import React from "react";
import {
  Form,
  Modal
} from "antd";

import { CustomersForm } from "../CustomersForm/CustomersForm";

const ViewCustomerModal = (props) => {
  const { image, setImage, handleDocCancel, openDoc, link, isViewCustomerModalOpen, handleViewCustomerOk, handleViewCustomerCancel, usertypeid, details, showDocModal, type, handleUpdateValues } = props;
  const [form] = Form.useForm();
  return (
    <Modal centered width={800} title={`${type === "view" ? 'View' : 'Edit'} ${usertypeid === 3 ? "Independent Distributor" : usertypeid === 4 ? "Retailer" : "Direct Customers"}`} open={isViewCustomerModalOpen} onCancel={handleViewCustomerCancel}
      styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }} okButtonProps={{
        style: { display: type === "view" && 'none', backgroundColor: "#252A4D" },
        autoFocus: true,
      }}
      okText={type === "edit" ? "Update" : null}
      onOk={
        async () => {
          try {
            const values = await form.validateFields();
            // form.resetFields();
            if (type === "view") {
              handleViewCustomerOk();
            } else {
              handleUpdateValues(values)
            }
          } catch (error) {
            // console.log('Failed:', error);
          }
        }
      }
      afterClose={() => form.resetFields()}
      destroyOnClose

    >
      <CustomersForm
        details={details}
        usertypeid={usertypeid}
        showModal={showDocModal}
        handleCancel={handleDocCancel}
        isModalOpen={openDoc}
        link={link}
        type={type}
        form={form}
        open={isViewCustomerModalOpen}
        setImage={setImage}
        image={image}
      />
    </Modal>
  );
};
export default ViewCustomerModal;
