import React from 'react';
import { PaystackButton } from 'react-paystack';
import { Button } from 'antd';
import getPayStackKey from '../Utils/getPaystackKeys';

function PayStackButtonMethod(props) {
  const { userProfileDetails, setIsLoadingPayment, locationDetails, dispatch, postVerifyTransaction, isLoadingPayment, showModal, isOrderButtonDisabled, authuserdetails, totalCost } = props;

  const psconfig = {
    reference: `NB${Date.now()}`,
    email: authuserdetails.email,
    amount: parseInt(totalCost * 100), // Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: getPayStackKey(),
    metadata: {
      name: authuserdetails.name,
      phone: authuserdetails.phone,
    },
  };

  const handlePaystackSuccessAction = (reference) => {
    console.log(reference);
    dispatch(
      postVerifyTransaction({
        reference: reference.reference,
        transaction_id: reference.transaction,
        location_id: locationDetails.location_id,
        payment_channel: "paystack",
        is_direct: false,
      })
    );
    setIsLoadingPayment(false);
  };

  const handlePaystackCloseAction = () => {
    console.log('closed');
    setIsLoadingPayment(false);
  };

  const componentProps = {
    ...psconfig,
    text: <><img src={"https://pluginicons.craft-cdn.com/commerce-paystackB9kA1pnH1vXzDH6GVLhRwcFRgcBVfIrmICuD.svg?1592998530"} alt="Paystack" style={{ height: "1.5em" }} />  Pay with Paystack</>,
    icon: "",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const isButtonDisabled = isOrderButtonDisabled || isLoadingPayment;

  const handleverifystat = () => {
    showModal()
  };

  return (
    <>
      {isButtonDisabled ? (
        <Button
          block
          className="paystackpaymentbutton typography mt-2"
          disabled={isButtonDisabled}
          icon={<img src={"https://pluginicons.craft-cdn.com/commerce-paystackB9kA1pnH1vXzDH6GVLhRwcFRgcBVfIrmICuD.svg?1592998530"} alt="Paystack" style={{ height: "1.5em" }} />}
        >
          Pay with Paystack
        </Button>
      ) : userProfileDetails?.data?.status === 0 ? (
        <Button
          style={{ width: "100%" }}
          className="paystackpaymentbutton typography mt-2"
          onClick={handleverifystat}
          icon={<img src={"https://pluginicons.craft-cdn.com/commerce-paystackB9kA1pnH1vXzDH6GVLhRwcFRgcBVfIrmICuD.svg?1592998530"} alt="Paystack" style={{ height: "1.5em" }} />}
        >
          Pay with Paystack
        </Button>
      ) : (
        <PaystackButton
          style={{ width: "100%" }}
          className="paystackpaymentbutton typography mt-2"
          {...componentProps}
        />
      )}
    </>
  );
}

export default PayStackButtonMethod;
