import React from 'react';
import { Button, Modal } from 'antd';
import verify from "../asset/verify-png.png";
const VerifyAccountModal = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal title={<div className='d-flex align-items-center gap-2'><div><img src={verify} alt='Verify' /></div><div><p>Verify Account</p></div></div>} open={isModalOpen} onCancel={handleCancel} footer={[
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="submit" onClick={()=>handleOk()} style={{ backgroundColor: "#252A4D", color: "#fff" }} >
        Verify Account
      </Button>,

    ]}>
      <p>You have to verify your account to be able to checkout your orders.

        This should only take a few minutes.</p>

    </Modal>
  );
};
export default VerifyAccountModal;