import React from 'react';
import { Modal } from 'antd';
import { DeleteIcon } from '../../asset/icons/icons';
const DeleteModal = ({ open, handleDelete, loading, onCancel, name, type }) => {
    const modalText = <p style={{fontSize:"16px"}}>Are you sure you want to permanently delete <b>{name}</b> from customers?</p>;

    return (
        <>
            <Modal
                title={<div className='d-inline-flex align-items-center gap-2'><div style={{ backgroundColor: "#feebec", padding:"5px 10px",borderRadius: "5px", boxSizing: "border-box", display: "inline-flex" }}><div><DeleteIcon /></div></div><div><h5 style={{padding:0, margin:0}}>Delete {type??'Customer'} Account</h5></div></div>}
                open={open}
                onOk={handleDelete}
                confirmLoading={loading}
                onCancel={onCancel}
                okText={"Delete"}
                okButtonProps={{ style: { backgroundColor: '#ff4d4f' } }}
                centered
            >
                <p>{modalText}</p>
            </Modal>
        </>
    );
};
export default DeleteModal;