import { CREATE_ORDER, CREATE_ORDER_FAILURE, CREATE_ORDER_SUCCESS, GET_ADMIN_ARCHIVE_ORDERS, GET_ADMIN_ARCHIVE_ORDERS_FAILURE, GET_ADMIN_ARCHIVE_ORDERS_SUCCESS, GET_ADMIN_ORDER_BY_ID, GET_ADMIN_ORDER_BY_ID_FAILURE, GET_ADMIN_ORDER_BY_ID_SUCCESS, GET_ALL_ADMIN_ORDERS, GET_ALL_ADMIN_ORDERS_BY_STATUS, GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE, GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS, GET_ALL_ADMIN_ORDERS_FAILURE, GET_ALL_ADMIN_ORDERS_SUCCESS, GET_ALL_QUEUE_ORDERS, GET_ALL_QUEUE_ORDERS_FAILURE, GET_ALL_QUEUE_ORDERS_SUCCESS, GET_ALL_TRANSACTION_LOG, GET_ALL_TRANSACTION_LOG_FAILURE, GET_ALL_TRANSACTION_LOG_SUCCESS, GET_ALL_USER_ORDERS, GET_ALL_USER_ORDERS_FAILURE, GET_ALL_USER_ORDERS_SUCCESS, UPDATE_ORDER_STATUS, UPDATE_ORDER_STATUS_FAILURE, UPDATE_ORDER_STATUS_SUCCESS } from "../ActionTypes";
const INIT_STATE = {
    allAdminOrders: [],
    isloadingAllAdminOrders: false,
    allAdminOrdersDatamsg: "",
    allAdminOrdersByStatusId: [],
    isloadingAllAdminOrdersByStatusId: false,
    allAdminOrdersByStatusIdDatamsg: "",
    allUserOrders: [],
    isloadingAllUserOrders: false,
    allUserOrdersDatamsg: "",
    adminArchiveOrders: [],
    isloadingAdminArchiveOrders: false,
    adminArchiveOrdersDatamsg: "",
    adminOrderById: [],
    isloadingAdminOrderById: false,
    adminOrderByIdDatamsg: "",
    updateOrderStat: {},
    isloadingUpdateOrderStat: false,
    updateOrderStatDatamsg: "",
    createAnOrder: {},
    isloadingCreateAnOrder: false,
    createAnOrderDatamsg: "",
    allTransaction: [],
    isloadingAllTransaction: false,
    allTransactionDatamsg: "",
    allQueueOrders: [],
    isloadingAllQueueOrders: false,
    allQueueOrdersDatamsg: "",
};
function OrderReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_ALL_ADMIN_ORDERS: {
            // debugger
            return {
                ...state,
                allAdminOrders: action.payload,
                isloadingAllAdminOrders: true,
                allAdminOrdersDatamsg: "",
            }
        }
        case GET_ALL_ADMIN_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                allAdminOrders: action.payload,
                isloadingAllAdminOrders: false,
                allAdminOrdersDatamsg: "",
            };
        }
        case GET_ALL_ADMIN_ORDERS_FAILURE: {
            return {
                ...state,
                allAdminOrders: action.payload,
                isloadingAllAdminOrders: false,
                allAdminOrdersDatamsg: "",
            };
        }
        case GET_ALL_ADMIN_ORDERS_BY_STATUS: {
            // debugger
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: true,
                allAdminOrdersByStatusIdDatamsg: "",
            }
        }
        case GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS: {
            // debugger
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
            };
        }
        case GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE: {
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
            };
        }
        case GET_ALL_USER_ORDERS: {
            // debugger
            return {
                ...state,
                allUserOrders: action.payload,
                isloadingAllUserOrders: true,
                allUserOrdersDatamsg: "",
            }
        }
        case GET_ALL_USER_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                allUserOrders: action.payload,
                isloadingAllUserOrders: false,
                allUserOrdersDatamsg: "",
            };
        }
        case GET_ALL_USER_ORDERS_FAILURE: {
            return {
                ...state,
                allUserOrders: action.payload,
                isloadingAllUserOrders: false,
                allUserOrdersDatamsg: "",
            };
        }
        case GET_ADMIN_ARCHIVE_ORDERS: {
            // debugger
            return {
                ...state,
                adminArchiveOrders: action.payload,
                isloadingAdminArchiveOrders: true,
                adminArchiveOrdersDatamsg: "",
            }
        }
        case GET_ADMIN_ARCHIVE_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                adminArchiveOrders: action.payload,
                isloadingaAdminArchiveOrders: false,
                adminArchiveOrdersDatamsg: "",
            };
        }
        case GET_ADMIN_ARCHIVE_ORDERS_FAILURE: {
            return {
                ...state,
                adminArchiveOrders: action.payload,
                isloadingaAdminArchiveOrders: false,
                adminArchiveOrdersDatamsg: "",
            };
        }
        case GET_ADMIN_ORDER_BY_ID: {
            // debugger
            return {
                ...state,
                adminOrderById: action.payload,
                isloadingAdminOrderById: true,
                adminOrderByIdDatamsg: "",
            }
        }
        case GET_ADMIN_ORDER_BY_ID_SUCCESS: {
            // debugger
            return {
                ...state,
                adminOrderById: action.payload,
                isloadingAdminOrderById: false,
                adminOrderByIdDatamsg: "",
            };
        }
        case GET_ADMIN_ORDER_BY_ID_FAILURE: {
            return {
                ...state,
                adminOrderById: action.payload,
                isloadingAdminOrderById: false,
                adminOrderByIdDatamsg: "",
            };
        }
        case UPDATE_ORDER_STATUS: {
            // debugger
            return {
                ...state,
                updateOrderStat: action.payload,
                isloadingUpdateOrderStat: true,
                updateOrderStatDatamsg: "",
            }
        }
        case UPDATE_ORDER_STATUS_SUCCESS: {
            // debugger
            return {
                ...state,
                updateOrderStat: action.payload,
                isloadingUpdateOrderStat: false,
                updateOrderStatDatamsg: "",
            };
        }
        case UPDATE_ORDER_STATUS_FAILURE: {
            return {
                ...state,
                updateOrderStat: action.payload,
                isloadingUpdateOrderStat: false,
                updateOrderStatDatamsg: "",
            };
        }
        case CREATE_ORDER: {
            // debugger
            return {
                ...state,
                createAnOrder: action.payload,
                isloadingCreateAnOrder: true,
                createAnOrderDatamsg: "",
            }
        }
        case CREATE_ORDER_SUCCESS: {
            // debugger
            return {
                ...state,
                createAnOrder: action.payload,
                isloadingCreateAnOrder: false,
                createAnOrderDatamsg: "",
            };
        }
        case CREATE_ORDER_FAILURE: {
            return {
                ...state,
                createAnOrder: action.payload,
                isloadingCreateAnOrder: false,
                createAnOrderDatamsg: "",
            };
        }
        case GET_ALL_TRANSACTION_LOG: {
            // debugger
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: true,
                allTransactionDatamsg: "",
            }
        }
        case GET_ALL_TRANSACTION_LOG_SUCCESS: {
            // debugger
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: false,
                allTransactionDatamsg: "",
            };
        }
        case GET_ALL_TRANSACTION_LOG_FAILURE: {
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: false,
                allTransactionDatamsg: "",
            };
        }
        case GET_ALL_QUEUE_ORDERS: {
            // debugger
            return {
                ...state,
                allQueueOrders: action.payload,
                isloadingAllQueueOrders: true,
                allQueueOrdersDatamsg: "",
            }
        }
        case GET_ALL_QUEUE_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                allQueueOrders: action.payload,
                isloadingAllQueueOrders: false,
                allQueueOrdersDatamsg: "",
            };
        }
        case GET_ALL_QUEUE_ORDERS_FAILURE: {
            return {
                ...state,
                allQueueOrders: action.payload,
                isloadingAllQueueOrders: false,
                allQueueOrdersDatamsg: "",
            };
        }

        default:
            return state;
    }
}
export default OrderReducer;

export const getOrderReducer = state => state.orderReducer