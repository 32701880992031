import React from 'react'
import {ReactComponent as Lock1} from "../../asset/Lock1.svg"

const NotFound = () => {
  return (
    <>
   <div>
  <div className='d-flex w-full flex-column justify-content-center align-items-center gap-3' style={{height:"100vh"}}>
    <div>
    <Lock1/>
    </div>
    <div><p className='not-found-title'>Permission Denied</p></div>
    <div className="not-found-title-txt text-center"><p>You do not have permission to view this page. Contact your admin for more information.</p></div>
  </div>
</div>



  </>
  )
}

export default NotFound