import React from 'react'

const StatusIndicator = ({ status }) => {
  const isApproved = status === 1
  return (
    <div className='d-flex align-items-center gap-2'>
      <div className='d-inline-flex justify-content-center align-items-center' style={{ backgroundColor: isApproved ? "#05964614" : "#FFF2F2", borderRadius: "20px", padding: "10px 20px" }}>
        <span style={{ color: isApproved ? "#059646" : "#FF404A", fontSize: "12px", fontWeight:600 }}>{isApproved ? "approved" : "pending"}</span>
      </div>
    </div>
  )
}

export default StatusIndicator