import { GET_COUNTRY, GET_COUNTRY_FAILURE,GET_COUNTRY_SUCCESS } from "../ActionTypes";
    const INIT_STATE = {
     countryData:[],
     isloadingCountry:false
    };
    function CountryReducer(state = INIT_STATE, action) {
      switch (action.type) {
        case GET_COUNTRY: {
          // debugger
          return{
            ...state,
            countryData:[],
            isloadingCountry:true
          }
        }
        case GET_COUNTRY_SUCCESS: {
          // debugger
                 return {
            ...state,
            countryData:action.payload,
            isloadingCountry:false
          };
        }
        case GET_COUNTRY_FAILURE: {
        return {
            ...state,
            countryData:action.payload,
            isloadingCountry:false,
          };
        }
        default:
          return state;
      }
    }
    export default CountryReducer;

    export const getCountryReducer = state => state.countryReducer