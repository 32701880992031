import React, {useState, useEffect} from "react";
import { requestPermission, onMessageListener } from "../firebase";
import { ToastContainer, toast } from "react-toastify";

function Notification() {
    // eslint-disable-next-line no-unused-vars
    const [notification, setNotification] = useState({
        title: "",
        body: ""
    });
    useEffect(() => {
      requestPermission();

      const unsubscribe = onMessageListener().then(payload => {
        setNotification({
            title: payload?.notification?.title,
            body: payload?.notification?.body
        });

        // toast notification
        toast.success(
            `${payload?.notification?.title}: ${payload?.notification?.body}`,
            {
                duration: 60000,
                position: "top-right"
            }
        );
      })
    
      return () => {
        unsubscribe.catch(err => console.log("failed: ", err));
      }
    }, []);

    return (
        <>
            <ToastContainer position="top-center" />
        </>
    )
}

export default Notification;