// import { Button } from 'antd'
import React from 'react'
import generalFunc from '../../../Utils/generalFunc'

const CustomerTable = ({ details }) => {
    return (
        <div style={{width:"100%"}}>
            {details?.map((item, index) => (
                <div key={index} className='pt-3'>
                    <p className='card-title'>Amount Paid by Customer</p>
                    <hr />
                    <div className='d-flex flex-column justify-content-between'>
                        {Object.entries(item).map(([key, value], i) => (
                            <div key={i} className='mb-2 d-flex justify-content-between'>
                                <p className={i === Object.keys(item).length - 1 ? 'payment-total' : 'payment-title'}>{key}</p>
                                <p className={i === Object.keys(item).length - 1 ? 'payment-total' : 'payment-value'}>{generalFunc.delimitNumber(value)}</p>
                            </div>
                        ))}
                        {/* <Button className='button'>Download Invoice</Button> */}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default CustomerTable