import React, { useState } from 'react';
import { Table, Steps } from 'antd';


const orderDetails = {
    color: 'var(--Gray-Scale-Gray-900, #1A1A1A)',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%' // or alternatively use '30px' if you want to be specific
  };

  const orderDetailsWrap = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };

  const orderDate = {
    color: 'var(--Gray-Scale-Gray-700, #4D4D4D)',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%'
  };
  

  const backToList = {
    color: 'var(--New-Primary, #252A4D)',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%' // or alternatively use '24px' if you want to specify the line height in pixels
  };

  const headerWrapper = {
    display: 'inline-flex',
    padding: 'var(--spacing-4, 16px) var(--spacing-6, 24px)',
    alignItems: 'center',
    gap: '509px',
    borderRadius: '8px 8px 0px 0px',
    background: 'var(--Gray-Scale-White, #FFF)',
    boxShadow: '0px 1px 0px 0px #E5E5E5',
    overflow: 'auto'
  };

//   const cardSection = {
//     display: "flex",
//     padding: "18px 20px",
//     flexDirection: "column",
//     alignItems: "flex-start",
//     gap: "var(--spacing-px, 1px)",
//     width: "20%",
//   };

  const subTotal={
    color: 'var(--Gray-Scale-Gray-600, #666)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    marginRight: '70px'
  }
  const subTotalValue={
    color: 'var(--Gray-Scale-Gray-900, #1A1A1A)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%'
  }

  const Total={
    color: 'var(--Gray-Scale-Gray-900, #1A1A1A)',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    marginRight: '70px'
  }

  const totalValue={
    color: 'var(--Branding-Success-Dark, #2C742F)',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%'
  }

  const subCard = {
    display: 'flex',
    padding: '18px 20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--spacing-px, 1px)',
    width: '20%',
    borderRadius: '5px'
  };

 

  const columns = [
    {
      title: 'PRODUCT',
      dataIndex: 'product',
      render: (text) => <span className="orderHistory-text">{text}</span>,
      key: 'product',
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      render: (text) => <span className="orderHistory-text">{text}</span>,
      key: 'price',
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      render: (text) => <span className="orderHistory-text">{text}</span>,
      key: 'quantity',
    },
    {
        title: 'SUBTOTAL',
        dataIndex: 'subtotal',
        render: (text) => <span className="orderHistory-text">{text}</span>,
        key: 'subtotal',
      },
  ];

  const data = [
    {
      product: 'Order Detail Product',
      price: '1,800.00',
      quantity: 'x25',
      subtotal: '45,000.00',
    },
    {
        product: 'Nibbles Tea-buns',
        price: '1,800.00',
        quantity: 'x25',
        subtotal: '45,000.00',
      },
      {
        product: 'Sun crust Jumbo size',
        price: '1,800.00',
        quantity: 'x25',
        subtotal: '45,000.00',
      },
  ];

  
  

  
  

const OrderHistoryDetails = () => {

    // eslint-disable-next-line no-unused-vars
    const [current, setCurrent] = useState(2);

    const items = [
        {
          title: 'Order recieved',
        },
        {
          title: 'Order confirmed',
        },
        {
          title: 'Order proccessing',
        },
        {
            title: 'Available for pickup',
        },
      ];


  return (
    <>
      <div>
      <div className="d-flex" style={headerWrapper}>
        <div
          className="col d-flex justify-content-start"
          style={orderDetailsWrap}
        >
          <span style={orderDetails}>Order Details</span>{" "}
          <span style={orderDate}>•</span>{" "}
          <span style={orderDate}>April 03, 2024</span>{" "}
          <span style={orderDate}>•</span>{" "}
          <span style={orderDate}>3 Products</span>
        </div>

        <div className="col d-flex justify-content-end">
          <span style={backToList}>Back to List</span>
        </div>
      </div>

      <div className="m-5">
        <div className="border " style={subCard}>
          <div className="d-flex justify-content-between">
            <span style={subTotal}>Subtotal:</span>
            <span style={subTotalValue}>₦365,000.00</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span style={subTotal}>Discount:</span>
            <span style={subTotalValue}>%20</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span style={Total}>Total:</span>
            <span style={totalValue}>₦173,000.00</span>
          </div>
        </div>
      </div>

      <div className="container mb-3">
        <Steps
          current={current}
          labelPlacement="vertical"
          items={items}
          iconFontSize="50"
          finishIconBorderColor="#1697ff"
        />
      </div>
      <br />

      <div className="p-2">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      </div>
    </>
  );
}
export default OrderHistoryDetails;
