import React from "react";
import "./Preloader.css";
import { ReactComponent as BreadLoader } from "../../asset/BreadLoader.svg"

const Preloader = () => {
    return (
        <div className="page-cover">
            <div className="inner-page-div">
                <div className="loader-img-div">
                    <BreadLoader />
                    <div className="random-text-div">
                        <p>Fetching data for you...</p>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Preloader;
