// import { toastr } from "react-redux-toastr";
import api from "../../Service/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GetItemsInCartApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/cart?user_id=${payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const AddItemsInCartApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/cart`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                //   toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                //   toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const UpdateItemsInCartApi = async (body, payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.put(`/cart/${body}`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                //   toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                //   toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const DeleteItemsInCartApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.delete(`/cart/${payload}`);
            if (response.data) {
                // debugger
                console.log(response)
                //   toast.success("item deleted successfully");
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                //   toast.error(response.data.message);
                console.log(response)
                return response.data;
            }
        } catch (error) {
            //   toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetAllAvailableLocationApi = async () => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/locations`);
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const GetAllProductApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get('/products/dropdown');
            if (response.status === 200) {
                // debugger
                //   toast.success(response.data.message);
                console.log(response)
                return response.data.data;
            } else {
                // toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const PostVerifyTransactionApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/transactions/verify`, payload);
            if (response.status === 200) {
                // debugger
                if (response?.data?.status === false) {
                    toast.error(response?.data?.message);
                    console.log(response)
                } else {
                    // debugger
                    console.log(response)
                    toast.success(response.data.message);
                    // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                }
                // toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                // toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const ClearCartItemsApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/cart/clear`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response)
                return response.data;
            } else {
                // toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};