import { Col, Divider, Form, Input, Modal, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { FormatNumber } from "../../../../Utils/cartmgt";

const Status = ({ status }) => {
  debugger;
  const success = status === 0;
  return (
    <div className="d-flex align-items-center gap-2">
      <div>
        <span style={{ fontWeight: 600, color: "#252A4D" }}>
          View Transaction Logs
        </span>
      </div>
      <div
        className="d-inline-flex justify-content-center align-items-center"
        style={{
          backgroundColor: success ? "#05964614" : "#FFF2F2",
          borderRadius: "20px",
          padding: "10px 20px",
        }}
      >
        <span
          style={{ color: success ? "#059646" : "#FF404A", fontSize: "14px" }}
        >
          {success ? "success" : "pending"}
        </span>
      </div>
    </div>
  );
};
const ViewTransactionLogs = ({ open, handleClose, record }) => {
  const [form] = Form.useForm();
  console.log(record, "utjdj");
  useEffect(() => {
    if (open && Object.keys(record)?.length !== 0) {
      form.setFieldsValue({
        transaction_id: record?.order?.reference,
        payment_method: record?.payment_method,
        amount: FormatNumber((Number(record?.amount)+Number(record?.tax)+Number(record?.fee))-Number(record?.discount)),
        tax: FormatNumber(record?.tax),
        time: moment(record?.created_at).format("h:mm A"),
        date: moment(record?.created_at).format("MMM Do YYYY"),
        discount: FormatNumber(record?.discount),
        status: record?.status === 0 && "success",
        business_name: record?.order?.user?.business?.name,
        order_amount: FormatNumber(record?.order?.amount),
        order_id: record?.order_id,
        name: record?.order?.user?.name,
        email: record?.order?.user?.email,
        phoneNumber: record?.order?.user?.phone,
        location: record?.order?.user?.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form]);

  const columns = [
    {
      title: "S/N",
      key: "sn",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: ["product", "name"],
      render: (text) => <span className="orderHistory-text">{text}</span>,
    },
    {
      title: "Quantity",
      dataIndex: ["qty"],
      render: (text) => <span className="orderHistory-text">{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: ["product", "amount"],
      render: (text, record) => (
        <span className="orderHistory-text">
          {FormatNumber(Math.round(text * record?.qty))}
        </span>
      ),
    },
  ];
  return (
    <Modal
      centered
      width={800}
      open={open}
      onCancel={handleClose}
      styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
      okButtonProps={{ hidden: true }}
      title={<Status status={record?.status} />}
    >
      <div style={{ padding: "20px 10px" }}>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="transaction_id"
                label="Transaction Reference"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="payment_method"
                label="Payment"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="amount"
                label="Total Amount Paid by Customer"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tax"
                label="Service Fee"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="time"
                label="Time"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="date"
                label="Date"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="discount"
                label="Discount"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <div
                className="fs-5 mb-4 mt-3 d-flex align-items-center"
                style={{ whiteSpace: "nowrap" }}
              >
                <span style={{ fontWeight: 600, color: "#252A4D" }}>
                  Order Details
                </span>
                <Divider
                  style={{
                    flexGrow: 1,
                    marginLeft: "10px",
                    borderWidth: "1px",
                    borderColor: "#dfdfdf",
                  }}
                />
              </div>
            </Col>
            <Col span={4}>
              <Divider />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="order_id"
                label="Order Id"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="order_amount"
                label="Total Order Amount"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Table columns={columns} dataSource={record?.order?.items} />
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <div
                className="fs-5 mb-4 mt-3 d-flex align-items-center"
                style={{ whiteSpace: "nowrap" }}
              >
                <span style={{ fontWeight: 600, color: "#252A4D" }}>
                  Business Details
                </span>
                <Divider
                  style={{
                    flexGrow: 1,
                    marginLeft: "10px",
                    borderWidth: "1px",
                    borderColor: "#dfdfdf",
                  }}
                />
              </div>
            </Col>
            <Col span={4}>
              <Divider />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Business Name"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="email"
                label="Business Email"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="location"
                label="Business Location"
                layout="vertical"
                style={{ fontWeight: 700 }}
              >
                <Input readOnly style={{ cursor: "not-allowed" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ViewTransactionLogs;
