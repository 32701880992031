import React from 'react';
import { Button, Modal, Col, Row, Form, Select, Spin } from 'antd';
import { LocationIcon } from '../asset/icons/icons';
const ChangeOrderLocationModal = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { modal2Open, setModal2Open, availableLocations, isloadingAvailableLocations, handleLocationChange, defaultsel, chosenPickUp } = props;

    return (
        <>
            <Modal
                title={<p><span><LocationIcon /> </span>Change Pickup Location</p>}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={null}
            >
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form layout='vertical'>
                            <Form.Item
                                label={<label className="typography aboutbusinessformlabel">State</label>}
                                className='aboutbusinessformitem'
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                {isloadingAvailableLocations ? (
                                    <Spin size="default" />
                                ) : (
                                    <Select
                                        placeholder="Select Branch"
                                        defaultValue={defaultsel}
                                        onChange={(value, option) => handleLocationChange(value, option.label)}
                                        options={availableLocations?.map(location => ({
                                            value: location?.id,
                                            label: `${location?.name} - ${location?.address}`,
                                        }))}
                                        // value={chosenPickUp?.location_id}
                                        size='large'
                                    />
                                )}
                            </Form.Item>
                            <div className='addcompliancelocDiv'>
                                <Button className='uploadComplianceImage' size='large' block>Change Location</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ChangeOrderLocationModal;