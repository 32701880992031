import React from "react";
import { Card, Radio } from "antd";
import { DialIcon, EmailIcon } from "../asset/icons/icons";
// import nibbleslogo from "../asset/nibbleslogo.jpg"
// import { CarIcon, RetailChain } from '../asset/icons/icons';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const RadioOptionCard = ({ setSelectedOption, selectedOption, item }) => {

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // const handleRadioChange = (e) => {
  //     setSelectedOption(e.target.value);
  // };
//   console.log(selectedOption)
  return (
    <Card
      style={{
        border:
          selectedOption === item.id
            ? "0.818px solid #998C16"
            : "1px solid #d9d9d9",
        cursor: "pointer", // Add cursor pointer for clickable effect
      }}
      onClick={() => setSelectedOption(item.id)} // Use arrow function to pass parameters
      className="radioOnbCard"
      key={item.id}
    >
      <div className="radioImageDescdiv">
        <div
          className="radioImageIcondiv"
          style={{
            background: selectedOption === item.id ? "#998C16" : "#F8F7F7",
          }}
        >
          <img src={item.icon} alt={item.name} />
        </div>
        <div className="radiotitledescdiv">
          <p className="bestdescribeoptiontitle">{item.name === "Independent Distributorship" ? "Independent Distributor" : item.name }</p>
          <p className="bestdescribeoptiondescription">{item.description}</p>
          {
            item.phone !== undefined ? 
            <p className="bestdescribeoptiondescription" style={{marginTop: "-1em"}}><span><DialIcon/> </span>{item.phone}</p>
            :
            ""
          }
          {
            item.email !== undefined ? 
            <p className="bestdescribeoptiondescription" style={{marginTop: "-1em"}}><span><EmailIcon/> </span>{item.email}</p>
            :
            ""
          }
        </div>
      </div>
      <Radio.Group
        className="radiogrouponbdiv"
        type="solid"
        value={selectedOption}
        onChange={handleRadioChange}
      >
        <Radio value={item.id}></Radio>
      </Radio.Group>
    </Card>
  );
};

export default RadioOptionCard;
