import { all, call, fork, takeEvery } from "redux-saga/effects";
import requestFunction from "../../Utils/sagaGenericFunction";
import { createOrderFailure, createOrderSuccess, getAdminArchiveOrdersFailure, getAdminArchiveOrdersSuccess, getAdminOrderByIdFailure, getAdminOrderByIdSuccess, getAllAdminOrdersByStatusIdFailure, getAllAdminOrdersByStatusIdSuccess, getAllAdminOrdersFailure, getAllAdminOrdersSuccess, getAllQueueOrdersFailure, getAllQueueOrdersSuccess, getAllTransactionLogFailure, getAllTransactionLogSuccess, getAllUserOrdersFailure, getAllUserOrdersSuccess, updateOrderStatusFailure, updateOrderStatusSuccess } from "../Actions";
import { CREATE_ORDER, GET_ADMIN_ARCHIVE_ORDERS, GET_ADMIN_ORDER_BY_ID, GET_ALL_ADMIN_ORDERS, GET_ALL_ADMIN_ORDERS_BY_STATUS, GET_ALL_QUEUE_ORDERS, GET_ALL_TRANSACTION_LOG, GET_ALL_USER_ORDERS, UPDATE_ORDER_STATUS } from "../ActionTypes";
import { CreateOrderApi, GetAdminArchiveOrdersApi, GetAdminOrderByIdApi, GetAllAdminOrdersApi, GetAllAdminOrdersByStatusIdApi, GetAllQueueOrdersApi, GetAllTransactionLogApi, GetAllUserOrdersApi, UpdateOrderStatusApi } from "../Api";
// =================Order sagas================

const getAllAdminOrdersWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAllAdminOrdersSuccess, getAllAdminOrdersFailure, GetAllAdminOrdersApi, payload);
};
export const getAllAdminOrdersWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_ADMIN_ORDERS, getAllAdminOrdersWorker);
};

const getAllAdminOrdersByStatusIdWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAllAdminOrdersByStatusIdSuccess, getAllAdminOrdersByStatusIdFailure, GetAllAdminOrdersByStatusIdApi, payload);
};
export const getAllAdminOrdersByStatusIdWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_ADMIN_ORDERS_BY_STATUS, getAllAdminOrdersByStatusIdWorker);
};

const getAllUserOrdersWorker = function* ({ body, payload }) {
    // debugger
    yield call(requestFunction, getAllUserOrdersSuccess, getAllUserOrdersFailure, GetAllUserOrdersApi, body, payload);
};
export const getAllUserOrdersWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_USER_ORDERS, getAllUserOrdersWorker);
};

const getAdminArchiveOrdersWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAdminArchiveOrdersSuccess, getAdminArchiveOrdersFailure, GetAdminArchiveOrdersApi, payload);
};
export const getAdminArchiveOrdersWatcher = function* () {
    // debugger
    yield takeEvery(GET_ADMIN_ARCHIVE_ORDERS, getAdminArchiveOrdersWorker);
};

const getAdminOrderByIdWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAdminOrderByIdSuccess, getAdminOrderByIdFailure, GetAdminOrderByIdApi, payload);
};
export const getAdminOrderByIdWatcher = function* () {
    // debugger
    yield takeEvery(GET_ADMIN_ORDER_BY_ID, getAdminOrderByIdWorker);
};

const getUpdateOrderStatusWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, updateOrderStatusSuccess, updateOrderStatusFailure, UpdateOrderStatusApi, payload);
};
export const getUpdateOrderStatusWatcher = function* () {
    // debugger
    yield takeEvery(UPDATE_ORDER_STATUS, getUpdateOrderStatusWorker);
};

const getCreateOrderWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, createOrderSuccess, createOrderFailure, CreateOrderApi, payload);
};
export const getCreateOrderWatcher = function* () {
    // debugger
    yield takeEvery(CREATE_ORDER, getCreateOrderWorker);
};

const getAllTransactionLogWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAllTransactionLogSuccess, getAllTransactionLogFailure, GetAllTransactionLogApi, payload);
};
export const getAllTransactionLogWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_TRANSACTION_LOG, getAllTransactionLogWorker);
};

const getAllQueueOrdersWorker = function* ({ payload }) {
    // debugger
    yield call(requestFunction, getAllQueueOrdersSuccess, getAllQueueOrdersFailure, GetAllQueueOrdersApi, payload);
};
export const getAllQueueOrdersWatcher = function* () {
    // debugger
    yield takeEvery(GET_ALL_QUEUE_ORDERS, getAllQueueOrdersWorker);
};


export default function* cartSaga() {
    yield all([
        fork(getAllAdminOrdersWatcher),
        fork(getAllAdminOrdersByStatusIdWatcher),
        fork(getAllUserOrdersWatcher),
        fork(getAdminArchiveOrdersWatcher),
        fork(getAdminOrderByIdWatcher),
        fork(getUpdateOrderStatusWatcher),
        fork(getCreateOrderWatcher),
        fork(getAllTransactionLogWatcher),
        fork(getAllQueueOrdersWatcher),
    ]);
}