import React from "react";
import { Steps, Card } from "antd";
import courierbus from "../../../asset/courierbus.jpg";
import moment from "moment";

const ProgressCard = ({ details }) => {
  console.log(details, "OrderStatusDetails");
  return (
    <>
      <Card title="Your order timeline" bordered={false}>
        <div>
          <Steps
            // progressDot
            initial={0}
            labelPlacement="vertical"
            current={details.orderstatusid}
            items={[
              {
                title: "Order received",
                // description: 'This is a description.',
              },
              {
                title: "Order confirmed",
                // description: 'This is a description.',
              },
              {
                title: "Order processing",
                // description: 'This is a description.',
              },
              {
                title: "Available for pickup",
                // description: 'This is a description.',
              },
            ]}
          />
        </div>
        <div className="ordertimelinecourierdiv">
          <img src={courierbus} alt="Courier Bus" />

          <p className="mt-2">
            {details.orderenddate === null
              ? "Your order is being prepared"
              : "Your order should be available for"}
          </p>
          {details.orderenddate === null ? (
            <p>We'll update you when it's ready.</p>
          ) : (
            <p>
              pickup on{" "}
              <span className="fw-bold">
                {moment(details.orderenddate).format("MMM Do YYYY")}
              </span>
            </p>
          )}
        </div>
      </Card>
    </>
    // <div style={{ width: "100%" }}>
    //     <div>
    //     <p className='card-title'>Your order timeline </p>
    //     <hr />
    //     </div>
    //     <div>
    //     <Steps
    //         // progressDot
    //         initial={0}
    //         labelPlacement="vertical"
    //         current={details}
    //         items={[
    //             {
    //                 title: 'Order received',
    //                 // description: 'This is a description.',
    //             },
    //             {
    //                 title: 'Order confirmed',
    //                 // description: 'This is a description.',
    //             },
    //             {
    //                 title: 'Order processing',
    //                 // description: 'This is a description.',
    //             },
    //             {
    //                 title: 'Available for pickup',
    //                 // description: 'This is a description.',
    //             },
    //         ]}
    //     />
    //     </div>
    //     <div className='mt-4' style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
    //     <img src={courierbus} alt="Courier Bus" />
    //     <p>Your order should be available for</p>
    //     <p>pickup on April 04, 2024 by 12:00pm</p>
    //     </div>
    // </div>
  );
};

export default ProgressCard;
