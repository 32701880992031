import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Spin, Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import nibbleslogo from "../../asset/nibbleslogo.jpg";
import "./onboarding.css";
import { LockIcon } from "../../asset/icons/icons";
import { useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { useNavigate } from "react-router-dom";
// import { Logout } from "../../Utils/logout";

const { Text } = Typography;

const AccountSetUp = () => {
  const navigate = useNavigate();
  const [accountSuccessfullyCreated, setAccountSuccessfullyCreated] =
    useState(false);

  const {
    userOnboardingDetails,
    // postinguserOnboardingDetails
    businessContact,
  } = useSelector(getAuthReducer);

  const handleSignInUser = (e) => {
    // debugger
    navigate("/signin");
    window.location.reload()
  };

  useEffect(() => {
    // debugger
    const delay = 3000; // Time delay in milliseconds (3 seconds in this example)
    const timer = setTimeout(() => {
      // Call your function here
      if (userOnboardingDetails.status === true) {
        setAccountSuccessfullyCreated(true);
        localStorage.clear();
      }
      if (businessContact === true) {
        setAccountSuccessfullyCreated(true);
        localStorage.clear();
      }
      // navigate("/signin");
    }, delay);

    // Clean up the timer when the component unmounts or the effect re-runs
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOnboardingDetails, businessContact]);

  return (
    <div className="onboardingbackgimg">
      <Row justify="center" align="middle" className="settinguprow">
        {/* <Col span={24} className='settinupcol' style={{ }}> */}
        <Col span={24} className="settinuptextcol">
          <img alt="nibbleslogo" src={nibbleslogo} />
        </Col>

        <Col span={24} className="settinuptextcol">
          {accountSuccessfullyCreated === false ? (
            <Text className="bestdescribetext">Setting up your account</Text>
          ) : (
            <Text className="bestdescribetext">Great Job !!!</Text>
          )}
        </Col>

        {accountSuccessfullyCreated === false ? (
          <span>
            <Col span={24}>
              <Text className="settingupaccouttext">
                Whipping up your customized bakery dashboard... Give us a moment
              </Text>
            </Col>
            <Col span={24}>
              <Text className="settingupaccouttext">
                as we prepare everything based on the details you've shared.
              </Text>
            </Col>
          </span>
        ) : (
          <Col span={24}>
            <Text className="settingupaccouttext">
              We have done all the operations!
            </Text>
          </Col>
        )}

        <Col span={24} className={accountSuccessfullyCreated === true ? "settingupaccoutsuccessful" : "settingupaccoutspin"}>
          {accountSuccessfullyCreated === false ? (
            <Spin size="large">
              <div className="content" />
            </Spin>
          ) : (
            <Result
              icon={<SmileOutlined />}
              extra={
                <Button
                  onClick={handleSignInUser}
                  className="signupbutton typography"
                  block
                >
                  Click here to Login Again
                </Button>
              }
            />
          )}
        </Col>

        <Col className="settinupsecuritycol" span={24}>
          <Text className="settingupaccoutsectext">
            <span style={{ marginRight: "1em" }}>
              <LockIcon />
            </span>{" "}
            Your information is protected by end-to-end encryption.
          </Text>
        </Col>
        {/* </Col> */}
      </Row>
    </div>
  );
};
export default AccountSetUp;
