import {React, useEffect} from "react";
import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Form,
  Input,
} from "antd";
// import { PictureOutlined } from "@ant-design/icons";
// const { Dragger } = Upload;

const ProductTypeModal = (props) => {
// const {handleOk, handleCancel, open, uploadproductimageprops, selectedProductAction} = props;
const { handleCancel, open, selectedProductAction, currentProductType, onFinish } = props;
// const { handleOk, handleCancel, open, selectedProductAction } = props;
const [form] = Form.useForm();

useEffect(() => {
  if (selectedProductAction === "Edit" && currentProductType) {
    form.setFieldsValue({
      name: currentProductType.name,
    });
  } else {
    form.resetFields();
  }
}, [selectedProductAction, currentProductType, form]);





console.log(selectedProductAction, "selectedProductAction")

  return (
    <>
      <Modal
        open={open}
        title={`${selectedProductAction ? selectedProductAction : "Add"} Category`}
        centered
        onOk={() => form.submit()}
        onCancel={handleCancel}
        footer={[
          <Button
          key="submit"
            className="typography createproducttbutton"
            closable={true}
            onClick={() => form.submit()}
          >
            {selectedProductAction ? selectedProductAction : "Add"} Category
          </Button>,
        ]}
      >
        <Row>
          <Divider type="vertical" />
          <Col
            className="gutter-row"
            style={{ marginTop: "auto", marginBottom: "auto" }}
            span={16}
          >
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
               name="name"
                label={
                  <label className="typography aboutbusinessformlabel">
                    Category Name
                  </label>
                }
                className="aboutbusinessformitem"
                rules={[{ required: true, message: 'Please enter category name' }]}
              >
                <Input placeholder="Enter category name" />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ProductTypeModal;
