import React, { useState } from "react";
import { Spin, Table } from "antd";
import moment from "moment";
import { GreenDot } from "../../asset/icons/icons";

const QueueAvailableForPickup = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [selectionType, setSelectionType] = useState("checkbox");
  const { isloadingAllAdminOrdersByStatusId, allAdminOrdersByStatusId } = props;

  const columns = [
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: (text, record, index) => index + 1, // Render the serial number
  },
    {
      title: "Order ID",
      dataIndex: "id",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Order Reference",
      dataIndex: "reference",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <span className="orderHistory-text">
          {text} <br />
        </span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Time of Order",
      dataIndex: "created_at",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text).format("h:mm A")}
        </span>
      ),
      sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      render: (text, record) => {
        return (
          <span>
            {text.name === "Pick-up" ? "Available For Pickup" : text.name}{" "}
            <GreenDot />
          </span>
        );
      },
      sorter: (a, b) => a.order_status?.id - b.order_status?.id,
    },
    {
      title: "Ready",
      dataIndex: "estimated_end_date",
      render: (text) => (
        <span className="orderHistory-text">
          {text === null ? "N/A" : moment(text).format("h:mm A")}
        </span>
      ),
      sorter: (a, b) =>
        a.estimated_end_date?.localeCompare(b?.estimated_end_date),
    },
  ];

  // rowSelection object indicates the need for row selection
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  return (
    <div>
      {/* <p>Available for pickup Queue Orders</p> */}
      <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        columns={columns}
        rowKey="id" // Specify the unique key for each row
        dataSource={allAdminOrdersByStatusId?.data}
            pagination={{
              pageSize: allAdminOrdersByStatusId?.meta?.per_page,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              total: allAdminOrdersByStatusId?.meta?.total,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }}
      />
      </Spin>
    </div>
  );
};
export default QueueAvailableForPickup;
