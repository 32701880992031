import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Button, Form, Input } from 'antd';
import nibbleslogo from "../../asset/nibbleslogo.jpg"
import "./onboarding.css";
// import { ArrowLeftIcon, GoogleIcon, PhoneIcon, SendIcon } from "../../asset/icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getForgotPassword } from "../../redux/Actions";

const { Title, Text } = Typography;

const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

export default function ForgotPassword() {
    // const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const { forgotPassworddetail, forgotpasswordLoading } = useSelector(getAuthReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [emailErrors, setEmailErrors] = useState("");
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const handleEmailChange = (e) => {
        const emailInput = e.target.value
        setEmail(emailInput)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailInput || !emailRegex.test(emailInput)) {
            setEmailErrors('Please enter a valid email address.');
        } else {
            setEmailErrors('');
        }
    };

    const handleForgotPassword = (e) => {
        // debugger
        localStorage.setItem("forgotpasswordemail", JSON.stringify(email));
        dispatch(getForgotPassword({email: email}))
        

        // if (authUser=== undefined) {
        //     // debugger
        //     return null
        // } else if (authUser?.user?.user_type === null ) {
        //     navigate("/onboarding")
        // } else if (authUser?.user?.user_type !== null ) {
        //     navigate("/dashboard")
        // } else {
        //     return null
        // }
        // console.log(email, "emailhandle")
    };

    useEffect(() => {
        if (forgotPassworddetail === true) {
            navigate("/forgotPasswordEnterOtp")
        }

        //   return () => {
        //     second
        //   }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPassworddetail])


    console.log(forgotPassworddetail, 'ForgotPasswordMessage')

    return (
        <>
            <Row>

                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartedbackground">

                </Col>

                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} className="getstartefirstrowlogo">
                    <Row className="getstartedrowlogo">
                        <Col span={24} className="getstartedcollogo">
                            <img alt="nibbleslogo" src={nibbleslogo} />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className="getstartedcolpad">
                            {/* <div> */}
                            <Title className="typography getstartedtext" style={{ marginBottom: "0px" }} level={1}>Forgot your Password?</Title>
                            <Text className="joinbakery typography">Need to reset your password, enter your email for a reset link</Text>
                        </Col>

                        <Col span={24} className="getstartedcolpad">
                            <Form
                                {...formLayout}
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                size={"large"}
                                // onValuesChange={handleFormValuesChange}
                                className="typography"
                            >
                                <Col xs={{ span: 24 }}>
                                    <Form.Item label={<label className="typography fw-bold">Email Address</label>} name="email">
                                        <Input type='email'
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="example@gmail.com"
                                        />
                                    </Form.Item>
                                    {emailErrors && <p className="error">{emailErrors}</p>}
                                </Col>

                                <Col xs={{ span: 24 }}>
                                    {/* <Form.Item> */}
                                    {/* <Link to={"/enterotp"} style={{textDecoration: "none"}}> */}
                                    <Button onClick={handleForgotPassword} className="signupbutton typography" htmlType="submit" disabled={email === "" || emailErrors !== "" || forgotpasswordLoading} block>
                                        {forgotpasswordLoading ? 'Loading...' : 'Send Code'}
                                    </Button>
                                    {/* </Link> */}
                                    {/* </Form.Item> */}
                                </Col>
                            </Form>


                        </Col>


                    </Row>

                    <Row>
                        <Col span={24} className="getstartedcolpad">
                            <p className="joinbakery typography text-center">Remember password? <Link to={"/signin"} style={{ color: "#998C16", textDecoration: "underline" }} className="signinlink">Log in</Link></p>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col span={24} className="getstartedcolpadterms">
                            <Text className="terms">By signing in, you already agree to the <Link to={"#"} style={{ color: "#252A4D" }} className="signinlink">Terms of Service</Link> and <Link to={"#"} style={{ color: "#252A4D" }} className="signinlink"> Privacy Policy </Link></Text>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        </>
    );
}
