import React from 'react'
import emptyNote from '../../../asset/emptyNote.png';
const ExtraNotesTable = ({ details }) => {
    return (

        <div className='pt-3'>
            <p className='card-title'>Extra Notes</p>
            <hr />
            <div className='d-flex flex-column justify-content-between align-items-center'>
                <img src={emptyNote} alt="No notes provided" />
                <p className='empty-note-text'>No notes provided</p>
            </div>
        </div>

    )
}

export default ExtraNotesTable