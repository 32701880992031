import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getPermissionReducer } from "../../redux/Reducer/permissionReducer";
import { Option } from "antd/es/mentions";


const { TextArea } = Input;

const RoleBasicInfo = (props) => {
  const { handleRoleNameChange, handleEmployeesChange, onFinish, onFinishFailed, readOnly, form: propForm } = props;
  const { employeeUnpaginatedData } = useSelector(getAuthReducer);
  const { roleName, description, userAssigned, } = useSelector(getPermissionReducer);
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (employeeUnpaginatedData?.length !== 0) {
      const newArr = employeeUnpaginatedData?.flat();
      const newOptions = newArr?.map((option) => ({
        value: option?.id,
        label: option?.name,
      }));
      setOptions(newOptions);
    }
  }, [employeeUnpaginatedData]);

  useEffect(() => {
    form.setFieldsValue({
      roleName: roleName ?? "",
      description: description ?? "",
      userAssigned: userAssigned ?? [],
    });
  }, [roleName, description, userAssigned, form]);

  useEffect(() => {
    console.log(readOnly, "Vlaue of readonly");
  }, [readOnly])

  return (
    <>
      <div>
        <p className="typography roledetailstext mt-3">Role Details</p>
        <p className="typography roledetailsdescription">Add basic information about this role you are creating</p>
      </div>

      <div>
        <Form
          layout="vertical"
          form={propForm || form}
          initialValues={{
            roleName: "",
            description: "",
            userAssigned: [],
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Role Name"
            name="roleName"
            rules={[{ required: true, message: 'Please input role name!' }]}
          >
            <Input
              name="roleName"
              onChange={handleRoleNameChange}
              size="large"
              placeholder="Role Name"
              readOnly={readOnly ?? false}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input description!' }]}
          >
            <TextArea
              onChange={handleRoleNameChange}
              name="description"
              placeholder="Description"
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
              readOnly={readOnly ?? false}
            />
          </Form.Item>
          <Form.Item label="User(s) Assigned" name="userAssigned">
            <Select
              mode="multiple"
              name="userAssigned"
              style={{
                width: "100%",
              }}
              placeholder={userAssigned?.length===0 && readOnly?"No users were assigned to this role":"Assign users to this role"}
              onChange={handleEmployeesChange}
              options={options}
              disabled={readOnly ?? false}
              showSearch
              optionFilterProp="label"
            >
              {options.map(option => (
                <Option key={option.value} value={option.value} label={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RoleBasicInfo;
