import React from "react";
import { Col, Row, Typography, Card, Form, Input, Radio, Spin } from "antd";
import Cleave from "cleave.js/react";
const { Text } = Typography;
const { TextArea } = Input;

const CuriousJourney = (props) => {
  const { businessHistoryForm, setBusinessHistoryForm, postingbusinessHistory } = props;
  // const [anotherCompVal, setAnotherCompVal] = useState("");
  // const [listBandP, setListBandP] = useState("");
  // const [strategyDistPlan, setStrategyDistPlan] = useState("");
  // const [suitableDelVan, setSuitableDelVan] = useState("");

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    // setAnotherCompVal(e.target.value);
    setBusinessHistoryForm({
      ...businessHistoryForm,
      is_new_partner: e.target.value,
    });
  };

  const handleBusinessListchange = (e) => {
    setBusinessHistoryForm({
      ...businessHistoryForm,
      strategy_brief: businessHistoryForm.is_new_partner === false ? "xxxxx" : e.target.value,
    });
  };

  const handleMaxTurnOverchange = (e) => {
    const valueWithoutCommas = e.target.value.replace(/,/g, "");
    setBusinessHistoryForm({
      ...businessHistoryForm,
      annual_turnover: valueWithoutCommas,
    });
  };

  // const handleMinTurnOverchange = (e) => {
  //   const valueWithoutCommas = e.target.value.replace(/,/g, "");
  //   setBusinessHistoryForm({
  //     ...businessHistoryForm,
  //     min_turnover_per_month: valueWithoutCommas,
  //   });
  // };

  const onSuitableDelVanChange = (e) => {
    // console.log('radio checked', e.target.value);
    // setSuitableDelVan(e.target.value);
    setBusinessHistoryForm({
      ...businessHistoryForm,
      has_delivery_van: e.target.value,
    });
  };

  const onDistributeLoafChange = (e) => {
    console.log('radio checked', e.target.value);
    // setSuitableDelVan(e.target.value);
    setBusinessHistoryForm({
      ...businessHistoryForm,
      distribute_loaf: e.target.value,
    });
  };

  return (
    <>
     <Spin spinning={postingbusinessHistory} tip="Loading...">
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Text className="bestdescribetext">
            We are curious: Share your journey
          </Text>
        </Col>
        <Col span={24} className="welcomebestdescriberow">
          <Text className="welcomebestdescribetext">
            We are interested in your journey, this helps us know how we can
            best cater for you.
          </Text>
        </Col>

        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Card className="aboutbusinesscard">
            <Form
              {...formLayout}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size={"large"}
              // onValuesChange={handleFormValuesChange}
              className="typography"
            >
              <Form.Item
                label={
                  <label className="typography aboutbusinessformlabel">
                    Do you do business with another Company?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                }
                className="aboutbusinessformitem"
              >
                <Radio.Group
                  className="radiotextonb"
                  onChange={onChange}
                  value={businessHistoryForm.is_new_partner}
                >
                  <Radio className="radiotextonb" value={true}>
                    Yes
                  </Radio>
                  <Radio className="radiotextonb" value={false}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {businessHistoryForm.is_new_partner === false ? (
                ""
              ) : (
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      If yes, please list the businesses and products{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                  className="aboutbusinessformitem"
                >
                  <TextArea
                    value={businessHistoryForm.strategy_brief}
                    onChange={handleBusinessListchange}
                    // placeholder="Controlled autosize"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                label={
                  <label className="typography aboutbusinessformlabel">
                    Do you own a suitable delivery van with an insurance policy
                    that meets the bakery requirements?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                }
                className="aboutbusinessformitem"
              >
                <Radio.Group
                  onChange={onSuitableDelVanChange}
                  value={businessHistoryForm.has_delivery_van}
                >
                  <Radio className="radiotextonb" value={true}>
                    Yes
                  </Radio>
                  <Radio className="radiotextonb" value={false}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={
                  <label className="typography aboutbusinessformlabel">
                    Are you able to distribute a minimum of 100 loaves a week.{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                }
                className="aboutbusinessformitem"
              >
                <Radio.Group
                  onChange={onDistributeLoafChange}
                  value={businessHistoryForm.distribute_loaf}
                >
                  <Radio className="radiotextonb" value={true}>
                    Yes
                  </Radio>
                  <Radio className="radiotextonb" value={false}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel">
                        What is your annual turnover?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    }
                    className="aboutbusinessformitem"
                  >
                    {/* <Input 
                                        placeholder="e.g 200000" 
                                        value={businessHistoryForm.annual_turnover}
                                    onChange={handleMaxTurnOverchange}
                                        /> */}
                    <Cleave
                      placeholder="e.g 200000"
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "3%",
                        // background: 'transparent',
                        border: "0.5px solid #C6C7C9",
                        borderRadius: "7px",
                        backgroundColor: "white",
                      }}
                      fullWidth
                      value={businessHistoryForm.annual_turnover}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                      }}
                      onChange={handleMaxTurnOverchange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      </Spin>
    </>
  );
};
export default CuriousJourney;
