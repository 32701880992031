import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  Form,
  Input,
  Button,
  Select,
  Radio,
  Spin,
  DatePicker,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Facebook,
  InstagramIcon,
  Tiktok,
  TwitterIcon,
} from "../asset/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../redux/Reducer/authReducer";
import {
  getLocalGovernmentByStateId,
  getRelationshipType,
  getState,
} from "../redux/Actions";
import RetailMOIUpload from "./retailerUploadMeansofId";
import { LoadScript } from "@react-google-maps/api";

const { Text } = Typography;
// const { Option } = Select;

const MoreDetailsAboutYou = (props) => {
  const {
    userData,
    setUserData,
    usertypeid,
    postinguserOnboardingDetails,
    userbusinessid,
  } = props;
  // const [gender, setGender] = useState("");
  const {
    stateData,
    isloadingStateData,
    localGovernmentData,
    // isloadingLocalGovernmentData,
    // userOnboardingDetails,
    relationshipTypeData,
    // isloadingRelationshipType
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [meansofId, setMeansofId] = useState("");
  const autocompleteServiceRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [optionsNOK, setOptionsNOK] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchValueNOK, setSearchValueNOK] = useState("");

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleNameChange = (e) => {
    setUserData({ ...userData, name: e.target.value });
  };

  // const handleDOBChange = (date, dateString) => {
  //     console.log(date, dateString, "DOB SELECTED");
  //     setUserData({ ...userData, date_of_birth: dateString })
  // };

  const handleDOBChange = (date, dateString) => {
    // console.log(date, dateString);
    setUserData({ ...userData, date_of_birth: dateString });
  };

  const handleLocalGovernmentChange = (value, lganame) => {
    // console.log(`selected ${value}`);
    setUserData({ ...userData, lga: lganame });
  };

  const onGenderChange = (e) => {
    // console.log('radio checked', e.target.value);
    setUserData({ ...userData, gender: e.target.value });
  };

  const handleStateChange = (value, statename) => {
    // debugger
    // console.log(`selected ${value}`);
    dispatch(getLocalGovernmentByStateId(value));
    setUserData({ ...userData, state: statename });
  };

  const phonehandler = (e) => {
    // debugger
    // console.log(e);
    setUserData({ ...userData, phone: e });
  };

  const whatsappPhoneHandler = (e) => {
    // debugger
    // console.log(e);
    setUserData({ ...userData, whatsapp_phone: e });
  };

  // const phoneNOKhandler = (e) => {
  //     // debugger
  //     console.log(e);
  // };

  const handleAddressChange = (value) => {
    setUserData({ ...userData, address: value });
    setSearchValue(value);
  };

  const handleAddressSearch = (value) => {
    setSearchValue(value);
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptions(results);
        }
      );
    } else {
      setOptions([]);
    }
  };

  const handleNOKAddressSearch = (value) => {
    setSearchValueNOK(value);
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
                value: prediction.description,
                label: prediction.description,
              }))
            : [];
          setOptionsNOK(results);
        }
      );
    } else {
      setOptionsNOK([]);
    }
  };

  // const handleNINChange = (e) => {
  //   console.log(e.target.value);
  // };

  const handleNextOfKinChange = (index, fieldName, value) => {
    setUserData((prevState) => ({
      ...prevState,
      next_of_kin: prevState.next_of_kin.map((kin, i) =>
        i === index ? { ...kin, [fieldName]: value } : kin
      ),
    }));
  };

  const handleAddressSelect = (index, value) => {
    handleNextOfKinChange(index, "address", value);
    setSearchValueNOK("");
  };

  const handleSocialsChange = (index, fieldName, value) => {
    const updatedSocials = [...userData.socials];
    updatedSocials[index][fieldName] = value;
    setUserData((prevState) => ({
      ...prevState,
      socials: updatedSocials,
    }));
  };

  const handleAddSocial = () => {
    setUserData((prevState) => ({
      ...prevState,
      socials: [...prevState.socials, { icon: "Instagram", link: "" }],
    }));
  };

  const handleRemoveSocial = (index) => {
    const updatedSocials = [...userData.socials];
    updatedSocials.splice(index, 1);
    setUserData((prevState) => ({
      ...prevState,
      socials: updatedSocials,
    }));
  };

  const handleMeansOfIdentification = (value) => {
    // console.log(`selected ${value}`);
    setMeansofId(value);
  };

  useEffect(() => {
    // dispatch(getCountry())
    dispatch(getState());
    dispatch(getRelationshipType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  //     <Form.Item
  //         name="prefix"
  //         noStyle
  //     >
  //         {userData.socials.map((social, index) => (
  //             <Select
  //                 // style={{
  //                 //     width: 70,
  //                 // }}
  //                 defaultValue="Instagram"
  //                 onChange={(value) => handleSocialsChange(index, 'icon', value)}
  //                 value={social.icon}
  //             // onClick={handlePhoneNumberPrefixChange}
  //             >
  //                 <Option value="Instagram"><InstagramIcon /></Option>
  //                 <Option value="Facebook"><Facebook /></Option>
  //                 <Option value="Twitter"><TwitterIcon /></Option>
  //                 <Option value="Tiktok"><Tiktok /></Option>
  //                 {/* <Option value="87">+87</Option> */}
  //             </Select>
  //         ))}
  //     </Form.Item>
  // );
  // console.log(meansofId, "meansofId");
  return (
    <>
      <Spin spinning={postinguserOnboardingDetails} tip="Loading...">
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Text className="bestdescribetext">More Details About You😀</Text>
          </Col>
          <Col span={24} className="welcomebestdescriberow">
            <Text className="welcomebestdescribetext">
              Lets know you personally. We are excited to meet you. Please share
              your details with us.
            </Text>
          </Col>

          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Card className="aboutbusinesscard">
              <Form
                {...formLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size={"large"}
                // onValuesChange={handleFormValuesChange}
                className="typography"
              >
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Full Name (Surname first){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                >
                  <Input
                    placeholder="Eunice Nwachukwu"
                    value={userData.name}
                    onChange={handleNameChange}
                  />
                </Form.Item>

                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Gender <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                    >
                      <Radio.Group
                        onChange={onGenderChange}
                        value={userData.gender}
                      >
                        <Radio value={"male"}>Male</Radio>
                        <Radio value={"female"}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Date of Birth <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        size="large"
                        style={{ width: "100%" }}
                        onChange={handleDOBChange}
                        format={"DD/MM/YYYY"}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          State of Origin{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      {isloadingStateData ? (
                        <Spin size="default" />
                      ) : (
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={filterOption}
                          placeholder="Select a state"
                          onChange={(value, option) =>
                            handleStateChange(value, option.label)
                          }
                          options={stateData.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Local Government{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                    >
                      <Select
                        placeholder="Select Local Government"
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        onChange={(value, option) =>
                          handleLocalGovernmentChange(value, option.label)
                        }
                        options={localGovernmentData.map((localgovernment) => ({
                          value: localgovernment.id,
                          label: localgovernment.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          WhatsApp Number
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <div className="phoneInputDiv">
                        <PhoneInput
                          // inputComponent
                          //   style={{ backgroundColor: 'white', height: '7vh' }}
                          international
                          defaultCountry="NG"
                          autoComplete="tel"
                          type="tel"
                          value={userData.whatsapp_phone}
                          // className=""
                          onChange={whatsappPhoneHandler}
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      label={
                        <label className="typography aboutbusinessformlabel">
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </label>
                      }
                      className="aboutbusinessformitem"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <div className="phoneInputDiv">
                        <PhoneInput
                          // inputComponent
                          //   style={{ backgroundColor: 'white', height: '7vh' }}
                          international
                          defaultCountry="NG"
                          autoComplete="tel"
                          type="tel"
                          value={userData.phone}
                          // className="rounded rounded-full bg-gray-100 py-1 px-2 text-gray-700 shadow-sm border-none"
                          onChange={phonehandler}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                {usertypeid === 4 ? (
                  <Form.Item
                    label={
                      <label className="typography aboutbusinessformlabel">
                        Means of Identification{" "}
                      </label>
                    }
                    className="aboutbusinessformitem"
                  >
                    <Select
                      // defaultValue="Bakery"
                      onChange={handleMeansOfIdentification}
                      placeholder="Choose a means of identification"
                      // options={natureOfBusinessData.map((business) => ({
                      //   value: business.name,
                      //   // label: business.name,
                      // }))}
                      options={[
                        {
                          value: "2",
                          label: `National Identity Number (NIN)`,
                        },
                        {
                          value: "3",
                          label: `Drivers License`,
                        },
                        {
                          value: "4",
                          label: `International Passport`,
                        },
                        {
                          value: "5",
                          label: `Voters card`,
                        },
                      ]}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
                {meansofId !== "" ? (
                  // <Form.Item
                  //   label={
                  //     <label className="typography aboutbusinessformlabel">
                  //       {meansofId === "NIN"
                  //         ? "NIN"
                  //         : meansofId === "driverslicense"
                  //         ? "Drivers License"
                  //         : meansofId === "voterscard"
                  //         ? "Voters card"
                  //         : "International Passport"}
                  //       <span style={{ color: "red" }}> *</span>
                  //     </label>
                  //   }
                  // >
                  //   <Input
                  //     placeholder={
                  //       meansofId === "NIN"
                  //         ? "Enter your NIN here"
                  //         : meansofId === "driverslicense"
                  //         ? "Enter Your Licence Id."
                  //         : meansofId === "voterscard"
                  //         ? "Enter Your Voters Id here"
                  //         : "Enter Your Passport No."
                  //     }
                  //     type="number"
                  //     //   value={userData.address}
                  //     // onChange={handleNINChange}
                  //   />
                  // </Form.Item>
                  <RetailMOIUpload
                    setUserData={setUserData}
                    userData={userData}
                    userbusinessid={userbusinessid}
                    meansofId={meansofId}
                  />
                ) : (
                  ""
                )}
                <Form.Item
                  label={
                    <label className="typography aboutbusinessformlabel">
                      Your Resident Address{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  }
                >
                  {/* <Input
                    placeholder="your street address goes here"
                    value={userData.address}
                    onChange={handleAddressChange}
                  /> */}
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    libraries={["places"]}
                  >
                    <Select
                      showSearch
                      placeholder="your street address goes here"
                      onSearch={handleAddressSearch}
                      onChange={handleAddressChange}
                      options={options}
                      filterOption={false}
                      // value={searchValue === "" ? businessLocationForm.address}
                      value={
                        searchValue === "" ? userData.address : searchValue
                      }
                      notFoundContent={null}
                    />
                  </LoadScript>
                </Form.Item>
                {usertypeid === 4 ? (
                  ""
                ) : (
                  <div>
                    {userData.next_of_kin.map((kin, index) => (
                      <div key={index}>
                        <Form.Item
                          label={
                            <label className="typography aboutbusinessformlabel">
                              Next of Kin (Surname first){" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          }
                          className="aboutbusinessformitem"
                        >
                          <Input
                            placeholder="e.g John Doe"
                            value={kin.name}
                            onChange={(e) =>
                              handleNextOfKinChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>

                        <Row gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            {/* <Form.Item label={<label className="typography aboutbusinessformlabel">Relationship to Next of Kin *</label>} className='aboutbusinessformitem'>
                                        <Input placeholder="e.g. John Doe" />
                                    </Form.Item> */}

                            <Form.Item
                              label={
                                <label className="typography aboutbusinessformlabel">
                                  Relationship to Next of Kin{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              }
                              className="aboutbusinessformitem"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                // defaultValue="Lagos"
                                placeholder="Select Relationship"
                                onChange={(value) =>
                                  handleNextOfKinChange(
                                    index,
                                    "relationship_type_id",
                                    value
                                  )
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                value={kin.relationship_type_id}
                                options={relationshipTypeData.map(
                                  (relationshiptypes) => ({
                                    value: relationshiptypes.id,
                                    label: relationshiptypes.name,
                                  })
                                )}
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Form.Item
                              label={
                                <label className="typography aboutbusinessformlabel">
                                  Next of Kin Phone Number{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              }
                              className="aboutbusinessformitem"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <div className="phoneInputDiv">
                                <PhoneInput
                                  // inputComponent
                                  //   style={{ backgroundColor: 'white', height: '7vh' }}
                                  international
                                  defaultCountry="NG"
                                  autoComplete="tel"
                                  type="tel"
                                  //   value={phone}
                                  // className="rounded rounded-full bg-gray-100 py-1 px-2 text-gray-700 shadow-sm border-none"
                                  value={kin.phone}
                                  onChange={(e) =>
                                    handleNextOfKinChange(index, "phone", e)
                                  }
                                />
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item
                          label={
                            <label className="typography aboutbusinessformlabel">
                              Contact Address of Next of Kin{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          }
                        >
                          {/* <Input
                            placeholder="your street address goes here"
                            value={kin.address}
                            onChange={(e) =>
                              handleNextOfKinChange(
                                index,
                                "address",
                                e.target.value
                              )
                            }
                          /> */}

                          <LoadScript
                            googleMapsApiKey={
                              process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                            }
                            libraries={["places"]}
                          >
                            <Select
                              showSearch
                              placeholder="Next of Kin street address goes here"
                              onSearch={handleNOKAddressSearch}
                              onChange={(value) =>
                                handleAddressSelect(index, value)
                              }
                              options={optionsNOK}
                              filterOption={false}
                              value={kin.address}
                              notFoundContent={null}
                            />
                          </LoadScript>
                        </Form.Item>
                      </div>
                    ))}

                    {userData.socials.map((social, index) => (
                      <Row
                        key={index}
                        gutter={[32, { xs: 2, sm: 6, md: 24, lg: 32 }]}
                      >
                        <Col
                          xs={{ span: 20 }}
                          sm={{ span: 20 }}
                          md={{ span: 22 }}
                          lg={{ span: 22 }}
                        >
                          <Form.Item
                            label={
                              <label className="typography aboutbusinessformlabel">
                                Social Media Handles
                              </label>
                            }
                            className="aboutbusinessformitem"
                          >
                            <Input
                              addonBefore={
                                <Select
                                  defaultValue={"Instagram"}
                                  onChange={(value) =>
                                    handleSocialsChange(index, "icon", value)
                                  }
                                >
                                  <Select.Option value="Instagram">
                                    <InstagramIcon />
                                  </Select.Option>
                                  <Select.Option value="Facebook">
                                    <Facebook />
                                  </Select.Option>
                                  <Select.Option value="Twitter">
                                    <TwitterIcon />
                                  </Select.Option>
                                  <Select.Option value="Tiktok">
                                    <Tiktok />
                                  </Select.Option>
                                </Select>
                              }
                              style={{ width: "100%" }}
                              value={social.link}
                              onChange={(e) =>
                                handleSocialsChange(
                                  index,
                                  "link",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 4 }}
                          sm={{ span: 4 }}
                          md={{ span: 2 }}
                          lg={{ span: 2 }}
                        >
                          <Form.Item className="mt-4 addanothersocialbuttonfi">
                            {userData.socials.length > 1 && (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => handleRemoveSocial(index)}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
                {usertypeid === 4 ? (
                  ""
                ) : (
                  <Form.Item className="addanothersocialbuttonfi">
                    <Button
                      type="text"
                      className="addanotherphonebutton"
                      onClick={handleAddSocial}
                      icon={<PlusOutlined />}
                    >
                      Add Social Link
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
export default MoreDetailsAboutYou;
