// import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from "redux-saga";
// import rootReducer from "../redux/Reducer/index";
// import rootSaga from "../redux/Sagas/index.js";

// const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: [sagaMiddleware],
// });

// sagaMiddleware.run(rootSaga);

// export { store };




import { applyMiddleware, compose, createStore } from "redux";
import reducers from "../redux/Reducer/index";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../redux/Sagas/index";
const sagaMiddleware = createSagaMiddleware();
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default (function store(initialState) {
  const store = createStore(
    reducers(),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);
  // @ts-ignore
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // @ts-ignore
    module.hot.accept("../redux/Reducer/index", () => {
      const nextRootReducer = require("../redux/Reducer/index");
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
})();