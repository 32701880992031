import { GET_COUNTRY, GET_COUNTRY_FAILURE, GET_COUNTRY_SUCCESS } from "../ActionTypes";

export const getCountry = () => ({
    type: GET_COUNTRY,
    // payload
});
export const getCountrySuccess = (payload) => ({
    type: GET_COUNTRY_SUCCESS,
    payload
});
// To log out new user
export const getCountryFailure = () => ({
    type: GET_COUNTRY_FAILURE,
});