import React, { useState, useEffect } from "react";
import { Table, Tabs, Spin, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../../redux/Reducer/authReducer";
import {
  getAllIndependentDistributorship,
  getApprovedApplication,
  getPendingApplication,
  getRejectedApplication,
  getUserById,
  rejectApplication,
} from "../../../redux/Actions";
import moment from "moment";
import ApplicationModal from "../../../Components/ApplicationModal/ApplicationModal";
import RejectModal from "../../../Components/ApplicationModal/RejectModal";
import { SearchIcon, ViewIcon } from "../../../asset/icons/icons";



const tag = {
  padding: "3.867px 7.734px",
  alignItems: "center",
  gap: "0px",
  borderRadius: "348.021px",
  background: "#F4F1FD",
};

const usermanText = {
  width: "189px",
  flexShrink: 0,
  color: "#45464E",
  fontFamily: '"MADE TOMMY"',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "28px",
};

const Application = () => {
  const user_type_id = 3;
  const {
    allApplicant,
    isloadingAllApplicant,
    userById,
    approvedApplication,
    isloadingApprovedApplication,
    pendingApplication,
    isloadingPendingApplication,
    rejectedApplication,
    isloadingRejectedApplication,
    applicationRejected,
    isRejectingApplication,
  } = useSelector(getAuthReducer);
  const [open, setOpen] = useState(false);
  const [viewApplicationContent, setViewApplicationContent] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1");
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [formValues, setFormValues] = useState();
  // eslint-disable-next-line no-unused-vars
  const [formInstance, setFormInstance] = useState();
  const [link, setLink] = useState("");



  //functions
  const onCreate = (values) => {
    setFormValues(values);
    setOpen(false);
    setOpenRejectModal(false)
  };
  const handleOpenRejectModal = () => {
    // debugger
    setOpenRejectModal(true)
    setOpen(false)
  }
  const handleCloseRejectModal = () => {
    // debugger
    setOpenRejectModal(false)
    setOpen(true)
  }
  const showModal = (link) => {
    setIsModalOpen(true);
    setLink(link)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onTabKeyChange = (key) => {
    setSelectedTab(key);
  };
  const handleViewApprovalModal = (value) => {
    setViewApplicationContent(value);
    setOpen(true);
  };
  const handleApproveApplicant = (value) => {
    dispatch(getUserById(value, { status: 1 }));
    setOpen(false);
    dispatch(getAllIndependentDistributorship({ user_type_id }));
    dispatch(getPendingApplication());
    dispatch(getApprovedApplication());
  };
  const handleRejectApplicant = (value, values) => {
    // dispatch(getUserById(value, { status: 0 }));
    // setOpen(false);
    setOpenRejectModal(false)
    const payload = {
      user_id: value,
      message: values.message,
    }
    dispatch(rejectApplication(payload))




  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = (selectedTab === "1" ? allApplicant : selectedTab === "2" ? pendingApplication : selectedTab === "3" ? approvedApplication : rejectedApplication)?.data?.filter((item) => {
    // debugger
    const matchesSearchQuery = Object.keys(item).some((key) => {
      const value = item[key];
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).some((nestedValue) =>
          nestedValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
    });

    if (searchQuery.toLowerCase() === 'pending') {
      return item?.status === 0 && item?.is_rejected === 0;
    }
    if (searchQuery.toLowerCase() === 'approved') {
      return item?.status === 1 && item.is_rejected === 0;;
    }
    if (searchQuery.toLowerCase() === 'rejected') {
      return item.is_rejected === 1;
    }

    return matchesSearchQuery;
  });

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  //useEffects
  useEffect(() => {
    if (selectedTab === "1") {
      dispatch(getAllIndependentDistributorship({ user_type_id }));
    } else if (selectedTab === "2") {
      dispatch(getPendingApplication());
    } else if (selectedTab === "3") {
      dispatch(getApprovedApplication());
    } else {
      dispatch(getRejectedApplication());
    }
  }, [selectedTab, dispatch]);

  useEffect(() => {
    dispatch(getPendingApplication());
    dispatch(getApprovedApplication());
    dispatch(getRejectedApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userById.status === true || applicationRejected) {
      dispatch(getAllIndependentDistributorship({ user_type_id }));
      dispatch(getPendingApplication());
      dispatch(getApprovedApplication());
      dispatch(getRejectedApplication());
      setOpenRejectModal(false);
      setOpen(false);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userById, applicationRejected]);



  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (<span className="orderHistory-text">{text}</span>),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Applicant",
      dataIndex: "business",
      render: (text) => (
        <span className="orderHistory-text">
          {text?.name} <br />
        </span>
      ),
      sorter: (a, b) => a.business?.name.localeCompare(b.business?.name),
      onFilter: (value, record) => record?.business?.name?.indexOf(value) === 0,
    },
    {
      title: "Membership Type",
      dataIndex: "user_type",
      render: (text) => (
        <span className="orderHistory-text">
          {text?.name === "Independent Distributorship" ? "Independent Distributor" : text?.name} <br />
        </span>
      ),
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Category Of Business",
      dataIndex: "business",
      render: (text) => (
        <span className="orderHistory-text">
          {text?.nature_of_business} <br />
        </span>
      ),
      sorter: (a, b) => a.business?.nature_of_business.localeCompare(b.business?.nature_of_business),
    },
    {
      title: "Date Applied",
      dataIndex: "email_verified_at",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text).format("MMM Do YYYY")}
        </span>
      ),
      sorter: (a, b) => a.email_verified_at?.localeCompare(b?.email_verified_at),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const isRejected = record?.is_rejected === 1;
        const status = text;
        let statusClass = "";
        let displayText = "";

        if (isRejected) {
          statusClass = "orderHistory rejected";
          displayText = "Rejected";
        } else {
          switch (status) {
            case 1:
              statusClass = "orderHistory approved-green";
              displayText = "Approved";
              break;
            case 0:
              statusClass = "orderHistory pending";
              displayText = "Pending";
              break;
            default:
              statusClass = "orderHistory unknown";
              displayText = "Unknown";
          }
        }
        return (
          // <span key={text} className={statusClass}>
          //   {isRejected?"rejected":isPending?"pending":"approved"}
          // </span>
          <div className={statusClass}>{displayText}</div>
        );
      },
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Actions",
      dataIndex: "business",
      render: (text, record) => (
        <Button
          type="text"
          className="orderHistory-view-details"
          onClick={() => handleViewApprovalModal(record)}
        >
          <ViewIcon />
        </Button>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    // debugger
    const payload = {
      user_type_id: user_type_id,
      page: pagination?.current
    }
    if (selectedTab === "1") {
      dispatch(getAllIndependentDistributorship(payload));
    } else if (selectedTab === "2") {
      dispatch(getPendingApplication(pagination?.current))
    } else if (selectedTab === "3") {
      dispatch(getApprovedApplication(pagination?.current))
    } else {
      dispatch(getRejectedApplication(pagination?.current))
    }
  };

  //Tab Layout
  const items = [
    {
      key: "1",
      label: (
        <div style={{ color: "#252A4D" }}>
          All
          <span className="mx-2" style={tag}>
            {allApplicant?.meta?.total}
          </span>
        </div>
      ),
      children: (
        <div className="row mt-5 mb-4">
          <Spin spinning={isloadingAllApplicant} tip="Loading...">
            <Table
              columns={columns}
              // dataSource={allApplicant?.data}
              dataSource={filteredData}
              scroll={{ x: 1000 }}
              onChange={onChange}
              pagination={{
                pageSize: allApplicant?.meta?.per_page,
                showSizeChanger: false,
                pageSizeOptions: ["10", "20", "30", "40"],
                showQuickJumper: true,
                total: allApplicant?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ color: "#252A4D" }}>
          Pending
          <span className="mx-2" style={tag}>
            {pendingApplication?.meta?.total}
          </span>
        </div>
      ),
      children: (
        <div className="row mt-5 mb-4">
          <Spin spinning={isloadingPendingApplication} tip="Loading...">
            <Table
              columns={columns}
              // dataSource={pendingApplication?.data}
              scroll={{ x: 1000 }}
              dataSource={filteredData}
              onChange={onChange}
              pagination={{
                pageSize: pendingApplication?.meta?.per_page,
                showSizeChanger: false,
                pageSizeOptions: ["10", "20", "30", "40"],
                showQuickJumper: true,
                total: pendingApplication?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div style={{ color: "#252A4D" }}>
          Approved
          <span className="mx-2" style={tag}>
            {approvedApplication?.meta?.total}
          </span>
        </div>
      ),
      children: (
        <div className="row mt-5 mb-4">
          <Spin spinning={isloadingApprovedApplication} tip="Loading...">
            <Table
              columns={columns}
              // dataSource={approvedApplication?.data}
              scroll={{ x: 1000 }}
              dataSource={filteredData}
              onChange={onChange}
              pagination={{
                pageSize: approvedApplication?.meta?.per_page,
                showSizeChanger: false,
                pageSizeOptions: ["10", "20", "30", "40"],
                showQuickJumper: true,
                total: approvedApplication?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div style={{ color: "#252A4D" }}>
          Rejected
          <span className="mx-2" style={tag}>
            {rejectedApplication?.meta?.total}
          </span>
        </div>
      ),
      children: (
        <div className="row mt-5 mb-4">
          <Spin spinning={isloadingRejectedApplication} tip="Loading...">
            <Table
              columns={columns}
              // dataSource={rejectedApplication?.data}
              scroll={{ x: 1000 }}
              dataSource={filteredData}
              onChange={onChange}
              pagination={{
                pageSize: rejectedApplication?.meta?.per_page,
                showSizeChanger: false,
                pageSizeOptions: ["10", "20", "30", "40"],
                showQuickJumper: true,
                total: rejectedApplication?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          </Spin>
        </div>
      ),
    },
  ];


  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Applications</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="col-md-5 col-sm-5 col-12 mt-3" >
            <Input
              placeholder={selectedTab === "1" ? "Search applications" : selectedTab === "2" ? "Search pending applications" : selectedTab === "3" ? "Search approved applications" : "Search rejected applications"}
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <Tabs
          className="border p-1 mt-4  overflow-x-hidden"
          defaultActiveKey="1"
          items={items}
          onChange={onTabKeyChange}
        />
      </div>
      <pre>{JSON.stringify(formValues, null, 2)}</pre>

      <ApplicationModal
        open={open}
        onCreate={onCreate}
        viewApplicationContent={viewApplicationContent}
        handleRejectApplicant={handleRejectApplicant}
        handleApproveApplicant={handleApproveApplicant}
        setFormInstance={setFormInstance}
        onCancel={() => setOpen(false)}
        showModal={showModal}
        link={link}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        initialValues={{
          modifier: "public",
        }}
        handleCloseRejectModal={handleCloseRejectModal}
        handleOpenRejectModal={handleOpenRejectModal}
        openRejectModal={openRejectModal}
      />
      <RejectModal openRejectModal={openRejectModal} handleCloseRejectModal={handleCloseRejectModal} handleReject={handleRejectApplicant} applicant={viewApplicationContent} loading={isRejectingApplication} />
    </>
  );
};
export default Application;
