import React, { useEffect, useState } from "react";
import { Input, Modal, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { addRoleDetails, getAllAvailableLocation, getAllRoles, getAllSystemAdmin } from "../../redux/Actions";
import RolePermissionSettings from "./rolePermissionSetting";
import { getPermissionReducer } from "../../redux/Reducer/permissionReducer";

const UpdateRoleModal = (props) => {
  const { open, onCancel, type, formValues, onUpdate, resetForm, setIsPermissionUpdated } = props;
  const text = `${type === "view" ? "View" : "Update"} Role Permissions`;
  const subtext = (
    <span style={{ color: "#5d5d5d" }}>
      {type === "view" ? "View" : "Update"} the permissions for this role -{" "}
      <span style={{ color: "#2F355B" }}>{formValues?.name}</span>
    </span>
  );

  const { permissionData } = useSelector(getPermissionReducer);
  const { createEmployee, roleUpdating } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [roles, setRoles] = useState({});

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllAvailableLocation());
  }, [dispatch]);

  useEffect(() => {
    if (createEmployee.status === true) {
      dispatch(getAllSystemAdmin());
    }
  }, [createEmployee, dispatch]);

  useEffect(() => {
    if (open && formValues) {
      form.setFieldsValue({
        name: formValues?.name,
        description: formValues?.description,
        total_users: formValues?.total_users,
        users_assigned: formValues?.total_users === 0 ? "No users have been assigned" : ""
      });

      const initialRoles = formValues?.permissions?.reduce((acc, role) => {
        acc[role.permission_id] = role.status === 1;
        return acc;
      }, {});
      setRoles(initialRoles);
    }
  }, [form, open, formValues]);

  const handleEnableAllRoles = (checked) => {
    const newRolesState = permissionData.reduce((acc, role) => {
      acc[role.id] = checked;
      return acc;
    }, {});
    setRoles(newRolesState);
    if (type === "update") {
      setIsPermissionUpdated(true);
    }
  };

  const handleRoleChange = (roleId) => {
    setRoles((prevRoles) => ({
      ...prevRoles,
      [roleId]: !prevRoles[roleId],
    }));
    if (type === "update") {
      setIsPermissionUpdated(true);
    }
  };

  const onNext = () => {
    const updatedPermissionData = Object.keys(roles).map((roleId) => ({
      permission_id: parseInt(roleId, 10),
      status: roles[roleId],
    }));

    dispatch(addRoleDetails({
      permissions: updatedPermissionData,
    }));

    return updatedPermissionData;
  };

  return (
    <>
      <Modal
        open={open}
        title={type === "view" ? "View Role" : "Update Role"}
        okText={roleUpdating ? "Updating" : "Update"}
        cancelText="Cancel"
        onCancel={onCancel}
        afterClose={() => { form.resetFields(); type === "update" && resetForm(); setIsPermissionUpdated(false); }}
        destroyOnClose
        confirmLoading={roleUpdating}
        width={"800px"}
        styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
        centered
        onOk={async () => {
          try {
            const values = await form.validateFields();
            const updatedPermissions = await onNext();
            onUpdate(values, updatedPermissions);
          } catch (error) {
            // Handle error if any
          }
        }}
        okButtonProps={{
          style: { display: type === "view" && 'none', backgroundColor: "#252A4D" },
          autoFocus: true,
        }}
      >
        {contextHolder}
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            name: formValues?.name,
            description: formValues?.description,
            total_users: formValues?.total_users,
            users_assigned: formValues?.total_users === 0 ? "No users assigned" : ""
          }}
          name="form_in_modal"
          className="mt-3"
        >
          <div className="row">
            <div className="col">
              <Form.Item label="Role Name" name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
                <Input type="text" placeholder="Full Name" readOnly={type === "view"} style={{ cursor: type === "view" ? "not-allowed" : "pointer" }} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input the description' }]}>
                <Input type="text" placeholder="Please enter the description" readOnly={type === "view"} style={{ cursor: type === "view" ? "not-allowed" : "pointer" }} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Item label="Total Number of Users Assigned to this Role" name="total_users">
                <Input type="text" readOnly disabled />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <RolePermissionSettings
                roles={roles}
                handleEnableAllRoles={handleEnableAllRoles}
                handleRoleChange={handleRoleChange}
                text={text}
                subtext={subtext}
                presets={formValues?.permissions}
                form={form}
                type={type}
              />
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateRoleModal;
