import { ADD_ROLE_DETAILS, CREATE_ROLE_WITH_PERMISSION, CREATE_ROLE_WITH_PERMISSION_FAILURE, CREATE_ROLE_WITH_PERMISSION_SUCCESS, GET_PERMISSION, GET_PERMISSION_FAILURE, GET_PERMISSION_SUCCESS, RESTORE_ROLE_DETAILS, UPDATE_ROLE_PERMISSION, UPDATE_ROLE_PERMISSION_FAILURE, UPDATE_ROLE_PERMISSION_SUCCESS } from "../ActionTypes";
const INIT_STATE = {
    permissionData: [],
    isLoadingPermission: false,

    //ROLES WITH PERMISSION
    roleName: "",
    description: "",
    userAssigned: [],
    permissions: [],

    roleCreated: false,
    isCreatingRole: false,

    rolePermissionUpdated: false,
    isUpdatingRolePermission: false,
};
function PermissionReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_PERMISSION: {
            // debugger
            return {
                ...state,
                permissionData: [],
                isLoadingPermission: true
            }
        }
        case GET_PERMISSION_SUCCESS: {
            // debugger
            return {
                ...state,
                permissionData: action.payload,
                isLoadingPermission: false
            };
        }
        case GET_PERMISSION_FAILURE: {
            return {
                ...state,
                permissionData: [],
                isLoadingPermission: false,
            };
        }
        case ADD_ROLE_DETAILS:
            const { roleName, description, userAssigned, permissions } = action.payload;
            return {
              ...state,
              roleName: roleName ?? state.roleName,
              description: description ?? state.description,
              userAssigned: userAssigned ?? state.userAssigned,
              permissions: permissions ?? state.permissions
            };
          
        case RESTORE_ROLE_DETAILS: {
            // debugger
            return {
                ...state,
                roleName: "",
                description: "",
                userAssigned: [],
                permissions: [],
                roleCreated:false,
                isCreatingRole:false,
                rolePermissionUpdated: false,
                isUpdatingRolePermission: false,
            }
        }
        case CREATE_ROLE_WITH_PERMISSION: {
            // debugger
            return {
                ...state,
                roleCreated: false,
                isCreatingRole: true,
            }
        }
        case CREATE_ROLE_WITH_PERMISSION_SUCCESS: {
            // debugger
            return {
                ...state,
                roleCreated: true,
                isCreatingRole: false,
            };
        }
        case CREATE_ROLE_WITH_PERMISSION_FAILURE: {
            return {
                ...state,
                roleCreated: false,
                isCreatingRole: false,
            };
        }
        case UPDATE_ROLE_PERMISSION: {
            // debugger
            return {
                ...state,
                rolePermissionUpdated: false,
                isUpdatingRolePermission: true,
            }
        }
        case UPDATE_ROLE_PERMISSION_SUCCESS: {
            // debugger
            return {
                ...state,
                rolePermissionUpdated: true,
                isUpdatingRolePermission: false,
            };
        }
        case UPDATE_ROLE_PERMISSION_FAILURE: {
            return {
                ...state,
                rolePermissionUpdated: false,
                isUpdatingRolePermission: false,
            };
        }
        default:
            return state;
    }
}
export default PermissionReducer;

export const getPermissionReducer = state => state.permissionReducer